import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';
import styles from '../FeedbackSection/styles.module.css';

export const FeedbackUsefulPage = ({ disabled, handleFeedback }) => (
  <div className={styles.feedback_section__item_container}>
    <span className={styles.feedback_section__item}>
      ¿Te pareció útil esta página?
    </span>
    <div
      className={`${styles.feedback_section__item} ${
        disabled && styles.feedback_section__item_disabled
      }`}
    >
      <div>
        <ButtonLink
          id="feedback-si"
          title="Si"
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            if (!disabled) {
              handleFeedback();
            }
          }}
          aria-label="Me pareció util esta pagina. Confirmar."
        />
      </div>
      <div>
        <ButtonLink
          id="feedback-no"
          title="No"
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            if (!disabled) {
              handleFeedback();
            }
          }}
          aria-label="No me pareció util esta pagina."
        />
      </div>
    </div>
  </div>
);

FeedbackUsefulPage.propTypes = {
  disabled: PropTypes.bool,
  handleFeedback: PropTypes.func,
};
