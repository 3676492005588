import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Auth from 'utils/Auth';

import * as ROUTES from 'constants/RouterConstants';
import { showExternalHeader } from 'actions/ui';
import CardSpinner from 'components/CardSpinner';

const ciudadanoSinAutenticarse = WrappedComponent => {
  const ComponenteCheckearAgenteNoAutenticado = props => {
    const dispatch = useDispatch();

    const [esAgente, setEsAgente] = useState(null);
    const [ciudadanoLogueado, setCiudadanoLogueado] = useState(null);

    const checkCiudadanoLogueado = forceLogin => {
      if (forceLogin) {
        Auth.getInstance().cleanStorage();
        dispatch(showExternalHeader());
      }
      if (Auth.getInstance().isAuthenticated()) {
        setCiudadanoLogueado(true);
      } else {
        Auth.getInstance()
          .obtainToken()
          .then(() =>
            setCiudadanoLogueado(Auth.getInstance().isAuthenticated()),
          )
          .catch(() => setCiudadanoLogueado(false));
      }
    };

    useEffect(() => {
      const parsed = queryString.parse(window.location.search);
      setEsAgente(parsed.agnt ? parsed.agnt === '1' : null);
      const forceLogin = parsed.forceLogin ? parsed.forceLogin : null;
      checkCiudadanoLogueado(forceLogin);
    }, []);

    if (ciudadanoLogueado === null) {
      return <CardSpinner spin className="spinner__transparent" />;
    }
    if (ciudadanoLogueado) {
      if (esAgente) {
        window.location.replace(window.REACT_APP_AGENTES_HOST);
        return <CardSpinner spin className="spinner__transparent" />;
      }
      return <Redirect to={ROUTES.AUTOGESTION_IDP} />;
    }
    return <WrappedComponent {...props} />;
  };
  return ComponenteCheckearAgenteNoAutenticado;
};

export default ciudadanoSinAutenticarse;
