/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// utils
import { goToField, getPaisesOptions } from 'utils/utils';
import convertKeys from 'utils/convert-keys';

// constants
import * as ROUTES from 'constants/RouterConstants';
import { BENEFICIOS_REGISTRO, REGISTRATE } from 'constants/commonConstants';

// services
import { createCiudadanoFromInside } from 'services/apiServices';

// components
import cardStyles from 'components/Card/styles.module.css';
import titleCardStyles from 'components/TitleCard/styles.module.css';
import Card from 'components/Card';
import TitleCard from 'components/TitleCard';
import BotonVolver from 'components/BotonVolver';

// internal components
import RegistroContent from './registroContent';
import RegistroFooter from './registroFooter';

import styles from './registro.module.css';

const Registro = ({
  paises,
  fetchPaises,
  registroErrors,
  createCiudadano,
  ciudadanoCreated,
  next,
  clearRegistroErrors,
  handleDatosPersonales,
  fetchMisDatos,
}) => {
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [paisesOptions, setPaisesOptions] = useState([]);
  const [documentosOptions, setDocumentosOptions] = useState([]);
  const [alertLink, setAlertLink] = useState('');
  const [alertText, setAlertText] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const [captchaTouched, setCaptchaTouched] = useState(false);

  const recaptchaRef = useRef();

  useEffect(() => {
    if (!paises.length) {
      fetchPaises();
    }
  }, []);

  useEffect(() => {
    if (paises.length) {
      // eslint-disable-next-line no-shadow
      const { paisesOptions } = getPaisesOptions(paises);
      setPaisesOptions(paisesOptions);
    }
  }, [paises]);

  useEffect(() => {
    if (registroErrors && registroErrors.field) {
      goToField(registroErrors.field);
    }
  }, [registroErrors]);

  const TERMINOS_CONDICIONES = (
    <span className={styles['terminos-y-condiciones']}>
      Acepto los{' '}
      <a href={ROUTES.TERMS_N_CONDS_ROUTE}> Términos y Condiciones </a>y la
      <a href={ROUTES.PRIVACIDAD_ROUTE}> Política de Privacidad </a>
      de uso de mi usuario.
    </span>
  );

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      if (ciudadanoCreated && !handleDatosPersonales) {
        history.push(ROUTES.REGISTRO_SENDED_ACTIVATION_EMAIL);
      } else if (handleDatosPersonales) {
        fetchMisDatos();
        history.push(ROUTES.SEGURIDAD_ROUTE);
      } else if (next !== null) {
        window.location.href = next;
      } else if (recaptchaRef.current.getValue() !== '') {
        recaptchaRef.current.reset();
      }
      setSubmitted(false);
    }
  }, [ciudadanoCreated, submitted]);

  // no se chequean errores porque react-hook-form sólo llama onSubmit
  // si no hay errores en el form
  const onSubmit = async values => {
    if (handleDatosPersonales) {
      await createCiudadanoFromInside(
        handleDatosPersonales('id'),
        convertKeys.toSnake(values),
      );
    } else {
      await createCiudadano(convertKeys.toSnake(values));
    }
    setSubmitted(true);
  };

  const showAlert = (value, text, link) => {
    setAlert(value);
    setAlertText(text);
    setAlertLink(link);
  };

  const handleOnClickAlert = () => {
    if (alertLink === 'Identificate con usuario gub.uy') {
      window.location.href = '/login';
    } else {
      window.location.href = ''; // validar la cuenta
    }
  };

  return (
    <>
      <BotonVolver />
      <div className={styles.container_flex}>
        <div className={styles.registro__title__container}>
          <TitleCard
            className={titleCardStyles.titulo__registro}
            title={REGISTRATE}
          />
          <div className={styles.registro__headline}>{BENEFICIOS_REGISTRO}</div>
        </div>
        <Card
          className={cardStyles.card__registro}
          innerClassName={cardStyles.card__inner__registro}
        >
          <RegistroContent
            captchaTouched={captchaTouched}
            setCaptchaTouched={setCaptchaTouched}
            onSubmit={onSubmit}
            termsAndConds={TERMINOS_CONDICIONES}
            paisesOptions={paisesOptions}
            paises={paises}
            documentosOptions={documentosOptions}
            setDocumentosOptions={setDocumentosOptions}
            recaptchaRef={recaptchaRef}
            showAlert={showAlert}
            alert={alert}
            handleOnClickAlert={handleOnClickAlert}
            alertLink={alertLink}
            alertText={alertText}
            registroErrors={registroErrors}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            clearRegistroErrors={clearRegistroErrors}
            handleDatosPersonales={handleDatosPersonales}
          />
          <RegistroFooter />
        </Card>
      </div>
    </>
  );
};

export default Registro;
