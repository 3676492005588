import { axiosAPI } from './axiosBackend';
import Auth from './Auth';

export default class SAML {
  /*
  Método para solicitar el deslogueo global.
  */
  static sendLogoutRequest() {
    const headers = Auth.getInstance().isAuthenticated()
      ? { Authorization: `Bearer ${Auth.getInstance().getAuthToken()}` }
      : {};
    return axiosAPI({
      method: 'get',
      url: `${window.REACT_APP_BACKEND_HOST}/saml-logout-id-uruguay`,
      headers,
    }).then(response => {
      window.location.href = response.data;
    });
  }
}
