/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { activateAccount } from 'actions/registro';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import ButtonSection from 'components/ButtonSection';
import { SIGNUP_ACTIVATE_ACCOUNT_TITLE } from 'constants/pageTitles';

class RegistroActivateAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.activateAccount = this.activateAccount.bind(this);
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(SIGNUP_ACTIVATE_ACCOUNT_TITLE);
  }

  activateAccount() {
    const query = queryString.parse(this.props.location.search);
    const { uuid } = query;
    this.props.activateAccount({ uuid });
  }

  render() {
    if (this.props.loading) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={SIGNUP_ACTIVATE_ACCOUNT_TITLE} />
        <CardSmall
          title="Un último paso..."
          desc="Terminá de activar tu cuenta haciendo clic en el botón debajo:"
        >
          <ButtonSection
            textPrimary="Activar cuenta"
            handleClickPrimary={this.activateAccount}
          />
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.registro.activating,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  activateAccount,
  updateAriaLiveMessage,
})(RegistroActivateAccountContainer);
