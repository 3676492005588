/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import ButtonSolid from 'components/ButtonSolid';
import { RenderField, SelectField } from 'components/RenderField (Nuevo)';
import {
  USERNAME_FIELD_NAME,
  USERNAME_FIELD_TEXT,
  COUNTRY_FIELD_NAME,
  COUNTRY_FIELD_ARIA_LABEL,
  DOCTYPE_FIELD_NAME,
  DOCTYPE_FIELD_ARIA_LABEL,
  REQUERIDO,
  NO_RECIBISTE_CORREO,
  MFA_SOLICITAR_NUEVO_EMAIL,
  REESTABLECE_TU_CONTRASENA,
} from 'constants/commonConstants';

import {
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
  RESET_PASSWORD_ROUTE,
} from 'constants/RouterConstants';
import TextoMultiformato from 'components/TextoMultiformato';
import styles from './mfa.css';

// ------------------------------------------------------------
// Formulario documento extranjero
// ------------------------------------------------------------
const Form = props => {
  const {
    documentosOptions,
    errores,
    errors,
    handleChange,
    handleSubmit,
    hidden,
    loading,
    nextText,
    onChange,
    onChangePais,
    onChangeTipoDoc,
    paisesOptions,
    placeholder,
    setFieldValue,
    touched,
    values,
  } = props;

  const obtenerComponenteError = () => {
    switch (errores.code) {
      case 'account_no_activated':
        return (
          <div className="resolver-error-no-activado-contenedor">
            {errores.error}
            <div className="resolver-error-textos">
              <TextoMultiformato
                listaNormal={[`${NO_RECIBISTE_CORREO} `]}
                listaLink={[
                  [
                    MFA_SOLICITAR_NUEVO_EMAIL,
                    REGISTRO_REENVIAR_CORREO_ACTIVACION,
                  ],
                ]}
              />
            </div>
          </div>
        );
      case 'account_blocked':
        return (
          <div className="resolver-error-bloqueado-contenedor">
            {errores.error}
            <a className="resolver-error-link" href={RESET_PASSWORD_ROUTE}>
              {REESTABLECE_TU_CONTRASENA}
            </a>
          </div>
        );
      default:
        return (
          <div className="resolver-error-bloqueado-contenedor">
            {errores.error}
          </div>
        );
    }
  };

  return (
    <form
      style={
        (hidden ? { visibility: 'hidden' } : null,
        { display: 'flex', flexDirection: 'column' })
      }
      onSubmit={handleSubmit}
    >
      <div className="mfa__foreign-container">
        <div
          className="mfa__foreign-container__top-field"
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '16px',
          }}
        >
          <span className="mfa__foreign-container__top-field__title">
            País emisor
          </span>
          <SelectField
            className="mfa__select"
            name={COUNTRY_FIELD_NAME}
            data={paisesOptions}
            // label={COUNTRY_FIELD_TEXT}
            label=""
            onChange={handleChange}
            onChangeCustom={e => onChangePais(e, setFieldValue)}
            ariaLabel={COUNTRY_FIELD_ARIA_LABEL}
            error={errors.pais_emisor}
            value={values.pais_emisor}
            touched={touched.pais_emisor}
            notTwoPoints
            style={{ marginTop: '0' }}
          />
        </div>

        <div
          className="mfa__foreign-container__bottom-field"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="mfa__foreign-container__bottom-field__fields">
            <div className="mfa__foreign-container__bottom-field__fields__field-container">
              <span className="mfa__foreign-container__bottom-field__title">
                Tipo de documento
              </span>
              <SelectField
                className="mfa__select mfa__foreign-container__bottom-field__fields__documento"
                name={DOCTYPE_FIELD_NAME}
                data={documentosOptions}
                label=""
                onChange={handleChange}
                onChangeCustom={onChangeTipoDoc}
                ariaLabel={DOCTYPE_FIELD_ARIA_LABEL}
                error={errors.tipo_documento}
                value={values.tipo_documento}
                touched={touched.tipo_documento}
                notTwoPoints
              />
            </div>
            <div className="mfa__foreign-container__bottom-field__fields__field-container">
              <span className="mfa__foreign-container__bottom-field__title mfa__foreign-container__bottom-field__fields__numero">
                Número
              </span>
              <RenderField
                name={USERNAME_FIELD_NAME}
                className=""
                type="text"
                label=""
                onChange={handleChange}
                resetFieldError={onChange}
                placeholder={placeholder}
                disabled={loading}
                aria-label={USERNAME_FIELD_TEXT}
                autocomplete="off"
                error={errors.username}
                value={values.username}
                touched={touched.username}
                notTwoPoints
              />
            </div>
          </div>
        </div>
      </div>
      {errores.error && obtenerComponenteError()}
      <div className="mfa__action-buttons__foreign">
        <ButtonSolid
          className="mfa__action-buttons__foreign__continuar"
          text={nextText}
          isSubmit
          isLoading={loading}
          isMFA
        />
      </div>
    </form>
  );
};

const MFAForeignForm = withFormik({
  mapPropsToValues: props => ({
    username: props.valueUsername,
    pais_emisor: props.valuePais,
    tipo_documento: props.valueTipoDoc,
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string().required(REQUERIDO),
  }),

  handleSubmit: (values, bag) => {
    if (!bag.props.loading) {
      bag.props.handleNext();
    }
  },
})(Form);

const MFAForeignField = ({
  title,
  loading,
  placeholder,
  onChangePais,
  onChangeTipoDoc,
  onChange,
  nextText,
  handleNext,
  previousText,
  handlePrevious,
  paisesOptions,
  documentosOptions,
  errores,
  hidden,
  valuePais,
  valueTipoDoc,
  valueUsername,
}) => (
  <div className="mfa__login__foreign">
    <h2 className="mfa__login__foreign__title">{title}</h2>
    <div className="mfa-container mfa-foreign mfa__login__foreign__contenido">
      {hidden ? (
        <Spinner
          className="mfa__spin-class"
          name="ball-spin-fade-loader"
          fadeIn="none"
          color={styles.blue}
        />
      ) : null}
      <MFAForeignForm
        placeholder={placeholder}
        onChange={onChange}
        onChangePais={onChangePais}
        onChangeTipoDoc={onChangeTipoDoc}
        paisesOptions={paisesOptions}
        documentosOptions={documentosOptions}
        errores={errores}
        nextText={nextText}
        handleNext={handleNext}
        loading={loading}
        hidden={hidden}
        valuePais={valuePais}
        valueTipoDoc={valueTipoDoc}
        valueUsername={valueUsername}
        previousText={previousText}
        handlePrevious={handlePrevious}
      />
    </div>
  </div>
);

MFAForeignField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  valuePais: PropTypes.string,
  valueTipoDoc: PropTypes.string,
  valueUsername: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onChangePais: PropTypes.func,
  onChangeTipoDoc: PropTypes.func,
  nextText: PropTypes.string,
  handleNext: PropTypes.func,
  previousText: PropTypes.string,
  handlePrevious: PropTypes.func,
  paisesOptions: PropTypes.array,
  documentosOptions: PropTypes.array,
  errores: PropTypes.object,
  hidden: PropTypes.bool,
};

export default MFAForeignField;
