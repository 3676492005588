import React from 'react';

import styles from './styles.module.css';

const Line = () => (
  <div className={styles.vector_container}>
    <div className={styles.vector} />
  </div>
);

export default Line;
