/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { FEEDBACK_FORM_SENT_TITLE } from 'constants/pageTitles';
import CardSuccess from 'components/CardSuccess';

class ContactoEnviadoContainer extends React.Component {
  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(FEEDBACK_FORM_SENT_TITLE);
  }

  render() {
    return (
      <>
        <Helmet title={FEEDBACK_FORM_SENT_TITLE} />
        <CardSuccess
          title="Su opinión fue enviada"
          description="Muchas gracias."
        />
      </>
    );
  }
}

export default connect(null, {
  showExternalHeader,
  updateAriaLiveMessage,
})(ContactoEnviadoContainer);
