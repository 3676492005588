import { FETCH_PAGINA_ESTATICA } from 'constants/ActionTypes';

export const INITIAL_STATE = {
  datos: null,
  loading: true,
};

// eslint-disable-next-line default-param-last
export default function paginaEstaticaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // FETCH_PAGINA_ESTATICA
    // -----------------------------------------------------------------------
    case FETCH_PAGINA_ESTATICA.SUCCESS:
      return {
        ...state,
        datos: action.payload.data,
        loading: false,
      };

    case FETCH_PAGINA_ESTATICA.ERROR:
      return {
        ...state,
        datos: null,
        loading: false,
      };

    case FETCH_PAGINA_ESTATICA.PENDING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
