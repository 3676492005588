import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const InfoItem = ({ label, labelClass, content, contentClass }) => (
  <div className={styles.item}>
    <div className={labelClass ? `${styles.left} ${labelClass}` : styles.left}>
      {label}
    </div>
    <div
      className={
        contentClass ? `${styles.right} ${contentClass}` : styles.right
      }
    >
      {content}
    </div>
  </div>
);

InfoItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  labelClass: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contentClass: PropTypes.string,
};

export default InfoItem;
