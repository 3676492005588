/* eslint-disable react/prop-types */
import React from 'react';
import ModalBox from 'components/Modal';
import ButtonSection from '../ButtonSection';

const ModalConfirmarAccion = props => (
  <ModalBox
    texto={props.text}
    titulo={props.title}
    abierto={props.isOpen}
    onClose={props.onClose}
    cargando={props.loading}
  >
    <ButtonSection
      isSubmit
      textPrimary={props.buttonTextPositiveAction}
      ariaLabelPrimary={props.buttonAriaLabelPositiveAction}
      handleClickPrimary={props.handleSubmitPositiveAction}
      textSecondary={props.buttonTextNegativeAction}
      ariaLabelSecondary={props.buttonAriaLabelNegativeAction}
      handleClickSecondary={props.handleSubmitNegativeAction}
      isDangerPrimary={props.isDangerPrimary}
    />
  </ModalBox>
);

export default ModalConfirmarAccion;
