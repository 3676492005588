import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import appStyles from '../../index.module.css';

const CheckboxField = ({
  register,
  name,
  required,
  disabled,
  content,
  label,
  error,
}) => (
  <div className={`${styles['render-field']} ${styles['checkbox-field']}`}>
    <div className={styles['checkbox-field__container']}>
      <div className={styles['checkbox-field__input']}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(name, { required })}
          type="checkbox"
          disabled={disabled}
          id={name}
          aria-label={label}
          className={styles['checkbox-field__checkbox']}
        />
      </div>
      <div className={styles['checkbox-field__label']}>
        <label className={appStyles.caption} htmlFor={name}>
          {content}
        </label>
      </div>
    </div>
    {error && error.type === 'required' && (
      <div
        className={`${styles['render-field__help-text__error']} ${styles['checkbox-field__error']}`}
      >
        Requerido
      </div>
    )}
  </div>
);

CheckboxField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  content: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.object,
};

export default CheckboxField;
