/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CardSmall from 'components/CardSmall';
import ButtonSection from 'components/ButtonSection';
import ButtonLink from 'components/ButtonLink';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import {
  AYUDA_ROUTE,
  CONTACTO_ROUTE,
  RESET_PASSWORD_ROUTE,
} from 'constants/RouterConstants';
import { RESET_PASSWORD_INVALID_LINK_TITLE } from 'constants/pageTitles';

const ResetPasswordLinkInvalidoContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(RESET_PASSWORD_INVALID_LINK_TITLE));
  }, []);

  const buttonCallback = () => {
    history.push(RESET_PASSWORD_ROUTE);
  };

  return (
    <>
      <Helmet title={RESET_PASSWORD_INVALID_LINK_TITLE} />
      <CardSmall
        title="Ups, ocurrió un problema..."
        desc="Al parecer el vínculo no existe o expiró y ya no es válido. 
                Para continuar te recomendamos solicitar uno nuevo."
      >
        <ButtonSection
          textPrimary="Solicitar vínculo nuevo"
          ariaLabelPrimary="Solicitar vínculo nuevo para restablecer contraseña"
          handleClickPrimary={buttonCallback}
        />
        <div className="instrucciones__divider"></div>
        <div className="caption">
          <span>Por más información visita nuestra sección de </span>
          <ButtonLink to={AYUDA_ROUTE} title="Preguntas Frecuentes" />
          <span> o </span>
          <ButtonLink to={CONTACTO_ROUTE} title="comunicate con nosotros." />
        </div>
      </CardSmall>
    </>
  );
};

export default ResetPasswordLinkInvalidoContainer;
