import React from 'react';
import PropTypes from 'prop-types';

import { isMobile, formatFullDate } from 'utils/utils';
import ButtonOutline from 'components/ButtonOutline';
import Box from 'components/Box';
import ButtonLink from 'components/ButtonLink';
import {
  INFO_SEC_APLICACIONES_BUTTON,
  INFO_SEC_APLICACIONES_ARIA_LABEL,
  INFO_SEC_APLICACIONES_TITLE,
} from 'constants/commonConstants';
import { AYUDA_ROUTE } from 'constants/RouterConstants';
import './servicioAsociado.css';

const ServicioAsociado = ({
  title,
  logo,
  accessInfo,
  date,
  buttonCallback,
}) => (
  <section className="servicio-asociado">
    <div className="servicio-asociado__separator"></div>
    <div className="servicio-asociado__logo">
      <img src={logo} alt={`Logo ${title}`} />
    </div>
    <div className="servicio-asociado__left">
      <div className="servicio-asociado__left__title">{title}</div>
      <div className="servicio-asociado__left__scopes">{accessInfo}</div>
      <div className="servicio-asociado__left__dateTime">
        Acceso concedido: {date}
      </div>
    </div>
    <div className="servicio-asociado__right">
      <ButtonOutline
        text={INFO_SEC_APLICACIONES_BUTTON}
        handleClick={buttonCallback}
        isSmall
        ariaLabel={INFO_SEC_APLICACIONES_ARIA_LABEL}
      />
    </div>
  </section>
);

ServicioAsociado.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.any,
  accessInfo: PropTypes.string,
  date: PropTypes.string,
  buttonCallback: PropTypes.func,
};

const BoxServiciosAsociados = ({
  aplicacionesConConsentimiento,
  retirarAcceso,
}) => {
  const accessText = aplicacion => {
    if (aplicacion.scopes.length === 1) {
      return `Tiene acceso a ${aplicacion.scopes[0].name.toLowerCase()}`;
    }
    if (aplicacion.scopes.length > 1) {
      return `Tiene acceso a ${aplicacion.scopes.map(
        scope => ` ${scope.name.toLowerCase()}`,
      )}`;
    }
    return '';
  };

  const serviciosAsociados = aplicacionesConConsentimiento.map(
    (aplicacion, index) => (
      <ServicioAsociado
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        title={aplicacion.client.name}
        logo={aplicacion.client.logo}
        accessInfo={accessText(aplicacion)}
        date={formatFullDate(aplicacion.date_given)}
        buttonCallback={() =>
          retirarAcceso(aplicacion.client.name, aplicacion.id)
        }
      />
    ),
  );

  return (
    <Box title={INFO_SEC_APLICACIONES_TITLE}>
      <div style={isMobile() ? { paddingBottom: '8px' } : {}}>
        {aplicacionesConConsentimiento.length > 0
          ? 'Le diste permiso a estas aplicaciones para acceder a tu Usuario gub.uy. '
          : 'No tenés ninguna aplicación conectada a tu cuenta. '}
        <ButtonLink
          to={AYUDA_ROUTE}
          title="Más información »"
          aria-label="Más información"
          external
          newTab
        />
      </div>
      {serviciosAsociados}
    </Box>
  );
};

BoxServiciosAsociados.propTypes = {
  aplicacionesConConsentimiento: PropTypes.array,
  retirarAcceso: PropTypes.func,
};

export default BoxServiciosAsociados;
