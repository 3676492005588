/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { totpSelector } from 'store/selectors';
import CardSpinner from 'components/CardSpinner';
import {
  MFA_VERIFY_CODE_TOTP_ROUTE,
  MFA_PAGE_ROUTE,
} from 'constants/RouterConstants';
import {
  MFA_TOTP_CARD_TITLE_TEXT,
  MFA_TOTP_CARD_DESC_TEXT,
} from 'constants/commonConstants';
import TOTPCard from './totpCard';

const TOTPInfoContainer = () => {
  const history = useHistory();
  const totp = useSelector(totpSelector);

  useEffect(() => {
    if (!totp) {
      history.push(MFA_PAGE_ROUTE);
    }
  }, []);

  const handleContinue = e => {
    e.preventDefault();
    history.push(MFA_VERIFY_CODE_TOTP_ROUTE);
  };

  const handleBack = e => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div>
      {totp ? (
        <TOTPCard
          title={MFA_TOTP_CARD_TITLE_TEXT}
          desc={MFA_TOTP_CARD_DESC_TEXT}
          code={totp.secret}
          url={totp.url}
          handleContinue={handleContinue}
          handleBack={handleBack}
        />
      ) : (
        <CardSpinner spin />
      )}
    </div>
  );
};

export default TOTPInfoContainer;
