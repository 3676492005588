import { CLIENT_DETAIL, ACCEPT_CONSENT } from 'constants/ActionTypes';

const INITIAL_STATE = {
  client: null,
  acceptConsent: null,
  loading: true,
};
// eslint-disable-next-line default-param-last
export default function brokerOidcReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLIENT_DETAIL.SUCCESS: {
      return {
        ...state,
        client: action.payload.data,
        loading: false,
      };
    }
    case CLIENT_DETAIL.ERROR:
      return {
        ...state,
        client: {},
        loading: false,
      };
    case CLIENT_DETAIL.PENDING:
      return {
        ...state,
        loading: true,
      };

    case ACCEPT_CONSENT.SUCCESS: {
      return {
        ...state,
        acceptConsent: true,
        loading: false,
      };
    }
    case ACCEPT_CONSENT.ERROR:
      return {
        ...state,
        acceptConsent: false,

        loading: false,
      };
    case ACCEPT_CONSENT.PENDING:
      return {
        ...state,
        loading: true,
      };
    // DEFAULT
    default:
      return state;
  }
}
