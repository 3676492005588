/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  CHANGE_PASSWORD_ROUTE_SUCCESS,
  VERIFY_PASSWORD_ROUTE,
  SEGURIDAD_ROUTE,
} from 'constants/RouterConstants';

import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import {
  updateUserPassword,
  clearPasswordChangeErrors,
} from 'actions/seguridad';
import CambioContrasena from 'components/CambioContrasena';
import CardSmall from 'components/CardSmall';
import { resetFieldError } from 'utils/utils';
import { CHANGE_PASSWORD_TITLE } from 'constants/pageTitles';

class CambioContraseñaContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      field: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.resetFieldError = resetFieldError.bind(this);
    this.handleResetFieldError = this.handleResetFieldError.bind(this);
  }

  componentDidMount() {
    // Si necesita authForPasswordChange se hace un redirect a un metodo de auth.
    // Por defecto la contraseña.
    if (this.props.authForPasswordChange && !this.props.verifiedPassword) {
      this.props.history.replace({
        pathname: VERIFY_PASSWORD_ROUTE,
        state: {
          prevPath: this.props.location.pathname,
          basePath: SEGURIDAD_ROUTE,
        },
      });
    } else {
      this.props.showExternalHeader();
      this.props.updateAriaLiveMessage(CHANGE_PASSWORD_TITLE);
    }
  }

  componentWillUnmount() {
    this.props.clearPasswordChangeErrors();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.startedPasswordChange === null &&
      nextProps.successfulPasswordChange === null
    ) {
      this.props.history.push(CHANGE_PASSWORD_ROUTE_SUCCESS);
    }

    if (nextProps.passwordChangeErrors) {
      this.setState({
        error: nextProps.passwordChangeErrors.error,
        field: nextProps.passwordChangeErrors.field,
      });
    }
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  async handleSubmit(values) {
    if (!this.props.loading) {
      await this.props.updateUserPassword(
        values.password,
        values.repetir_password,
      );
      this.props.history.push(CHANGE_PASSWORD_ROUTE_SUCCESS);
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.props.history.push(SEGURIDAD_ROUTE);
  }

  handleResetFieldError() {
    if (this.state.error) {
      this.setState({
        error: null,
        field: null,
      });
    }
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    return (
      <>
        <Helmet title={CHANGE_PASSWORD_TITLE} />
        <CardSmall>
          <CambioContrasena
            title="Modificá tu contraseña"
            description="Creá una nueva contraseña para iniciar sesión."
            labelPassword="Nueva contraseña"
            labelRepeatPassword="Repetir nueva contraseña"
            handleSubmitForm={this.handleSubmit}
            resetFieldError={this.handleResetFieldError}
            handlePrevious={this.handleBack}
            error={this.state.error}
            field={this.state.field}
            currentUser={this.props.currentUser}
            {...this.props}
          />
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.seguridad.loading,
  startedPasswordChange: state.seguridad.startedPasswordChange,
  successfulPasswordChange: state.seguridad.successfulPasswordChange,
  authForPasswordChange: state.seguridad.authForPasswordChange,
  verifiedPassword: state.seguridad.verifiedPassword,
  passwordChangeErrors: state.seguridad.passwordChangeErrors,
  currentUser: state.mis_datos.currentUser,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  updateUserPassword,
  clearPasswordChangeErrors,
  updateAriaLiveMessage,
})(CambioContraseñaContainer);
