import {
  API_REQUEST,
  FETCH_PAISES_URL,
  CIUDADANO_CREATE_URL,
  RESEND_ACTIVATION_EMAIL_URL,
  ACTIVATE_ACCOUNT_URL,
} from 'constants/ApiConstants';
import {
  FETCH_PAISES,
  CREATE_CIUDADANO,
  RESEND_ACTIVATION,
  CLEAR_REGISTRO_ERRORS,
  RESET,
  ACTIVATE_ACCOUNT,
} from 'constants/ActionTypes';

export const fetchPaises = () => ({
  type: API_REQUEST,
  payload: {
    url: `${FETCH_PAISES_URL}`,
    method: 'get',
    next: FETCH_PAISES,
  },
});

export const createCiudadano = data => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_CREATE_URL,
    method: 'post',
    data,
    next: CREATE_CIUDADANO,
  },
});

export const reenvioEmailActivacion = (
  doc,
  paisEmisor,
  tipoDoc,
  captchaResponse,
) => ({
  type: API_REQUEST,
  payload: {
    url: RESEND_ACTIVATION_EMAIL_URL,
    method: 'post',
    data: {
      doc,
      pais_emisor: paisEmisor,
      tipo_doc: tipoDoc,
      captcha_response: captchaResponse,
    },
    next: RESEND_ACTIVATION,
  },
});

export const clearRegistroErrors = () => ({
  type: CLEAR_REGISTRO_ERRORS,
  payload: {
    next: CLEAR_REGISTRO_ERRORS,
  },
});

export const resetRegistro = () => ({
  type: RESET,
});

export const activateAccount = data => ({
  type: API_REQUEST,
  payload: {
    url: ACTIVATE_ACCOUNT_URL,
    method: 'post',
    data,
    next: ACTIVATE_ACCOUNT,
  },
});
