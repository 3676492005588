import React, { useState } from 'react';

import { FeedbackUsefulPage } from '../FeedbackUsefulPage';
import { FeedbackPageForm } from '../FeedbackPageForm';
import styles from './styles.module.css';

export const FeedbackSection = () => {
  const [disabled, setDisabled] = useState(false);

  const handleFeedback = () => {
    if (!disabled) {
      setDisabled(!disabled);
    }
  };

  return (
    <div className={styles.feedback_section}>
      <div className={styles.feedback_section_wrapper}>
        <FeedbackUsefulPage
          disabled={disabled}
          handleFeedback={handleFeedback}
        />
        <FeedbackPageForm />
      </div>
    </div>
  );
};
