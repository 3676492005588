/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import CardSuccess from 'components/CardSuccess';
import ButtonLink from 'components/ButtonLink';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { clearRegistroErrors } from 'actions/registro';
import { resendActivationEmailLogged } from 'actions/seguridad';
import Auth from 'utils/Auth';
import {
  CONTACTO_ROUTE,
  RESENDED_ACTIVATION_EMAIL_LOGGED,
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
} from 'constants/RouterConstants';
import { SIGNUP_LINK_SENT_TITLE } from 'constants/pageTitles';
import {
  NO_RECIBISTE_CORREO,
  RESEND_EMAIL_LINK_TEXT,
  CONTACTO_LINK_TEXT,
} from 'constants/commonConstants';
import { isAuthenticatedSelector } from 'store/selectors';

const RegistroMailEnviadoContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    dispatch(clearRegistroErrors());
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(SIGNUP_LINK_SENT_TITLE));
    return () => dispatch(showInternalHeader());
  }, []);

  const handleReenviarActivacionCuenta = e => {
    e.preventDefault();
    if (isAuthenticated) {
      dispatch(resendActivationEmailLogged(Auth.getInstance().getUserId()));
      history.push(RESENDED_ACTIVATION_EMAIL_LOGGED);
    } else {
      history.push(REGISTRO_REENVIAR_CORREO_ACTIVACION);
    }
  };

  return (
    <>
      <Helmet title={SIGNUP_LINK_SENT_TITLE} />
      <CardSuccess
        title="Tu usuario fue creado correctamente"
        description="Para continuar, verificá tu cuenta con el correo electrónico
                       que enviamos a tu casilla."
        extra1="Puede encontrarse entre los correos no deseados (SPAM)."
        extra2={
          <div>
            <p>
              {NO_RECIBISTE_CORREO}
              <ButtonLink
                title={RESEND_EMAIL_LINK_TEXT}
                onClick={handleReenviarActivacionCuenta}
              />
            </p>
            <p>
              ¿Estás teniendo problemas?
              <ButtonLink title={CONTACTO_LINK_TEXT} to={CONTACTO_ROUTE} />
            </p>
          </div>
        }
      />
    </>
  );
};

export default RegistroMailEnviadoContainer;
