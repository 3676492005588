import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const ButtonFlat = ({
  text,
  handleOnClick,
  className = '',
  // eslint-disable-next-line react/prop-types
  desktop = false,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={`${
      desktop ? 'button-flat-desktop ' : 'button-flat-mobile '
    }${className}`}
    onClick={handleOnClick}
  >
    <span className="button-flat__text">{text}</span>
  </button>
);

ButtonFlat.propTypes = {
  text: PropTypes.string,
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
};

export default ButtonFlat;
