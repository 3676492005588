/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import {
  ERROR_CODES,
  TITLE_IDP_ERROR,
  ERRORES_ABITAB,
} from 'constants/ErrorPageConstants';
import {
  showExternalHeader,
  showInternalHeader,
  hideFooter,
  hideHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { ERROR_404_TITLE } from 'constants/pageTitles';
import ErrorPage from './errorPage';
import ErrorPageVertical from './errorPageVertical';
import ErrorPageAbitab from './errorPageAbitab';

const ErrorPageContainer = ({
  ocultarFooter,
  ocultarHeader,
  actualizarAriaLiveMessage,
}) => {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [descripcionConUrlRegistro, setDescripcionConUrlRegistro] =
    useState('');
  const [descripcionSinUrlRegistro, setDescripcionSinUrlRegistro] =
    useState('');
  const [textoBoton, setTextoBoton] = useState('');
  const [tituloPagina, setTituloPagina] = useState('');
  const [imagenes, setImagenes] = useState([]);
  const [altImagen, setAltImagen] = useState('');
  const [handleClick, setHandleClick] = useState(null);
  const [params, setParams] = useState(null);
  const [horizontal, setHorizontal] = useState(false);
  const [errorAbitab, setErrorAbitab] = useState(false);
  const [textoBotonAbitab, setTextoBotonAbitab] = useState(null);
  const [handleBotonAbitab, setHandleBotonAbitab] = useState(null);
  const [urlRegistro, setUrlRegistro] = useState(null);

  const setErrorPage = data => {
    setTitulo(data.title);
    setDescripcion(data.description);
    setDescripcionSinUrlRegistro(data.description_sin_url_registro);
    setDescripcionConUrlRegistro(data.description_con_url_registro);
    setTextoBoton(data.buttonText);
    setTituloPagina(data.pageTitle);
    setImagenes(data.images);
    setAltImagen(data.altImg);
    setHandleClick(() => data.callback);
    setHorizontal(data.horizontal === undefined);
  };

  useEffect(() => {
    ocultarHeader();
    ocultarFooter();
    actualizarAriaLiveMessage(ERROR_404_TITLE);

    const parsed = queryString.parse(window.location.search);
    const errorCode = parsed?.errorCode;

    const map = new Map(Object.entries(ERROR_CODES));
    const errorPageData = map.has(errorCode)
      ? map.get(errorCode)
      : map.get('DEFAULT_ERROR');
    setErrorPage(errorPageData);

    if (errorCode && errorCode === 'IDP_ERROR') {
      const errorDescription = parsed?.error_description;
      const esErrorAbitab =
        errorDescription && ERRORES_ABITAB.includes(errorDescription);
      // Si no es error de abitab, debe tener email de contacto
      if (parsed?.process_state && (esErrorAbitab || parsed?.contacto)) {
        let queryParams = `process_state=${parsed?.process_state}`;
        if (parsed?.nid) {
          queryParams = `${params}&nid=${parsed?.nid}`;
        } else if (parsed?.ae) {
          queryParams = `${params}&ae=${parsed?.ae}`;
        }
        if (esErrorAbitab) {
          // Errores de abitab
          setParams(queryParams);
          setErrorAbitab(true);
          setTitulo(errorPageData?.abitab?.titulo);
          setDescripcion(errorPageData?.abitab?.descripcionError);
          setTextoBoton(errorPageData?.abitab?.textoBoton);
          setTextoBotonAbitab(errorPageData?.abitab?.textoRedireccion);
          setHandleBotonAbitab(() => errorPageData?.abitab?.handleRedireccion);
        } else {
          // Otros errores del IDP
          setParams(queryParams);
          setDescripcionSinUrlRegistro(
            `${errorPageData.description_sin_url_registro}${parsed.contacto}.`,
          );
          setDescripcionConUrlRegistro(
            `${errorPageData.description_con_url_registro}${parsed.contacto} o registrarte `,
          );
          setUrlRegistro(parsed?.url_registro);
        }
      } else {
        const errorDefaultPageData = map.get('DEFAULT_ERROR');
        setErrorPage(errorDefaultPageData);
      }
    }
  }, []);

  const paginaError = () => {
    if (errorAbitab) {
      return (
        <ErrorPageAbitab
          titulo={titulo}
          descripcion={descripcion}
          textoBotonAtras={textoBoton}
          handleBotonAtras={() => handleClick(params)}
          textoBotonAbitab={textoBotonAbitab}
          handleBotonAbitab={handleBotonAbitab}
        />
      );
    }
    if (!horizontal) {
      return (
        <ErrorPageVertical
          titulo={titulo}
          descripcion={descripcion}
          imagen={imagenes}
          altImagen={altImagen}
        />
      );
    }
    return (
      <ErrorPage
        titulo={titulo}
        descripcion={descripcion}
        descripcionSinUrlRegistro={descripcionSinUrlRegistro}
        descripcionConUrlRegistro={descripcionConUrlRegistro}
        urlRegistro={urlRegistro}
        imagen={imagenes}
        altImagen={altImagen}
        textoBoton={textoBoton}
        handleBoton={
          titulo === TITLE_IDP_ERROR ? () => handleClick(params) : handleClick
        }
      />
    );
  };

  return (
    <>
      <Helmet title={tituloPagina} />
      {paginaError()}
    </>
  );
};

const mapStateToProps = state => ({
  actualPath: state.router.location.pathname,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  ocultarFooter: hideFooter,
  ocultarHeader: hideHeader,
  actualizarAriaLiveMessage: updateAriaLiveMessage,
})(ErrorPageContainer);
