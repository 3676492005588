import {
  ACTIVATE_TOTP,
  VERIFY_CODE_TOTP,
  MFA_RESET,
  FETCH_MFA,
  DELETE_TOTP,
  RESEND_RECOVERY_CODE_EMAIL,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  estado: {},
  methods: {},
  errors: null,
  loading: false,
};
// eslint-disable-next-line default-param-last
export default function mfaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVATE_TOTP.SUCCESS: {
      return {
        ...state,
        methods: { ...state.methods, totp: action.payload.data },
        errors: null,
        loading: false,
      };
    }
    case ACTIVATE_TOTP.ERROR:
      return {
        ...state,
        errors: action.payload.error,
        loading: false,
      };
    case ACTIVATE_TOTP.PENDING:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_CODE_TOTP.SUCCESS: {
      return {
        ...state,
        errors: null,
        loading: false,
      };
    }
    case VERIFY_CODE_TOTP.ERROR:
      return {
        ...state,
        errors: action.payload.error,
        loading: false,
      };
    case VERIFY_CODE_TOTP.PENDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MFA.SUCCESS: {
      return {
        ...state,
        estado: action.payload.data.estado,
        methods: action.payload.data.methods,
        loading: false,
      };
    }
    case FETCH_MFA.ERROR:
      return {
        ...state,
        errors: action.payload.error,
        loading: false,
      };
    case FETCH_MFA.PENDING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TOTP.SUCCESS: {
      return {
        ...state,
        estado: { ...state.estado, activado: false, fecha_activado: null },
        methods: {},
        loading: false,
      };
    }
    case DELETE_TOTP.ERROR:
      return {
        ...state,
        errors: action.payload.error,
        loading: false,
      };
    case DELETE_TOTP.PENDING:
      return {
        ...state,
        loading: true,
      };

    case MFA_RESET:
      return {
        ...state,
        errors: null,
      };

    case RESEND_RECOVERY_CODE_EMAIL.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case RESEND_RECOVERY_CODE_EMAIL.ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESEND_RECOVERY_CODE_EMAIL.PENDING:
      return {
        ...state,
        loading: true,
      };

    // DEFAULT
    default:
      return state;
  }
}
