/* eslint-disable react/prop-types */
import React from 'react';

import styles from './styles.module.css';

const ErrorHelpText = ({ error, msg, small }) => (
  <div
    className={
      small
        ? styles['form-field__help-text__error__div--small']
        : styles['form-field__help-text__error__div']
    }
  >
    {error && (
      <span
        className={
          small
            ? styles['form-field__help-text__error--small']
            : styles['form-field__help-text__error']
        }
      >
        {msg}
      </span>
    )}
  </div>
);

export default ErrorHelpText;
