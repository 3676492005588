import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// api services
import {
  crearConsultaVideollamada,
  obtenerHorasConsultaPorFecha,
  obtenerDiasConsultaDisponibles,
} from 'services/apiServices';

// constantes
import { CONTACT_VIDEOLLAMADA_TITLE } from 'constants/pageTitles';
import {
  CONSULTA_PENDIENTE_ERROR,
  CUPO_OCUPADO_ERROR,
  FLUJO_VIDEOLLAMADA_CONTINUAR,
  FLUJO_VIDEOLLAMADA_CONFIRMAR_AGENDA,
  TIMEZONE_MONTEVIDEO,
  CONTACTO_VIDEOLLAMADA_TITULO_TEXTO,
  CONTACTO_VIDEOLLAMADA_SUBTITULO_TEXTO,
  CAPTCHA_FIELD_NAME,
  TIPO_TURNO_CONSULTA,
  FLUJO_VIDEOLLAMADA_AGENDA,
  FLUJO_VIDEOLLAMADA_CONFIRMACION,
  VOLVER_AL_INICIO,
} from 'constants/commonConstants.js';

// componentes
import { Helmet } from 'react-helmet';
import Stepper from 'components/Stepper';
import Contacto from 'screens/contacto/contacto';
import ButtonSolid from 'components/ButtonSolid';
import CardSpinner from 'components/CardSpinner';

// componentes internos
import { datosUsuarioLogueado } from 'constants/selectors';
import { ConfirmacionConsulta } from 'screens/videollamada/Confirmacion';
import ContextoAgenda from 'screens/videollamada/ContextoAgenda';
import PasoAgenda from 'screens/videollamada/PasoAgenda';
import ExitoReserva from 'screens/videollamada/ExitoReserva';
import { craftVideollamadaConsultaPayload } from './utils';

import styles from './styles.module.css';

// TODO: Fetch this from the backend
const fetchAsuntos = () => [
  { id: 1, nombre: 'Servicios en línea' },
  { id: 2, nombre: 'Usuario gub uy' },
  { id: 3, nombre: 'Firma digital' },
  { id: 4, nombre: 'Otro' },
];

const ContactoVideollamadaContainer = () => {
  const usuarioLogueado = useSelector(datosUsuarioLogueado);

  // eslint-disable-next-line
  const [errors, setErrors] = useState(null);
  const [datosContacto, setDatosContacto] = useState(null);
  const [asuntos, setAsuntos] = useState([]);
  const recaptchaRef = useRef();
  const [pasoActual, setPasoActual] = useState(0);
  // Pasos completos es un arreglo que informa que pasos [0, 1, 2] estan completos
  const [pasosCompletos, setPasosCompletos] = useState([
    false,
    false,
    // Paso 2 siempre habilitado. No hay forma ilegal de llegar.
    true,
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [fechasDisponibles, setFechasDisponibles] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [horasDisponibles, setHorasDisponibles] = useState([]);
  const [disponibilidadSeleccionada, setDisponibilidadSeleccionada] =
    useState(null);
  const [errorCupos, setErrorCupos] = useState(false);
  const [errorVideollamadaAgendada, setErrorVideollamadaAgendada] =
    useState(false);
  const [botonCargando, setBotonCargando] = useState(false);

  useEffect(() => {
    setAsuntos(fetchAsuntos());
    setIsLoading(false);
  }, []);

  const asuntosOptions = asuntos.map(a => ({
    value: a.nombre,
    label: a.nombre,
  }));

  const pasos = [
    { id: 1, stepText: FLUJO_VIDEOLLAMADA_AGENDA },
    { id: 2, stepText: FLUJO_VIDEOLLAMADA_CONFIRMACION },
  ];

  const cambiarPaso = nuevoPaso => {
    if (nuevoPaso < pasoActual) {
      setPasoActual(nuevoPaso);
    }
  };

  const handleMoveToAgenda = async () => {
    setIsLoading(true);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const fechaActualUruguay = dayjs().tz(TIMEZONE_MONTEVIDEO);
    const fechaInicio = fechaActualUruguay.add(1, 'day').format('YYYY-MM-DD');
    const fechaFin = fechaActualUruguay.add(3, 'month').format('YYYY-MM-DD');

    try {
      const response = await obtenerDiasConsultaDisponibles(
        fechaInicio,
        fechaFin,
      );
      const fechas = response.data.map(dia => dayjs(dia.fecha).toDate());
      setFechasDisponibles(fechas);
      setFechaSeleccionada(fechas[0]);
    } finally {
      setIsLoading(false);
    }
  };

  const pasoSiguiente = async () => {
    if (pasoActual === 0) {
      handleMoveToAgenda();
    }
    setPasoActual(pasoActual + 1);
  };

  const enviarMensaje = async (values, setFieldValue) => {
    setDatosContacto(values);
    if (recaptchaRef.current.getValue() !== '') {
      setFieldValue(CAPTCHA_FIELD_NAME, '');
      recaptchaRef.current.reset();
    }
    const copiaPasosCompletos = [...pasosCompletos];
    copiaPasosCompletos[0] = values !== null;
    setPasosCompletos(copiaPasosCompletos);
    pasoSiguiente();
  };

  useEffect(() => {
    const datosCompletos =
      (fechaSeleccionada && disponibilidadSeleccionada) !== null;
    const copiaPasosCompletos = [...pasosCompletos];
    copiaPasosCompletos[1] = datosCompletos;
    setPasosCompletos(copiaPasosCompletos);
    // Limpiar errores cuando se cambia la disponibilidad
    setErrorCupos(false);
  }, [disponibilidadSeleccionada]);

  const confirmarReserva = async () => {
    setIsLoading(true);
    setErrorCupos(false);
    setBotonCargando(true);
    setErrorVideollamadaAgendada(false);
    const datosConsulta = craftVideollamadaConsultaPayload(
      datosContacto,
      fechaSeleccionada,
      disponibilidadSeleccionada,
    );
    try {
      await crearConsultaVideollamada(datosConsulta);
      pasoSiguiente();
    } catch (error) {
      if (
        error?.data?.error === CONSULTA_PENDIENTE_ERROR(datosContacto.email)
      ) {
        setErrorVideollamadaAgendada(true);
      } else if (
        error?.data?.error ===
        CUPO_OCUPADO_ERROR(datosConsulta.fecha, datosConsulta.hora)
      ) {
        setErrorCupos(true);
      }
    }
    setIsLoading(false);
    setBotonCargando(false);
  };

  const generarTextoBoton = () =>
    pasoActual === 2
      ? FLUJO_VIDEOLLAMADA_CONFIRMAR_AGENDA
      : FLUJO_VIDEOLLAMADA_CONTINUAR;

  const handleClickBotonContinuar = () => {
    if (pasoActual < 2) {
      pasoSiguiente();
    } else if (pasoActual === 2) {
      confirmarReserva();
    }
  };

  const contextoValue = useMemo(
    () => ({
      pasoActual,
      fechaSeleccionada,
      setFechaSeleccionada,
      disponibilidadSeleccionada,
      setDisponibilidadSeleccionada,
      setHorasDisponibles,
      errorCupos,
      errorVideollamadaAgendada,
    }),
    [
      pasoActual,
      fechaSeleccionada,
      setFechaSeleccionada,
      disponibilidadSeleccionada,
      setDisponibilidadSeleccionada,
      setHorasDisponibles,
      errorCupos,
      errorVideollamadaAgendada,
    ],
  );

  const generarBoton = () =>
    pasoActual === 3 ? (
      <Link to="/">{VOLVER_AL_INICIO}</Link>
    ) : (
      <ButtonSolid
        text={generarTextoBoton()}
        ariaLabel={FLUJO_VIDEOLLAMADA_CONTINUAR}
        handleClick={handleClickBotonContinuar}
        isDisabled={!pasosCompletos[pasoActual]}
        isLoading={botonCargando}
        className={styles.botonContinuar}
      />
    );

  if (isLoading)
    return (
      <CardSpinner
        spin
        text="Cargando..."
        className="spinner__transparent__videollamada"
      />
    );

  return (
    <div className={styles.container}>
      <Helmet title={CONTACT_VIDEOLLAMADA_TITLE} />
      {pasoActual > 0 && pasoActual <= 2 && (
        <Stepper
          steps={pasos}
          currentStep={pasoActual}
          handleClick={cambiarPaso}
        />
      )}
      <ContextoAgenda.Provider value={contextoValue}>
        {pasoActual === 0 && (
          <Contacto
            enviarMensaje={enviarMensaje}
            title={CONTACTO_VIDEOLLAMADA_TITULO_TEXTO}
            subtitle={CONTACTO_VIDEOLLAMADA_SUBTITULO_TEXTO}
            asuntosOptions={asuntosOptions}
            currentUser={usuarioLogueado}
            envioContactoError={errors}
            recaptchaRef={recaptchaRef}
          />
        )}
        {pasoActual === 1 && (
          <PasoAgenda
            fechasDisponibles={fechasDisponibles}
            horasDisponibles={horasDisponibles}
            obtenerHorasPorFecha={obtenerHorasConsultaPorFecha}
          />
        )}
        {pasoActual === 2 && (
          <ConfirmacionConsulta
            fechaSeleccionada={fechaSeleccionada}
            disponiblidadSeleccionada={disponibilidadSeleccionada}
          />
        )}
        {pasoActual === 3 && <ExitoReserva tipoTurno={TIPO_TURNO_CONSULTA} />}
      </ContextoAgenda.Provider>
      {pasoActual !== 0 && generarBoton()}
    </div>
  );
};

export default ContactoVideollamadaContainer;
