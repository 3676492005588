import {
  AUTHN_RESPONSE,
  FETCH_LOGOUT_REQUESTS,
  FETCH_LOGOUT_RESPONSE,
  SET_LOGOUT_REQUESTS,
} from 'constants/ActionTypes';
import {
  BROKER_REQUEST,
  LOGOUT_RESPONSE_SAML_URL,
  LOGOUT_FORCE_AUTH_SAML_URL,
} from 'constants/ApiConstants';

export const setAuthnResponseInfo = data => ({
  type: AUTHN_RESPONSE,
  payload: {
    data,
  },
});

export const fetchLogoutRequests = processState => ({
  type: BROKER_REQUEST,
  payload: {
    url: LOGOUT_RESPONSE_SAML_URL(processState),
    method: 'get',
    next: FETCH_LOGOUT_REQUESTS,
  },
});

export const fetchLogoutResponse = processState => ({
  type: BROKER_REQUEST,
  payload: {
    url: `${LOGOUT_RESPONSE_SAML_URL(processState)}&status=finish`,
    method: 'get',
    next: FETCH_LOGOUT_RESPONSE,
  },
});

export const setLogoutRequests = data => ({
  type: SET_LOGOUT_REQUESTS,
  payload: {
    data,
    next: SET_LOGOUT_REQUESTS,
  },
});

export const fetchLogoutRequestsForceAuth = processState => ({
  type: BROKER_REQUEST,
  payload: {
    url: LOGOUT_FORCE_AUTH_SAML_URL(processState),
    method: 'get',
    next: FETCH_LOGOUT_REQUESTS,
  },
});
