/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

// eslint-disable-next-line react/prop-types
const Step = ({ text, number, isCurrentStep, handleClick }) => (
  <div className={styles.main_container} onClick={() => handleClick(number)}>
    <div
      className={`${styles.box} ${isCurrentStep && styles.box_is_actual_step}`}
    >
      {number}
    </div>
    <p
      className={`${
        isCurrentStep ? styles.p_actual_step : styles.p_inactive_step
      }`}
    >
      {text}
    </p>
  </div>
);

Step.propTypes = {
  text: PropTypes.string,
  number: PropTypes.number,
  isCurrentStep: PropTypes.bool,
};

export default Step;
