import $ from 'jquery';
import moment from 'moment';
import 'moment/locale/es';
import _camelCase from 'lodash/camelCase';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ERROR_ROUTE, INDEX_ROUTE } from 'constants/RouterConstants';
import { LOGOUT_BASE_URL } from 'constants/ApiConstants';
import {
  UY_CODE,
  REQUERIDO_VALIDADOR_MENSAJE,
  REQUERIDO,
  ONLY_NUMBERS_VALIDATOR_MSG,
  PASSWORD_SIMILARITY_MSG,
  VALIDADOR_VALOR_ERROR,
  VALIDADOR_VAL_NULL,
  VALIDADOR_VALOR_BUENO,
  VALIDADOR_VALOR_EXCELENTE,
  VALIDADOR_VALOR_AVISO,
  VALIDADOR_AVISO,
  VALIDADOR_BUENO,
  VALIDADOR_EXCELENTE,
  TIMEZONE_MONTEVIDEO,
} from 'constants/commonConstants';

/*
Metodo para iniciar el fetch de paises.
Se usa en componentWillMount.
En caso que los paises ya se encuentren en el state, genera los options para
el Select.
*/
export async function initPaises(filtrar = null) {
  if (!this.props.paises.length) {
    await this.props.fetchPaises();
  } else {
    this.renderPaisesOptions(this.props.paises, filtrar);
  }
}

/*
Metodo para renderizar paises en Select.
Dado la lista de paises previamente inicializada, genera la lista de options
para el Select y renderiza los documentos para ese país.
*/
export function renderPaisesOptions(paises, filtrar = null) {
  const paisesFiltrado = filtrar
    ? paises.filter(pais => pais.codigo !== UY_CODE)
    : paises;
  this.setState(
    {
      paisesOptions: paisesFiltrado.map(pais => ({
        value: pais.codigo,
        label: pais.nombre,
      })),
      pais_emisor: this.state.pais_emisor || paisesFiltrado[0].codigo,
    },
    () => {
      this.renderDocumentosOptions(this.state.pais_emisor, paises);
    },
  );
}

// Método para hacer lo mismo que arriba pero sin cambiar el estado (refactor a Hooks)
export function getPaisesOptions(paises, filtrar = null) {
  const paisesFiltrado = filtrar
    ? paises.filter(pais => pais.codigo !== UY_CODE)
    : paises;
  return {
    paisesOptions: paisesFiltrado.map(pais => ({
      value: pais.codigo,
      label: pais.nombre,
    })),
    paisEmisor: paisesFiltrado.length > 0 ? paisesFiltrado[0].codigo : null,
  };
}

/*
Encapsula el comportamiento de actualización del estado en los
lugares donde se usa formik con formularios de paises/tipo-doc
 */
export async function handleOnChangePaises(e, setFieldValue) {
  this.setState({ pais_emisor: e.target.value });
  await this.renderDocumentosOptions(e.target.value);
  // Necesario para actualizar el valor en formik del tipo_documento
  if (setFieldValue) {
    setFieldValue('tipo_documento', this.state.tipo_documento);
  }
}

/*
Metodo para generar lista de options de tipo de documentos dado un pais.
*/
export function renderDocumentosOptions(paisEmisor, paises) {
  const listaPaises = paises || this.props.paises;
  const obj = listaPaises.filter(pais => pais.codigo === paisEmisor);
  this.setState({
    documentosOptions: obj[0].documentos.map(documento => ({
      value: documento.tipo_documento.codigo,
      label: documento.tipo_documento.nombre,
    })),
    tipo_documento: obj[0].documentos[0].tipo_documento.codigo.toString(),
  });
}

// Método para hacer lo mismo que arriba pero sin cambiar el estado (refactor a Hooks)
export function getDocumentosOptions(paisEmisor, paises) {
  const listaPaises = paises;
  const obj = listaPaises.filter(pais => pais.codigo === paisEmisor);
  if (obj.length > 0) {
    const documentosOptions = {
      documentosOptions: obj[0].documentos.map(documento => ({
        value: documento.tipo_documento.codigo,
        label: documento.tipo_documento.nombre,
      })),
    };
    if (obj[0].documentos.length > 1) {
      return {
        ...documentosOptions,
        defaultTipoDocumento: 'Seleccionar',
        tipoDocumento: obj[0].documentos[0].tipo_documento.codigo.toString(),
        updateDefaultTipoDocumento: true,
      };
    }
    return {
      ...documentosOptions,
      defaultTipoDocumento: obj[0].documentos[0].tipo_documento.nombre,
      tipoDocumento: obj[0].documentos[0].tipo_documento.codigo.toString(),
    };
  }
  return [];
}

/*
Metodo para borrar el mensaje en el field que viene del backend.
formErrors: Del state, donde se guardan los errores de ese flujo.
handleResetFieldError: funcion para resetear los errores.
*/
export function resetFieldError(e) {
  if (this.props.formErrors) {
    if (this.props.formErrors.field === e.target.name) {
      this.handleResetFieldError();
    }
  }
}

/*
Metodo para saber si el usuario entro desde un celular.
*/
export function isMobile() {
  return (
    window.navigator.userAgent.match(/Android/i) ||
    window.navigator.userAgent.match(/webOS/i) ||
    window.navigator.userAgent.match(/iPhone/i) ||
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPod/i) ||
    window.navigator.userAgent.match(/BlackBerry/i) ||
    window.navigator.userAgent.match(/Windows Phone/i)
  );
}

/*
Método auxiliar para moverse hasta un input dado por su name
*/
export function goToField(name) {
  const fieldName = _camelCase(name);
  const field = $(`input[name=${fieldName}]`);
  $('div').animate({
    scrollTop: field.offset().top + $('div').scrollTop(),
  });
}

export function buildErrorUrl(code = null) {
  const errorCode = code === null ? 'DEFAULT_ERROR' : code;
  return `${ERROR_ROUTE}/?errorCode=${errorCode}`;
}

/*
Método para saber el grado de seguridad de la password
*/
export function handleChangeErrorWidget(password, errorMsg) {
  const msg = {
    text: '',
    code: '',
    val: VALIDADOR_VAL_NULL,
  };
  // Error min 8 carcateres, solo numeros o contraseñas similares
  if (
    errorMsg &&
    (errorMsg === REQUERIDO_VALIDADOR_MENSAJE ||
      errorMsg === ONLY_NUMBERS_VALIDATOR_MSG ||
      errorMsg === PASSWORD_SIMILARITY_MSG)
  ) {
    msg.text = errorMsg;
    msg.code = 'error';
    msg.val = `${VALIDADOR_VALOR_ERROR}%`;
    // Error requerido
  } else if ((errorMsg && errorMsg === REQUERIDO) || password.length === 0) {
    msg.text = REQUERIDO_VALIDADOR_MENSAJE;
    msg.code = 'base';
    msg.val = `${VALIDADOR_VAL_NULL}%`;
    // 8 carcateres
  } else if (password.length === 8) {
    msg.text = VALIDADOR_AVISO;
    msg.code = 'warning';
    msg.val = `${VALIDADOR_VALOR_AVISO}%`;
    // Entre 9 y 15 carcateres
  } else if (password.length >= 9 && password.length <= 15) {
    msg.text = VALIDADOR_BUENO;
    msg.code = 'ok';
    msg.val = `${VALIDADOR_VALOR_BUENO}%`;
    // Más de 15 carcateres
  } else {
    msg.text = VALIDADOR_EXCELENTE;
    msg.code = 'ok';
    msg.val = `${VALIDADOR_VALOR_EXCELENTE}%`;
  }
  return msg;
}

export const formatFullDate = (date = new Date()) =>
  `${moment(date).format('L')}, ${moment(date).format('LT')} hs`;

export function buildUrlLogoutOidc(redirectUri, state) {
  let url = LOGOUT_BASE_URL;
  if (redirectUri) {
    url = url.concat(`?redirect_uri=${redirectUri}`);
    if (state) {
      url = url.concat(`&state=${state}`);
    }
  }
  return url;
}

export function buildRedirectUrlLogoutOidc(redirectUri, state) {
  let url = '';
  if (redirectUri) {
    url = redirectUri;
    if (state) {
      url = url.concat(`?state=${state}`);
    }
    return url;
  }
  return INDEX_ROUTE;
}

export const puedeIngresarVideollamada = videollamadaInfo => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Si faltan más de 5 minutos, no se le permite ingresar
  const fechaHoraVideollamada = dayjs
    .tz(
      `${videollamadaInfo?.fecha} ${videollamadaInfo?.hora_inicio}`,
      TIMEZONE_MONTEVIDEO,
    )
    .subtract(6, 'minute');
  return dayjs().tz(TIMEZONE_MONTEVIDEO).isAfter(fechaHoraVideollamada);
};
