import React from 'react';
import PropTypes from 'prop-types';

import { CEDULA_ELECTRONICA_ID, IDP_AE3_LIST } from 'constants/commonConstants';
import ButtonSection from 'components/ButtonSection';
import avanzado from 'assets/icons/avanzado.svg';
import { LoginOption } from './loginSection';

import './mfa.css';

const IdentityProviderPrivate = ({ identityProviders }) => {
  const ae3List = new Set(IDP_AE3_LIST);
  const IDPs = identityProviders
    .filter(idp => ae3List.has(idp.identifier))
    .map((idp, index) => (
      <LoginOption
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        name={idp.name}
        ariaLabel={idp.ariaLabel}
        description={idp.description}
        logo={idp.logo}
        loading={idp.loading}
        handleClick={e => idp.handleClick(e, idp.identifier)}
        nivel_seguridad="Avanzado"
        nivel_seguridad_logo={avanzado}
      />
    ));
  return IDPs;
};

IdentityProviderPrivate.propTypes = {
  identityProviders: PropTypes.array,
};

// ------------------------------------------------------------
// Aumentar NID
// ------------------------------------------------------------
const Ae3Methods = ({
  title,
  description,
  previousText,
  handlePrevious,
  cancelLoading,
  identityProviders,
}) => (
  <div className="mfa__ae3_methods">
    <h2 className="mfa__title">{title}</h2>
    <div className="mfa__subheading">{description}</div>
    <div className="mfa-container">
      {/*  TODO: 19/12/2018 - AGESIC pidió quitar login con CI. */}
      {identityProviders !== null &&
        identityProviders.length > 0 &&
        identityProviders[0].identifier === CEDULA_ELECTRONICA_ID && (
          <LoginOption
            name={identityProviders[0].name}
            ariaLabel={identityProviders[0].ariaLabel}
            description={identityProviders[0].description}
            logo={identityProviders[0].logo}
            handleClick={e => identityProviders[0].handleClick(e)}
            nivel_seguridad="Avanzado"
            nivel_seguridad_logo={avanzado}
          />
        )}
      <IdentityProviderPrivate identityProviders={identityProviders} />
      <ButtonSection
        textSecondary={previousText}
        handleClickSecondary={handlePrevious}
        isLoadingSecondary={cancelLoading || false}
        style={{ justifyContent: 'center', display: 'flex' }}
        isMFA
      />
    </div>
  </div>
);

Ae3Methods.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  previousText: PropTypes.string,
  handlePrevious: PropTypes.func,
  cancelLoading: PropTypes.bool,
  identityProviders: PropTypes.array,
};

export default Ae3Methods;
