import { axiosAPI } from 'utils/axiosBackend';
import Auth from 'utils/Auth';
import {
  CIUDADANO_CREATE_URL,
  CIUDADANO_CREATE_FROM_INSIDE_URL,
  ENVIAR_FEEDBACK_FORM_URL,
  ENVIAR_CONTACTO_URL,
  FETCH_ASUNTOS_URL,
  REQUEST_RESET_PASSWORD_URL,
  OBTENER_DIAS_DISPONIBLES,
  OBTENER_HORAS_DISPONIBLES,
  TRAMITE_VIDEOLLAMADA_BASE_URL,
  INFO_DNIC_BASE_URL,
  CIUDADANO_ACCIONES_URL,
  ELEVARSE_POR_VIDEOLLAMADA_URL,
  DSS_SIGN_REQUEST_URL,
  CIUDADANO_CAMBIAR_NUMERO_TELEFONO,
  CONFIRM_RESET_PASSWORD_URL,
  CIUDADANO_RESEND_ACTIVATION_LOGGED,
  OBTENER_DIAS_DISPONIBLES_CONSULTA,
  OBTENER_HORAS_DISPONIBLES_CONSULTA,
  VIDEOLLAMADA_CONSULTA_BASE_URL,
} from 'constants/ApiConstants';

export const enviarFeedbackForm = formData => {
  const data = {
    pregunta_1: formData.pregunta1,
    pregunta_2: formData.pregunta2,
    pregunta_3_checkbox_1: formData.pregunta3Checkbox1,
    pregunta_3_checkbox_2: formData.pregunta3Checkbox2,
    pregunta_3_checkbox_3: formData.pregunta3Checkbox3,
    pregunta_3_checkbox_4: formData.pregunta3Checkbox4,
    pregunta_3_checkbox_5: formData.pregunta3Checkbox5,
    pregunta_3_checkbox_6: formData.pregunta3Checkbox6,
    pregunta_4: formData.pregunta4,
    email: formData.email,
    captcha_response: formData.captchaResponse,
  };
  return axiosAPI({
    method: 'post',
    url: ENVIAR_FEEDBACK_FORM_URL,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const enviarMensajeContacto = data =>
  axiosAPI({
    method: 'post',
    url: ENVIAR_CONTACTO_URL,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const fetchAsuntos = () =>
  axiosAPI({
    method: 'get',
    url: FETCH_ASUNTOS_URL,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const requestResetPassword = data =>
  axiosAPI({
    method: 'post',
    url: REQUEST_RESET_PASSWORD_URL,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const createCiudadano = data =>
  axiosAPI({
    method: 'post',
    url: CIUDADANO_CREATE_URL,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const createCiudadanoFromInside = (id, data) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANO_CREATE_FROM_INSIDE_URL(id),
    data,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const confirmarConsultaVideollamada = uuid =>
  axiosAPI({
    method: 'post',
    url: `${VIDEOLLAMADA_CONSULTA_BASE_URL}/${uuid}/confirmar`,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const crearConsultaVideollamada = data =>
  axiosAPI({
    method: 'post',
    url: `${VIDEOLLAMADA_CONSULTA_BASE_URL}`,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const crearTramiteVideollamada = data => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: `${TRAMITE_VIDEOLLAMADA_BASE_URL}/`,
    data,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const validarInfoDnic = data => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: INFO_DNIC_BASE_URL,
    params: data,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerDiasDisponibles = (fechaInicio, fechaFin) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${OBTENER_DIAS_DISPONIBLES}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerDiasConsultaDisponibles = (fechaInicio, fechaFin) =>
  axiosAPI({
    method: 'get',
    url: `${OBTENER_DIAS_DISPONIBLES_CONSULTA}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&disponible=true`,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

export const obtenerHorasConsultaPorFecha = fecha =>
  // Unauthorized endpoint, no auth headers required
  axiosAPI({
    method: 'get',
    url: `${OBTENER_HORAS_DISPONIBLES_CONSULTA}?fecha=${fecha}`,
  });

export const obtenerHorasPorFecha = fecha => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${OBTENER_HORAS_DISPONIBLES}?fecha=${fecha}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const cancelarReservaVideollamada = idReserva => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'delete',
    url: `${TRAMITE_VIDEOLLAMADA_BASE_URL}/${idReserva}/`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const elevarsePorTramiteVideollamada = () => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: ELEVARSE_POR_VIDEOLLAMADA_URL,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const fetchAccionesCiudadano = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: CIUDADANO_ACCIONES_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const iniciarProcesoFirmaDigital = () => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: DSS_SIGN_REQUEST_URL,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const cambiarNumeroTelefono = (data, id) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANO_CAMBIAR_NUMERO_TELEFONO(id),
    headers,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const cambiarContrasena = (password, repetirPassword, token) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CONFIRM_RESET_PASSWORD_URL,
    headers,
    data: { password, passwordRepeat: repetirPassword, token },
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const reenviarEmailActivación = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    headers,
    url: CIUDADANO_RESEND_ACTIVATION_LOGGED(id),
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const fetchVideollamadaSoporte = uuid =>
  axiosAPI({
    method: 'get',
    url: `${VIDEOLLAMADA_CONSULTA_BASE_URL}/${uuid}`,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
