import {
  AUTHN_RESPONSE,
  FETCH_LOGOUT_REQUESTS,
  FETCH_LOGOUT_RESPONSE,
  SET_LOGOUT_REQUESTS,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  action: null,
  authnResponse: null,
  relayState: null,
  logoutRequests: [],
  logoutResponse: null,
  logoutRedirect: null,
  waitLonger: null,
};
// eslint-disable-next-line default-param-last
export default function brokerSamlReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTHN_RESPONSE: {
      return {
        ...state,
        action: action.payload.data.action,
        authnResponse: action.payload.data.authn_response,
        relayState: action.payload.data.relay_state
          ? action.payload.data.relay_state
          : null,
      };
    }
    case FETCH_LOGOUT_REQUESTS.SUCCESS: {
      return {
        ...state,
        logoutRequests: action.payload.data.logout_requests || [],
        waitLonger: action.payload.data.wait_longer || null,
        logoutResponse: action.payload.data.logout_response,
        logoutRedirect: action.payload.data.logout_redirect,
      };
    }
    case FETCH_LOGOUT_REQUESTS.ERROR:
      return {
        ...state,
        logoutRequests: {},
        waitLonger: null,
        logoutResponse: {},
        logoutRedirect: {},
      };
    case FETCH_LOGOUT_REQUESTS.PENDING:
      return {
        ...state,
      };
    case FETCH_LOGOUT_RESPONSE.SUCCESS: {
      return {
        ...state,
        logoutResponse: action.payload.data.logout_response,
        logoutRedirect: action.payload.data.logout_redirect,
      };
    }
    case FETCH_LOGOUT_RESPONSE.ERROR:
      return {
        ...state,
        logoutResponse: {},
        logoutRedirect: {},
      };
    case FETCH_LOGOUT_RESPONSE.PENDING:
      return {
        ...state,
      };

    case SET_LOGOUT_REQUESTS:
      return {
        ...state,
        logoutRequests: action.payload.data.logout_requests,
        waitLonger: action.payload.data.wait_longer,
      };
    // DEFAULT
    default:
      return state;
  }
}
