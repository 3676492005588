/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import { COMPLETA_EXACTAMENTE } from 'constants/commonConstants';

import Alert from 'components/Alert';

import RegistroForm from './registroForm';

import styles from './registro.module.css';

const RegistroContent = props => {
  const SIMPLIFIED_FIELD_NAMES = {
    primerNombre: 'Nombre',
    primerApellido: 'Apellido',
    numeroDocumento: 'Número',
    email: 'Correo electrónico',
    repetirEmail: 'Repetir correo electrónico',
    password: 'Contraseña',
    repetirPassword: 'Repetir contraseña',
  };
  const invalidFieldsMessages = props.invalidFields.map(
    field => SIMPLIFIED_FIELD_NAMES[field],
  );
  return (
    <div className={styles.registro__container}>
      <main aria-labelledby="formulario-registro">
        {props.invalidFields && props.invalidFields.length !== 0 && (
          <Alert type="error" listErrors={invalidFieldsMessages} />
        )}
        <p className={styles.subtitulo}>{COMPLETA_EXACTAMENTE}</p>
        <RegistroForm {...props} />
      </main>
    </div>
  );
};

export default RegistroContent;
