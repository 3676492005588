import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from 'store/ConfigureStore';

// Actions
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';

// Selectors
import { usuarioLogueadoSelector } from 'store/selectors';

// Services
import { enviarMensajeContacto, fetchAsuntos } from 'services/apiServices';

// Router constants
import { ENVIO_MENSAJE_CONTACTO_SUCCESS } from 'constants/RouterConstants';

// Common constants
import {
  CAPTCHA_FIELD_NAME,
  CONTACTO_TITULO_TEXTO,
  CONTACTO_SUBTITULO_TEXTO,
} from 'constants/commonConstants';

// Componentes
import { CONTACT_TITLE } from 'constants/pageTitles';
import CardSpinner from 'components/CardSpinner';
import { Helmet } from 'react-helmet';
import Contacto from './contacto';

const ContactoContainer = () => {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  const [errors, setErrors] = useState(null);
  const [asuntosOpciones, setAsuntosOpciones] = useState(null);
  const [cargando, setCargando] = useState(true);
  const recaptchaRef = useRef();
  const usuarioLogueado = useSelector(usuarioLogueadoSelector);
  const dispatch = useDispatch();

  const handleExternalHeader = () => dispatch(showExternalHeader());
  const handleAriaLiveMessage = mensaje =>
    dispatch(updateAriaLiveMessage(mensaje));

  useEffect(() => {
    handleExternalHeader();
    handleAriaLiveMessage(CONTACT_TITLE);
    const obtenerAsuntos = async () => {
      try {
        setCargando(true);
        const response = await fetchAsuntos();
        setAsuntosOpciones(response.data);
      } finally {
        setCargando(false);
      }
    };
    obtenerAsuntos();
  }, []);

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  const enviarMensaje = async (values, setFieldValue) => {
    try {
      await enviarMensajeContacto(values);
      history.push(ENVIO_MENSAJE_CONTACTO_SUCCESS);
    } catch (response) {
      if (response.data.next) {
        window.location.href = response.data.next;
      } else {
        setErrors(response.data);
        if (recaptchaRef.current.getValue() !== '') {
          setFieldValue(CAPTCHA_FIELD_NAME, '');
          recaptchaRef.current.reset();
        }
      }
    }
  };

  if (cargando) {
    return <CardSpinner spin />;
  }

  const asuntos = asuntosOpciones.map(asunto => ({
    value: asunto.id,
    label: asunto.nombre,
  }));

  return (
    <>
      <Helmet title={CONTACT_TITLE} />
      <Contacto
        enviarMensaje={enviarMensaje}
        title={CONTACTO_TITULO_TEXTO}
        subtitle={CONTACTO_SUBTITULO_TEXTO}
        asuntosOptions={asuntos}
        currentUser={usuarioLogueado}
        envioContactoError={errors}
        recaptchaRef={recaptchaRef}
      />
    </>
  );
};

export default ContactoContainer;
