// ---------------------------------------------------------------------------
// Page Titles
// ---------------------------------------------------------------------------
const SUFFIX = ' - Usuario gub.uy';
export const pageTitleWithSuffix = title => `${title}${SUFFIX}`;

// ---------------------------------------------------------------------------
// Login
// ---------------------------------------------------------------------------
export const LOGIN_PROCESS_TITLE = pageTitleWithSuffix('Cargando...');
export const LOGIN_TITLE = pageTitleWithSuffix('Iniciar sesión');
export const CONSENT_TITLE = pageTitleWithSuffix('Autorización');

// ---------------------------------------------------------------------------
// Logout
// ---------------------------------------------------------------------------
export const LOGOUT_PROCESS_TITLE = pageTitleWithSuffix('Cerrando sesión');

// ---------------------------------------------------------------------------
// Landing
// ---------------------------------------------------------------------------
export const LANDING_HOME_TITLE = pageTitleWithSuffix('Autogestión');

// ---------------------------------------------------------------------------
// Preguntas frecuentes
// ---------------------------------------------------------------------------
export const HELP_TITLE = pageTitleWithSuffix('Preguntas frecuentes');

// ---------------------------------------------------------------------------
// Contacto
// ---------------------------------------------------------------------------
export const CONTACT_TITLE = pageTitleWithSuffix('Contacto');
export const CONTACT_SENT_TITLE = pageTitleWithSuffix('Contacto enviado');

// ---------------------------------------------------------------------------
// Contacto videollamada
// ---------------------------------------------------------------------------
export const CONTACT_VIDEOLLAMADA_TITLE = pageTitleWithSuffix('Agenda soporte');
export const CONTACT_VIDEOLLAMADA_CONFIRM = pageTitleWithSuffix(
  'Confirmación videollamada',
);

// ---------------------------------------------------------------------------
// Registro
// ---------------------------------------------------------------------------
export const SIGNUP_TITLE = pageTitleWithSuffix('Registro');
export const SIGNUP_SUCCESS_TITLE = pageTitleWithSuffix('Registro exitoso');
export const SIGNUP_ACTIVATE_ACCOUNT_TITLE =
  pageTitleWithSuffix('Activar cuenta');
export const SIGNUP_ACCOUNT_ACTIVATED_TITLE =
  pageTitleWithSuffix('Cuenta activada');
export const SIGNUP_ERROR_TITLE = pageTitleWithSuffix(
  'Error al activar cuenta',
);
export const SIGNUP_LINK_SENT_TITLE = pageTitleWithSuffix(
  'Correo electrónico de activación de cuenta enviado',
);
export const SIGNUP_LINK_RESENT_TITLE = pageTitleWithSuffix(
  'Correo electrónico de activación de cuenta reenviado',
);
export const SIGNUP_RESEND_LINK_TITLE = pageTitleWithSuffix(
  'Reenviar correo electrónico de activación de cuenta',
);

// ---------------------------------------------------------------------------
// ResetPassword
// ---------------------------------------------------------------------------
export const RESET_PASSWORD_TITLE = pageTitleWithSuffix(
  '¿Olvidaste tu contraseña?',
);
export const RESET_PASSWORD_LINK_SENT_TITLE = pageTitleWithSuffix(
  'Ya casi terminas...',
);
export const RESET_PASSWORD_SET_PASSWORD_TITLE = pageTitleWithSuffix(
  'Modificá tu contraseña',
);
export const RESET_PASSWORD_INVALID_LINK_TITLE = pageTitleWithSuffix(
  'Ups, ocurrió un problema...',
);

// ---------------------------------------------------------------------------
// Seguridad
// ---------------------------------------------------------------------------
export const SECURITY_TITLE = pageTitleWithSuffix('Seguridad');
export const AUTH_TITLE = pageTitleWithSuffix('Verificá tu identidad');

// ---------------------------------------------------------------------------
// Error
// ---------------------------------------------------------------------------
export const ERROR_404_TITLE = pageTitleWithSuffix('Página no encontrada');
export const ERROR_500_TITLE = pageTitleWithSuffix('Ocurrió un error');
export const ERROR_UNREGISTERED_USER = pageTitleWithSuffix('No registrado');

// ---------------------------------------------------------------------------
// Change password
// ---------------------------------------------------------------------------
export const CHANGE_PASSWORD_TITLE = pageTitleWithSuffix(
  'Modificá tu contraseña',
);
export const CHANGE_PASSWORD_SUCCESS_TITLE = pageTitleWithSuffix(
  'Tu contraseña se modificó',
);

// ---------------------------------------------------------------------------
// Change email
// ---------------------------------------------------------------------------
export const CHANGE_EMAIL_TITLE = pageTitleWithSuffix('Modificá tu correo');
export const CHANGE_EMAIL_LINK_SENT_TITLE = pageTitleWithSuffix(
  'Ya casi terminas...',
);
export const CHANGE_EMAIL_SUCCESS_TITLE = pageTitleWithSuffix(
  'Tu correo electrónico se modificó exitosamente',
);
export const CHANGE_EMAIL_ERROR_TITLE = pageTitleWithSuffix(
  'Ups, ocurrió un problema...',
);
export const CHANGE_EMAIL_APPROVE_TITLE = pageTitleWithSuffix(
  'Validá el cambio de tu correo electrónico',
);

// ---------------------------------------------------------------------------
// Federated Identity
// ---------------------------------------------------------------------------
export const UPDATE_INFO_TITLE = pageTitleWithSuffix('Actualizar información');

// ---------------------------------------------------------------------------
// Multi Factor Authentication
// ---------------------------------------------------------------------------
export const MFA_TITLE = 'Autenticación en dos pasos';
export const MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE = pageTitleWithSuffix(
  'Totp activado exitosamente',
);

// ---------------------------------------------------------------------------
// Feedback
// ---------------------------------------------------------------------------
export const FEEDBACK_FORM_TITLE = 'Ayúdenos a mejorar';
export const FEEDBACK_FORM_SENT_TITLE = pageTitleWithSuffix('Feedback enviado');
