/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { hideHeader, hideFooter } from 'actions/ui';
import ButtonSolid from 'components/ButtonSolid';
import CardSpinner from 'components/CardSpinner';
import SAML from 'utils/SAML';
import Auth from 'utils/Auth';

class SAMLIndexContainerViejo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      extra: null,
      loading: false,
    };

    this.handleIniciarSesionIDUruguay =
      this.handleIniciarSesionIDUruguay.bind(this);
    this.handleCerrarSesionIDUruguay =
      this.handleCerrarSesionIDUruguay.bind(this);
  }

  async componentDidMount() {
    this.props.hideFooter();
    this.props.hideHeader();

    const parsed = queryString.parse(this.props.location.search);
    const extra = parsed.extra ? parsed.extra : null;
    this.setState({ extra });

    await Auth.getInstance().obtainToken();
  }

  handleCerrarSesionIDUruguay() {
    if (Auth.getInstance().isAuthenticated()) {
      this.setState({ loading: true });
      SAML.sendLogoutRequest();
    }
    this.setState({ extra: 'Falta iniciar sesión' });
  }

  handleIniciarSesionIDUruguay() {
    this.setState({ loading: true });
    window.location.href = `${window.REACT_APP_BACKEND_HOST}/saml-login-id-uruguay`;
  }

  render() {
    if (this.state.loading) {
      return (
        <CardSpinner
          spin
          className="spinner__transparent"
          text="Enviando SAMLRequest"
        />
      );
    }
    return (
      <div>
        <p style={{ textAlign: 'center', fontSize: '40px', marginTop: '50px' }}>
          Service Provider
        </p>

        <div style={{ textAlign: 'center', margin: '80px 0' }}>
          <ButtonSolid
            style={{ width: '370px', marginRight: '10px' }}
            handleClick={this.handleIniciarSesionIDUruguay}
            text="AUTHENTICATION Request gub.uy"
          />
          <ButtonSolid
            style={{ width: '370px', marginLeft: '10px' }}
            handleClick={this.handleCerrarSesionIDUruguay}
            text="LOGOUT Request gub.uy"
          />
        </div>

        {this.state.extra && (
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            {this.state.extra}
          </p>
        )}
      </div>
    );
  }
}

export default connect(null, {
  hideFooter,
  hideHeader,
})(SAMLIndexContainerViejo);
