import React from 'react';
import { connect } from 'react-redux';

const offScreenStyle = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  width: '1px',
  position: 'absolute',
};

// eslint-disable-next-line react/prop-types
const AriaLiveContainer = ({ message }) => (
  <div style={offScreenStyle} role="log" aria-live="polite" aria-atomic="true">
    {message}
  </div>
);

const mapStateToProps = state => ({
  message: state.ui.aria.ariaLiveMessage,
});

export default connect(mapStateToProps, null)(AriaLiveContainer);
