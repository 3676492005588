import {
  API_REQUEST,
  CIUDADANO_UPDATE_PASSWORD,
  CIUDADANO_VERIFY_PASSWORD,
  CIUDADANO_UPDATE_EMAIL,
  CIUDADANO_RESEND_ACTIVATION_LOGGED,
  APPROVE_EMAIL_CHANGE_URL,
  USERCONSENT_BASE_URL,
  USERCONSENT_DETAIL_URL,
} from 'constants/ApiConstants';

import {
  PASSWORD_CHANGE_PROCESS,
  CHANGE_USER_PASSWORD,
  SET_AUTH_WITH_PASSWORD,
  UNSET_AUTH_WITH_PASSWORD,
  VERIFIED_USER_PASSOWRD,
  CLEAR_PASSWORD_VERIFICATION_ERRORS,
  CLEAR_PASSWORD_CHANGE_ERRORS,
  CHANGE_USER_EMAIL_REQUEST,
  CLEAR_EMAIL_UPDATE_ERRORS,
  RESEND_ACTIVATION_EMAIL_LOGGED,
  RESET_RESEND_ACTIVATION_EMAIL_LOGGED,
  APPROVE_EMAIL_CHANGE,
  FETCH_APLICACIONES_CON_CONSENTIMIENTO,
  REVOCAR_CONSENTIMIENTO_APLICACION,
  RESET_CONSENTIMIENTO_REVOCADO,
} from 'constants/ActionTypes';

import Auth from 'utils/Auth';

// ---------------------------------------------------------------------------
// Email
// ---------------------------------------------------------------------------
export const updateUserEmail = (
  email,
  id = Auth.getInstance().getUserId(),
) => ({
  type: API_REQUEST,
  payload: {
    data: {
      email,
    },
    url: CIUDADANO_UPDATE_EMAIL(id),
    method: 'post',
    next: CHANGE_USER_EMAIL_REQUEST,
  },
});

export const approveEmailChange = uuid => ({
  type: API_REQUEST,
  payload: {
    url: APPROVE_EMAIL_CHANGE_URL,
    method: 'post',
    next: APPROVE_EMAIL_CHANGE,
    data: { uuid },
  },
});

export const clearEmailUpdateErrors = () => ({
  type: CLEAR_EMAIL_UPDATE_ERRORS,
});

export const resendActivationEmailLogged = (
  id = Auth.getInstance().getUserId(),
) => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_RESEND_ACTIVATION_LOGGED(id),
    method: 'post',
    next: RESEND_ACTIVATION_EMAIL_LOGGED,
  },
});

export const resetResendActivationEmail = () => ({
  type: RESET_RESEND_ACTIVATION_EMAIL_LOGGED,
});

// ---------------------------------------------------------------------------
// Password
// ---------------------------------------------------------------------------
export const updateUserPassword = (
  pwd,
  repeatPwd,
  id = Auth.getInstance().getUserId(),
) => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_UPDATE_PASSWORD(id),
    data: {
      new_pass: pwd,
      repeat_pass: repeatPwd,
    },
    method: 'post',
    next: CHANGE_USER_PASSWORD,
  },
});

export const verifyUserPassword = (
  pwd,
  id = Auth.getInstance().getUserId(),
) => ({
  type: API_REQUEST,
  payload: {
    data: {
      pwd,
    },
    url: CIUDADANO_VERIFY_PASSWORD(id),
    method: 'post',
    next: VERIFIED_USER_PASSOWRD,
  },
});

export const setChangePasswordStarts = () => ({
  type: PASSWORD_CHANGE_PROCESS,
});

export const setAuthForPasswordChange = () => ({
  type: SET_AUTH_WITH_PASSWORD,
});

export const unsetAuthForPasswordChange = () => ({
  type: UNSET_AUTH_WITH_PASSWORD,
});

export const clearPasswordErrors = () => ({
  type: CLEAR_PASSWORD_VERIFICATION_ERRORS,
});

export const clearPasswordChangeErrors = () => ({
  type: CLEAR_PASSWORD_CHANGE_ERRORS,
});

// ---------------------------------------------------------------------------
// Aplicaciones
// ---------------------------------------------------------------------------
export const fetchAplicacionesConConsentimiento = () => ({
  type: API_REQUEST,
  payload: {
    url: USERCONSENT_BASE_URL,
    method: 'get',
    next: FETCH_APLICACIONES_CON_CONSENTIMIENTO,
  },
});

export const revocarConsentimientoAplicacion = userConsentID => ({
  type: API_REQUEST,
  payload: {
    data: {
      userConsentID,
    },
    url: USERCONSENT_DETAIL_URL(userConsentID),
    method: 'delete',
    next: REVOCAR_CONSENTIMIENTO_APLICACION,
  },
});

export const resetConsentimientoRevocado = () => ({
  type: RESET_CONSENTIMIENTO_REVOCADO,
});
