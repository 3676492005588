/* eslint-disable react/prop-types */
import React from 'react';

import { PLURAL_SCOPES } from 'constants/commonConstants';
import ButtonLink from 'components/ButtonLink';
import './consent.css';

const ConsentIntro = props => (
  <div className="consent__intro">
    {props.client_url ? (
      <ButtonLink
        to={props.client_url}
        external
        target="_blank"
        title={props.client_name}
      />
    ) : (
      <span>{props.client_name} </span>
    )}{' '}
    quiere:{' '}
  </div>
);

const ConsentScopeList = props => {
  const scopes = props.scopes.length ? (
    props.scopes.map(scope => (
      <div className="consent__list" key={scope.name}>
        <div className="consent__divider" />
        <span>
          <p className="consent__scope-title">
            Conocer tu{PLURAL_SCOPES.includes(scope.scope) ? 's' : null}{' '}
            {scope.name.toLowerCase()}
          </p>
          <p className="consent__scope-description">{scope.description}</p>
        </span>
      </div>
    ))
  ) : (
    <p>No quiere acceder a ninguna información.</p>
  );
  return <div>{scopes}</div>;
};

const ConsentTermsAndCondition = props =>
  props.terms_url ? (
    <div>
      <div className="consent__divider" />
      <span>
        <p className="consent__terms-title">
          ¿Querés permitir a {props.client_name} hacer esto?
        </p>
        <p className="consent__terms-description">
          Antes de continuar te recomendamos que revises sus{' '}
          <ButtonLink
            to={props.terms_url}
            target="_blank"
            external
            title="Términos y Condiciones"
          />
          {props.priv_url && (
            <span>
              {' y '}
              <ButtonLink
                to={props.conds}
                target="_blank"
                external
                title="Políticas de Privacidad"
              />
            </span>
          )}
        </p>
      </span>
    </div>
  ) : null;

export { ConsentIntro, ConsentScopeList, ConsentTermsAndCondition };
