import {
  PASSWORD_CHANGE_PROCESS,
  CHANGE_USER_PASSWORD,
  RESET_PASSWORD_CHANGE_PROCESS,
  SET_AUTH_WITH_PASSWORD,
  UNSET_AUTH_WITH_PASSWORD,
  VERIFIED_USER_PASSOWRD,
  CLEAR_PASSWORD_VERIFICATION_ERRORS,
  CLEAR_PASSWORD_CHANGE_ERRORS,
  RESET_EMAIL_CHANGE_PROCESS,
  EMAIL_CHANGE_PROCESS,
  APPROVE_EMAIL_CHANGE,
  CLEAR_EMAIL_UPDATE_ERRORS,
  CHANGE_USER_EMAIL_REQUEST,
  RESEND_ACTIVATION_EMAIL_LOGGED,
  RESET_RESEND_ACTIVATION_EMAIL_LOGGED,
  FETCH_APLICACIONES_CON_CONSENTIMIENTO,
  REVOCAR_CONSENTIMIENTO_APLICACION,
  REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS,
  RESET_CONSENTIMIENTO_REVOCADO,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  prevPath: null,
  verifiedPasswordErrors: null, // errores al verificar contraseña
  verifiedPassword: false, // contraseña verificada
  authForPasswordChange: true, // flag para pedir autenticación para cambio de contraseña
  authForEmailChange: true, // flag para pedir autenticación para cambio de email
  startedPasswordChange: false, // flag para marcar comienzo de cambio de contraseña
  successfulPasswordChange: false, // resultado del proceso de cambio de contraseña
  startedEmailChange: false, // flag para marcar comienzo de cambio de email
  successfulEmailChange: false, // resultado del proceso de cambio de email
  sendingActivationEmail: null, // flag para marcar comienzo de envio de email de activacion
  successfulSendingActivationEmail: null, // resultado del proceso de envio de email
  passwordChangeErrors: null,
  emailUpdateErrors: null,
  loading: false,
  aplicacionesConConsentimiento: [],
  removed: null,
  removing: false,
};

// eslint-disable-next-line default-param-last
export default function seguridadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_EMAIL_UPDATE_ERRORS:
      return {
        ...state,
        emailUpdateErrors: null,
      };
    case RESET_EMAIL_CHANGE_PROCESS:
      return {
        ...state,
        startedEmailChange: false,
        successfulEmailChange: false,
      };
    // EMAIL_CHANGE_PROCESS
    case EMAIL_CHANGE_PROCESS.PENDING:
      return {
        ...state,
        startedEmailChange: true,
        successfulEmailChange: false,
        emailUpdateErrors: null,
      };
    case EMAIL_CHANGE_PROCESS.SUCCESS:
      return {
        ...state,
        startedEmailChange: null,
        successfulEmailChange: null,
        emailUpdateErrors: null,
      };
    case EMAIL_CHANGE_PROCESS.ERROR:
      return {
        ...state,
        startedEmailChange: false,
        successfulEmailChange: true,
      };
    // APPROVE_EMAIL_CHANGE
    case APPROVE_EMAIL_CHANGE.PENDING:
      return {
        ...state,
        loading: true,
      };
    case APPROVE_EMAIL_CHANGE.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case APPROVE_EMAIL_CHANGE.ERROR:
      return {
        ...state,
        loading: false,
      };
    // CLEAR_PASSWORD_CHANGE_ERRORS
    case CLEAR_PASSWORD_CHANGE_ERRORS:
      return {
        ...state,
        passwordChangeErrors: null,
      };
    case CHANGE_USER_EMAIL_REQUEST.SUCCESS:
      return {
        ...state,
        loading: false,
        emailUpdateErrors: null,
        // currentUser: action.payload.data,
      };
    case CHANGE_USER_EMAIL_REQUEST.ERROR:
      return {
        ...state,
        emailUpdateErrors: action.payload,
        loading: false,
      };
    case CHANGE_USER_EMAIL_REQUEST.PENDING:
      return {
        ...state,
        emailUpdateErrors: null,
        loading: true,
      };
    // CLEAR_PASSWORD_VERIFICATION_ERRORS
    case CLEAR_PASSWORD_VERIFICATION_ERRORS:
      return {
        ...state,
        verifiedPasswordErrors: null,
      };
    // VERIFIED_USER_PASSOWRD
    case VERIFIED_USER_PASSOWRD.PENDING:
      return {
        ...state,
        verifiedPassword: false,
        verifiedPasswordErrors: null,
        loading: true,
      };
    case VERIFIED_USER_PASSOWRD.SUCCESS:
      return {
        ...state,
        verifiedPassword: true,
        verifiedPasswordErrors: null,
        loading: false,
      };
    case VERIFIED_USER_PASSOWRD.ERROR:
      return {
        ...state,
        verifiedPassword: false,
        verifiedPasswordErrors: action.payload,
        loading: false,
      };
    // SET_AUTH_WITH_PASSWORD
    case SET_AUTH_WITH_PASSWORD:
      return {
        ...state,
        authForPasswordChange: true,
        authForEmailChange: true,
      };
    // UNSET_AUTH_WITH_PASSWORD
    case UNSET_AUTH_WITH_PASSWORD:
      return {
        ...state,
        authForPasswordChange: false,
        authForEmailChange: false,
      };
    // RESET_PASSWORD_CHANGE_PROCESS
    case RESET_PASSWORD_CHANGE_PROCESS:
      return {
        ...state,
        startedPasswordChange: false,
        successfulPasswordChange: false,
      };
    // PASSWORD_CHANGE_PROCESS
    case PASSWORD_CHANGE_PROCESS.PENDING:
      return {
        ...state,
        startedPasswordChange: true,
        successfulPasswordChange: false,
        passwordChangeErrors: null,
      };
    case PASSWORD_CHANGE_PROCESS.SUCCESS:
      return {
        ...state,
        startedPasswordChange: null,
        successfulPasswordChange: null,
        passwordChangeErrors: null,
      };
    case PASSWORD_CHANGE_PROCESS.ERROR:
      return {
        ...state,
        startedPasswordChange: false,
        successfulPasswordChange: true,
      };
    case CHANGE_USER_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        passwordChangeErrors: null,
        // currentUser: action.payload.data,
      };
    case CHANGE_USER_PASSWORD.ERROR:
      return {
        ...state,
        passwordChangeErrors: action.payload,
        loading: false,
      };
    case CHANGE_USER_PASSWORD.PENDING:
      return {
        ...state,
        loading: true,
        passwordChangeErrors: null,
      };
    case RESEND_ACTIVATION_EMAIL_LOGGED.PENDING:
      return {
        ...state,
        sendingActivationEmail: true,
        successfulSendingActivationEmail: false,
      };
    case RESEND_ACTIVATION_EMAIL_LOGGED.SUCCESS:
      return {
        ...state,
        sendingActivationEmail: false,
        successfulSendingActivationEmail: true,
      };
    case RESEND_ACTIVATION_EMAIL_LOGGED.ERROR:
      return {
        ...state,
        sendingActivationEmail: false,
        successfulSendingActivationEmail: false,
      };
    case RESET_RESEND_ACTIVATION_EMAIL_LOGGED:
      return {
        ...state,
        sendingActivationEmail: null,
        successfulSendingActivationEmail: null,
      };

    // ---------------------------------------------------------------------------
    // Aplicaciones
    // ---------------------------------------------------------------------------
    case FETCH_APLICACIONES_CON_CONSENTIMIENTO.SUCCESS:
      return {
        ...state,
        aplicacionesConConsentimiento: action.payload.data,
        loading: false,
      };
    case FETCH_APLICACIONES_CON_CONSENTIMIENTO.ERROR:
      return {
        ...state,
        aplicacionesConConsentimiento: [],
        loading: false,
      };
    case FETCH_APLICACIONES_CON_CONSENTIMIENTO.PENDING:
      return {
        ...state,
        loading: true,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.SUCCESS:
      return {
        ...state,
        removing: false,
        aplicacionesConConsentimiento:
          state.aplicacionesConConsentimiento.filter(
            element => element.id !== action.payload.userConsentID,
          ),
        removed: true,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.ERROR:
      return {
        ...state,
        removing: false,
        removed: false,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.PENDING:
      return {
        ...state,
        removing: true,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION.ERROR:
      return {
        ...state,
        loading: false,
      };
    case REVOCAR_CONSENTIMIENTO_APLICACION.PENDING:
      return {
        ...state,
        loading: true,
      };
    case RESET_CONSENTIMIENTO_REVOCADO:
      return {
        ...state,
        loading: false,
        removing: false,
        removed: null,
      };
    default:
      return state;
  }
}
