/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  REQUERIDO,
  MFA_TOTP_CODE_LENGTH,
  TOTP_CODE_ERROR,
  EXACT_LEN_ERROR,
} from 'constants/commonConstants';

import { RenderField } from 'components/RenderField (Nuevo)';
import CardSmall from 'components/CardSmall';
import ButtonSection from 'components/ButtonSection';

const Form = props => {
  const {
    values,
    touched,
    handleChange,
    handleSubmit,
    loading,
    handleBack,
    handleContinueText,
    errors,
    envioCodigoErrors,
    resetErrors,
  } = props;

  return (
    <main aria-labelledby="formulario-verificar-totp">
      <form onSubmit={handleSubmit}>
        <RenderField
          name="codigo"
          type="text"
          value={values.codigo}
          expanded={false}
          label="Código"
          onChange={handleChange}
          resetFieldError={resetErrors}
          hidePlaceholder
          disabled={loading}
          error={errors.codigo || envioCodigoErrors}
          touched={touched.codigo}
          autocomplete="off"
          autofocus
        />
        <div>
          <ButtonSection
            textPrimary={handleContinueText}
            isSubmit
            isLoadingPrimary={loading}
            ariaLabelPrimary="Verificar código TOTP"
            handleClickSecondary={handleBack}
            textSecondary="Cancelar"
            isVertical
          />
        </div>
      </form>
    </main>
  );
};

const TOTPVerificarCodigoForm = withFormik({
  mapPropsToValues: () => ({
    codigo: '',
  }),

  validationSchema: Yup.object().shape({
    codigo: Yup.string()
      .required(REQUERIDO)
      .matches(/^[0-9]*$/, TOTP_CODE_ERROR)
      .min(MFA_TOTP_CODE_LENGTH, EXACT_LEN_ERROR(MFA_TOTP_CODE_LENGTH))
      .max(MFA_TOTP_CODE_LENGTH, EXACT_LEN_ERROR(MFA_TOTP_CODE_LENGTH)),
  }),

  handleSubmit: (values, bag) => {
    if (!bag.props.loading) {
      bag.props.handleContinue(values);
    }
  },
})(Form);

const TOTPVerificarCodigo = props => {
  const {
    title,
    desc,
    handleContinue,
    handleContinueText,
    handleBack,
    envioCodigoErrors,
    resetErrors,
    loading,
  } = props;

  return (
    <CardSmall>
      <div className="totp-card__title">{title}</div>
      <div className="totp-card__desc">{desc}</div>
      <TOTPVerificarCodigoForm
        handleContinue={handleContinue}
        handleContinueText={handleContinueText}
        envioCodigoErrors={envioCodigoErrors}
        resetErrors={resetErrors}
        loading={loading}
        handleBack={handleBack}
      />
    </CardSmall>
  );
};

TOTPVerificarCodigo.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  handleContinueText: PropTypes.string,
  handleContinue: PropTypes.func,
  handleBack: PropTypes.func,
  envioCodigoErrors: PropTypes.string,
  resetErrors: PropTypes.func,
  loading: PropTypes.bool,
};
export default TOTPVerificarCodigo;
