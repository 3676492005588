import difflib from 'difflib';
import {
  VALIDADOR_TIPO_ERROR,
  VALIDADOR_VALOR_ERROR,
  ONLY_NUMBERS_VALIDATOR_MSG,
  VALIDADOR_TIPO_AVISO,
  VALIDADOR_VAL_NULL,
  REQUERIDO_VALIDADOR_MENSAJE,
  getMensajeErrorLargoMinimo,
  VALIDADOR_VALOR_AVISO,
  VALIDADOR_AVISO,
  MIN_LEN_PASSWORD,
  EXCELENTE_LEN_PASSWORD,
  VALIDADOR_BUENO,
  VALIDADOR_EXCELENTE,
  VALIDADOR_VALOR_BUENO,
  VALIDADOR_VALOR_EXCELENTE,
  VALIDADOR_TIPO_OK,
  PASSWORD_SIMILARITY_MSG,
  PERCENT_SIMILARITY_PASSWORD,
  DOCUMENTO_INVALIDO,
  CONTRASENAS_DEBEN_SER_IGUALES,
  REQUERIDO,
  CORREOS_DEBEN_SER_IGUALES,
  CORREO_INVALIDO,
  NOMBRE_INVALIDO,
  MAX_LEN_NAMES,
  MAX_LEN_DOC,
  getMensajeErrorLargoMaximo,
  FORMATO_CI_INVALIDO,
} from 'constants/commonConstants';

//----------------------------------------------------------------------------
// Regexs
//----------------------------------------------------------------------------
export const onlyNumbersRegex = /^\d+$/;
export const validNameRegex =
  /^[A-Za-zñÑäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÇçÃẼĨÕŨãẽĩõũ\-'`´ ]+$/;
export const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/i;

//----------------------------------------------------------------------------
// Funciones auxiliares
//----------------------------------------------------------------------------
// Validador digito CI.
const digitValidator = ci => {
  if (!ci) return false;
  let a = 0;
  let i = 0;
  let value = ci;
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i += 1) {
      value = `0${value}`;
    }
  }
  for (i = 0; i < 7; i += 1) {
    a += (parseInt('2987634'[i], 10) * parseInt(value[i], 10)) % 10;
  }
  const result = a % 10 === 0 ? 0 : 10 - (a % 10);
  return result;
};

export const formatoCI = ci => {
  const dig = ci[ci.length - 1];
  const num = ci.toString().slice(0, -1);
  if (dig !== digitValidator(num).toString() || ci.length > 8) return false;
  return true;
};

// Validador CI Uruguaya
export const uruguayanCIValidator = ci => {
  if (ci && !onlyNumbersRegex.test(ci)) return FORMATO_CI_INVALIDO;
  if (!ci || !formatoCI(ci)) return DOCUMENTO_INVALIDO;
  return null;
};

export const notOnlyNumbersRegex = /.*?[^0-9].*/;

export const maxLengthValidator = (value, max) => {
  if (value) {
    return value.length > max;
  }
  return false;
};

export const documentValidator = value => value && !value.includes('-');

// ----------------------------------------------------------------------------
// Respuestas de validadores
// ----------------------------------------------------------------------------
export const createResponse = (msg, val, type) => ({
  msg,
  val: `${val}%`,
  classVal: type,
});

// ----------------------------------------------------------------------------
// Validadores
// ----------------------------------------------------------------------------
export const required = value =>
  value
    ? undefined
    : createResponse(REQUERIDO, VALIDADOR_VALOR_ERROR, VALIDADOR_TIPO_ERROR);

export const matchEmail = (value, values) =>
  value !== values.email
    ? createResponse(
        CORREOS_DEBEN_SER_IGUALES,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

export const repeatEmail = (value, values) =>
  value !== values.email
    ? createResponse(
        CORREOS_DEBEN_SER_IGUALES,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

export const email = value =>
  value && !validEmailRegex.test(value)
    ? createResponse(
        CORREO_INVALIDO,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

// Validador email para formik
export const validEmail = value => (value ? validEmailRegex.test(value) : true);

export const validUruguayanCI = value =>
  uruguayanCIValidator(value) === null
    ? undefined
    : createResponse(
        uruguayanCIValidator(value),
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      );

export const validName = value =>
  validNameRegex.test(value)
    ? undefined
    : createResponse(
        NOMBRE_INVALIDO,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      );

export const maxLenght = value =>
  maxLengthValidator(value, MAX_LEN_NAMES)
    ? createResponse(
        getMensajeErrorLargoMaximo(MAX_LEN_NAMES),
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

export const maxLenghtDocument = value =>
  maxLengthValidator(value, MAX_LEN_DOC)
    ? createResponse(
        getMensajeErrorLargoMaximo(MAX_LEN_DOC),
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

export const validDocument = value =>
  documentValidator(value)
    ? createResponse(
        DOCUMENTO_INVALIDO,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;
// ----------------------------------------------------------------------------
// Validadores de contraseña
// ----------------------------------------------------------------------------

// Validador Match contraseña y repetir contraseña
export const matchPassword = (value, values) =>
  value !== values.password
    ? createResponse(
        CONTRASENAS_DEBEN_SER_IGUALES,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

// Validador contraseña requerida
export const requiredPassword = value =>
  value
    ? undefined
    : createResponse(
        REQUERIDO_VALIDADOR_MENSAJE,
        VALIDADOR_VAL_NULL,
        VALIDADOR_TIPO_AVISO,
      );

// Validador contraseña solo números
export const onlyNumbers = value =>
  /^\d+$/.test(value)
    ? createResponse(
        ONLY_NUMBERS_VALIDATOR_MSG,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

// Validador contraseña solo números para formik
export const passwordOnlyNumbers = value => !/^\d+$/.test(value);

// Validador mínimo número de caracteres.
export const minLen = value =>
  value.length < MIN_LEN_PASSWORD
    ? createResponse(
        getMensajeErrorLargoMinimo(value),
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

// Validador para similaridad de contraseña con datos personales.
export const failSimilarity = (value, values) => {
  const copy = { ...values };
  Object.keys(copy).forEach(key => {
    if (copy[key] === '') {
      delete copy[key];
    }
  });
  delete copy.password;
  delete copy.repetirPassword;
  delete copy.tipoDocumento;
  delete copy.paisEmisor;
  delete copy.terminos;
  delete copy.captchaResponse;
  return Object.values(copy).some(data =>
    data
      .split(/[\W\s_]/)
      .concat(data)
      .some(
        splittedData =>
          new difflib.SequenceMatcher(
            null,
            splittedData.toLowerCase(),
            value.toLowerCase(),
          ).quickRatio() > PERCENT_SIMILARITY_PASSWORD,
      ),
  );
};

export const passSimilarity = (value, values) =>
  failSimilarity(value, values)
    ? createResponse(
        PASSWORD_SIMILARITY_MSG,
        VALIDADOR_VALOR_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

// Validador para similaridad de contraseña con datos personales para formik.
const failPasswordSimilarity = (props, value = '') => {
  let segundoApellido = '';
  let segundoNombre = '';

  if (props.segundo_apellido !== null) {
    segundoApellido = props.segundo_apellido;
  }

  if (props.segundo_nombre !== null) {
    segundoNombre = props.segundo_nombre;
  }

  const copy = {
    email: props.email,
    numero_documento: props.numero_documento,
    primer_apellido: props.primer_apellido,
    primer_nombre: props.primer_nombre,
    segundo_apellido: segundoApellido,
    segundo_nombre: segundoNombre,
  };
  return Object.values(copy).some(data =>
    data
      .split(/[\W\s_]/)
      .concat(data)
      .some(
        splittedData =>
          new difflib.SequenceMatcher(
            null,
            splittedData.toLowerCase(),
            value.toLowerCase(),
          ).quickRatio() > PERCENT_SIMILARITY_PASSWORD,
      ),
  );
};

export const passwordSimilarity = (props, value) =>
  !failPasswordSimilarity(props, value);

// Validador: Warning, aceptable minimamente.
export const warning = value =>
  value.length === MIN_LEN_PASSWORD
    ? createResponse(
        VALIDADOR_AVISO,
        VALIDADOR_VALOR_AVISO,
        VALIDADOR_TIPO_AVISO,
      )
    : undefined;

// Validador: Good.
export const good = value =>
  value.length > MIN_LEN_PASSWORD && value.length < EXCELENTE_LEN_PASSWORD
    ? createResponse(VALIDADOR_BUENO, VALIDADOR_VALOR_BUENO, VALIDADOR_TIPO_OK)
    : undefined;

// Validador: Excelent.
export const excelent = value =>
  value.length >= EXCELENTE_LEN_PASSWORD
    ? createResponse(
        VALIDADOR_EXCELENTE,
        VALIDADOR_VALOR_EXCELENTE,
        VALIDADOR_TIPO_OK,
      )
    : undefined;
