/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FORTALEZA_PASSWORD,
  PASSWORD_SEGURA,
  TIP_PASSWORD_SEGURA_1,
  TIP_PASSWORD_SEGURA_2,
} from 'constants/commonConstants';

import widgetStyles from './stylesWidget.module.scss';
import appStyles from '../../index.module.css';

const PasswordWidget = ({
  showTips,
  barWidth,
  message,
  className,
  handleShowTips,
}) => (
  <div className={widgetStyles['password-field__popup']}>
    <h4 className={widgetStyles['password-field__popup__title']}>
      {FORTALEZA_PASSWORD}
    </h4>
    {message && (
      <div>
        <div className={widgetStyles['password-field__bar-base']}>
          <div
            style={{ width: `${barWidth}%` }}
            className={widgetStyles[`password-field__bar-${className}`]}
          ></div>
        </div>
        <p
          className={`${appStyles.caption} ${
            widgetStyles[`password-field__text-${className}`]
          }`}
        >
          {message}
        </p>
      </div>
    )}
    {showTips ? (
      <div className={widgetStyles['password-field__popup__tips']}>
        <p className={appStyles.caption}>{TIP_PASSWORD_SEGURA_1}</p>
        <p className={appStyles.caption}>{TIP_PASSWORD_SEGURA_2}</p>
      </div>
    ) : (
      <button
        className={`${appStyles.caption} ${widgetStyles['password-field__popup__tips__link']}`}
        onClick={handleShowTips}
        tabIndex="-1"
      >
        {PASSWORD_SEGURA}
      </button>
    )}
  </div>
);

export default PasswordWidget;
