import React, { useState } from 'react';

// utils
import Auth from 'utils/Auth';

// componentes
import EnvioInstrucciones from 'components/EnvioInstrucciones';
import CardSpinner from 'components/CardSpinner';
import Snackbar from 'components/Snackbar';

// constantes
import {
  SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION,
  SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION_ERROR,
  YA_CASI_TERMINAS,
  REVISA_CORREO_ELECTRONICO,
  DEFAULT_SNACKBAR_INFO,
} from 'constants/commonConstants';

// api services
import { reenviarEmailActivación } from 'services/apiServices';

const ReenvioActivacionSuccess = () => {
  const [snackBarInfo, setSnackBarInfo] = useState(DEFAULT_SNACKBAR_INFO);
  const [cargandoReenvioEmail, setCargandoReenvioEmail] = useState(false);

  const handleResendEmail = async e => {
    e.preventDefault();
    try {
      setCargandoReenvioEmail(true);
      await reenviarEmailActivación(Auth.getInstance().getUserId());
      setSnackBarInfo({
        mostrar: true,
        texto: SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION,
      });
    } catch {
      setSnackBarInfo({
        mostrar: true,
        texto: SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION_ERROR,
      });
    } finally {
      setCargandoReenvioEmail(false);
    }
  };

  if (cargandoReenvioEmail) {
    return <CardSpinner spin />;
  }

  return (
    <>
      <EnvioInstrucciones
        title={YA_CASI_TERMINAS}
        desc={REVISA_CORREO_ELECTRONICO}
        action1={handleResendEmail}
      />
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo(DEFAULT_SNACKBAR_INFO)}
      />
    </>
  );
};

export default ReenvioActivacionSuccess;
