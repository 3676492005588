/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

const diasLetras = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const Calendario = ({
  fechasDisponibles,
  setFechaSeleccionada,
  fechaSeleccionada,
}) => {
  const handleOnChange = newDate => {
    setFechaSeleccionada(newDate);
  };

  return (
    <div className="calendario">
      <Calendar
        locale="es-ES"
        onChange={handleOnChange}
        value={fechaSeleccionada || fechasDisponibles[0]}
        formatShortWeekday={(_, date) => diasLetras[date.getDay()]}
        formatMonthYear={(_, date) =>
          `${meses[date.getMonth()]} ${date.getFullYear()}`
        }
        maxDate={fechasDisponibles[fechasDisponibles.length - 1]}
        minDate={fechasDisponibles[0]}
        next2Label={null}
        prev2Label={null}
        tileDisabled={date =>
          fechasDisponibles.find(
            dia => dayjs(dia).diff(dayjs(date.date), 'day') === 0,
          ) === undefined
        }
      />
    </div>
  );
};

Calendario.propTypes = {
  fechasDisponibles: PropTypes.arrayOf(PropTypes.object),
  setFechaSeleccionada: PropTypes.func,
  fechaSeleccionada: PropTypes.object,
};

export default Calendario;
