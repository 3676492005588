import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const ModalBox = ({
  abierto,
  cargando,
  titulo,
  texto,
  onClose,
  modalClass,
  children,
}) => {
  const cerrarModal = () => {
    if (!cargando) {
      document.body.classList.remove('no-scroll');
      onClose();
    }
  };

  const modalClassAux = modalClass || styles.modal;

  return (
    <ReactModal
      isOpen={abierto}
      contentLabel="Modal"
      className={modalClassAux}
      overlayClassName={styles.modal_overlay}
      appElement={document.getElementById('root')}
      ariaHideApp={false}
    >
      <button
        type="button"
        className={styles.modal_close_button}
        onClick={cerrarModal}
      >
        &times;
      </button>
      {titulo && <h1 className={styles.titulo}>{titulo}</h1>}
      <p className={styles.modal_content}>{texto}</p>
      {children}
    </ReactModal>
  );
};

ModalBox.propTypes = {
  abierto: PropTypes.bool,
  cargando: PropTypes.bool,
  titulo: PropTypes.string,
  texto: PropTypes.string,
  onClose: PropTypes.func,
  modalClass: PropTypes.string,
  children: PropTypes.node,
};

export default ModalBox;
