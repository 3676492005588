import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { VOLVER_ATRAS } from 'constants/commonConstants';
import volver from 'assets/icons/volver.svg';
import styles from './styles.module.css';

const BotonVolver = ({ funcionIrAtras, texto }) => {
  const history = useHistory();
  const defaultIrAtras = () => {
    history.go(-1);
  };

  return (
    <div className={styles.volver}>
      <button
        className={styles.volver__boton}
        type="button"
        onClick={funcionIrAtras || defaultIrAtras}
        aria-label={VOLVER_ATRAS}
      >
        <img src={volver} className={styles.volver__boton__icono} alt={texto} />
        <span className={styles.volver__boton__texto}>{texto}</span>
      </button>
    </div>
  );
};

BotonVolver.propTypes = {
  funcionIrAtras: PropTypes.func,
  texto: PropTypes.string,
};

BotonVolver.defaultProps = {
  texto: VOLVER_ATRAS,
};

export default BotonVolver;
