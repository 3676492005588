/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// utils
import Auth from 'utils/Auth';

// constantes
import {
  CHANGE_PASSWORD_ROUTE,
  MFA_PAGE_ROUTE,
  CHANGE_USER_EMAIL_ROUTE,
  CHANGE_USER_EMAIL_ROUTE_SUCCESS,
  RESENDED_ACTIVATION_EMAIL_LOGGED,
  SEGURIDAD_ROUTE,
  CAMBIAR_TELEFONO_ROUTE,
  AUTOGESTION_IDP,
} from 'constants/RouterConstants';
import { SECURITY_TITLE } from 'constants/pageTitles';
import {
  SNACKBAR_TEXTO_REENVIO_EMAIL_VERIFICACION,
  TEXTO_HUBO_ERROR_RETIRAR_ACCESO,
  TEXTO_SE_RETIRO_ACCESO,
  VOLVER_ATRAS,
} from 'constants/commonConstants';

// actions
import {
  fetchAplicacionesConConsentimiento,
  updateUserEmail,
  revocarConsentimientoAplicacion,
  resetConsentimientoRevocado,
  resendActivationEmailLogged,
} from 'actions/seguridad';
import { fetchMFA } from 'actions/mfa';
import { showInternalHeader, updateAriaLiveMessage } from 'actions/ui';

// componentes
import BotonVolver from 'components/BotonVolver';
import CardSpinner from 'components/CardSpinner';
import Snackbar from 'components/Snackbar';
import SeguridadComponent from './seguridad';

const SeguridadContainer = props => {
  const [mostrarModalRetirarAcceso, setMostrarModalRetirarAcceso] =
    useState(false);
  const [mostrarModalFirmaElectronica, setMostrarModalFirmaElectronica] =
    useState(false);
  const [serviceName, setServiceName] = useState('');
  const [userConsentID, setUserConsentID] = useState(null);
  const [mostrarSnackBar, setMostrarSnackBar] = useState(false);
  const [snackBarTexto, setSnackBarTexto] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  // --------------------------------------------------------------------------
  // Snackbar
  // --------------------------------------------------------------------------
  const mostrarSnackbarConsentimientoRevocado = value => {
    const textoCompleto = value
      ? TEXTO_SE_RETIRO_ACCESO
      : TEXTO_HUBO_ERROR_RETIRAR_ACCESO;

    setMostrarSnackBar(true);
    setSnackBarTexto(textoCompleto);
    dispatch(resetConsentimientoRevocado());
  };

  // --------------------------------------------------------------------------
  // Modal
  // --------------------------------------------------------------------------
  const noScrollBody = shouldNotScroll => {
    if (shouldNotScroll) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const handleBotonVolver = () => {
    history.push(AUTOGESTION_IDP);
  };

  const handleMostrarModalRetirarAcceso = (servicio, ucID) => {
    setServiceName(servicio);
    setUserConsentID(ucID);
    setMostrarModalRetirarAcceso(!mostrarModalRetirarAcceso);
    noScrollBody(mostrarModalRetirarAcceso);
  };

  const handleMostrarModalFirmaElectronica = () => {
    setMostrarModalFirmaElectronica(!mostrarModalFirmaElectronica);
    noScrollBody(mostrarModalFirmaElectronica);
  };

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  const handleCambioContrasena = event => {
    event.preventDefault();
    history.push(CHANGE_PASSWORD_ROUTE);
  };

  const handleReenviarValidacion = e => {
    e.preventDefault();
    if (props.currentUser.email_por_validar.email) {
      dispatch(updateUserEmail(props.currentUser.email_por_validar.email));
      setMostrarSnackBar(true);
      setSnackBarTexto(SNACKBAR_TEXTO_REENVIO_EMAIL_VERIFICACION);
    }
  };

  const handleReenviarActivacionCuenta = e => {
    e.preventDefault();
    if (
      !props.currentUser.cuenta_activa &&
      props.currentUser.email_por_validar.email
    ) {
      dispatch(resendActivationEmailLogged(Auth.getInstance().getUserId()));
    }
  };

  const handleCambiarCorreoElectronico = e => {
    e.preventDefault();
    history.push(CHANGE_USER_EMAIL_ROUTE);
  };

  const handleCambiarTelefono = e => {
    e.preventDefault();
    history.push(CAMBIAR_TELEFONO_ROUTE);
  };

  const handleGoToMfa = e => {
    e.preventDefault();
    history.push(MFA_PAGE_ROUTE);
  };

  const removeUserConsent = () => {
    handleMostrarModalRetirarAcceso();
    dispatch(revocarConsentimientoAplicacion(userConsentID));
  };

  useEffect(() => {
    dispatch(
      fetchAplicacionesConConsentimiento(Auth.getInstance().getUserId()),
    );
    if (!Auth.getInstance().getEsIDP()) {
      dispatch(fetchMFA(Auth.getInstance().getUserId()));
    }
    dispatch(showInternalHeader());
    dispatch(updateAriaLiveMessage(SECURITY_TITLE));

    // Parsea y muestra mensaje del backend (si existe)
    const parsed = queryString.parse(props.location.search);
    history.replace(SEGURIDAD_ROUTE);
    const msg = parsed.msg ? parsed.msg : null;
    if (msg) {
      setSnackBarTexto(msg);
      setMostrarSnackBar(true);
    }
  }, []);

  useEffect(() => {
    if (props.consentimientoRevocado === true) {
      mostrarSnackbarConsentimientoRevocado(true);
    } else if (props.consentimientoRevocado === false) {
      mostrarSnackbarConsentimientoRevocado(false);
    }
    if (
      props.startedEmailChange === null &&
      props.successfulEmailChange === null
    ) {
      history.push(CHANGE_USER_EMAIL_ROUTE_SUCCESS);
    }
    if (
      props.sendingActivationEmail === true &&
      props.successfulSendingActivationEmail === false
    ) {
      history.push(RESENDED_ACTIVATION_EMAIL_LOGGED);
    }
  }, [
    props.consentimientoRevocado,
    props.startedEmailChange,
    props.successfulEmailChange,
    props.sendingActivationEmail,
    props.successfulSendingActivationEmail,
  ]);

  if (props.loading || props.mfa.loading) {
    return <CardSpinner spin />;
  }

  return (
    <>
      <Helmet title={SECURITY_TITLE} />
      <BotonVolver texto={VOLVER_ATRAS} handlePrevious={handleBotonVolver} />
      <SeguridadComponent
        {...props}
        buttonEmailCallback={handleCambiarCorreoElectronico}
        buttonTelefonoCallback={handleCambiarTelefono}
        handleReenviarActivacionCuenta={handleReenviarActivacionCuenta}
        handleReenviarValidacion={handleReenviarValidacion}
        handleContraseña={handleCambioContrasena}
        showModalRetirarAcceso={mostrarModalRetirarAcceso}
        handleShowModalRetirarAcceso={handleMostrarModalRetirarAcceso}
        showModalFirmaElectronica={mostrarModalFirmaElectronica}
        handleShowModalFirmaElectronica={handleMostrarModalFirmaElectronica}
        serviceName={serviceName}
        removeUserConsent={removeUserConsent}
        loadingRemoveUserConsent={props.revocandoConsentimiento}
        goToMfa={handleGoToMfa}
      />
      <Snackbar
        desplegar={mostrarSnackBar}
        mensaje={snackBarTexto}
        onClose={() => setMostrarSnackBar(false)}
      />
    </>
  );
};

SeguridadContainer.propTypes = {
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
  startedEmailChange: PropTypes.bool,
  successfulEmailChange: PropTypes.bool,
  sendingActivationEmail: PropTypes.bool,
  successfulSendingActivationEmail: PropTypes.bool,
  ultimo_cambio_contrasena: PropTypes.string,
  authForPasswordChange: PropTypes.bool,
  aplicacionesConConsentimiento: PropTypes.array,
  consentimientoRevocado: PropTypes.bool,
  revocandoConsentimiento: PropTypes.bool,
  isOpenModalDigitalSignature: PropTypes.bool,
  mfa: PropTypes.object,
  updateUserEmail: PropTypes.func,
  resendActivationEmailLogged: PropTypes.func,
  showInternalHeader: PropTypes.func,
  updateAriaLiveMessage: PropTypes.func,
  fetchAplicacionesConConsentimiento: PropTypes.func,
  revocarConsentimientoAplicacion: PropTypes.func,
  resetConsentimientoRevocado: PropTypes.func,
  fetchMFA: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = state => ({
  loading: state.mis_datos.loading,
  currentUser: state.mis_datos.currentUser,
  startedEmailChange: state.seguridad.startedEmailChange,
  successfulEmailChange: state.seguridad.successfulEmailChange,
  sendingActivationEmail: state.seguridad.sendingActivationEmail,
  successfulSendingActivationEmail:
    state.seguridad.successfulSendingActivationEmail,
  ultimo_cambio_contrasena:
    state.mis_datos.currentUser?.ultimo_cambio_contrasena,
  authForPasswordChange: state.seguridad.authForPasswordChange,
  aplicacionesConConsentimiento: state.seguridad.aplicacionesConConsentimiento,
  consentimientoRevocado: state.seguridad.removed,
  revocandoConsentimiento: state.seguridad.removing,
  isOpenModalDigitalSignature:
    state.ui.misDatos.showModalAssociateDigitalSignature,
  mfa: state.mfa,
});

export default connect(mapStateToProps, {
  updateUserEmail,
  resendActivationEmailLogged,
  showInternalHeader,
  updateAriaLiveMessage,
  fetchAplicacionesConConsentimiento,
  revocarConsentimientoAplicacion,
  resetConsentimientoRevocado,
  fetchMFA,
})(SeguridadContainer);
