/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

import CardSpinner from 'components/CardSpinner';

import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import {
  fetchPaises,
  createCiudadano,
  resetRegistro,
  clearRegistroErrors,
} from 'actions/registro';
import { SIGNUP_TITLE } from 'constants/pageTitles';
import Registro from './registro';

class RegistroContainer extends React.Component {
  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(SIGNUP_TITLE);
  }

  componentWillUnmount() {
    this.props.resetRegistro();
  }

  render() {
    if (this.props.loading) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={SIGNUP_TITLE} />
        <Registro {...this.props} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  creating: state.registro.creating,
  loading: state.registro.loading,
  paises: state.registro.paises,
  ciudadanoCreated: state.registro.ciudadanoCreated,
  registroErrors: state.registro.registroErrors,
  formErrors: state.registro.registroErrors,
  next: state.registro.next,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  fetchPaises,
  createCiudadano,
  resetRegistro,
  clearRegistroErrors,
  updateAriaLiveMessage,
})(RegistroContainer);
