/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  REQUERIDO_VALIDADOR_MENSAJE,
  REQUERIDO,
  CONTRASENA_INVALIDA,
  ONLY_NUMBERS_VALIDATOR_MSG,
  PASSWORD_SIMILARITY_MSG,
  PASSWORD_MOSTRAR_CONSTRASEÑA,
} from 'constants/commonConstants';
import { handleChangeErrorWidget } from 'utils/utils';
import showPassword from 'assets/icons/show_password.svg';
import hidePassword from 'assets/icons/hide-password.svg';

import './styles.css';
import ButtonLink from '../ButtonLink';

// ---------------------------------------------------------------------------
// Helper Component
// ---------------------------------------------------------------------------
const HelperTextAndErrors = ({
  showHelperText,
  helperTextMsg,
  showError,
  errorMsg,
}) =>
  showHelperText ? (
    <div>
      <div className="hidden-tablet" style={{ position: 'relative' }}>
        <div className="popup-nuevo">
          <span>{helperTextMsg}</span>
        </div>
      </div>
      <div className="hidden-desktop">
        <span className="caption">{helperTextMsg}</span>
      </div>
    </div>
  ) : (
    (showError && (
      <span className="render-field-nuevo__help-text__error">{errorMsg}</span>
    )) ||
    null
  );

// ---------------------------------------------------------------------------
// RenderField
// ---------------------------------------------------------------------------
export const RenderField = ({
  tabIndex,
  type,
  label,
  name,
  value,
  disabled,
  required,
  placeholder,
  hidePlaceholder,
  hasTooltip,
  resetFieldError,
  expanded,
  style,
  linkUrl,
  linkText,
  handleLink,
  error,
  touched,
  onChange,
  onBlur,
  onFocus,
  msg,
  ariaLabel,
  autocomplete,
  autofocus,
  className,
  notTwoPoints,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [typeInput, setTypeInput] = useState(type);
  const [imgButton, setImgButton] = useState(showPassword);
  const [altImgButton, setAltImgButton] = useState('Mostrar contraseña');

  const [showPasswordCheckbox, setShowPasswordCheckbox] = useState(false);

  const handleFocus = () => {
    setShowPopup(true);
  };

  const handleBlur = () => {
    setShowPopup(false);
  };

  const togglePassword = () => {
    if (typeInput === 'password') {
      setTypeInput('text');
      setImgButton(hidePassword);
      setAltImgButton('Ocultar contraseña');
    } else {
      setTypeInput('password');
      setImgButton(showPassword);
      setAltImgButton('Mostrar contraseña');
    }
  };

  const togglePasswordCheckbox = () => {
    if (typeInput === 'password') {
      setTypeInput('text');
      setShowPasswordCheckbox(!showPasswordCheckbox);
    } else {
      setTypeInput('password');
      setShowPasswordCheckbox(!showPasswordCheckbox);
    }
  };

  const showHelperText = showPopup && hasTooltip;

  const esSafari =
    navigator.userAgent.indexOf('Safari') !== -1 &&
    navigator.userAgent.indexOf('Chrome') === -1;

  return (
    <div
      className={`${className || ''} ${
        expanded ? 'render-field-nuevo--expanded' : 'render-field-nuevo'
      }`}
      style={style}
    >
      <div className="render-field-nuevo__label-container">
        <div>
          <label
            className="render-field-nuevo__label-container__label"
            htmlFor={name}
          >{`${label}${required ? ' *' : ''}${notTwoPoints ? '' : ':'}`}</label>
        </div>
      </div>
      <div className="render-field-nuevo__input-div-container">
        <span className="render-field-nuevo__input-container">
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autofocus}
            className={
              error && touched ? 'render-field-nuevo__input--error' : ''
            }
            value={value}
            tabIndex={tabIndex}
            placeholder={hidePlaceholder ? null : placeholder}
            type={typeInput}
            disabled={disabled}
            id={name}
            onFocus={e => {
              if (onFocus) onFocus(e);
              handleFocus();
            }}
            onBlur={e => {
              if (onBlur) onBlur(e);
              handleBlur();
            }}
            onChange={e => {
              if (onChange) onChange(e);
              if (resetFieldError) resetFieldError(e);
            }}
            aria-label={ariaLabel || label}
            autoComplete={autocomplete}
          />
          {type === 'password' && !esSafari && (
            <button
              tabIndex="-1"
              className="render-field-nuevo__input-container__button"
              type="button"
              onClick={togglePassword}
            >
              <img tabIndex="-1" alt={altImgButton} src={imgButton} />
            </button>
          )}
        </span>
      </div>
      {error && touched && (
        <div className="render-field-nuevo__helper-text-container">
          <HelperTextAndErrors
            showHelperText={showHelperText}
            helperTextMsg={msg}
            showError={error && touched}
            errorMsg={error}
          />
        </div>
      )}
      {esSafari ? (
        <div className="checkbox-olvide-mi-contraseña-contenedor">
          {type === 'password' && (
            <div className="checkbox-contenedor">
              <CheckboxField
                input
                name={name}
                label={PASSWORD_MOSTRAR_CONSTRASEÑA}
                onChange={togglePasswordCheckbox}
                checked={showPasswordCheckbox}
              />
            </div>
          )}
          <div className="render-field-nuevo__label-container__link_container">
            {linkText && (
              <ButtonLink
                title={linkText}
                className="render-field-nuevo__label-container__link"
                to={linkUrl}
                onClick={handleLink}
                aria-label={linkText}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="render-field-nuevo__label-container__link_container">
          {linkText && (
            <ButtonLink
              title={linkText}
              className="render-field-nuevo__label-container__link"
              to={linkUrl}
              onClick={handleLink}
              aria-label={linkText}
            />
          )}
        </div>
      )}
    </div>
  );
};

RenderField.propTypes = {
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  resetFieldError: PropTypes.func,
  expanded: PropTypes.bool,
  style: PropTypes.object,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  handleLink: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  msg: PropTypes.string,
  ariaLabel: PropTypes.string,
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  notTwoPoints: PropTypes.bool,
};

RenderField.defaultProps = {
  tabIndex: 0,
  expanded: false,
  linkUrl: '',
  autocomplete: 'on',
  autofocus: false,
  notTwoPoints: false,
};

// ---------------------------------------------------------------------------
// SelectField
// ---------------------------------------------------------------------------
export const SelectField = ({
  data,
  defaultOption,
  disabled,
  error,
  expanded,
  hidePlaceholder,
  label,
  name,
  onBlur,
  onChange,
  onChangeCustom,
  required,
  resetFieldError,
  tabIndex = 0,
  touched,
  value,
  className,
  notTwoPoints,
}) => (
  <div
    className={`${className || ''} ${
      expanded ? 'render-field-nuevo--expanded' : 'render-field-nuevo'
    } `}
  >
    <span className="render-field-nuevo__label-container">
      <label className="alt" htmlFor={name}>{`${label}${required ? ' *' : ''}${
        notTwoPoints ? '' : ':'
      }`}</label>
    </span>
    <span
      className={`render-field-nuevo__input-container ${
        notTwoPoints ? 'render-field-nuevo__foreign-user' : ''
      }`}
    >
      <select
        placeholder={hidePlaceholder ? null : label}
        disabled={disabled}
        tabIndex={tabIndex}
        id={name}
        value={value || ''}
        className={error && touched ? 'render-field-nuevo__input--error' : ''}
        onChange={e => {
          if (onChange) onChange(e);
          if (onChangeCustom) onChangeCustom(e);
          if (resetFieldError) resetFieldError(e);
        }}
        onBlur={e => {
          if (onBlur) onBlur(e);
        }}
      >
        {defaultOption && (
          <option value="" disabled>
            {defaultOption}
          </option>
        )}
        {data &&
          data.map(obj => (
            <option key={obj.value} value={obj.value}>
              {obj.label}
            </option>
          ))}
      </select>
    </span>
    {error && touched && (
      <div className="render-field-nuevo__helper-text-container">
        <span className="render-field-nuevo__help-text__error">{error}</span>
      </div>
    )}
  </div>
);

SelectField.propTypes = {
  data: PropTypes.array,
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  expanded: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  resetFieldError: PropTypes.func,
  tabIndex: PropTypes.number,
  touched: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  notTwoPoints: PropTypes.bool,
};

SelectField.defaultProps = {
  notTwoPoints: false,
};

// ---------------------------------------------------------------------------
// TextField
// ---------------------------------------------------------------------------
export const TextField = ({
  tabIndex = 0,
  disabled,
  label,
  name,
  required,
  hidePlaceholder,
  placeholder,
  expanded = false,
  error,
  touched,
  onChange,
  resetFieldError,
  className,
}) => (
  <div
    className={`${className || ''} ${
      expanded ? 'render-field-nuevo--expanded' : 'render-field-nuevo'
    }`}
  >
    <span className="render-field-nuevo__label-container render-field-nuevo__label-container--text-field">
      <label className="alt" htmlFor={name}>{`${label}${
        required ? ' *' : ''
      }:`}</label>
    </span>
    <span className="render-field-nuevo__input-container">
      <textarea
        tabIndex={tabIndex}
        placeholder={hidePlaceholder ? null : placeholder}
        disabled={disabled}
        id={name}
        className={error && touched ? 'render-field-nuevo__input--error' : ''}
        onChange={e => {
          if (onChange) onChange(e);
          if (resetFieldError) resetFieldError(e);
        }}
      />
    </span>
    <div className="render-field-nuevo__helper-text-container">
      {error && touched && (
        <span className="render-field-nuevo__help-text__error">{error}</span>
      )}
    </div>
  </div>
);

TextField.propTypes = {
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  expanded: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  resetFieldError: PropTypes.func,
  className: PropTypes.string,
};

// ---------------------------------------------------------------------------
// CheckboxField
// ---------------------------------------------------------------------------
export const CheckboxField = ({
  tabIndex = 0,
  name,
  disabled,
  label,
  error,
  touched,
  onFocus,
  onBlur,
  onChange,
  resetFieldError,
  className,
  checked,
}) => (
  <div className={`render-field-nuevo checkbox-field-nuevo ${className}`}>
    <input
      tabIndex={tabIndex}
      type="checkbox"
      role="button"
      disabled={disabled}
      id={name}
      aria-label={label}
      aria-pressed="true"
      onFocus={e => {
        if (onFocus) onFocus(e);
      }}
      onBlur={e => {
        if (onBlur) onBlur(e);
      }}
      onChange={e => {
        if (onChange) onChange(e);
        if (resetFieldError) resetFieldError(e);
      }}
      className="checkbox-field-nuevo__checkbox"
      checked={checked}
    />
    <label className="alt" htmlFor={name}>
      {label}
    </label>
    {error && touched && (
      <div className="render-field-nuevo__help-text__error">{error}</div>
    )}
  </div>
);

CheckboxField.propTypes = {
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  resetFieldError: PropTypes.func,
};

CheckboxField.defaultProps = {
  className: '',
  checked: false,
};

// ---------------------------------------------------------------------------
// CaptchaField
// ---------------------------------------------------------------------------
export const CaptchaField = ({
  error,
  name,
  recaptchaRef,
  resetFieldError,
  setFieldValue,
  tabIndex,
  touched,
}) => (
  <div className="render-field-nuevo captcha-nuevo">
    <ReCAPTCHA
      hl="es"
      ref={recaptchaRef}
      sitekey={window.REACT_APP_RECAPTCHA_KEY}
      onChange={response => {
        setFieldValue(name, response || '');
        if (resetFieldError) resetFieldError(response);
      }}
      tabIndex={tabIndex}
      id={name}
    />
    {error && touched && (
      <span className="render-field-nuevo__help-text__error">{error}</span>
    )}
  </div>
);

CaptchaField.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  recaptchaRef: PropTypes.object,
  resetFieldError: PropTypes.func,
  tabIndex: PropTypes.number,
  touched: PropTypes.bool,
};

CaptchaField.defaultProps = {
  tabIndex: 0,
};

const showErrors = (error, widgetText) => {
  if (error === REQUERIDO) {
    return (
      <span className="render-field-nuevo__help-text__error">{REQUERIDO}</span>
    );
  }
  if (
    widgetText === ONLY_NUMBERS_VALIDATOR_MSG ||
    widgetText === PASSWORD_SIMILARITY_MSG ||
    widgetText === REQUERIDO_VALIDADOR_MENSAJE
  ) {
    return (
      <span className="render-field-nuevo__help-text__error">
        {CONTRASENA_INVALIDA}
      </span>
    );
  }
  return <span className="render-field-nuevo__help-text__error">{error}</span>;
};

// ---------------------------------------------------------------------------
// PasswordField
// ---------------------------------------------------------------------------
export class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWidget: false,
      showTips: false,
      showingTips: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleShowTips = this.handleShowTips.bind(this);
  }

  // ---------------------------------------------------------------------------
  // Events
  // ---------------------------------------------------------------------------
  handleFocus() {
    this.setState({ showWidget: true });
  }

  handleBlur() {
    setTimeout(() => {
      if (!this.state.showingTips) {
        this.setState({ showWidget: false, showTips: false });
      } else {
        this.setState({ showingTips: false });
        // eslint-disable-next-line react/no-find-dom-node, react/no-string-refs
        ReactDOM.findDOMNode(this.refs.passwordInput).focus();
      }
    }, 150);
  }

  handleShowTips() {
    this.setState({ showTips: true, showingTips: true });
  }

  // ---------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------
  render() {
    const {
      label,
      value,
      name,
      type,
      onFocus,
      onBlur,
      onChange,
      required,
      tabIndex,
      resetFieldError,
      expanded,
      error,
      touched,
    } = this.props;

    const msgs = handleChangeErrorWidget(value, error);
    const barWidth = msgs ? { width: msgs.val } : { width: 0 };

    return (
      <div
        className={`${
          expanded ? 'render-field-nuevo--expanded' : 'render-field-nuevo'
        } password-field-nuevo`}
      >
        <span className="render-field-nuevo__label-container">
          <label className="alt" htmlFor={name}>{`${label}${
            required ? ' *' : ''
          }:`}</label>
        </span>
        <div className="render-field-nuevo__input-container">
          <input
            placeholder={label}
            type={type}
            value={value}
            tabIndex={tabIndex}
            id={name}
            onFocus={e => {
              if (onFocus) onFocus(e);
              this.handleFocus();
            }}
            onBlur={e => {
              if (onBlur) onBlur(e);
              this.handleBlur();
            }}
            onChange={e => {
              if (onChange) onChange(e);
              if (resetFieldError) resetFieldError(e);
            }}
            // eslint-disable-next-line react/no-string-refs
            ref="passwordInput"
            className={
              error && touched ? 'render-field-nuevo__input--error' : ''
            }
          />
          <div style={{ position: 'relative' }}>
            {this.state.showWidget && (
              <div className="password-field-nuevo__popup">
                <h4 className="password-field-nuevo__popup__title">
                  Fortaleza de tu contraseña
                </h4>
                <div>
                  <div className="password-field__bar-base">
                    <div
                      style={barWidth}
                      className={`password-field__bar-${msgs.code}`}
                    ></div>
                  </div>
                  <p className={`caption password-field__text-${msgs.code}`}>
                    {msgs.text}
                  </p>
                </div>
                {this.state.showTips ? (
                  <div className="password-field-nuevo__popup__tips">
                    <p className="caption">
                      Una contraseña segura es larga y difícil de adivinar. Crea
                      una frase larga que sea entendible únicamente por ti.
                    </p>
                    <p className="caption">
                      Podés agregarle números y caracteres especiales si querés,
                      pero tené en cuenta que sea fácil de recordar después!
                    </p>
                  </div>
                ) : (
                  // eslint-disable-next-line react/button-has-type
                  <button
                    className="caption password-field-nuevo__popup__tips__link"
                    onClick={this.handleShowTips}
                    tabIndex={-1}
                  >
                    ¿Cómo es una contraseña segura?
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="render-field-nuevo__helper-text-container">
          {error && touched && showErrors(error, msgs.text)}
        </div>
      </div>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  tabIndex: PropTypes.number,
  resetFieldError: PropTypes.func,
  expanded: PropTypes.bool,
};

// ---------------------------------------------------------------------------
// RadioButtonField
// ---------------------------------------------------------------------------
export const RadioButtonField = ({
  tabIndex = 0,
  name,
  label,
  value,
  disabled,
  onChange,
  resetFieldError,
}) => (
  <div className="radio-buttton-field-nuevo">
    <input
      id={`${name}-${value}`}
      tabIndex={tabIndex}
      type="radio"
      disabled={disabled}
      name={name}
      aria-label={label}
      value={value}
      className="radio-buttton-field-nuevo__radio-button"
      onChange={e => {
        if (onChange) onChange(e);
        if (resetFieldError) resetFieldError(e);
      }}
    />
    <label className="alt" htmlFor={`${name}-${value}`}>
      {label}
    </label>
  </div>
);

RadioButtonField.propTypes = {
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  msg: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  resetFieldError: PropTypes.func,
};
