/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import ButtonSection from 'components/ButtonSection';
import ButtonLink from 'components/ButtonLink';

import Auth from 'utils/Auth';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { updateUserEmail } from 'actions/seguridad';
import { fetchEmailPorValidar } from 'actions/misDatos';
import {
  AYUDA_ROUTE,
  CONTACTO_ROUTE,
  CHANGE_USER_EMAIL_ROUTE_SUCCESS,
  LOGIN_ROUTE,
  CHANGE_USER_EMAIL_ROUTE,
} from 'constants/RouterConstants';
import { CHANGE_EMAIL_ERROR_TITLE } from 'constants/pageTitles';

class CambioEmailErrorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickResendValidationLink =
      this.handleClickResendValidationLink.bind(this);
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(CHANGE_EMAIL_ERROR_TITLE);
    if (this.props.isAuthenticated) {
      this.props.fetchEmailPorValidar(Auth.getInstance().getUserId());
    }
  }

  handleClickResendValidationLink() {
    if (this.props.isAuthenticated) {
      if (this.props.email_por_validar) {
        this.props.updateUserEmail(this.props.email_por_validar);
        this.props.history.push(CHANGE_USER_EMAIL_ROUTE_SUCCESS);
      } else {
        this.props.history.push(CHANGE_USER_EMAIL_ROUTE);
      }
    } else {
      this.props.history.push(LOGIN_ROUTE);
    }
  }

  render() {
    if (this.props.loading) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={CHANGE_EMAIL_ERROR_TITLE} />
        <CardSmall
          title="Ups, ocurrió un problema..."
          desc="Al parecer el vínculo no existe o expiró y ya no es válido. 
          Para continuar te recomendamos solicitar uno nuevo."
        >
          <ButtonSection
            textPrimary={
              this.props.isAuthenticated
                ? 'Solicitar vínculo nuevo'
                : 'Iniciar Sesión'
            }
            handleClickPrimary={this.handleClickResendValidationLink}
          />
          <div className="instrucciones__divider"></div>
          <div className="caption">
            <span>Por más información visita nuestra sección de </span>
            <ButtonLink to={AYUDA_ROUTE} title="Preguntas Frecuentes" />
            <span> o </span>
            <ButtonLink to={CONTACTO_ROUTE} title="comunicate con nosotros." />
          </div>
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.mis_datos.loadingEmailPorValidar,
  email_por_validar:
    state.mis_datos.currentUser && state.mis_datos.currentUser.email_por_validar
      ? state.mis_datos.currentUser.email_por_validar.email
      : null,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  updateUserEmail,
  fetchEmailPorValidar,
  updateAriaLiveMessage,
})(CambioEmailErrorContainer);
