/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ButtonSolid from 'components/ButtonSolid';
import ButtonLink from 'components/ButtonLink';

import './mfa.css';

// ------------------------------------------------------------
// Cédula de Identidad
// ------------------------------------------------------------
const CIMFAField = ({
  title,
  description,
  img,
  loading,
  nextText,
  handleNext,
}) => (
  <div className="mfa__ci_container">
    <div className="mfa__ci_card">
      <h2 className="mfa__ci_card__titulo">{title}</h2>
      <div className="mfa__ci_card__contenido">
        <div className="mfa__subheading" style={{ marginTop: '0' }}>
          {description}{' '}
          <ButtonLink
            style={{ fontSize: '16px' }}
            external
            newTab
            to="https://www.sconnect.com/tests/auto/GA/"
          >
            Clic aquí.
          </ButtonLink>
        </div>
        <div className="mfa-container">
          <div className="mfa-container__img">
            <img src={img} alt="Ícono Inicio de Usuario gub.uy" />
          </div>
        </div>
        <ButtonSolid
          text={nextText}
          handleClick={handleNext}
          isLoading={loading}
          className="mfa__ci_card__contenido__continuar"
        />
      </div>
    </div>
  </div>
);

CIMFAField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  img: PropTypes.PropTypes.array,
  loading: PropTypes.bool,
  nextText: PropTypes.string,
  handleNext: PropTypes.func,
};

export default CIMFAField;
