import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card';
import ButtonLink from 'components/ButtonLink';
import TitleCard from 'components/TitleCard';
import { CONTACTO_ROUTE, AYUDA_ROUTE } from 'constants/RouterConstants';

import iconContacto from 'assets/icons/contactanos.png';
import iconContacto2x from 'assets/icons/contactanos@2x.png';
import iconContacto3x from 'assets/icons/contactanos@3x.png';

import './ayuda.css';

const AyudaFooter = () => (
  <div className="ayuda__footer">
    <div className="ayuda__footer__icon">
      <img
        src={iconContacto}
        srcSet={`${iconContacto2x} 2x, ${iconContacto3x} 3x`}
        alt="Contáctate con nosotros"
      />
    </div>
    <div className="subheading ayuda__footer__text">
      ¿No encontraste una respuesta?
    </div>
    <div className="subheading ayuda__footer__link">
      <ButtonLink to={CONTACTO_ROUTE} title="Contáctate con nosotros »" />
    </div>
  </div>
);

const AyudaItems = ({ faqs }) => {
  const items = faqs
    ? faqs.map(faq => (
        <div className="ayuda__items-container__link" key={faq.id}>
          <ButtonLink
            to={{ pathname: `${AYUDA_ROUTE}/${faq.slug}`, state: { faq } }}
            title={faq.titulo}
          />
        </div>
      ))
    : null;
  return <div>{items}</div>;
};

AyudaItems.propTypes = {
  faqs: PropTypes.array,
};

const AyudaList = ({ listaFaqs }) => {
  const seccion = listaFaqs.length
    ? listaFaqs.map(obj => (
        <div key={obj.nombre}>
          <h2 className="margin-title">{obj.nombre}</h2>
          <AyudaItems faqs={obj.faqs} />
        </div>
      ))
    : null;
  return <div>{seccion}</div>;
};

AyudaList.propTypes = {
  listaFaqs: PropTypes.array,
};

const Ayuda = ({ leftList, rightList }) => (
  <Card className="ayuda">
    <TitleCard title="Preguntas frecuentes" />
    <div className="row ayuda__items-container">
      <div className="col-md-6 p-l-0">
        <AyudaList listaFaqs={leftList} />
      </div>
      <div className="col-md-6 p-l-0">
        <AyudaList listaFaqs={rightList} />
      </div>
    </div>
    <hr className="ayuda__line"></hr>
    <AyudaFooter />
  </Card>
);

Ayuda.propTypes = {
  leftList: PropTypes.array,
  rightList: PropTypes.array,
};

export default Ayuda;
