/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import ReactCSSTransitionReplace from 'react-css-transition-replace';

import ButtonSolid from 'components/ButtonSolid';
import ButtonOutline from 'components/ButtonOutline';
import { RenderField } from 'components/RenderField (Nuevo)';
import {
  CONTACTO_LINK_ARIA_LABEL,
  CONTACTO_LINK_TEXT,
  CONTACTO_TEXT,
  EXACT_LEN_ERROR,
  FOREIGN_USERNAME_CHALLENGE,
  MFA_RECOVERY_CODE_LENGTH,
  MFA_TOTP_CODE_LENGTH,
  PASSWORD_FIELD_NAME,
  RECOVERY_CODE_ERROR,
  RECOVERY_CODE_FIELD_NAME,
  REQUERIDO,
  RESEND_EMAIL_LINK_ARIA_LABEL,
  RESEND_EMAIL_LINK_TEXT,
  RESEND_EMAIL_TEXT,
  TOTP_CODE_ERROR,
  TOTP_FIELD_NAME,
  USERNAME_FIELD_NAME,
  ADD_INST_EMAIL_FIELD_NAME,
  ALERT_DOMAINS_TEXT,
  UNREGISTERED_DOMAIN_ERROR,
  REESTABLECE_TU_CONTRASENA,
  NO_RECIBISTE_CORREO,
  MFA_SOLICITAR_NUEVO_EMAIL,
} from 'constants/commonConstants';
import {
  CONTACTO_ROUTE,
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
  RESET_PASSWORD_ROUTE,
} from 'constants/RouterConstants';

import './mfa.css';
import ButtonLink from 'components/ButtonLink';
import Alert from 'components/Alert';
import TextoMultiformato from 'components/TextoMultiformato';
import { LoginSection } from './loginSection';

export const MFA = ({ children, direction }) => (
  <div className="mfa">
    <div>
      <ReactCSSTransitionReplace
        transitionName={`slide-${direction}`}
        transitionEnter={direction === 'left' || direction === 'right'}
        transitionLeave={direction === 'left' || direction === 'right'}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {children}
      </ReactCSSTransitionReplace>
      <ReactCSSTransitionReplace
        transitionName="slide-idps"
        transitionEnter={direction === 'left' || direction === 'right'}
        transitionLeave={direction === 'left' || direction === 'right'}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <div>
          {children.props.type === 'idp' && (
            <div className="mfa__idp_section">
              <LoginSection
                handleNext={children.props.handleNext}
                gub_uy_allowed={children.props.gub_uy_allowed}
                identityProviders={children.props.identityProviders}
              />
            </div>
          )}
          {(children.props.name === USERNAME_FIELD_NAME ||
            children.props.name === FOREIGN_USERNAME_CHALLENGE) && (
            <div className="mfa__info">
              <div className="mfa__info__line" />
              <ButtonOutline
                text="Crear mi identificación digital"
                className="mfa__info__crear"
                handleClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  children.props.type === 'idp'
                    ? (window.location.href =
                        '/metodos-disponibles-diferencias')
                    : (window.location.href = '/registro');
                }}
                isLoading={false}
                isSecondary
                isMFA
              />
              {children.props.type === 'idp' && (
                <ButtonLink
                  className="mfa__info__que-es"
                  buttonType="header-dark"
                  to="https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/politicas-y-gestion/identidad-digital"
                  aria-label="¿Qué es la identificación digital?"
                  title="¿Qué es la identificación digital?"
                  external
                  newTab
                />
              )}
            </div>
          )}
        </div>
      </ReactCSSTransitionReplace>
    </div>
  </div>
);

const EmailSection = ({ handleLink }) => (
  <div className="mfa__email mfa__email--section">
    <p>
      {RESEND_EMAIL_TEXT}
      <ButtonLink
        title={RESEND_EMAIL_LINK_TEXT}
        className="mfa__link"
        type="button"
        onClick={handleLink}
        aria-label={RESEND_EMAIL_LINK_ARIA_LABEL('código')}
      />
    </p>
    <p>
      {CONTACTO_TEXT}
      <ButtonLink
        title={CONTACTO_LINK_TEXT}
        className="mfa__link"
        to={CONTACTO_ROUTE}
        aria-label={CONTACTO_LINK_ARIA_LABEL}
      />
    </p>
  </div>
);

const Form = props => {
  const {
    ariaLabel,
    envioFormError,
    errors,
    handleChange,
    handleLink,
    handleSubmit,
    label,
    linkText,
    linkUrl,
    loading,
    name,
    nextText,
    onChange,
    placeholder,
    touched,
    type,
    values,
  } = props;

  const getFieldProp = prop => {
    if (name === USERNAME_FIELD_NAME) {
      return prop.username;
    }
    if (name === PASSWORD_FIELD_NAME) {
      return prop.password;
    }
    if (name === TOTP_FIELD_NAME) {
      return prop.totp;
    }
    if (name === RECOVERY_CODE_FIELD_NAME) {
      return prop.recoverycode;
    }
    if (name === ADD_INST_EMAIL_FIELD_NAME) {
      return prop.institutional_email;
    }
    return null;
  };

  const errorHandler = () => {
    if (getFieldProp(errors)) {
      return getFieldProp(errors);
    }
    if (name === USERNAME_FIELD_NAME && envioFormError?.field === name) {
      if (envioFormError.code === 'account_blocked') {
        return (
          <div className="resolver-error-bloqueado-contenedor">
            {envioFormError.error}
            <a className="resolver-error-link" href={RESET_PASSWORD_ROUTE}>
              {REESTABLECE_TU_CONTRASENA}
            </a>
          </div>
        );
      }
      if (envioFormError.code === 'account_no_activated') {
        return (
          <div className="resolver-error-no-activado-contenedor">
            {envioFormError.error}
            <div className="resolver-error-textos">
              <TextoMultiformato
                listaNormal={[`${NO_RECIBISTE_CORREO} `]}
                listaLink={[
                  [
                    MFA_SOLICITAR_NUEVO_EMAIL,
                    REGISTRO_REENVIAR_CORREO_ACTIVACION,
                  ],
                ]}
              />
            </div>
          </div>
        );
      }
      return envioFormError.error;
    }
    if (envioFormError?.field.split(':')[3] === name) {
      return envioFormError.error;
    }
    if (name === ADD_INST_EMAIL_FIELD_NAME && envioFormError?.field === name) {
      return envioFormError.error;
    }
    return null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <RenderField
        ariaLabel={ariaLabel}
        autocomplete="off"
        autofocus
        disabled={loading}
        error={errorHandler()}
        handleLink={handleLink}
        label={label}
        linkText={linkText}
        linkUrl={linkUrl}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        resetFieldError={onChange}
        touched={getFieldProp(touched)}
        type={type}
        value={getFieldProp(values)}
        notTwoPoints
      />

      <div className="mfa__action-buttons">
        <ButtonSolid
          className="mfa__action-buttons__continuar"
          text={nextText}
          isSubmit
          isLoading={loading}
        />
      </div>
    </form>
  );
};

const MFAForm = withFormik({
  mapPropsToValues: props => ({
    [props.name]: props.value,
  }),

  validationSchema: props => {
    if (props.name === USERNAME_FIELD_NAME) {
      return Yup.object().shape({
        username: Yup.string().required(REQUERIDO),
      });
    }
    if (props.name === PASSWORD_FIELD_NAME) {
      return Yup.object().shape({
        password: Yup.string().required(REQUERIDO),
      });
    }
    if (props.name === TOTP_FIELD_NAME) {
      return Yup.object().shape({
        totp: Yup.string()
          .required(REQUERIDO)
          .matches(/^[0-9]*$/, TOTP_CODE_ERROR)
          .min(MFA_TOTP_CODE_LENGTH, EXACT_LEN_ERROR(MFA_TOTP_CODE_LENGTH))
          .max(MFA_TOTP_CODE_LENGTH, EXACT_LEN_ERROR(MFA_TOTP_CODE_LENGTH)),
      });
    }
    if (props.name === RECOVERY_CODE_FIELD_NAME) {
      return Yup.object().shape({
        recoverycode: Yup.string()
          .required(REQUERIDO)
          .matches(/^[a-zA-Z0-9]*$/, RECOVERY_CODE_ERROR)
          .min(
            MFA_RECOVERY_CODE_LENGTH,
            EXACT_LEN_ERROR(MFA_RECOVERY_CODE_LENGTH),
          )
          .max(
            MFA_RECOVERY_CODE_LENGTH,
            EXACT_LEN_ERROR(MFA_RECOVERY_CODE_LENGTH),
          ),
      });
    }
    if (props.name === ADD_INST_EMAIL_FIELD_NAME) {
      return Yup.object().shape({
        institutional_email: Yup.string().required(REQUERIDO),
      });
    }
    return null;
  },

  handleSubmit: (values, bag) => {
    if (!bag.props.loading) {
      bag.props.handleNext();
    }
  },
})(Form);

// Username y password---------------------------------------------
export const MFAField = ({
  ariaLabel,
  cancelLoading,
  className,
  description,
  email,
  errores,
  handleLink,
  handleNext,
  handlePrevious,
  label,
  linkText,
  linkUrl,
  loading,
  name,
  nextText,
  onChange,
  placeholder,
  previousText,
  title,
  type,
  value,
  nombre_ciudadano,
  nivel_seguridad,
  extraFieldValue,
}) => (
  <div className={className}>
    <h2 className="mfa__title">{title}</h2>
    {name === 'totp' && (
      <div className="mfa__totp">
        <p className="mfa__totp__informacion">
          Para ingresar, tienes que ingresar el código de tu aplicación de
          autenticación
        </p>
        <div className="mfa__totp__donde_encuentro">
          <ButtonLink
            className="mfa__totp__donde_encuentro__link"
            buttonType="header-dark"
            to="/ayuda/que-es-una-app-de-autenticacion"
            aria-label="¿Dónde encuentro el código?"
            title="¿Dónde encuentro el código?"
            external={false}
            newTab={false}
          />
        </div>
      </div>
    )}
    <div className="mfa__contenido">
      {type === 'idp' && (
        <div className="mfa__subtitles">
          <ButtonLink
            className="mfa__subtitles__cual-elijo"
            buttonType="header-dark"
            to="/metodos-disponibles-diferencias"
            aria-label="¿Cuál elijo?"
            title="¿Cuál elijo?"
            external={false}
            newTab={false}
          />
          {/* tooltip */}
          <div>
            <div className="tooltip">
              Nivel de seguridad
              <div className="tooltiptext">
                El nivel de tu Identificación Digital te permite acceder a más
                servicios o trámites y refiere a la seguridad de tu cuenta.
              </div>
            </div>
          </div>
        </div>
      )}
      {name === 'totp' && (
        <div className="mfa__user_level">
          <Alert
            listErrors={null}
            type="info"
            text={`El usuario corresponde a un ${nivel_seguridad}`}
          />
        </div>
      )}
      {extraFieldValue && (
        <div>
          <p className="mfa__extra_field--title">
            {name === 'totp' ? nombre_ciudadano : 'Número de documento'}
          </p>
          <div className="mfa__extra_field--value">
            <p className="mfa__extra_field">{extraFieldValue}</p>
          </div>
        </div>
      )}
      {description && (
        <div className="mfa__subheading">
          {description}
          {email && <b style={{ letterSpacing: '1px' }}>{email}</b>}.
        </div>
      )}
      {email && (
        <div className="mfa__subheading mfa__email mfa__email--info">
          Puede encontrarse en los correos no deseados (SPAM).
        </div>
      )}
      {type !== 'idp' && (
        <div className="mfa-container">
          <MFAForm
            ariaLabel={ariaLabel || label}
            cancelLoading={cancelLoading}
            envioFormError={errores}
            handleLink={handleLink}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            label={label}
            linkText={linkText}
            linkUrl={linkUrl}
            loading={loading}
            name={name}
            nextText={nextText}
            onChange={onChange}
            placeholder={placeholder}
            previousText={previousText}
            type={type}
            value={value}
          />
        </div>
      )}
      {name === 'totp' && handleLink && (
        <ButtonLink
          title="Recibir código por correo electrónico"
          className="mfa__link mfa__link--card-bottom recibir_por_correo"
          type="button"
          onClick={handleLink}
          aria-label="Recibir código por correo electrónico"
        />
      )}
      {email && <EmailSection handleLink={handleLink} />}
      {type !== 'idp' && name !== 'totp' && name !== 'recoverycode' && (
        <div>
          {errores.error === UNREGISTERED_DOMAIN_ERROR && (
            <Alert
              type="warning"
              className="seguridad__alert"
              text={ALERT_DOMAINS_TEXT}
              isMFA
            />
          )}
        </div>
      )}
    </div>
  </div>
);

MFAField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  cancelLoading: PropTypes.bool,
  onChange: PropTypes.func,
  nextText: PropTypes.string,
  handleNext: PropTypes.func,
  previousText: PropTypes.string,
  handlePrevious: PropTypes.func,
  handleLink: PropTypes.func,
  errores: PropTypes.object,
  ariaLabel: PropTypes.string,
  email: PropTypes.string,
  nombre_ciudadano: PropTypes.string,
  nivel_seguridad: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  gub_uy_allowed: PropTypes.bool,
  extraFieldValue: PropTypes.string,
};

MFAField.defaultProps = {
  description: null,
  className: null,
  linkText: null,
  linkUrl: '',
  handleLink: null,
  email: null,
  gub_uy_allowed: true,
  extraFieldValue: null,
};
