import React from 'react';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

import Card from '../Card';
import styles from './styles.module.css';

const CardSpinner = ({ spin, text = 'Cargando...', className }) => (
  <Card
    className={`${styles.spinner} ${styles.spinner__transparent} ${className}`}
    innerClassName={styles.card__inner__spinner}
  >
    {spin ? (
      <div className={styles.spinner__container}>
        <Spinner
          className={styles.spin}
          name="ball-spin-fade-loader"
          fadeIn="none"
          color={styles.blue}
        />
        <div className={`${styles.headline} ${styles.spin__text}`}>{text}</div>
      </div>
    ) : null}
  </Card>
);

CardSpinner.propTypes = {
  spin: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default CardSpinner;
