/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import {
  AYUDA_ROUTE,
  CONTACTO_ROUTE,
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
} from 'constants/RouterConstants';
import {
  UPS_PROBLEMA,
  LINK_INEXISTENTE_O_INVALIDO,
  SOLICITAR_VINCULO_NUEVO,
  PREGUNTAS_FRECUENTES,
  COMUNICATE_CON_NOSOTROS,
} from 'constants/commonConstants';
import CardSmall from 'components/CardSmall';
import ButtonLink from 'components/ButtonLink';
import ButtonSection from 'components/ButtonSection';
import { SIGNUP_ERROR_TITLE } from 'constants/pageTitles';

const RegistroErrorContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(SIGNUP_ERROR_TITLE));
  }, []);

  return (
    <>
      <Helmet title={SIGNUP_ERROR_TITLE} />
      <CardSmall title={UPS_PROBLEMA} desc={LINK_INEXISTENTE_O_INVALIDO}>
        <div>
          <ButtonSection
            textPrimary={SOLICITAR_VINCULO_NUEVO}
            ariaLabelPrimary="Solicitar nuevo vínculo para activar cuenta"
            handleClickPrimary={() => {
              history.push(REGISTRO_REENVIAR_CORREO_ACTIVACION);
            }}
          />
          <div className="instrucciones__divider"></div>
          <span>Por más información visita nuestra sección de </span>
          <ButtonLink to={AYUDA_ROUTE} title={PREGUNTAS_FRECUENTES} />
          <span> o </span>
          <ButtonLink to={CONTACTO_ROUTE} title={COMUNICATE_CON_NOSOTROS} />
        </div>
      </CardSmall>
    </>
  );
};

export default RegistroErrorContainer;
