/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import {
  RenderField,
  SelectField,
  TextField,
  CaptchaField,
} from 'components/RenderField (Nuevo)';
import ButtonSolid from 'components/ButtonSolid';
import ButtonLink from 'components/ButtonLink';
import Card from 'components/Card';
import TitleCard from 'components/TitleCard';
import Box from 'components/Box';
import {
  DOCUMENTO_MENSAJE,
  REQUERIDO,
  MAX_LEN_NAMES,
  getMensajeErrorLargoMaximo,
  CORREO_INVALIDO,
} from 'constants/commonConstants';
import { validEmail } from 'utils/validators';

import acDigital from 'assets/ac-digital.svg';
import acPresencial from 'assets/ac-presencial.svg';
import acTelefonica from 'assets/ac-telefonica.svg';

import './contacto.css';

const ContactAside = () => (
  <div className="contacto__aside">
    <h2 className="contacto__aside__title">Atención a la ciudadanía</h2>
    <p className="caption contacto__aside__subtitle">
      Por consultas sobre información de Trámites y Servicios del Estado podés
      dirigirte a nuestros canales de Atención a la Ciudadanía.
    </p>
    <div className="contacto__aside__section">
      <p className="caption contacto__aside__section__title">DIGITAL</p>
      <img
        src={acDigital}
        alt="Contacto a través de medios digitales"
        width="48"
        height="48"
      />
      <div className="contacto__aside__section__info">
        <ButtonLink
          title="info@atencionalaciudadania.gub.uy"
          to="mailto:info@atencionalaciudadania.gub.uy"
          external
        />
        <div>
          Facebook:{' '}
          <ButtonLink
            title="Facebook/AtencionUY"
            to="https://www.facebook.com/atencionuy"
            external
          />
        </div>
      </div>
    </div>
    <div className="contacto__aside__section">
      <p className="caption contacto__aside__section__title">TELEFÓNICA</p>
      <img
        src={acTelefonica}
        alt="Contacto telefónico"
        width="48"
        height="48"
      />
      <div className="contacto__aside__section__info">
        <div>
          <b>0800 INFO</b> (4636)
        </div>
        <div>*463 para celulares</div>
      </div>
    </div>
    <div className="contacto__aside__section">
      <p className="caption contacto__aside__section__title">PRESENCIAL</p>
      <img
        src={acPresencial}
        alt="Contacto de forma presencial"
        width="48"
        height="48"
      />
      <div className="contacto__aside__section__info">
        <ButtonLink
          title="Puntos de Atención a la Ciudadanía"
          to="http://atencionciudadana.gub.uy/"
          external
        />
      </div>
    </div>
  </div>
);

const Form = props => {
  const {
    values,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    asuntosOptions,
    isSubmitting,
    errors,
    envioContactoError,
    recaptchaRef,
  } = props;

  return (
    <main
      className="contacto__container__form"
      aria-labelledby="formulario-contacto"
    >
      <form onSubmit={handleSubmit} id="formulario-contacto">
        <Box title="Datos de Contacto">
          <RenderField
            name="nombre_completo"
            type="text"
            value={values.nombre_completo}
            expanded
            label="Nombre completo"
            onChange={handleChange}
            required
            hidePlaceholder
            disabled={isSubmitting}
            error={
              envioContactoError !== null &&
              envioContactoError.field === 'nombre_completo'
                ? envioContactoError.error
                : errors.nombre_completo
            }
            touched={touched.nombre_completo}
          />
          <RenderField
            name="numero_documento"
            type="text"
            value={values.numero_documento}
            expanded
            hasTooltip
            msg={DOCUMENTO_MENSAJE}
            label="Número de documento"
            onChange={handleChange}
            required
            hidePlaceholder
            disabled={isSubmitting}
            error={
              envioContactoError !== null &&
              envioContactoError.field === 'numero_documento'
                ? envioContactoError.error
                : errors.numero_documento
            }
            touched={touched.numero_documento}
            className="contacto__field-popup"
          />
          <RenderField
            name="email"
            type="email"
            value={values.email}
            expanded
            label="Correo electrónico"
            onChange={handleChange}
            required
            hidePlaceholder
            disabled={isSubmitting}
            error={
              envioContactoError !== null &&
              envioContactoError.field === 'email'
                ? envioContactoError.error
                : errors.email
            }
            touched={touched.email}
          />
          <SelectField
            name="asunto"
            expanded
            label="Asunto"
            onBlur={handleChange}
            data={asuntosOptions}
            value={values.asunto}
            onChange={handleChange}
            defaultOption="Seleccione un asunto"
            required
            hidePlaceholder
            disabled={isSubmitting}
            error={
              envioContactoError !== null &&
              envioContactoError.field === 'asunto'
                ? envioContactoError.error
                : errors.asunto
            }
            touched={touched.asunto}
          />
          <TextField
            name="mensaje"
            expanded
            label="Mensaje"
            onChange={handleChange}
            required
            hidePlaceholder={false}
            placeholder="Escribí acá..."
            disabled={isSubmitting}
            error={
              envioContactoError !== null &&
              envioContactoError.field === 'mensaje'
                ? envioContactoError.error
                : errors.mensaje
            }
            touched={touched.mensaje}
          />
          <div className="captchaContainer">
            <CaptchaField
              name="captcha_response"
              setFieldValue={setFieldValue}
              recaptchaRef={recaptchaRef}
              error={
                envioContactoError !== null &&
                envioContactoError.field === 'captcha_response'
                  ? envioContactoError.error
                  : errors.captcha_response
              }
              touched={touched.captcha_response}
            />
          </div>
          <div className="botonContainer">
            <ButtonSolid
              text="Continuar"
              ariaLabel="Enviar formulario de contacto"
              isSubmit
              isLoading={isSubmitting}
            />
          </div>
        </Box>
      </form>
    </main>
  );
};

const ContactForm = withFormik({
  mapPropsToValues: props => ({
    nombre_completo: props.nombreCompleto,
    numero_documento: props.numeroDocumento,
    email: props.email,
    asunto: '',
    mensaje: '',
    captcha_response: '',
  }),

  validationSchema: Yup.object().shape({
    nombre_completo: Yup.string()
      .required(REQUERIDO)
      .max(MAX_LEN_NAMES, getMensajeErrorLargoMaximo(MAX_LEN_NAMES)),
    numero_documento: Yup.string().required(REQUERIDO),
    email: Yup.string()
      .required(REQUERIDO)
      .test('validEmail', CORREO_INVALIDO, value => validEmail(value)),
    asunto: Yup.string().required(REQUERIDO),
    mensaje: Yup.string().required(REQUERIDO),
    captcha_response: Yup.string().required(REQUERIDO),
  }),

  handleSubmit: async (values, bag) => {
    await bag.props.enviarMensaje(values, bag.setFieldValue);
    bag.setSubmitting(false);
  },
})(Form);

const Contacto = props => (
  <div className="contacto">
    <Card className="contacto--card">
      <TitleCard title={props.title} />
      <div className="headline">{props.subtitle}</div>
      <section className="contacto__container">
        <ContactForm
          nombreCompleto={
            props.currentUser !== null
              ? `${props.currentUser.nombre_completo}`
              : ''
          }
          numeroDocumento={
            props.currentUser !== null ? props.currentUser.numero_documento : ''
          }
          email={
            props.currentUser !== null ? props.currentUser.user?.email : ''
          }
          envioContactoError={props.envioContactoError}
          enviarMensaje={props.enviarMensaje}
          recaptchaRef={props.recaptchaRef}
          asuntosOptions={props.asuntosOptions}
        />
      </section>
    </Card>
    <ContactAside />
  </div>
);

export default Contacto;
