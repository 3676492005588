import React, { useEffect } from 'react';

import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';

const scrollToTop = WrappedComponent => {
  const ComponenteScrollToTop = props => {
    const location = useLocation();
    useEffect(() => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.scrollTop = 0;
      }
    }, [location]);

    return <WrappedComponent {...props} />;
  };
  return withRouter(ComponenteScrollToTop);
};

export default scrollToTop;
