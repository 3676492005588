/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { RenderField } from 'components/RenderField (Nuevo)';
import CardSmall from 'components/CardSmall';
import ButtonSection from 'components/ButtonSection';

import { RESET_PASSWORD_ROUTE } from 'constants/RouterConstants';
import { REQUERIDO } from 'constants/commonConstants';

const Form = props => {
  const {
    values,
    touched,
    handleChange,
    handleSubmit,
    loading,
    handleBack,
    errors,
    resetFieldError,
    passwordErrors,
    field,
  } = props;

  return (
    <div aria-labelledby="formulario-authContrasena">
      <form onSubmit={handleSubmit}>
        <RenderField
          name="password"
          type="password"
          value={values.password}
          label="Contraseña"
          onChange={handleChange}
          hidePlaceholder
          disabled={loading}
          error={
            passwordErrors !== null && field === 'password'
              ? passwordErrors
              : errors.password
          }
          resetFieldError={resetFieldError}
          touched={touched.password}
          style={{ marginBottom: '16px' }}
          linkText="¿Olvidaste tu contraseña?"
          linkUrl={RESET_PASSWORD_ROUTE}
        />
        <ButtonSection
          textPrimary="Continuar"
          isSubmit
          isLoadingPrimary={loading}
          ariaLabelPrimary="Verificar contraseña"
          textSecondary="Volver"
          ariaLabelSecondary="Volver a seguridad"
          handleClickSecondary={handleBack}
          isVertical
        />
        <div className="help-text__error text-center">
          {passwordErrors && field === 'button' ? passwordErrors.error : ''}
        </div>
      </form>
    </div>
  );
};

const AuthCambioContrasenaForm = withFormik({
  mapPropsToValues: () => ({
    password: '',
  }),

  validationSchema: Yup.object().shape({
    password: Yup.string().required(REQUERIDO),
  }),

  handleSubmit: (values, bag) => {
    if (!bag.props.loading) {
      bag.props.handleSubmitAuthCotrasena(values);
    }
  },
})(Form);

const AuthCambioContrasena = props => (
  <CardSmall title={props.title} desc={props.desc}>
    <AuthCambioContrasenaForm
      loading={props.loading}
      handleSubmitAuthCotrasena={props.handleSubmit}
      handleBack={props.handleBack}
      resetFieldError={props.resetFieldError}
      verifiedPasswordErrors={props.verifiedPasswordErrors}
      passwordErrors={props.passwordErrors}
      field={props.field}
    />
  </CardSmall>
);

export default AuthCambioContrasena;
