import React from 'react';
import PropTypes from 'prop-types';

import ButtonSolid from 'components/ButtonSolid';
import ButtonOutline from 'components/ButtonOutline';

import styles from './errorPage.module.css';

const ErrorPageAbitab = ({
  titulo,
  descripcion,
  textoBotonAtras,
  handleBotonAtras,
  textoBotonAbitab,
  handleBotonAbitab,
}) => (
  <div className={styles.error_abitab}>
    <div className={styles.contenido}>
      <h1 className={styles.titulo}>{titulo}</h1>
      <p className={styles.descripcion}>{descripcion}</p>
      <div className={styles.botones}>
        <ButtonSolid
          text={textoBotonAbitab}
          handleClick={handleBotonAbitab}
          className={styles.error__desc__boton_principal}
        />
        <ButtonOutline
          text={textoBotonAtras}
          handleClick={handleBotonAtras}
          className={styles.error__desc__boton_secundario}
        />
      </div>
    </div>
  </div>
);

ErrorPageAbitab.propTypes = {
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
  textoBotonAtras: PropTypes.string,
  handleBotonAtras: PropTypes.func,
  textoBotonAbitab: PropTypes.string,
  handleBotonAbitab: PropTypes.func,
};

export default ErrorPageAbitab;
