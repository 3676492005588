import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { hideFooter } from 'actions/ui';
import { JitsiMeeting } from '@jitsi/react-sdk';

// Constantes
import { SEGURIDAD_ROUTE } from 'constants/RouterConstants';
import { FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL } from 'constants/commonConstants';

// Componentes
import Card from 'components/Card';
import CardSpinner from 'components/CardSpinner';
import BotonVolver from 'components/BotonVolver';

// Estilos
import styles from './ingresoVideollamada.module.css';

const IngresoVideollamada = ({ ocultarFooter, usuarioLogueado, cargando }) => {
  useEffect(() => {
    ocultarFooter();
  }, []);

  const linkVideollamada =
    usuarioLogueado?.info_tramite_videollamada_pendiente?.link;

  const jwtVideollamada =
    usuarioLogueado?.info_tramite_videollamada_pendiente?.jwt;

  const irASeguridad = () => {
    window.location.href = SEGURIDAD_ROUTE;
  };

  if (cargando) {
    return <CardSpinner />;
  }
  return (
    <>
      <BotonVolver
        funcionIrAtras={irASeguridad}
        texto={FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL}
      />
      <Card>
        <JitsiMeeting
          domain={window.REACT_APP_DOMINIO_VIDEOLLAMADA}
          roomName={linkVideollamada}
          jwt={jwtVideollamada}
          getIFrameRef={n => {
            n.classList.add(styles.iframeVideollamada);
          }}
          configOverwrite={{
            defaultLanguage: 'es',
            disableDeepLinking: true,
          }}
          userInfo={{
            displayName: `${usuarioLogueado?.primer_nombre}`,
          }}
          onApiReady={externalApi => {
            externalApi.addListener('videoConferenceLeft', irASeguridad);
          }}
        />
      </Card>
    </>
  );
};

IngresoVideollamada.propTypes = {
  ocultarFooter: PropTypes.func,
  usuarioLogueado: PropTypes.object,
  cargando: PropTypes.bool,
};

const mapStateToProps = state => ({
  usuarioLogueado: state.mis_datos.currentUser,
  cargando: state.mis_datos.loading,
});

export default connect(mapStateToProps, {
  ocultarFooter: hideFooter,
})(IngresoVideollamada);
