/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { passwordOnlyNumbers, passwordSimilarity } from 'utils/validators';
import {
  REQUERIDO,
  REQUERIDO_VALIDADOR_MENSAJE,
  CONTRASENAS_DEBEN_SER_IGUALES,
  PASSWORD_SIMILARITY_MSG,
  ONLY_NUMBERS_VALIDATOR_MSG,
} from 'constants/commonConstants';

import { PasswordField as PasswordFieldNuevo } from 'components/PasswordField';

import ButtonSection from '../ButtonSection';

import { RenderField, PasswordField } from '../RenderField (Nuevo)';

import './styles.css';

const Form = props => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    errors,
    resetFieldError,
    labelPassword,
    labelRepeatPassword,
    error,
    field,
    handlePrevious,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      {!window.REACT_APP_FEATURED_FLAG_REFACTORS_PASSWORD_FIELD ? (
        <PasswordField
          name="password"
          type="password"
          value={values.password}
          label={labelPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={
            error !== null && field === 'password' ? error : errors.password
          }
          resetFieldError={resetFieldError}
          touched={touched.password}
        />
      ) : (
        <PasswordFieldNuevo
          name="password"
          type="password"
          value={values.password}
          label={labelPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={
            error !== null && field === 'password' ? error : errors.password
          }
          resetFieldError={resetFieldError}
          touched={touched.password}
        />
      )}
      <RenderField
        name="repetir_password"
        type="password"
        value={values.repetir_password}
        label={labelRepeatPassword}
        onChange={handleChange}
        required
        placeholder={labelRepeatPassword}
        error={
          error !== null && field === 'repetir_password'
            ? error
            : errors.repetir_password
        }
        resetFieldError={resetFieldError}
        touched={touched.repetir_password}
      />
      <ButtonSection
        idPrimary="cambiar-contraseña-form-submit-button"
        textPrimary="Continuar"
        isSubmit
        isLoadingPrimary={loading}
        ariaLabelPrimary="Enviar formulario de cambio contraseña"
        textSecondary={handlePrevious ? 'Volver' : ''}
        ariaLabelSecondar="Cancelar"
        handleClickSecondary={handlePrevious}
        isVertical
      />
    </form>
  );
};

const CambioContrasenaForm = withFormik({
  mapPropsToValues: () => ({
    password: '',
    repetir_password: '',
  }),

  validationSchema: props =>
    Yup.object().shape({
      password: Yup.string()
        .required(REQUERIDO)
        .min(8, REQUERIDO_VALIDADOR_MENSAJE)
        .test('passwordSimilarity', PASSWORD_SIMILARITY_MSG, value =>
          passwordSimilarity(props, value),
        )
        .test('onlyNumbers', ONLY_NUMBERS_VALIDATOR_MSG, value =>
          passwordOnlyNumbers(value),
        ),
      repetir_password: Yup.string()
        .required(REQUERIDO)
        .oneOf([Yup.ref('password')], CONTRASENAS_DEBEN_SER_IGUALES),
    }),

  handleSubmit: (values, bag) => {
    if (!bag.props.loading) {
      bag.props.handleSubmitCambioCotrasena(values);
    }
  },
})(Form);

const CambioContrasena = props => (
  <div className="cambio_contrasena">
    <h2>{props.title}</h2>
    <div className="subheading">{props.description}</div>
    <div style={{ marginTop: '24px' }}>
      <CambioContrasenaForm
        handleSubmitCambioCotrasena={props.handleSubmitForm}
        resetFieldError={props.resetFieldError}
        handlePrevious={props.handlePrevious}
        error={props.error}
        field={props.field}
        labelPassword={props.labelPassword}
        labelRepeatPassword={props.labelRepeatPassword}
        email={props.currentUser !== null ? props.currentUser.user.email : ''}
        numero_documento={
          props.currentUser !== null ? props.currentUser.numero_documento : ''
        }
        primer_nombre={
          props.currentUser !== null ? props.currentUser.primer_nombre : ''
        }
        segundo_nombre={
          props.currentUser !== null ? props.currentUser.segundo_nombre : ''
        }
        primer_apellido={
          props.currentUser !== null ? props.currentUser.primer_apellido : ''
        }
        segundo_apellido={
          props.currentUser !== null ? props.currentUser.segundo_apellido : ''
        }
        loading={props.loading}
      />
    </div>
  </div>
);
export default CambioContrasena;
