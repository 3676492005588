import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

// actions
import { showExternalHeader } from 'actions/ui';
import fetchPaginasEstaticas from 'actions/paginaEstatica';

// constantes
import { pageTitleWithSuffix } from 'constants/pageTitles';

// componentes
import CardSpinner from 'components/CardSpinner';
import {
  PaginaEstatica,
  PaginaEstaticaMetodosDisponibles,
} from './paginaEstaticaDetail';

const PaginasEstaticasContainer = ({
  match,
  tieneCard,
  skipHtml,
  mostrarHeaderExterno,
  cargando,
  paginaEstatica,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    mostrarHeaderExterno();
    dispatch(fetchPaginasEstaticas(match.path.replace('/', '')));
  }, []);

  if (cargando) {
    return <CardSpinner spin />;
  }
  return (
    <>
      <Helmet title={pageTitleWithSuffix(paginaEstatica.titulo)} />
      {!tieneCard ? (
        <PaginaEstaticaMetodosDisponibles
          pagina={paginaEstatica}
          skipHtml={skipHtml}
        />
      ) : (
        <PaginaEstatica pagina={paginaEstatica} skipHtml={skipHtml} />
      )}
    </>
  );
};

PaginasEstaticasContainer.propTypes = {
  match: PropTypes.object,
  tieneCard: PropTypes.bool,
  skipHtml: PropTypes.bool,
  mostrarHeaderExterno: PropTypes.func,
  paginaEstatica: PropTypes.object,
  cargando: PropTypes.bool,
};

export const AcercaDeContainer = connect(
  state => ({
    skipHtml: true,
    tieneCard: true,
    paginaEstatica: state.paginaEstatica?.datos,
    cargando: state.paginaEstatica?.loading,
  }),
  {
    mostrarHeaderExterno: showExternalHeader,
  },
)(PaginasEstaticasContainer);

export const TermAndCondContainer = connect(
  state => ({
    skipHtml: true,
    tieneCard: true,
    paginaEstatica: state.paginaEstatica?.datos,
    cargando: state.paginaEstatica?.loading,
  }),
  {
    mostrarHeaderExterno: showExternalHeader,
  },
)(PaginasEstaticasContainer);

export const PrivacidadContainer = connect(
  state => ({
    skipHtml: true,
    tieneCard: true,
    paginaEstatica: state.paginaEstatica?.datos,
    cargando: state.paginaEstatica?.loading,
  }),
  {
    mostrarHeaderExterno: showExternalHeader,
  },
)(PaginasEstaticasContainer);

export const MetodosDisponiblesContainer = connect(
  state => ({
    tieneCard: false,
    skipHtml: false,
    paginaEstatica: state.paginaEstatica?.datos,
    cargando: state.paginaEstatica?.loading,
  }),
  {
    mostrarHeaderExterno: showExternalHeader,
  },
)(PaginasEstaticasContainer);
