export const API_REQUEST = 'API_REQUEST';
export const BROKER_REQUEST = 'BROKER_REQUEST';

// ---------------------------------------------------------------------------
// Auth
// ---------------------------------------------------------------------------
export const REFRESH_TOKEN_URL = '/jwt/refresh/';
export const OBTAIN_TOKEN_URL = '/jwt/obtain/';
export const REFRESH_INTERVAL = 55000; // Miliseconds
export const DSS_SIGN_REQUEST_URL = 'acceso/dss/request';

// ---------------------------------------------------------------------------
// Ciudadano
// ---------------------------------------------------------------------------
export const CIUDADANO_BASE_URL = '/rest/ciudadanos';
export const CIUDADANO_CREATE_URL = `${CIUDADANO_BASE_URL}/`;
export const CIUDADANO_CREATE_FROM_INSIDE_URL = id =>
  `${CIUDADANO_BASE_URL}/${id}/create_inside/`;
export const CIUDADANO_DETAIL_URL = id => `${CIUDADANO_BASE_URL}/${id}/`;
export const CIUDADANO_PENDING_EMAIL_URL = id =>
  `${CIUDADANO_BASE_URL}/${id}/email_por_validar/`;
export const CIUDADANO_VERIFY_PASSWORD = id =>
  `${CIUDADANO_BASE_URL}/${id}/verify_password/`;
export const CIUDADANO_UPDATE_PASSWORD = id =>
  `${CIUDADANO_BASE_URL}/${id}/update_password/`;
export const CIUDADANO_CAMBIAR_NUMERO_TELEFONO = id =>
  `${CIUDADANO_BASE_URL}/${id}/update_numero_telefono/`;
export const CIUDADANO_UPDATE_EMAIL = id =>
  `${CIUDADANO_BASE_URL}/${id}/update_email/`;
export const CIUDADANO_RESEND_ACTIVATION_LOGGED = id =>
  `${CIUDADANO_BASE_URL}/${id}/resend_activation/`;
export const CIUDADANO_ACCIONES_URL = id =>
  `${CIUDADANO_BASE_URL}/${id}/obtener_acciones/`;
export const ELEVARSE_POR_VIDEOLLAMADA_URL = `${CIUDADANO_BASE_URL}/elevarse_a_intermedio_con_videollamada/`;

// ---------------------------------------------------------------------------
// Paises y documentos
// ---------------------------------------------------------------------------
export const FETCH_PAISES_URL = '/rest/paises/';

// ---------------------------------------------------------------------------
// Páginas Estáticas
// ---------------------------------------------------------------------------
export const FETCH_PAGINAS_ESTATICAS_URL = '/rest/paginas-estaticas/';

// ---------------------------------------------------------------------------
// FAQs
// ---------------------------------------------------------------------------
export const FETCH_FAQS_URL = '/rest/faqs/';

// ---------------------------------------------------------------------------
// Reset Password/Account Activation/E-mail validation
// ---------------------------------------------------------------------------
export const REQUEST_RESET_PASSWORD_URL = 'account/reset-password/';
export const CONFIRM_RESET_PASSWORD_URL = 'account/reset-password/confirm';
export const RESEND_ACTIVATION_EMAIL_URL = 'account/resend_activation/';
export const ACTIVATE_ACCOUNT_URL = 'account/activate_account/';
export const APPROVE_EMAIL_CHANGE_URL = 'account/approve_email_change/';

// ---------------------------------------------------------------------------
// Contacto
// ---------------------------------------------------------------------------
export const FETCH_ASUNTOS_URL = '/rest/contacto/asuntos/';
export const ENVIAR_CONTACTO_URL = '/contacto/enviar/';

// ---------------------------------------------------------------------------
// Feedback
// ---------------------------------------------------------------------------
export const FEEDBACK_FORM_BASE_URL = '/rest/feedback-form';
export const ENVIAR_FEEDBACK_FORM_URL = `${FEEDBACK_FORM_BASE_URL}/`;

// ---------------------------------------------------------------------------
// BROKER - OIDC
// ---------------------------------------------------------------------------
export const BROKER_BASE_URL = `${window.REACT_APP_BROKER_HOST}`;
export const BACKEND_BASE_URL = `${window.REACT_APP_BACKEND_HOST}`;

export const CREATE_CONSENT_URL = `${BROKER_BASE_URL}/oidc/v1/consent`;
export const USERCONSENT_BASE_URL = '/rest/aplicaciones/';
export const USERCONSENT_DETAIL_URL = id => `${USERCONSENT_BASE_URL}${id}/`;
export const CLIENTS_BASE_URL = `${BACKEND_BASE_URL}/rest/clients`;
export const CLIENTS_DETAIL_URL = (CLIENT_ID, SCOPES) =>
  `${CLIENTS_BASE_URL}/${CLIENT_ID}/?scopes=${SCOPES}`;

export const LOGIN_BASE_URL = `${BROKER_BASE_URL}/auth/login`;
export const CANCEL_LOGIN_URL = processState =>
  `${BROKER_BASE_URL}/auth/cancel-login/?process_state=${processState}`;
export const LOGOUT_BASE_URL = `${BROKER_BASE_URL}/auth/logout`;
export const CI_LOGIN_REDIRECT = `${BROKER_BASE_URL}/auth/login-con-ci`;

// ---------------------------------------------------------------------------
// BROKER - SAML
// ---------------------------------------------------------------------------
export const LOGOUT_RESPONSE_SAML_URL = processState =>
  `${BROKER_BASE_URL}/v1.1/idp/profile/SAML2/slo-response?process_state=${processState}`;
export const LOGOUT_FORCE_AUTH_SAML_URL = processState =>
  `${BROKER_BASE_URL}/v1.1/idp/profile/SAML2/logout-requests-force-auth?process_state=${processState}`;

// ---------------------------------------------------------------------------
// Federated Identity
// ---------------------------------------------------------------------------
export const IDP_BASE_URL = 'federated-identity';
export const IDP_LOGIN_URL = (idIdP, processState) =>
  `${IDP_BASE_URL}/login/?idp=${idIdP}&process_state=${processState}`;
export const FETCH_IDPS_URL = processState =>
  `/rest/idps/${processState ? `?process_state=${processState}` : ''}`;
export const MIS_DATOS_IDP_URL = id =>
  `${IDP_BASE_URL}/obtener-mis-datos-idp/${id}/`;

// ---------------------------------------------------------------------------
// MFA
// ---------------------------------------------------------------------------
export const ACTIVATE_TOTP_URL = `${CIUDADANO_BASE_URL}/authenticators/totp/`;
export const MFA_GET_STATUS = id => `${CIUDADANO_BASE_URL}/${id}/mfa_status/`;
export const VERIFY_CODE_TOTP_URL = id =>
  `${CIUDADANO_BASE_URL}/authenticators/totp/${id}/verify/`;
export const DELETE_TOTP_URL = id =>
  `${CIUDADANO_BASE_URL}/authenticators/totp/${id}/`;
export const RESEND_RECOVERY_CODE_EMAIL_URL =
  'account/resend-recovery-code-email/';

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------

export const OBTENER_DIAS_DISPONIBLES =
  'rest/configuracion-turno/lista-fechas-configuradas/';
export const OBTENER_HORAS_DISPONIBLES = 'rest/disponibilidad/';
export const TRAMITE_VIDEOLLAMADA_BASE_URL = '/rest/tramite-videollamada';

// ---------------------------------------------------------------------------
// Videollamada Consulta
// ---------------------------------------------------------------------------
export const OBTENER_DIAS_DISPONIBLES_CONSULTA =
  'rest/configuracion-turno-consulta/lista-fechas-configuradas/';
export const OBTENER_HORAS_DISPONIBLES_CONSULTA =
  'rest/disponibilidad-consulta/';
export const VIDEOLLAMADA_CONSULTA_BASE_URL = '/rest/videollamada-soporte';

// ---------------------------------------------------------------------------
// Info DNIC
// ---------------------------------------------------------------------------
export const INFO_DNIC_BASE_URL = '/rest/info-dnic/';

// ---------------------------------------------------------------------------
// Página Estática
// ---------------------------------------------------------------------------

export const PAGINA_ESTATICA_BASE_URL = '/rest/paginas-estaticas/';
export const FETCH_PAGINA_ESTATICA_URL = slug =>
  `${PAGINA_ESTATICA_BASE_URL}${slug}/`;
