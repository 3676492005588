import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { connect, useDispatch } from 'react-redux';

// libraries
import { useForm } from 'react-hook-form';

// react-phone-input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

// actions
import { clearRegistroErrors, createCiudadano } from 'actions/registro';

// components
import Alert from 'components/Alert';
import ButtonSolid from 'components/ButtonSolid';
import CaptchaField from 'components/HookForm/CaptchaField';
import CheckboxField from 'components/HookForm/CheckboxField';
import PasswordFieldWithWidget from 'components/HookForm/PasswordFieldWithWidget';
import PasswordField from 'components/HookForm/PasswordField';
import SelectField from 'components/HookForm/SelectField';
import TextField from 'components/HookForm/TextField';

// utils
import {
  validNameRegex,
  validEmailRegex,
  onlyNumbersRegex,
  formatoCI,
  documentValidator,
  notOnlyNumbersRegex,
} from 'utils/validators';
import { getDocumentosOptions } from 'utils/utils';

// constants
import {
  MAX_LEN_DOC,
  MAX_LEN_NAMES,
  UY_CODE,
  CORREO_INVALIDO,
  NOMBRE_INVALIDO,
  FORMATO_CI_INVALIDO,
  DOCUMENTO_INVALIDO,
  CORREOS_DEBEN_SER_IGUALES,
  MIN_LEN_PASSWORD,
  ONLY_NUMBERS_VALIDATOR_MSG,
  PASSWORD_SIMILARITY_MSG,
  EMAIL_AVISO,
  PRIMER_NOMBRE,
  PRIMER_APELLIDO,
  SEGUNDO_NOMBRE,
  SEGUNDO_APELLIDO,
  PAIS_EMISOR,
  TIPO_DOCUMENTO,
  NUMERO_DE_DOCUMENTO,
  CORREO,
  REPETIR_CORREO,
  CONTRASENA,
  REPETIR_CONTRASENA,
  TERMINOS_CONDICIONES,
  CREAR_USUARIO,
  ERROR_BACKEND_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO,
  TEXTO_PLACEHOLDER_NUMERO_TELEFONO,
  TEXTO_NUMERO_TELEFONO,
  TEXTO_OPCIONAL,
  TEXTO_NUMERO_TELEFONO_AVISO,
} from 'constants/commonConstants';

// styles
import styles from './registro.module.css';

const RegistroForm = ({
  onSubmit,
  registroErrors,
  paises,
  paisesOptions,
  documentosOptions,
  setDocumentosOptions,
  showAlert,
  alert,
  alertText,
  alertLink,
  handleOnClickAlert,
  termsAndConds,
  recaptchaRef,
  creating,
  captchaTouched,
  setCaptchaTouched,
  invalidFields,
  setInvalidFields,
  handleDatosPersonales,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    setFocus,
    trigger,
    clearErrors,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [camposDeshabilitados, setCamposDeshabilitados] = useState({
    email: false,
    numeroDocumento: false,
    primerNombre: false,
    segundoNombre: false,
    primerApellido: false,
    segundoApellido: false,
  });

  const watchPaisEmisor = watch('paisEmisor');
  const watchEmail = watch('email');
  const watchNumeroTelefono = watch('numeroTelefono');
  const watchPassword = watch('password');
  const watchRepetirPassword = watch('repetirPassword');
  const watchApellido = watch('primerApellido');

  // paisEmisor y tipoDocumento también son requeridos, pero nunca son vacíos
  const watchRequiredValues = watch([
    'primerNombre',
    'primerApellido',
    'numeroDocumento',
    'email',
    'repetirEmail',
    'password',
    'repetirPassword',
    'terminos',
    'captchaResponse',
  ]);

  useEffect(() => {
    setValue('captchaResponse', false);
    setValue('paisEmisor', 'uy');
    setError('captchaResponse', { type: 'custom' });

    if (handleDatosPersonales) {
      const campos = [
        { name: 'numeroTelefono', label: 'numero_telefono', value: '' },
        { name: 'email', label: 'email', value: '' },
        { name: 'repetirEmail', label: 'email', value: '' },
        { name: 'numeroDocumento', label: 'numero_documento', value: '' },
        { name: 'primerNombre', label: 'primer_nombre', value: '' },
        { name: 'segundoNombre', label: 'segundo_nombre', value: '' },
        { name: 'primerApellido', label: 'primer_apellido', value: '' },
        { name: 'segundoApellido', label: 'segundo_apellido', value: '' },
      ];

      const nuevosCamposDeshabilitados = { ...camposDeshabilitados };

      campos.forEach(field => {
        const value = handleDatosPersonales(field.label);
        if (value) {
          setValue(field.name, value);
          nuevosCamposDeshabilitados[field.name] = true;
        }
      });

      setCamposDeshabilitados(nuevosCamposDeshabilitados);
    }
  }, []);

  useEffect(() => {
    const fieldsHaveValues = watchRequiredValues.reduce(
      (previousValue, currentValue) => previousValue && !!currentValue,
      true,
    );
    const noErrors = Object.keys(errors).length === 0;
    setSubmitDisabled(!(fieldsHaveValues && noErrors));
  }, [watchRequiredValues]);

  const addInvalidField = field => {
    if (!invalidFields.includes(field))
      setInvalidFields([...invalidFields, field]);
  };

  const removeInvalidField = field => {
    setInvalidFields(invalidFields.filter(item => item !== field));
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { documentosOptions, tipoDocumento } = getDocumentosOptions(
      watchPaisEmisor,
      paises,
    );
    if (documentosOptions) setDocumentosOptions(documentosOptions);
    setValue('tipoDocumento', tipoDocumento);
  }, [watchPaisEmisor]);

  useEffect(() => {
    if (
      registroErrors?.error ===
      ERROR_BACKEND_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO
    ) {
      dispatch(clearRegistroErrors());
    }
  }, [watchApellido]);

  const handleCaptcha = response => {
    setCaptchaTouched(true);
    if (response === null) {
      setError('captchaResponse', { type: 'custom' });
    } else {
      setValue('captchaResponse', response);
      clearErrors();
    }
  };

  const submitYBorrarCaptcha = datosFormulario => {
    onSubmit(datosFormulario);
    // En caso de error, el captcha debe refrescarse.
    // Los otros datos se mantienen cargados
    setValue('captchaResponse', false);
  };

  const handleOnFocusNumeroTelefono = valor => {
    if (isEmpty(watchNumeroTelefono) && valor.key !== 'Backspace') {
      setValue('numeroTelefono', '+598');
    }
  };

  const handleOnBlurNumeroTelefono = () => {
    if (watchNumeroTelefono.length <= 4) {
      setValue('numeroTelefono', '');
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitYBorrarCaptcha)}
      className={styles.registro__formulario}
    >
      <div className={styles.datosPersonales}>
        <TextField
          register={register}
          name="primerNombre"
          label={PRIMER_NOMBRE}
          type="text"
          required
          validationRegex={validNameRegex}
          regexErrorMessage={NOMBRE_INVALIDO}
          maxLength={MAX_LEN_NAMES}
          error={errors.primerNombre}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
          isDisabled={camposDeshabilitados.primerNombre}
        />
        <TextField
          register={register}
          name="segundoNombre"
          label={SEGUNDO_NOMBRE}
          type="text"
          validationRegex={validNameRegex}
          regexErrorMessage={NOMBRE_INVALIDO}
          maxLength={MAX_LEN_NAMES}
          error={errors.segundoNombre}
          isDisabled={camposDeshabilitados.segundoNombre}
        />
        <TextField
          register={register}
          name="primerApellido"
          label={PRIMER_APELLIDO}
          type="text"
          required
          validationRegex={validNameRegex}
          regexErrorMessage={NOMBRE_INVALIDO}
          maxLength={MAX_LEN_NAMES}
          error={errors.primerApellido}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
          isDisabled={camposDeshabilitados.primerApellido}
        />
        <TextField
          register={register}
          name="segundoApellido"
          label={SEGUNDO_APELLIDO}
          type="text"
          validationRegex={validNameRegex}
          regexErrorMessage={NOMBRE_INVALIDO}
          maxLength={MAX_LEN_NAMES}
          error={errors.segundoApellido}
          isDisabled={camposDeshabilitados.segundoApellido}
        />
      </div>
      <div className={styles.datosDocumento}>
        {paisesOptions && paisesOptions.length && (
          <SelectField
            register={register}
            name="paisEmisor"
            label={PAIS_EMISOR}
            required
            options={paisesOptions}
            error={errors.paisEmisor}
          />
        )}
        <div className={styles.divDocumento}>
          {documentosOptions && documentosOptions.length !== null && (
            <SelectField
              register={register}
              name="tipoDocumento"
              label={TIPO_DOCUMENTO}
              small
              required
              disabled={documentosOptions.length === 1}
              options={documentosOptions}
              error={errors.tipoDocumento}
            />
          )}
          <TextField
            register={register}
            name="numeroDocumento"
            label={NUMERO_DE_DOCUMENTO}
            type="text"
            small
            placeholder="Ej. 12345678"
            required
            maxLength={watchPaisEmisor !== UY_CODE && MAX_LEN_DOC}
            validationRegex={watchPaisEmisor === UY_CODE && onlyNumbersRegex}
            regexErrorMessage={FORMATO_CI_INVALIDO}
            validateDocument={
              watchPaisEmisor === UY_CODE ? formatoCI : documentValidator
            }
            validationErrorMessage={DOCUMENTO_INVALIDO}
            error={errors.numeroDocumento}
            registroErrors={registroErrors}
            showAlert={showAlert}
            addInvalidField={addInvalidField}
            removeInvalidField={removeInvalidField}
            clearRegistroErrors={clearRegistroErrors}
            isDisabled={camposDeshabilitados.numeroDocumento}
          />
        </div>

        {alert && (
          <Alert
            type="error"
            text={alertText}
            linkText={alertLink}
            linkOnClick={handleOnClickAlert}
          />
        )}
      </div>
      <div className={styles.datosUsuario}>
        <TextField
          register={register}
          trigger={trigger}
          name="email"
          type="email"
          label={CORREO}
          value={watchEmail}
          required
          validationRegex={validEmailRegex}
          regexErrorMessage={CORREO_INVALIDO}
          error={errors.email}
          touched={touchedFields.email}
          registroErrors={registroErrors}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
          isDisabled={camposDeshabilitados.email}
        />
        <p className={styles.help_text}>{EMAIL_AVISO}</p>

        <TextField
          register={register}
          name="repetirEmail"
          type="email"
          label={REPETIR_CORREO}
          required
          validationRegex={validEmailRegex}
          regexErrorMessage={CORREO_INVALIDO}
          matchValue={watchEmail}
          validationErrorMessage={CORREOS_DEBEN_SER_IGUALES}
          error={errors.repetirEmail}
          showSuccess={touchedFields.repetirEmail}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
          isDisabled={camposDeshabilitados.repetirEmail}
        />

        {window.REACT_APP_HABILITAR_NUMERO_TELEFONO_REGISTRO && (
          <div className={styles.field_numero_telefono}>
            <label>{TEXTO_NUMERO_TELEFONO}</label>
            <label className={styles.field_numero_telefono_parentesis}>
              {TEXTO_OPCIONAL}
            </label>
            <div className={styles.contenedor_numero_telefono}>
              <PhoneInput
                country="uy"
                placeholder={TEXTO_PLACEHOLDER_NUMERO_TELEFONO}
                inputClass={styles.inputNumeroTelefono}
                value={watchNumeroTelefono}
                onChange={valor => setValue('numeroTelefono', valor)}
                localization={es}
                enableLongNumbers
                preferredCountries={['uy']}
                disableCountryCode={isEmpty(watchNumeroTelefono)}
                onFocus={handleOnFocusNumeroTelefono}
                onBlur={handleOnBlurNumeroTelefono}
              />
            </div>
            <p className={styles.help_text}>{TEXTO_NUMERO_TELEFONO_AVISO}</p>
          </div>
        )}

        <PasswordFieldWithWidget
          register={register}
          trigger={trigger}
          touched={touchedFields.password}
          getValues={getValues}
          value={watchPassword}
          setFocus={() => setFocus('password')}
          name="password"
          label={CONTRASENA}
          required
          minLength={MIN_LEN_PASSWORD}
          validationRegex={notOnlyNumbersRegex}
          regexErrorMessage={ONLY_NUMBERS_VALIDATOR_MSG}
          validationErrorMessage={PASSWORD_SIMILARITY_MSG}
          error={errors.password}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
        />

        <PasswordField
          register={register}
          name="repetirPassword"
          type="password"
          value={watchRepetirPassword}
          label={REPETIR_CONTRASENA}
          required
          matchValue={watchPassword}
          error={errors.repetirPassword}
          showSuccess={touchedFields.repetirPassword}
          addInvalidField={addInvalidField}
          removeInvalidField={removeInvalidField}
        />
      </div>
      <div className={styles.condicionesYBoton}>
        <CheckboxField
          register={register}
          name="terminos"
          component={CheckboxField}
          content={termsAndConds}
          required
          error={errors.terminos}
          label={TERMINOS_CONDICIONES}
        />
        {!creating && (
          <CaptchaField
            register={register}
            name="captchaResponse"
            recaptchaRef={recaptchaRef}
            onChange={handleCaptcha}
            touched={captchaTouched}
            error={errors.captchaResponse}
            required
          />
        )}
        <ButtonSolid
          text={CREAR_USUARIO}
          isSubmit
          ariaLabel={CREAR_USUARIO}
          isDisabled={submitDisabled}
          isLoading={creating}
          className={styles.botonRegistro}
        />
      </div>
    </form>
  );
};

RegistroForm.propTypes = {
  onSubmit: PropTypes.func,
  registroErrors: PropTypes.object,
  paises: PropTypes.array,
  paisesOptions: PropTypes.array,
  documentosOptions: PropTypes.array,
  setDocumentosOptions: PropTypes.func,
  showAlert: PropTypes.func,
  alert: PropTypes.bool,
  alertText: PropTypes.string,
  alertLink: PropTypes.string,
  handleOnClickAlert: PropTypes.func,
  termsAndConds: PropTypes.object,
  recaptchaRef: PropTypes.object,
  creating: PropTypes.bool,
  captchaTouched: PropTypes.bool,
  setCaptchaTouched: PropTypes.func,
  invalidFields: PropTypes.array,
  setInvalidFields: PropTypes.func,
  handleDatosPersonales: PropTypes.func,
};

const mapStateToProps = state => ({
  creating: state.registro.creating,
});

export default connect(mapStateToProps, {
  createCiudadano,
  clearRegistroErrors,
})(RegistroForm);
