import React, { useContext } from 'react';
import {
  CONDICIONES_MAYOR_DE_EDAD,
  CONDICION_CEDULA_VIGENTE,
  CONDICION_CEDULA_CON_CHIP,
  CONDICION_BUENA_CONEXION,
  CONDICION_BUENA_LUZ,
  CONDICION_BUENA_CALIDAD,
  CONDICION_EVITAR_ACCESORIOS,
  CONDICION_EVITAR_MAQUILLAJE,
  CONDICION_FOTO_ROSTRO,
  CONDICIONES_TITULO,
  ACEPTO_TERMINOS,
  CONDICIONES_PARRAFO_INICIAL,
  PARRAFO_ETAPAS,
  ETAPA_AGENDA_VIDEOLLAMADA,
  ETAPA_REALIZA_VIDEOLLAMADA,
  ETAPA_ACEPTA_TERMINOS,
  PARRAFO_CONDICIONES,
  CLAUSULA_TITULO,
  CLAUSULA_PRIMER_PARRAFO,
  CLAUSULA_SEGUNDO_PARRAFO,
  CLAUSULA_TERCER_PARRAFO,
  CLAUSULA_CUARTO_PARRAFO,
  CLAUSULA_ACEPTA,
  CONDICIONES_TEXTO_NEGRITA,
} from 'constants/commonConstants';
import TitleCard from 'components/TitleCard';
import { CheckboxField } from 'components/RenderField (Nuevo)';
import ContextoAgenda from '../ContextoAgenda';
import styles from './Requisitos.module.css';

const Requisitos = () => {
  const { handleAceptaCondiciones, aceptaCondiciones } =
    useContext(ContextoAgenda);

  const condicionEtapaAgendaVideollamada = (
    <p key="agendaVideollamada">{ETAPA_AGENDA_VIDEOLLAMADA}</p>
  );
  const condicionEtapaRealizaVideollamada = (
    <p key="realizaVideollamada">{ETAPA_REALIZA_VIDEOLLAMADA}</p>
  );
  const condicionEtapaAceptaTerminos = (
    <p key="aceptaTerminos">{ETAPA_ACEPTA_TERMINOS}</p>
  );

  const condicion1 = <p key="mayorDeEdad">{CONDICIONES_MAYOR_DE_EDAD}</p>;
  const condicion2 = <p key="cedulaVigente">{CONDICION_CEDULA_VIGENTE}</p>;
  const condicion3 = <p key="cedulaConChip">{CONDICION_CEDULA_CON_CHIP}</p>;
  const condicion4 = <p key="buenaConexion">{CONDICION_BUENA_CONEXION}</p>;
  const condicion5 = <p key="buenaLuz">{CONDICION_BUENA_LUZ}</p>;
  const condicion6 = <p key="buenaCalidad">{CONDICION_BUENA_CALIDAD}</p>;
  const condicion7 = (
    <p key="evitarAccesorios">{CONDICION_EVITAR_ACCESORIOS}</p>
  );
  const condicion8 = (
    <p key="evitarMaquillaje">{CONDICION_EVITAR_MAQUILLAJE}</p>
  );
  const condicion9 = <p key="fotoRostro">{CONDICION_FOTO_ROSTRO}</p>;

  return (
    <div className={styles.container}>
      <div className={styles.containerTexto}>
        <TitleCard title={CONDICIONES_TITULO} className={styles.titulo} />
        <p className={styles.parrafoCondiciones}>
          {CONDICIONES_PARRAFO_INICIAL}
        </p>
        <p className={styles.parrafoNegrita}>{CONDICIONES_TEXTO_NEGRITA}</p>
        <p className={styles.parrafoCondiciones}>{PARRAFO_ETAPAS}</p>
        <ol className={styles.orderedList}>
          <li key="condicionEtapas1" className={styles.condicion}>
            {condicionEtapaAgendaVideollamada}
          </li>
          <li key="condicionEtapas2" className={styles.condicion}>
            {condicionEtapaRealizaVideollamada}
          </li>
          <li key="condicionEtapas3" className={styles.condicion}>
            {condicionEtapaAceptaTerminos}
          </li>
        </ol>
        <p className={styles.parrafoCondiciones}>{PARRAFO_CONDICIONES}</p>
        <ol className={styles.orderedList}>
          <li key="condicion1" className={styles.condicion}>
            {condicion1}
          </li>
          <li key="condicion2" className={styles.condicion}>
            {condicion2}
          </li>
          <li key="condicion3" className={styles.condicion}>
            {condicion3}
          </li>
          <li key="condicion4" className={styles.condicion}>
            {condicion4}
          </li>
          <li key="condicion5" className={styles.condicion}>
            {condicion5}
          </li>
          <li key="condicion6" className={styles.condicion}>
            {condicion6}
          </li>
          <li key="condicion7" className={styles.condicion}>
            {condicion7}
          </li>
          <li key="condicion8" className={styles.condicion}>
            {condicion8}
          </li>
          <li key="condicion9" className={styles.condicion}>
            {condicion9}
          </li>
        </ol>
        <TitleCard title={CLAUSULA_TITULO} className={styles.titulo} />
        <p className={styles.parrafoCondiciones}>{CLAUSULA_PRIMER_PARRAFO}</p>
        <p className={styles.parrafoCondiciones}>{CLAUSULA_SEGUNDO_PARRAFO}</p>
        <p className={styles.parrafoCondiciones}>{CLAUSULA_TERCER_PARRAFO}</p>
        <p className={styles.parrafoCondiciones}>{CLAUSULA_CUARTO_PARRAFO}</p>
      </div>
      <div className={styles.checkboxesContenedor}>
        <CheckboxField
          name="checkboxAceptoTerminos"
          label={ACEPTO_TERMINOS}
          onChange={() =>
            handleAceptaCondiciones({
              ...aceptaCondiciones,
              terminos: !aceptaCondiciones.terminos,
            })
          }
          className={styles.checkbox}
          checked={aceptaCondiciones.terminos}
        />
        <CheckboxField
          name="checkboxAceptoClausula"
          label={CLAUSULA_ACEPTA}
          onChange={() =>
            handleAceptaCondiciones({
              ...aceptaCondiciones,
              clausula: !aceptaCondiciones.clausula,
            })
          }
          className={styles.checkbox}
          checked={aceptaCondiciones.clausula}
        />
      </div>
    </div>
  );
};

export default Requisitos;
