/* eslint-disable react/prop-types */
import React from 'react';

const IframeTemplate = ({ id, src }) => (
  <iframe
    title={id}
    id={id}
    src={src}
    allow
    hidden
    style={{ display: 'none' }}
  />
);

export default IframeTemplate;
