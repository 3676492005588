import React from 'react';
import PropTypes from 'prop-types';

import styles from './errorPage.module.css';

const ErrorPageVertical = ({ titulo, descripcion, imagen, altImagen }) => (
  <div className={styles.error_vertical}>
    <div className={styles.error_vertical__img}>
      <img alt={altImagen} src={imagen[0]} />
    </div>
    <div className={styles.error_vertical__desc}>
      <h1>{titulo}</h1>
      {descripcion}
    </div>
  </div>
);

ErrorPageVertical.propTypes = {
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
  imagen: PropTypes.array,
  altImagen: PropTypes.string,
};

export default ErrorPageVertical;
