/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { CONTACT_SENT_TITLE } from 'constants/pageTitles';
import CardSuccess from 'components/CardSuccess';

const ContactoEnviadoContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(CONTACT_SENT_TITLE));
  }, []);

  return (
    <>
      <Helmet title={CONTACT_SENT_TITLE} />
      <CardSuccess
        title="Tu mensaje se ha enviado"
        description="Un agente se estará contactando contigo a la brevedad."
      />
    </>
  );
};

export default ContactoEnviadoContainer;
