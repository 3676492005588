/* eslint-disable react/prop-types */
import React from 'react';
import {
  CARD_SEGURIDAD_TITLE,
  INFO_SEC_CONTRASEÑA_TITLE,
  INFO_SEC_CONTRASEÑA_CONTEXT_TEXT,
  INFO_SEC_CONTRASEÑA_BUTTON,
  INFO_SEC_CONTRASEÑA_ARIA_LABEL,
  INFO_SEC_ACTIVIDAD_TITLE,
  INFO_SEC_ACTIVIDAD_CONTEXT_TEXT,
  INFO_SEC_MFA_TITLE,
  INFO_SEC_MFA_DISABLED_BUTTON,
  INFO_SEC_MFA_DISABLED_ARIA_LABEL,
  INFO_SEC_MFA_EDIT_BUTTON,
  INFO_SEC_MFA_STATE_TEXT,
  INFO_SEC_MFA_ACTIVADA,
  INFO_SEC_MFA_DESACTIVADA,
  REVERIFICAR_CORREO_TEXT,
  REVERIFICAR_CORREO_LINK_TEXT,
  ACTIVAR_CUENTA_TEXT,
  ACTIVAR_CUENTA_LINK_TEXT,
  FIRMA_ELECTRONICA_ASOCIAR_REQUERIMIENTO,
  FIRMA_ELECTRONICA_ASOCIAR_VALIDAR_CORREO,
  FIRMA_ELECTRONICA_ASOCIAR_ACTIVAR_CUENTA,
  WARNING_NO_PASSWORD_METHOD_SEGURIDAD,
  WARNING_NO_PASSWORD_METHOD_SEGURIDAD_LINK,
  MFA_TOTP_DEACTIVATE_BUTTON_TEXT,
  WARNING_RID_0_SEGURIDAD,
  TEXTO_RID_0,
  INFO_SEC_EMAIL_CONTEXT_TEXT,
  INFO_SEC_EMAIL_TITLE,
  INFO_SEC_EMAIL_BUTTON,
  INFO_SEC_EMAIL_ARIA_LABEL,
  INFO_SEC_TELEFONO_TITLE,
  INFO_SEC_TELEFONO_CONTEXT_TEXT,
  INFO_SEC_TELEFONO_BUTTON,
  INFO_SEC_TELEFONO_ARIA_LABEL,
  INFO_SEC_TELEFONO_SIN_REGISTRO,
  INFO_SEC_TELEFONO_BUTTON_AGREGAR,
  WARNING_CUENTA_BLOQUEADA_SEGURIDAD,
  WARNING_CUENTA_BLOQUEADA_SEGURIDAD_LINK,
} from 'constants/commonConstants';
import { RESET_PASSWORD_ROUTE } from 'constants/RouterConstants';
import { fetchAccionesCiudadano } from 'services/apiServices';
import Card from 'components/Card';
import TitleCard from 'components/TitleCard';
import Alert from 'components/Alert';
import ModalConfirmarAccion from 'components/ModalConfirmarAccion';
import { isMobile, formatFullDate } from 'utils/utils';
import BoxServiciosAsociados from './serviciosAsociados/serviciosAsociados';
import NivelSeguridadSection from './nivelSeguridad';
import InfoSection from './infoSection';
import TablaAcciones from './tablaAcciones';
import styles from './seguridad.module.css';

class Seguridad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: true,
      showAcciones: false,
      accionesCargando: false,
      acciones: [],
    };

    this.isMobile = isMobile.bind(this);
    this.handleRetirarAcceso = this.handleRetirarAcceso.bind(this);
    this.handleFetchAcciones = this.handleFetchAcciones.bind(this);
    this.setLinkOnClick = this.setLinkOnClick.bind(this);
  }

  setLinkOnClick(e) {
    if (this.props.currentUser.email_por_validar !== null) {
      if (this.props.currentUser.email_por_validar.email) {
        if (!this.props.currentUser.cuenta_activa) {
          return this.props.handleReenviarActivacionCuenta(e);
        }
        return this.props.handleReenviarValidacion(e);
      }
    }
    return null;
  }

  handleRetirarAcceso(name, id) {
    this.props.handleShowModalRetirarAcceso(name, id);
  }

  async handleFetchAcciones(id) {
    if (this.state.showAcciones) {
      this.setState({ showAcciones: false });
    } else {
      this.setState({ accionesCargando: true });
      fetchAccionesCiudadano(id).then(response => {
        this.setState({
          acciones: response.data,
          showAcciones: true,
          accionesCargando: false,
        });
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const { email } = currentUser.user;
    const ultimoCambioContrasena = this.props.ultimo_cambio_contrasena
      ? formatFullDate(this.props.ultimo_cambio_contrasena)
      : 'Ningún cambio registrado';

    const ul = currentUser.ultimo_login || new Date();
    const ultimoLogin = formatFullDate(ul);

    const mailAlertInfo = () => {
      if (
        currentUser.email_por_validar !== null &&
        !currentUser.email_validado
      ) {
        if (currentUser.cuenta_activa) {
          return {
            correoAlertTexto: REVERIFICAR_CORREO_TEXT(
              currentUser.email_por_validar.email,
            ),
            linkAlertTexto: REVERIFICAR_CORREO_LINK_TEXT,
          };
        }
        return {
          correoAlertTexto: ACTIVAR_CUENTA_TEXT,
          linkAlertTexto: ACTIVAR_CUENTA_LINK_TEXT,
        };
      }
      return { correoAlertTexto: null, linkAlertTexto: null };
    };
    const { correoAlertTexto, linkAlertTexto } = mailAlertInfo();

    const firmaModelText = () => {
      if (this.isMobile()) {
        return FIRMA_ELECTRONICA_ASOCIAR_REQUERIMIENTO;
      }
      if (currentUser.cuenta_activa) {
        return FIRMA_ELECTRONICA_ASOCIAR_VALIDAR_CORREO;
      }
      return FIRMA_ELECTRONICA_ASOCIAR_ACTIVAR_CUENTA;
    };

    const infoSectionActivado = props => (
      <InfoSection
        title={INFO_SEC_MFA_TITLE}
        contentText={INFO_SEC_MFA_STATE_TEXT}
        contentValue={INFO_SEC_MFA_ACTIVADA}
        buttonName={MFA_TOTP_DEACTIVATE_BUTTON_TEXT}
        buttonCallback={props.goToMfa}
        buttonIsDisabled={props.buttonIsDisabled}
        ariaLabel={INFO_SEC_MFA_EDIT_BUTTON}
      />
    );

    const infoSectionNoActivado = props => (
      <InfoSection
        title={INFO_SEC_MFA_TITLE}
        contentText={INFO_SEC_MFA_STATE_TEXT}
        contentValue={INFO_SEC_MFA_DESACTIVADA}
        buttonName={INFO_SEC_MFA_DISABLED_BUTTON}
        buttonCallback={props.goToMfa}
        buttonIsDisabled={props.buttonIsDisabled}
        ariaLabel={INFO_SEC_MFA_DISABLED_ARIA_LABEL}
      />
    );

    const InfoSectionMFA = props =>
      props.mfa.estado.activado
        ? infoSectionActivado(props)
        : infoSectionNoActivado(props);

    const botonesDeshabilitados =
      !currentUser.acceso_funcionalidades || currentUser?.rid === TEXTO_RID_0;

    const obtenerTextosAlert = () => {
      if (!currentUser.acceso_funcionalidades) {
        return [
          WARNING_NO_PASSWORD_METHOD_SEGURIDAD,
          WARNING_NO_PASSWORD_METHOD_SEGURIDAD_LINK,
        ];
      }
      if (currentUser.bloqueado && !currentUser.cuenta_activa) {
        return [WARNING_RID_0_SEGURIDAD, REVERIFICAR_CORREO_LINK_TEXT];
      }
      return [
        WARNING_CUENTA_BLOQUEADA_SEGURIDAD,
        WARNING_CUENTA_BLOQUEADA_SEGURIDAD_LINK,
      ];
    };

    const funcionAlert = e => {
      e.preventDefault();
      if (currentUser.bloqueado && !currentUser.cuenta_activa) {
        return this.props.handleReenviarActivacionCuenta(e);
      }
      return this.props.history.push(RESET_PASSWORD_ROUTE);
    };

    const textosAlert = obtenerTextosAlert();

    return (
      <>
        {botonesDeshabilitados && this.state.showAlert && (
          <Alert
            type="warning"
            className={styles.seguridad__alert}
            text={textosAlert[0]}
            linkText={textosAlert[1]}
            linkOnClick={funcionAlert}
          />
        )}
        <Card>
          <TitleCard title={CARD_SEGURIDAD_TITLE} />
          <NivelSeguridadSection
            currentUser={currentUser}
            isMobile={this.isMobile()}
            handleShowModalFirmaElectronica={
              this.props.handleShowModalFirmaElectronica
            }
            mfaActivado={this.props.mfa.estado.activado}
            botonesDeshabilitados={botonesDeshabilitados}
          />
          <InfoSectionMFA
            mfa={this.props.mfa}
            goToMfa={this.props.goToMfa}
            buttonIsDisabled={botonesDeshabilitados}
          />
          <InfoSection
            title={INFO_SEC_CONTRASEÑA_TITLE}
            contentText={INFO_SEC_CONTRASEÑA_CONTEXT_TEXT}
            contentValue={ultimoCambioContrasena}
            buttonName={INFO_SEC_CONTRASEÑA_BUTTON}
            ariaLabel={INFO_SEC_CONTRASEÑA_ARIA_LABEL}
            buttonCallback={this.props.handleContraseña}
            buttonIsDisabled={botonesDeshabilitados}
          />
          <InfoSection
            title={INFO_SEC_EMAIL_TITLE}
            contentText={INFO_SEC_EMAIL_CONTEXT_TEXT}
            contentValue={email}
            buttonName={INFO_SEC_EMAIL_BUTTON}
            ariaLabel={INFO_SEC_EMAIL_ARIA_LABEL}
            showAlert={
              !currentUser.email_validado &&
              currentUser.email_por_validar &&
              currentUser.acceso_funcionalidades
            }
            typeAlert="warning"
            textAlert={correoAlertTexto}
            linkText={linkAlertTexto}
            linkOnClickAlert={this.setLinkOnClick}
            buttonCallback={this.props.buttonEmailCallback}
            buttonIsDisabled={botonesDeshabilitados}
          />
          {window.REACT_APP_HABILITAR_NUMERO_TELEFONO_AUTOGESTION && (
            <InfoSection
              title={INFO_SEC_TELEFONO_TITLE}
              contentText={INFO_SEC_TELEFONO_CONTEXT_TEXT}
              contentValue={
                currentUser.numero_telefono
                  ? `+${currentUser.numero_telefono}`
                  : INFO_SEC_TELEFONO_SIN_REGISTRO
              }
              buttonName={
                currentUser.numero_telefono
                  ? INFO_SEC_TELEFONO_BUTTON
                  : INFO_SEC_TELEFONO_BUTTON_AGREGAR
              }
              ariaLabel={INFO_SEC_TELEFONO_ARIA_LABEL}
              buttonCallback={this.props.buttonTelefonoCallback}
              buttonIsDisabled={botonesDeshabilitados}
            />
          )}
          <InfoSection
            title={INFO_SEC_ACTIVIDAD_TITLE}
            contentText={INFO_SEC_ACTIVIDAD_CONTEXT_TEXT}
            contentValue={ultimoLogin}
            buttonName={
              this.state.showAcciones
                ? 'Ocultar'
                : 'Ver últimas acciones realizadas'
            }
            buttonCallback={() => this.handleFetchAcciones(currentUser.id)}
            actionIsLoading={this.state.accionesCargando}
            showExtraContent={this.state.showAcciones}
            extraContent={
              <TablaAcciones acciones={this.state.acciones}></TablaAcciones>
            }
          />
          <ModalConfirmarAccion
            isOpen={this.props.showModalFirmaElectronica}
            onClose={this.props.handleShowModalFirmaElectronica}
            text={firmaModelText()}
            buttonTextPositiveAction="Entiendo"
            buttonAriaLabelPositiveAction="Entiendo que debo validar mi mail"
            handleSubmitPositiveAction={
              this.props.handleShowModalFirmaElectronica
            }
          />
          <BoxServiciosAsociados
            aplicacionesConConsentimiento={
              this.props.aplicacionesConConsentimiento
            }
            retirarAcceso={this.handleRetirarAcceso}
          />
          <ModalConfirmarAccion
            isOpen={this.props.showModalRetirarAcceso}
            onClose={this.props.handleShowModalRetirarAcceso}
            title="¿Querés retirar el acceso?"
            text={
              `${this.props.serviceName} ya no tendrá ` +
              'acceso a tu Usuario gub.uy. Vas a tener que concederle ' +
              'el acceso nuevamente si querés utilizarlo nuevamente en el futuro.'
            }
            buttonTextPositiveAction="Aceptar"
            buttonAriaLabelPositiveAction={`Quiero retirarle el acceso al servicio ${this.props.serviceName}`}
            handleSubmitPositiveAction={this.props.removeUserConsent}
            buttonTextNegativeAction="Cancelar"
            buttonAriaLabelNegativeAction={`No quiero retirarle el acceso al servicio ${this.props.serviceName}`}
            handleSubmitNegativeAction={this.props.handleShowModalRetirarAcceso}
            loading={this.props.loadingRemoveUserConsent}
          />
        </Card>
      </>
    );
  }
}

export default Seguridad;
