import { useSelector } from 'react-redux';
import {
  cargandoUsuarioLogueadoSelector,
  obtenerPermisosCiudadanoSelector,
} from 'store/selectors';

const usePermissions = listaPermisos => {
  const cargando = useSelector(cargandoUsuarioLogueadoSelector);
  const permisos = useSelector(obtenerPermisosCiudadanoSelector);

  if (cargando) {
    return false;
  }

  return listaPermisos?.some(perm => {
    const permApp = perm[0];
    const permNombre = perm[1];
    if (permisos[permApp]) {
      return permisos[permApp].includes(permNombre);
    }
    return false;
  });
};

export default usePermissions;
