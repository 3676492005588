import { puedeIngresarVideollamada } from 'utils/utils';
import {
  ESTADO_AGENDADO,
  ESTADO_PENDIENTE_AGENTE,
  ESTADO_PENDIENTE_VERIFICACION,
  ESTADO_RECHAZADO_VERIFICACION,
} from './commonConstants';

const estadosPermitidosVideollamada = [
  ESTADO_AGENDADO,
  ESTADO_PENDIENTE_AGENTE,
  ESTADO_PENDIENTE_VERIFICACION,
  ESTADO_RECHAZADO_VERIFICACION,
];

export const condicionPantallaIngresoVideollamada = usuarioLogueado => {
  const esHoraVideollamada =
    !window.REACT_APP_HABILITAR_VIDEOLLAMADA_5_MINUTOS_ANTES ||
    puedeIngresarVideollamada(
      usuarioLogueado?.info_tramite_videollamada_pendiente,
    );
  const tieneEstadoValido = estadosPermitidosVideollamada.includes(
    usuarioLogueado?.info_tramite_videollamada_pendiente?.estado,
  );

  return esHoraVideollamada && tieneEstadoValido;
};
