import {
  API_REQUEST,
  ACTIVATE_TOTP_URL,
  VERIFY_CODE_TOTP_URL,
  MFA_GET_STATUS,
  DELETE_TOTP_URL,
  RESEND_RECOVERY_CODE_EMAIL_URL,
} from 'constants/ApiConstants';
import {
  ACTIVATE_TOTP,
  VERIFY_CODE_TOTP,
  MFA_RESET,
  FETCH_MFA,
  DELETE_TOTP,
  RESEND_RECOVERY_CODE_EMAIL,
} from 'constants/ActionTypes';
import Auth from 'utils/Auth';

export const TOTPActivate = () => ({
  type: API_REQUEST,
  payload: {
    url: ACTIVATE_TOTP_URL,
    method: 'post',
    next: ACTIVATE_TOTP,
  },
});

export const TOTPVerifyCode = (id, code) => ({
  type: API_REQUEST,
  payload: {
    url: VERIFY_CODE_TOTP_URL(id),
    method: 'post',
    data: {
      code,
    },
    next: VERIFY_CODE_TOTP,
  },
});

export const MFAResetErrors = () => ({
  type: MFA_RESET,
});

export const fetchMFA = (id = Auth.getInstance().getUserId()) => ({
  type: API_REQUEST,
  payload: {
    url: MFA_GET_STATUS(id),
    method: 'get',
    next: FETCH_MFA,
  },
});

export const deleteTOTP = id => ({
  type: API_REQUEST,
  payload: {
    url: DELETE_TOTP_URL(id),
    method: 'delete',
    next: DELETE_TOTP,
  },
});

export const resendRecoveryCodeEmail = token => ({
  type: API_REQUEST,
  payload: {
    data: {
      token,
    },
    url: RESEND_RECOVERY_CODE_EMAIL_URL,
    method: 'post',
    next: RESEND_RECOVERY_CODE_EMAIL,
  },
});
