import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Acciones
import { hideFooter, hideHeader } from 'actions/ui';

// Constantes
import { REDIRIGIENDO } from 'constants/commonConstants';

// Componentes
import CardSpinner from 'components/CardSpinner';
import {
  authnResponseSelector,
  brokerSamlActionSelector,
  relayStateSelector,
} from 'store/selectors';

/**
 * Este componente recibe el contenido de una SAMLResponse y lo envía mediante
 * el binding POST. Para ello carga los datos en un formulario y realiza un submit.
  
 * Action: acs a donde se envía la response.
 * AuthnResponse: La respuesta SAML.
 * RelayState: url de redirección a donde se debería ir luego de recibir la respuesta.
*/

const SAMLResponseContainer = () => {
  const dispatch = useDispatch();

  const formRef = useRef();

  const authnResponse = useSelector(authnResponseSelector);
  const action = useSelector(brokerSamlActionSelector);
  const relayState = useSelector(relayStateSelector);

  useEffect(() => {
    dispatch(hideHeader());
    dispatch(hideFooter());
    if (authnResponse && action) {
      formRef.current?.submit();
    }
  }, []);

  return (
    <div>
      <CardSpinner spin text={REDIRIGIENDO} />
      {authnResponse && action && (
        <form method="post" action={action} ref={formRef}>
          <input type="hidden" name="SAMLResponse" value={authnResponse} />
          {relayState && (
            <input type="hidden" name="RelayState" value={relayState} />
          )}
        </form>
      )}
    </div>
  );
};

export default SAMLResponseContainer;
