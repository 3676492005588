import React from 'react';
import dayjs from 'dayjs';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

import useWindowSize from 'hooks/useWindowSize';
import { ANCHO_TABLET } from 'constants/commonConstants';

import styles from './styles.module.scss';

const SelectorHora = ({ disabled, periodos, onChange, selected, loading }) => {
  const [ancho] = useWindowSize();
  const esMovil = ancho < ANCHO_TABLET;
  const handleListItemClick = item => {
    if (!disabled) {
      const itemIndex = periodos.findIndex(periodo => periodo.id === item.id);
      onChange(periodos[itemIndex]);
    }
  };

  const componenteHorasPorTamanoPantalla = () => {
    if (esMovil) {
      return (
        <div className={styles.grid}>
          {periodos.map(item => (
            // eslint-disable-next-line react/button-has-type
            <button
              key={item.id}
              className={`${styles.button} ${
                selected && selected.id === item.id && styles.selected
              }`}
              onClick={() => handleListItemClick(item)}
              disabled={disabled}
            >
              {`${dayjs(item.value).format('HH:mm')} hs`}
            </button>
          ))}
        </div>
      );
    }
    return (
      <>
        <div
          className={`${styles.horasInternasDiv} ${
            disabled && styles.disabled
          }`}
        >
          <ul className={styles.ul}>
            {periodos.map(item => (
              <li className={styles.li} key={item.id}>
                <button
                  type="button"
                  className={`${styles.boton} ${
                    selected && selected.id === item.id && styles.selected
                  }`}
                  onClick={() => handleListItemClick(item)}
                  disabled={disabled}
                >
                  {`${dayjs(item.value).format('HH:mm')} hs`}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {disabled && <div className={styles.fakeBar} />}
      </>
    );
  };

  return (
    <div className={styles.contenedor}>
      {loading ? (
        <div className={styles.contenedorSpinner}>
          <Spinner
            className={`${styles.spinner} spin-class`}
            name="ball-spin-fade-loader"
            fadeIn="none"
          />
        </div>
      ) : (
        componenteHorasPorTamanoPantalla()
      )}
    </div>
  );
};

SelectorHora.propTypes = {
  disabled: PropTypes.bool,
  periodos: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.object,
  loading: PropTypes.bool,
};

export default SelectorHora;
