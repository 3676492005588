import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { history } from 'store/ConfigureStore';

// constantes
import {
  FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL,
  FLUJO_VIDEOLLAMADA_OBTENER_NIVEL_INTERMEDIO,
  NO_TIENES_VIDEOLLAMADA,
  IR_A_MI_PERFIL,
} from 'constants/commonConstants';
import { SEGURIDAD_ROUTE } from 'constants/RouterConstants';

// componentes
import ButtonOutline from 'components/ButtonOutline';
import Card from 'components/Card';
import BotonVolver from 'components/BotonVolver';
import TitleCard from 'components/TitleCard';

import styles from './CancelarReserva.module.css';
import stylesAgenda from '../Agenda/Agenda.module.css';

const CancelarReserva = () => {
  const [cancelacionConfirmada] = useState(true);

  const renderCancelacionConfirmada = () => (
    <>
      <p>{NO_TIENES_VIDEOLLAMADA}</p>
      <Link to={SEGURIDAD_ROUTE}>
        <ButtonOutline
          text={IR_A_MI_PERFIL}
          className={stylesAgenda.botonPerfil}
        />
      </Link>
    </>
  );

  return (
    <>
      <BotonVolver
        funcionIrAtras={() => history.push(SEGURIDAD_ROUTE)}
        texto={FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL}
      />
      <TitleCard
        title={FLUJO_VIDEOLLAMADA_OBTENER_NIVEL_INTERMEDIO}
        className={styles.titulo}
      />
      <Card innerClassName={styles.container}>
        {cancelacionConfirmada && renderCancelacionConfirmada()}
      </Card>
    </>
  );
};

export default CancelarReserva;
