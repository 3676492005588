import { API_REQUEST, FETCH_IDPS_URL } from 'constants/ApiConstants';
import { FETCH_IDPS } from 'constants/ActionTypes';

const fetchIDPS = processState => ({
  type: API_REQUEST,
  payload: {
    url: FETCH_IDPS_URL(processState),
    method: 'get',
    next: FETCH_IDPS,
  },
});

export default fetchIDPS;
