/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import CardSpinner from 'components/CardSpinner';
import { TOTPVerifyCode, MFAResetErrors } from 'actions/mfa';
import { MFA_PAGE_ROUTE } from 'constants/RouterConstants';
import {
  MFA_TOTP_VERIFY_TITLE,
  MFA_TOTP_VERIFY_DESC,
  MFA_TOTP_VERIFY_SUBMIT_BUTTON_TEXT,
} from 'constants/commonConstants';
import TOTPVerificarCodigo from './totpVerificarCodigo';

class TOTPVerificarCodigoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    if (!this.props.totp) {
      this.props.history.push(MFA_PAGE_ROUTE);
    }
  }

  handleContinue(values) {
    this.props.TOTPVerifyCode(this.props.totp.id, values.codigo);
  }

  handleBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    if (!this.props.totp) {
      return <CardSpinner spin />;
    }
    return (
      <TOTPVerificarCodigo
        title={MFA_TOTP_VERIFY_TITLE}
        desc={MFA_TOTP_VERIFY_DESC}
        handleContinue={this.handleContinue}
        handleContinueText={MFA_TOTP_VERIFY_SUBMIT_BUTTON_TEXT}
        handleBack={this.handleBack}
        envioCodigoErrors={this.props.envioCodigoErrors}
        resetErrors={this.props.MFAResetErrors}
        loading={this.props.loading}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.mis_datos.currentUser,
  totp: state.mfa.methods.totp,
  envioCodigoErrors: state.mfa.errors,
  loading: state.mfa.loading,
});

export default connect(mapStateToProps, {
  TOTPVerifyCode,
  MFAResetErrors,
})(TOTPVerificarCodigoContainer);
