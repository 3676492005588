import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { hideHeader, hideFooter } from 'actions/ui';
import ButtonSolid from 'components/ButtonSolid';
import CardSpinner from 'components/CardSpinner';
import SAML from 'utils/SAML';
import Auth from 'utils/Auth';

import {
  SAML_INDEX_TEXTO_BOTON_LOGIN,
  SAML_INDEX_TEXTO_BOTON_LOGOUT,
  SAML_INDEX_TEXTO_FALTA_LOGIN,
  SAML_INDEX_TITULO,
} from 'constants/commonConstants';
import styles from './styles.module.css';

const SAMLIndexContainer = () => {
  const [extra, setExtra] = useState(null);
  const [cargando, setCargando] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(hideFooter());
    dispatch(hideHeader());

    const parsed = queryString.parse(location.search);
    const extraParam = parsed.extra ? parsed.extra : null;
    setExtra(extraParam);

    const obtenerToken = async () => {
      await Auth.getInstance().obtainToken();
    };
    obtenerToken();
  }, []);

  const handleCerrarSesionIDUruguay = () => {
    if (Auth.getInstance().isAuthenticated()) {
      setCargando(true);
      SAML.sendLogoutRequest();
    }
    setExtra(SAML_INDEX_TEXTO_FALTA_LOGIN);
  };

  const handleIniciarSesionIDUruguay = () => {
    setCargando(true);
    window.location.href = `${window.REACT_APP_BACKEND_HOST}/saml-login-id-uruguay`;
  };

  if (cargando) {
    return (
      <CardSpinner
        spin
        className="spinner__transparent"
        text="Enviando SAMLRequest"
      />
    );
  }

  return (
    <div className={styles.contenedorPrincipal}>
      <p className={styles.textoPrincipal}>{SAML_INDEX_TITULO}</p>

      <div className={styles.contenedorBotones}>
        <ButtonSolid
          className={styles.boton}
          handleClick={handleIniciarSesionIDUruguay}
          text={SAML_INDEX_TEXTO_BOTON_LOGIN}
        />
        <ButtonSolid
          className={styles.boton}
          handleClick={handleCerrarSesionIDUruguay}
          text={SAML_INDEX_TEXTO_BOTON_LOGOUT}
        />
      </div>

      {extra && <p className={styles.textoInfo}>{extra}</p>}
    </div>
  );
};

export default SAMLIndexContainer;
