import imgError404 from 'assets/404.svg';
import imgServerError from 'assets/internal-server-error.svg';
import imgOIDCError from 'assets/oidc-error.svg';
import {
  ERROR_404_TITLE,
  ERROR_500_TITLE,
  ERROR_UNREGISTERED_USER,
} from './pageTitles';
import {
  HOME_ROUTE,
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
  REGISTRO_ROUTE,
  LOGIN_ROUTE,
} from './RouterConstants';

// Titulos -----------------------------------------------------------------------------------------
const TITLE_404_ERROR = 'Te perdiste.';
const TITLE_SERVER_ERROR = 'Uy! Algo salió mal.';
const TITLE_UNREGISTERED_USER = 'No estás registrado.';
const TITLE_UNACTIVE_ACCOUNT = 'No activaste tu cuenta.';
const TITLE_BLOCKED_ACCOUNT = 'Tu cuenta esta bloqueada.';
export const TITLE_IDP_ERROR = 'Error con el proveedor de identidad.';

// Descripcion -------------------------------------------------------------------------------------
const DESCRIPTION_404_ERROR =
  'La dirección a la que estás tratando de acceder no existe o fue cambiada.';
const DESCRIPTION_SERVER_ERROR =
  'No te preocupes que no sos vos, somos nosotros. Estamos trabajando para resolver este problema.';
const DESCRIPTION_ERROR_OIDC =
  'Reintentá en unos minutos. Si el problema continúa comunicate con nosotros.';
const DESCRIPTION_ERROR_SAML =
  'Pedido SAML inválido. Si el problema continúa comunícate con nosotros.';
const DESCRIPTION_ERROR_UNREGISTERED_USER =
  'La cédula que ingresaste no existe en nuestro sistema.';
const DESCRIPTION_ERROR_UNACTIVE_ACCOUNT = `Para poder ingresar a tu Usuario gub.uy, debes activar tu cuenta.
  Puedes hacerlo con el correo de activación que te enviamos o reenviártelo.`;
const DESCRIPTION_BLOCKED_ACCOUNT =
  'Para desbloquear tu cuenta debes contactarte con un Agente.';
const DESCRIPTION_IDP_ERROR_WITHOUT_ERROR_URL_REGISTRO =
  'Ha ocurrido un error con el proveedor de identidad que elegiste. Puedes iniciar sesión con otro método o puedes comunicarte a ';
const DESCRIPTION_IDP_ERROR_WITH_ERROR_URL_REGISTRO =
  'Ha ocurrido un error con el proveedor de identidad que elegiste. Puedes iniciar sesión con otro método, comunicarte a ';
const DESCRIPTION_IDP_ERROR_GENERICO =
  'Consultá el estado de tu Identidad para continuar';
const DESCRIPTION_IDP_ERROR_ERROR_GENERICO_TEXTO_LINK =
  'Consultar el estado de mi Identidad';
const DESCRIPTION_IDP_ERROR_ERROR_GENERICO_LINK =
  'https://estado-identidad.id.com.uy/';
const TITULO_ERROR_ABITAB =
  'Hubo un inconveniente al identificarte con tu Identidad Mobile Abitab';

// Titulos de botones ------------------------------------------------------------------------------
const BUTTON_TEXT_HOME = 'Ir a gub.uy';
const BUTTON_TEXT_REGISTRATE = 'Registrate';
const BUTTON_TEXT_REENVIAR = 'Reenviar';
const BUTTON_TEXT_INICIAR_SESION = 'Iniciar sesión';
const VOLVER_INICIO_SESION = 'Volver a iniciar sesión';

// Alt de las imágenes --------------------------------------------------------------------
const ALT_404_ERROR = 'Imagen de página no encontrada.';
const ALT_SERVER_ERROR = 'Imagen de error del servidor';

// Callbacks ------------------------------------------------------------------------------
export function handleGoResendActivation(props) {
  props.history.push(REGISTRO_REENVIAR_CORREO_ACTIVACION);
}
export function handleGoToHome() {
  window.location.href = HOME_ROUTE;
}

export function handleGoToRegistro() {
  window.location.href = REGISTRO_ROUTE;
}

export function handleGoToLogin(params) {
  window.location.href = `${LOGIN_ROUTE}/?${params}`;
}

export function handlePaginaAbitab() {
  window.location.href = DESCRIPTION_IDP_ERROR_ERROR_GENERICO_LINK;
}

// Diccionario de errores --------------------------------------------------------------------------
const UNACTIVE_ACCOUNT = {
  title: TITLE_UNACTIVE_ACCOUNT,
  description: DESCRIPTION_ERROR_UNACTIVE_ACCOUNT,
  buttonText: BUTTON_TEXT_REENVIAR,
  pageTitle: ERROR_404_TITLE,
  images: [imgError404],
  altImg: ALT_404_ERROR,
  callback: handleGoResendActivation,
};

const UNREGISTERED_USER = {
  title: TITLE_UNREGISTERED_USER,
  description: DESCRIPTION_ERROR_UNREGISTERED_USER,
  buttonText: BUTTON_TEXT_REGISTRATE,
  pageTitle: ERROR_UNREGISTERED_USER,
  images: [imgError404],
  altImg: ALT_404_ERROR,
  callback: handleGoToRegistro,
};

const BLOCKED_ACCOUNT = {
  title: TITLE_BLOCKED_ACCOUNT,
  description: DESCRIPTION_BLOCKED_ACCOUNT,
  buttonText: BUTTON_TEXT_HOME,
  pageTitle: ERROR_UNREGISTERED_USER,
  images: [imgError404],
  altImg: ALT_404_ERROR,
  callback: handleGoToHome,
};

const NOT_FOUND_ERROR = {
  title: TITLE_404_ERROR,
  description: DESCRIPTION_404_ERROR,
  buttonText: BUTTON_TEXT_HOME,
  pageTitle: ERROR_404_TITLE,
  images: [imgError404],
  altImg: ALT_404_ERROR,
  callback: handleGoToHome,
};

const SERVER_ERROR = {
  title: TITLE_SERVER_ERROR,
  description: DESCRIPTION_SERVER_ERROR,
  buttonText: BUTTON_TEXT_HOME,
  pageTitle: ERROR_500_TITLE,
  images: [imgServerError],
  altImg: ALT_SERVER_ERROR,
  callback: handleGoToHome,
};

const OIDC_ERROR = {
  title: TITLE_SERVER_ERROR,
  description: DESCRIPTION_ERROR_OIDC,
  buttonText: '',
  pageTitle: ERROR_500_TITLE,
  images: [imgOIDCError],
  altImg: ALT_SERVER_ERROR,
  callback: null,
  horizontal: false,
};

const SAML_ERROR = {
  title: TITLE_SERVER_ERROR,
  description: DESCRIPTION_ERROR_SAML,
  buttonText: '',
  pageTitle: ERROR_500_TITLE,
  images: [imgOIDCError],
  altImg: ALT_SERVER_ERROR,
  callback: null,
  horizontal: false,
};

const ABITAB_ERROR = {
  titulo: TITULO_ERROR_ABITAB,
  textoRedireccion: DESCRIPTION_IDP_ERROR_ERROR_GENERICO_TEXTO_LINK,
  handleRedireccion: handlePaginaAbitab,
  descripcionError: DESCRIPTION_IDP_ERROR_GENERICO,
  textoBoton: VOLVER_INICIO_SESION,
};

const IDP_ERROR = {
  title: TITLE_IDP_ERROR,
  description_sin_url_registro:
    DESCRIPTION_IDP_ERROR_WITHOUT_ERROR_URL_REGISTRO,
  description_con_url_registro: DESCRIPTION_IDP_ERROR_WITH_ERROR_URL_REGISTRO,
  buttonText: BUTTON_TEXT_INICIAR_SESION,
  pageTitle: ERROR_500_TITLE,
  images: [imgServerError],
  altImg: ALT_SERVER_ERROR,
  callback: handleGoToLogin,
  abitab: ABITAB_ERROR,
};

const DEFAULT_ERROR = {
  title: TITLE_404_ERROR,
  description: DESCRIPTION_404_ERROR,
  buttonText: BUTTON_TEXT_HOME,
  pageTitle: ERROR_404_TITLE,
  images: [imgError404],
  altImg: ALT_404_ERROR,
  callback: handleGoToHome,
};

export const ERROR_CODES = {
  UNACTIVE_ACCOUNT,
  UNREGISTERED_USER,
  BLOCKED_ACCOUNT,
  NOT_FOUND_ERROR,
  SERVER_ERROR,
  OIDC_ERROR,
  SAML_ERROR,
  IDP_ERROR,
  DEFAULT_ERROR,
};

export const ERRORES_ABITAB = [
  'UnregisteredSignIdentityException',
  'BloquedUser',
  'PlanTimedOut',
  'GenericError',
];
