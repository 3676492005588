import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

// actions y services
import { cambiarContrasena } from 'services/apiServices';
import { updateAriaLiveMessage } from 'actions/ui';

// constantes y validators
import { RESET_PASSWORD_SET_PASSWORD_TITLE } from 'constants/pageTitles';
import {
  MODIFICA_TU_CONTRASENA,
  CREA_UNA_NUEVA_CONTRASENA,
  NUEVA_CONTRASENA,
  REPETIR_NUEVA_CONTRASENA,
  CONTINUAR,
  MIN_LEN_PASSWORD,
  ONLY_NUMBERS_VALIDATOR_MSG,
  PASSWORD_SIMILARITY_MSG,
} from 'constants/commonConstants';
import { notOnlyNumbersRegex } from 'utils/validators';

// componentes
import CardSmall from 'components/CardSmall';
import ButtonSolid from 'components/ButtonSolid';
import PasswordFieldWithWidget from 'components/HookForm/PasswordFieldWithWidget';
import PasswordField from 'components/HookForm/PasswordField';

import {
  RESET_PASSWORD_INVALID_LINK_ROUTE,
  CHANGE_PASSWORD_ROUTE_SUCCESS,
} from 'constants/RouterConstants';

// estilos
import styles from './styles.module.css';

const NuevaContrasenaContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setFocus,
    trigger,
    formState: { errors, touchedFields, isDirty, isValid },
  } = useForm({
    mode: 'onTouched',
  });

  const [desactivarSubmit, setDesactivarSubmit] = useState(true);
  const [cargando, setCargando] = useState(false);
  const [errorsPasswordBackend, setErrorsPasswordBackend] = useState(null);

  const watchPassword = watch('password');
  const watchRepetirPassword = watch('repetirPassword');

  useEffect(() => {
    dispatch(updateAriaLiveMessage(RESET_PASSWORD_SET_PASSWORD_TITLE));
  }, []);

  const watchRequiredValues = watch(['password', 'repetirPassword']);
  const { token } = queryString.parse(location.search);

  useEffect(() => {
    const fieldsHaveValues = watchRequiredValues.reduce(
      (previousValue, currentValue) => previousValue && !!currentValue,
      true,
    );
    const noErrors = Object.keys(errors).length === 0;
    setDesactivarSubmit(!(fieldsHaveValues && noErrors));
  }, [isValid, isDirty]);

  useEffect(() => {
    setErrorsPasswordBackend(null);
  }, [watchPassword]);

  const onSubmit = async datosFormulario => {
    try {
      setCargando(true);
      const { password, repetirPassword } = datosFormulario;
      await cambiarContrasena(password, repetirPassword, token);
      history.push(CHANGE_PASSWORD_ROUTE_SUCCESS);
    } catch (error) {
      // Los errores de validación que se hacen en el backend no se están parseando.
      // Si el response es 'password' se setea el mensaje al error que trae el backend y
      // se deshabilita el boton.
      if (error?.data?.field === 'password') {
        setErrorsPasswordBackend(error?.data?.error);
        setDesactivarSubmit(true);
      }
      // En caso de recibir un error indicando que el token es inválido.
      // Se redirige a RESET_PASSWORD_INVALID_LINK_ROUTE.
      if (error?.data?.invalid_token) {
        history.push(RESET_PASSWORD_INVALID_LINK_ROUTE);
      }
    } finally {
      setCargando(false);
    }
  };

  return (
    <>
      <Helmet title={RESET_PASSWORD_SET_PASSWORD_TITLE} />
      <CardSmall
        title={MODIFICA_TU_CONTRASENA}
        desc={CREA_UNA_NUEVA_CONTRASENA}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordFieldWithWidget
            register={register}
            trigger={trigger}
            touched={touchedFields.password}
            getValues={getValues}
            value={watchPassword}
            setFocus={() => setFocus('password')}
            name="password"
            label={NUEVA_CONTRASENA}
            required
            minLength={MIN_LEN_PASSWORD}
            validationRegex={notOnlyNumbersRegex}
            regexErrorMessage={ONLY_NUMBERS_VALIDATOR_MSG}
            validationErrorMessage={PASSWORD_SIMILARITY_MSG}
            error={errors.password}
          />
          {errorsPasswordBackend && (
            <div className={styles['form-field__help-text__error__div']}>
              <span className={styles['form-field__help-text__error']}>
                {errorsPasswordBackend}
              </span>
            </div>
          )}
          <PasswordField
            register={register}
            name="repetirPassword"
            type="password"
            value={watchRepetirPassword}
            label={REPETIR_NUEVA_CONTRASENA}
            required
            matchValue={watchPassword}
            error={errors.repetirPassword}
            showSuccess={touchedFields.repetirPassword}
          />
          <ButtonSolid
            text={CONTINUAR}
            isSubmit
            ariaLabel={CONTINUAR}
            isDisabled={desactivarSubmit}
            isLoading={cargando}
            className={styles.boton}
          />
        </form>
      </CardSmall>
    </>
  );
};

export default NuevaContrasenaContainer;
