import {
  CLEAR_RESET_PASSWORD_ERRORS,
  CONFIRM_RESET_PASSWORD,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  loading: false,
  confirmFormError: null,
  next: null,
};

// eslint-disable-next-line default-param-last
export default function resetPasswordReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_RESET_PASSWORD.PENDING:
      return {
        ...state,
        loading: true,
        next: null,
      };
    case CONFIRM_RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        next: null,
      };
    case CONFIRM_RESET_PASSWORD.ERROR:
      return {
        ...state,
        confirmFormError: action.payload,
        loading: false,
      };
    case CLEAR_RESET_PASSWORD_ERRORS:
      return {
        ...state,
        confirmFormError: null,
      };
    default:
      return state;
  }
}
