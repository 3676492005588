import React from 'react';
import { FEEDBACK_FORM_ROUTE } from 'constants/RouterConstants';
import ButtonLink from '../ButtonLink';
import styles from '../FeedbackSection/styles.module.css';

export const FeedbackPageForm = () => (
  <div className={styles.feedback_section__item_container}>
    <span className={styles.feedback_section__item}>
      Si encontraste algún error en este sitio:
    </span>
    <div className={styles.feedback_section__item}>
      <ButtonLink
        title="ayúdanos a mejorarlo"
        to={FEEDBACK_FORM_ROUTE}
        aria-label="Si encontraste algún error en este sitio:"
      />
    </div>
  </div>
);
