import React from 'react';
import PropTypes from 'prop-types';

import successfulIcon from 'assets/icons/icon-success.svg';
import ButtonLink from '../ButtonLink';

import './styles.css';

const CardSuccess = ({
  title,
  description,
  extra1,
  extra2,
  returnUrl,
  headerText,
}) => (
  <div className="successful-card">
    <div className="successful-card__img">
      <img
        src={successfulIcon}
        alt="Operación realizada con éxito"
        height="48"
        width="48"
      />
    </div>
    <div className="successful-card__content">
      <h2>{title}</h2>
      <div className="subheading" style={{ marginTop: '16px' }}>
        {description}
      </div>
      {extra1 && (
        <div
          className="successful-card__content__extra"
          style={{ marginTop: '16px' }}
        >
          {extra1}
        </div>
      )}
      {extra2 && (
        <div className="successful-card__content__extra">{extra2}</div>
      )}
      {returnUrl && (
        <div className="successful-card__content__button__link">
          <ButtonLink
            buttonType="header"
            to={returnUrl}
            aria-label={headerText}
            title={headerText}
          />
        </div>
      )}
    </div>
  </div>
);

CardSuccess.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  extra1: PropTypes.string,
  extra2: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  returnUrl: PropTypes.string,
  headerText: PropTypes.string,
};

CardSuccess.defaultProps = {
  extra1: '',
  extra2: null,
};

export default CardSuccess;
