/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import ButtonLink from '../ButtonLink';

import './styles.css';

// ---------------------------------------------------------------------------
// Helper Component
// ---------------------------------------------------------------------------
const HelperTextAndErrors = ({
  showHelperText,
  helperTextMsg,
  showError,
  errorMsg,
}) =>
  showHelperText ? (
    <div>
      <div className="hidden-tablet" style={{ position: 'relative' }}>
        <div className="popup">
          <span>{helperTextMsg}</span>
        </div>
      </div>
      <div className="hidden-desktop">
        <span className="caption">{helperTextMsg}</span>
      </div>
    </div>
  ) : (
    (showError && (
      <span className="render-field__help-text__error">{errorMsg}</span>
    )) ||
    null
  );

// ---------------------------------------------------------------------------
// RenderField
// ---------------------------------------------------------------------------
export class RenderField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleFocus() {
    this.setState({ showPopup: true });
  }

  handleBlur() {
    this.setState({ showPopup: false });
  }

  render() {
    const {
      input,
      tabIndex = 0,
      type,
      label,
      required,
      disabled,
      hidePlaceholder,
      msg,
      hasTooltip,
      resetFieldError,
      expanded = false,
      style,
      linkUrl,
      linkText,
    } = this.props;
    const { touched, error, visited, submitFailed } = this.props.meta;

    const showHelperText = this.state.showPopup && hasTooltip;
    const submitError =
      this.props.error && this.props.error.field === input.name;
    const validationError = ((touched && visited) || submitFailed) && error;

    let errorMsg = null;
    if (submitError) {
      errorMsg = this.props.error.error;
    } else if (validationError) {
      errorMsg = error.msg;
    }

    return (
      <div
        className={`${expanded ? 'render-field--expanded' : 'render-field'}`}
        style={style}
      >
        <span className="render-field__label-container">
          <label className="alt" htmlFor={input.name}>{`${label}${
            required ? ' *' : ''
          }:`}</label>
          {linkUrl ? (
            <div className="render-field__label-container__link">
              <ButtonLink to={linkUrl} title={linkText} />
            </div>
          ) : (
            ''
          )}
        </span>
        <span className="render-field__input-container">
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            className={
              submitError || validationError ? 'render-field__input--error' : ''
            }
            tabIndex={tabIndex}
            placeholder={hidePlaceholder ? null : label}
            type={type}
            disabled={disabled}
            id={input.name}
            onFocus={e => {
              if (input) input.onFocus(e);
              this.handleFocus();
            }}
            onBlur={e => {
              if (input) input.onBlur(e);
              this.handleBlur();
            }}
            onChange={e => {
              if (input) input.onChange(e);
              if (resetFieldError) resetFieldError(e);
            }}
          />
        </span>
        <div className="render-field__helper-text-container">
          <HelperTextAndErrors
            showHelperText={showHelperText}
            helperTextMsg={msg}
            showError={submitError || validationError}
            errorMsg={errorMsg}
          />
        </div>
      </div>
    );
  }
}

RenderField.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  meta: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  resetFieldError: PropTypes.func,
  expanded: PropTypes.bool,
};

// ---------------------------------------------------------------------------
// SelectField
// ---------------------------------------------------------------------------
export const SelectField = ({
  input,
  tabIndex = 0,
  meta,
  type,
  label,
  required,
  hidePlaceholder,
  data,
  expanded,
}) => (
  <div className={`${expanded ? 'render-field--expanded' : 'render-field'}`}>
    <span className="render-field__label-container">
      <label className="alt" htmlFor={input.name}>{`${label}${
        required ? ' *' : ''
      }:`}</label>
    </span>
    <span className="render-field__input-container">
      <select
        {...input}
        placeholder={hidePlaceholder ? null : label}
        type={type}
        tabIndex={tabIndex}
        id={input.name}
        className={
          ((meta.touched && meta.visited) || meta.submitFailed) && meta.error
            ? 'render-field__input--error'
            : ''
        }
        selected={data !== undefined ? data[0] : null}
      >
        {data
          ? data.map(obj => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))
          : null}
      </select>
    </span>
    <div className="render-field__helper-text-container">
      {(((meta.touched && meta.visited) || meta.submitFailed) &&
        ((meta.error && (
          <span className="render-field__help-text__error">{meta.error}</span>
        )) ||
          (meta.warning && (
            <span className="render-field__help-text__warning">
              {meta.warning}
            </span>
          )))) || <span className="render-field__help-text__warning"></span>}
    </div>
  </div>
);

SelectField.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  meta: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  data: PropTypes.array,
  help: PropTypes.string,
  expanded: PropTypes.bool,
};

// ---------------------------------------------------------------------------
// TextField
// ---------------------------------------------------------------------------
export const TextField = ({
  input,
  tabIndex = 0,
  meta,
  disabled,
  type,
  label,
  required,
  hidePlaceholder,
  placeholder,
  error,
  expanded = false,
  className,
}) => (
  <div
    className={`${className || ''} ${
      expanded ? 'render-field--expanded' : 'render-field'
    }`}
  >
    <span className="render-field__label-container">
      <label className="alt" htmlFor={input.name}>{`${label}${
        required ? '*' : ''
      }`}</label>
    </span>
    <span className="render-field__input-container">
      <textarea
        {...input}
        style={{ resize: 'none', fontSize: '16px', minHeight: '128px' }}
        tabIndex={tabIndex}
        placeholder={hidePlaceholder ? null : placeholder}
        type={type}
        disabled={disabled}
        id={input.name}
        className={
          ((meta.touched && meta.visited) || meta.submitFailed) && meta.error
            ? 'render-field__input--error'
            : ''
        }
      />
    </span>
    <div className="render-field__helper-text-container">
      {(((meta.touched && meta.visited) || meta.submitFailed) && meta.error && (
        <span className="render-field__help-text__error">{meta.error.msg}</span>
      )) ||
        (error !== null && error.field === input.name && (
          <span className="render-field__help-text__error">{error.error}</span>
        )) ||
        null}
    </div>
  </div>
);

TextField.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  error: PropTypes.object,
  expanded: PropTypes.bool,
};

// ---------------------------------------------------------------------------
// CheckboxField
// ---------------------------------------------------------------------------
export const CheckboxField = ({
  input,
  tabIndex = 0,
  meta,
  disabled,
  msg,
  label,
  error,
}) => (
  <div className="render-field checkbox-field">
    <div className="checkbox-field__container">
      <div className="checkbox-field__input">
        <input
          {...input}
          tabIndex={tabIndex}
          type="checkbox"
          role="button"
          disabled={disabled}
          id={input.name}
          aria-label={label}
          aria-pressed="true"
          onFocus={e => {
            if (input) input.onFocus(e);
          }}
          onBlur={e => {
            if (input) input.onBlur(e);
          }}
          className="checkbox-field__checkbox"
        />
      </div>
      <div className="checkbox-field__label">
        <label className="caption" htmlFor={input.name}>
          {msg}
        </label>
      </div>
    </div>
    {((meta.touched && meta.visited) || meta.submitFailed) && meta.error ? (
      <div className="render-field__help-text__error checkbox-field__error">
        {meta.error.msg}
      </div>
    ) : null}
    {error && error.field === input.name ? (
      <div className="render-field__help-text__error checkbox-field__error">
        {error.error}
      </div>
    ) : null}
  </div>
);

CheckboxField.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  msg: PropTypes.object,
};

// ---------------------------------------------------------------------------
// CaptchaField
// ---------------------------------------------------------------------------
export const CaptchaField = ({
  input,
  tabIndex = 0,
  meta,
  recaptchaRef,
  error,
}) => (
  <div className="render-field captcha">
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={window.REACT_APP_RECAPTCHA_KEY}
      onChange={response => input.onChange(response)}
      tabIndex={tabIndex}
    />
    {(((meta.touched && meta.visited) || meta.submitFailed) && meta.error && (
      <span className="render-field__help-text__error">{meta.error.msg}</span>
    )) ||
      (error && error.field === input.name && (
        <span className="render-field__help-text__error">{error.error}</span>
      )) ||
      null}
  </div>
);

CaptchaField.propTypes = {
  input: PropTypes.object,
  tabIndex: PropTypes.number,
  meta: PropTypes.object,
  recaptchaRef: PropTypes.object,
  error: PropTypes.object,
};
