/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React from 'react';

import menuDown from 'assets/icons/menu-down.png';
import menuDown2x from 'assets/icons/menu-down@2x.png';
import menuDown3x from 'assets/icons/menu-down@3x.png';

import menuUp from 'assets/icons/menu-up.png';
import menuUp2x from 'assets/icons/menu-up@2x.png';
import menuUp3x from 'assets/icons/menu-up@3x.png';

import {
  HEADER_LOGOUT_TEXT,
  HEADER_SEGURIDAD_SCREEN_TEXT,
} from 'constants/commonConstants';
import ButtonFlat from '../ButtonFlat';

import './styles.css';
import '../Header (viejo)/styles.css';

const ButtonMenuNavbar = ({
  handleOpenMenu,
  isMenuOpen,
  ariaLabel,
  ariaHasPopup = 'false',
  ariaExpanded = 'false',
  ariaControls,
  username,
  refButtonMenuNavbar,
  classScreenSize,
  // menu buttons
  refButtonFlat,
  showSeguridadButton,
  handleCerrarSesion,
  handleGoSeguridad,
  nivel,
}) => (
  <div className={classScreenSize}>
    <div ref={refButtonMenuNavbar}>
      <div>
        <button
          className="button-menu-navbar"
          onClick={handleOpenMenu}
          aria-label={ariaLabel}
          aria-haspopup={ariaHasPopup}
          aria-expanded={ariaExpanded}
          aria-controls={ariaControls}
        >
          {username || ''}{' '}
          {classScreenSize === 'hidden-tablet' && nivel !== null
            ? `- ${nivel}`
            : ''}
          {isMenuOpen ? (
            <img
              src={menuUp}
              srcSet={`${menuUp2x} 2x, ${menuUp3x} 3x`}
              alt="Menú para ver más opciones: Abierto"
            />
          ) : (
            <img
              src={menuDown}
              srcSet={`${menuDown2x} 2x, ${menuDown3x} 3x`}
              alt="Menú para ver más opciones: Cerrado"
            />
          )}
        </button>
      </div>
    </div>
    {/* menu buttons */}
    {isMenuOpen && (
      <div
        className="header-nav"
        ref={refButtonFlat}
        role="menu"
        id={ariaControls}
      >
        {classScreenSize === 'hidden-desktop' && (
          <ButtonFlat
            text={nivel}
            desktop={classScreenSize === 'hidden-tablet'}
          />
        )}
        {showSeguridadButton && (
          <ButtonFlat
            text={HEADER_SEGURIDAD_SCREEN_TEXT}
            handleOnClick={handleGoSeguridad}
            desktop={classScreenSize === 'hidden-tablet'}
            className={
              classScreenSize === 'hidden-tablet'
                ? 'button-flat-desktop_both'
                : null
            }
          />
        )}
        <div
          className={
            classScreenSize === 'hidden-tablet' && showSeguridadButton
              ? 'middle-line'
              : 'middle-line-hidden'
          }
        ></div>
        <ButtonFlat
          text={HEADER_LOGOUT_TEXT}
          handleOnClick={handleCerrarSesion}
          desktop={classScreenSize === 'hidden-tablet'}
          className={
            showSeguridadButton
              ? 'button-flat-desktop_both'
              : 'button-flat-desktop_one'
          }
        />
      </div>
    )}
  </div>
);

export default ButtonMenuNavbar;
