import React from 'react';
import PropTypes from 'prop-types';

import { REGISTRO_ROUTE, LOGIN_ROUTE } from 'constants/RouterConstants';
import sec2 from 'assets/ahorraTiempo.png';
import basico from 'assets/icons/basico.svg';
import avanzado from 'assets/icons/avanzado.svg';
import intermedio from 'assets/icons/basico_intermedio.svg';
import sec4 from 'assets/verficacion.png';
import ButtonSolid from 'components/ButtonSolid';
import Card from 'components/Card';
import {
  LANDING_HERO_TEXT,
  LANDING_HERO_TEXT_DESC,
  LANDING_SMALL_SECTION_2_TITLE,
  LANDING_SMALL_SECTION_2_DESC,
  LANDING_SMALL_SECTION_3_TITLE,
  LANDING_SMALL_SECTION_3_DESC,
  LANDING_SMALL_SECTION_3_SUBTITLE_1,
  LANDING_SMALL_SECTION_3_SUBDESC_1,
  LANDING_SMALL_SECTION_3_SUBTITLE_2,
  LANDING_SMALL_SECTION_3_SUBTITLE_3,
  LANDING_SMALL_SECTION_4_TITLE,
  LANDING_SMALL_SECTION_4_DESC,
  LANDING_SMALL_SECTION_4_DESC_2,
  LANDING_SMALL_SECTION_4_TITLE_2,
  LANDING_SMALL_SECTION_4_DESC_3,
  LANDING_BOTON_PREGUNTAS_FRECUENTES,
  LANDING_BOTON_REGISTRATE,
  LANDING_BOTON_INGRESA,
  LANDING_BENEFICIOS,
  LANDING_MANERA_COMODA,
  LANDING_INGRESA_DE_MANERA,
  LANDING_AHORRAS_TIEMPO,
  LANDING_PODES_REALIZAR,
  LANDING_INFORMACION_PROTEGIDA,
  LANDING_SISTEMA_HERRAMIENTA,
  LANDING_CON_ESTE_NIVEL,
  LANDING_MEDIANTE_FIRMA_DIGITAL,
  LANDING_PRESENCIALMENTE_EN_PUNTOS_AUTORIZADOS,
  CEDULA_ELECTRONICA_NOMBRE,
  LANDING_NECESITAS_CEDULA,
  LANDING_ID_DIGITAL_ABITAB,
  LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ABITAB,
  LANDING_LOCAL_ABITAB,
  LANDING_DESCARGAR_CELULAR,
  LANDING_ID_ANTEL,
  LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ANTEL,
  LANDING_LOCAL_ANTEL,
  LANDING_MAS_INFORMACION,
  LANDING_EMPEZA_A_USAR_USUARIO_GUB,
  FIRMA_DIGITAL,
  PUNTOS_ATENCION_CIUDADANIA,
  MAS_INFO,
  LOCALES_ABITAB,
  LOCALES_ANTEL,
} from 'constants/commonConstants';

import './landing.css';

const DescSectionSmall = props => (
  <div className="landing__section-small__container">
    <div className="landing__section-small__icon">
      {props.img && (
        <img src={props.img} alt={props.title} style={props.style} />
      )}
    </div>
    <div>
      <h2 className="landing__section-small__title">{props.title}</h2>
      <div className="landing__section-small__desc">{props.desc}</div>
    </div>
  </div>
);

const DescSectionSmallSubsection = props => (
  <div className="landing__section-small__subsection">
    <div className="landing__section-small__subsection__icon">
      <img src={props.img} alt={props.title} />
    </div>
    <h3 className="landing__section-small__subsection__title">{props.title}</h3>
    {props.desc && (
      <div className="landing__section-small__subsection__desc">
        {props.desc}
      </div>
    )}
  </div>
);

const Landing = () => (
  <main className="landing landing-home">
    <div className="landing__section-small container">
      <Card className="landing__section-small__card">
        <h1 className="landing__page__title">{LANDING_HERO_TEXT}</h1>
        <div className="landing__page__desc">{LANDING_HERO_TEXT_DESC}</div>
        <div className="landing__page__subdesc">
          <b>{LANDING_BENEFICIOS}</b>
          <ul>
            <li>
              <b>{LANDING_MANERA_COMODA}</b> {LANDING_INGRESA_DE_MANERA}
            </li>
            <li>
              <b>{LANDING_AHORRAS_TIEMPO}</b> {LANDING_PODES_REALIZAR}
            </li>
            <li>
              <b>{LANDING_INFORMACION_PROTEGIDA}</b>{' '}
              {LANDING_SISTEMA_HERRAMIENTA}
            </li>
          </ul>
        </div>
      </Card>
      <Card className="landing__section-small__card">
        <DescSectionSmall
          title={LANDING_SMALL_SECTION_2_TITLE}
          desc={LANDING_SMALL_SECTION_2_DESC}
          img={sec2}
          style={{ borderRadius: '50%' }}
        />
      </Card>
      <Card className="landing__section-small__card">
        <DescSectionSmall
          title={LANDING_SMALL_SECTION_3_TITLE}
          desc={LANDING_SMALL_SECTION_3_DESC}
        />
        <div>
          {/* nivel básico */}
          <DescSectionSmallSubsection
            img={basico}
            title={LANDING_SMALL_SECTION_3_SUBTITLE_1}
            desc={LANDING_SMALL_SECTION_3_SUBDESC_1}
          />

          {/* nivel intermedio */}
          <DescSectionSmallSubsection
            img={intermedio}
            title={LANDING_SMALL_SECTION_3_SUBTITLE_2}
            desc={
              <div>
                {LANDING_CON_ESTE_NIVEL}{' '}
                <a href={FIRMA_DIGITAL}>{LANDING_MEDIANTE_FIRMA_DIGITAL}</a> o{' '}
                <a href={PUNTOS_ATENCION_CIUDADANIA}>
                  {LANDING_PRESENCIALMENTE_EN_PUNTOS_AUTORIZADOS}
                </a>
                .
              </div>
            }
          />

          {/* nivel avanzado */}
          <DescSectionSmallSubsection
            title={LANDING_SMALL_SECTION_3_SUBTITLE_3}
            img={avanzado}
            desc={
              <div>
                <div>
                  <h4>{CEDULA_ELECTRONICA_NOMBRE}</h4>
                  {LANDING_NECESITAS_CEDULA}
                </div>
                <div>
                  <h4>{LANDING_ID_DIGITAL_ABITAB}</h4>
                  {LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ABITAB}{' '}
                  <a href={LOCALES_ABITAB} alt="local de Abitab">
                    {LANDING_LOCAL_ABITAB}
                  </a>{' '}
                  {LANDING_DESCARGAR_CELULAR}
                </div>
                <div>
                  <h4>{LANDING_ID_ANTEL}</h4>
                  {LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ANTEL}{' '}
                  <a href={LOCALES_ANTEL} alt="local de Antel">
                    {LANDING_LOCAL_ANTEL}
                  </a>{' '}
                  {LANDING_DESCARGAR_CELULAR}
                </div>
              </div>
            }
          />
        </div>
      </Card>
      <Card className="landing__section-small__card">
        <DescSectionSmall
          title={LANDING_SMALL_SECTION_4_TITLE}
          img={sec4}
          desc={LANDING_SMALL_SECTION_4_DESC}
        />
        <div style={{ marginTop: '15px' }}>
          {LANDING_SMALL_SECTION_4_DESC_2}
        </div>
        <div style={{ marginTop: '15px' }}>
          <b>{LANDING_SMALL_SECTION_4_TITLE_2}</b>
          <br />
          {LANDING_SMALL_SECTION_4_DESC_3}
        </div>
        <a
          href={MAS_INFO}
          alt="Más información sobre la Verificación en dos pasos"
        >
          {LANDING_MAS_INFORMACION}
        </a>
      </Card>
    </div>

    {/* Sección con botones */}
    <div className="landing__section-buttons container">
      <div className="landing__section-buttons__title">
        <p>{LANDING_EMPEZA_A_USAR_USUARIO_GUB}</p>
      </div>
      <div className="landing__section-buttons__buttons">
        <div className=" col-md-3">
          <ButtonSolid
            className="landing__section__button__container"
            text={LANDING_BOTON_REGISTRATE}
            handleClick={() => {
              window.location.href = REGISTRO_ROUTE;
            }}
            ariaLabel={LANDING_BOTON_PREGUNTAS_FRECUENTES}
          />
        </div>
        <div className=" col-md-3">
          <ButtonSolid
            className="landing__section__button__container"
            text={LANDING_BOTON_INGRESA}
            isSecondary
            handleClick={() => {
              window.location.href = LOGIN_ROUTE;
            }}
            ariaLabel={LANDING_BOTON_REGISTRATE}
          />
        </div>
      </div>
    </div>
  </main>
);

DescSectionSmall.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  img: PropTypes.string,
  style: PropTypes.object,
};

DescSectionSmallSubsection.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Landing;
