/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
  USERNAME_FIELD_PLACEHOLDER,
  USERNAME_FIELD_TEXT,
  COUNTRY_FIELD_NAME,
  COUNTRY_FIELD_TEXT,
  COUNTRY_FIELD_ARIA_LABEL,
  DOCNUM_FIELD_NAME,
  DOCTYPE_FIELD_NAME,
  DOCTYPE_FIELD_TEXT,
  DOCTYPE_FIELD_ARIA_LABEL,
  REQUERIDO,
  NEXT_CHALLENGE_BUTTON_TEXT,
  PREVIOUS_CHALLENGE_BUTTON_TEXT,
} from 'constants/commonConstants';

import ButtonSection from '../ButtonSection';
import { RenderField, CaptchaField, SelectField } from '../RenderField (Nuevo)';

// ------------------------------------------------------------
// Formulario reenviar link
// ------------------------------------------------------------
const Form = props => {
  const {
    documentosOptions,
    errores,
    errors,
    handleChange,
    handlePrevious,
    handleSubmit,
    nextAriaLabel,
    nextText,
    onChangePais,
    onChangeTipoDoc,
    paisesOptions,
    previousAriaLabel,
    previousText,
    recaptchaRef,
    resetFieldError,
    sending,
    setFieldValue,
    touched,
    values,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <SelectField
        ariaLabel={COUNTRY_FIELD_ARIA_LABEL}
        data={paisesOptions}
        disabled={sending}
        error={
          errores !== null && errores.field === COUNTRY_FIELD_NAME
            ? errores.error
            : errors.pais_emisor
        }
        label={COUNTRY_FIELD_TEXT}
        name={COUNTRY_FIELD_NAME}
        onChange={handleChange}
        onChangeCustom={e => onChangePais(e, setFieldValue)}
        required
        resetFieldError={resetFieldError}
        touched={touched.pais_emisor}
        value={values.pais_emisor}
      />
      <SelectField
        ariaLabel={DOCTYPE_FIELD_ARIA_LABEL}
        data={documentosOptions}
        disabled={sending}
        error={
          errores !== null && errores.field === DOCTYPE_FIELD_NAME
            ? errores.error
            : errors.tipo_documento
        }
        label={DOCTYPE_FIELD_TEXT}
        name={DOCTYPE_FIELD_NAME}
        onChange={handleChange}
        onChangeCustom={onChangeTipoDoc}
        required
        resetFieldError={resetFieldError}
        touched={touched.tipo_documento}
        value={values.tipo_documento}
      />
      <RenderField
        name={DOCNUM_FIELD_NAME}
        type="text"
        label={USERNAME_FIELD_TEXT}
        onChange={handleChange}
        required
        placeholder={USERNAME_FIELD_PLACEHOLDER}
        ariaLabel={USERNAME_FIELD_TEXT}
        disabled={sending}
        autocomplete="off"
        className="mail_activacion__nro_documento"
        error={
          errores !== null && errores.field === DOCNUM_FIELD_NAME
            ? errores.error
            : errors.numero_documento
        }
        resetFieldError={resetFieldError}
        value={values.numero_documento}
        touched={touched.numero_documento}
      />
      <CaptchaField
        name="captcha_response"
        setFieldValue={setFieldValue}
        recaptchaRef={recaptchaRef}
        error={
          errores !== null && errores.field === 'captcha_response'
            ? errores.error
            : errors.captcha_response
        }
        resetFieldError={resetFieldError}
        touched={touched.captcha_response}
      />
      <ButtonSection
        textPrimary={nextText}
        isLoadingPrimary={sending}
        isSubmit
        ariaLabelPrimary={nextAriaLabel}
        textSecondary={previousText}
        ariaLabelSecondary={previousAriaLabel}
        handleClickSecondary={handlePrevious}
      />
    </form>
  );
};

const RequestNewLinkForm = withFormik({
  mapPropsToValues: props => ({
    pais_emisor: props.valuePais,
    tipo_documento: props.valueTipoDoc,
    numero_documento: '',
    captcha_response: '',
  }),

  validationSchema: Yup.object().shape({
    numero_documento: Yup.string().required(REQUERIDO),
    captcha_response: Yup.string().required(REQUERIDO),
  }),

  handleSubmit: (values, bag) => {
    if (!bag.props.sending) {
      bag.props.handleNext(values, bag.setFieldValue);
    }
  },
})(Form);

const RequestNewLink = ({
  documentosOptions,
  errores,
  handleNext,
  handlePrevious,
  nextAriaLabel,
  nextText,
  onChangePais,
  onChangeTipoDoc,
  paisesOptions,
  previousAriaLabel,
  previousText,
  recaptchaRef,
  resetFieldError,
  sending,
  valuePais,
  valueTipoDoc,
}) => (
  <RequestNewLinkForm
    onChangePais={onChangePais}
    onChangeTipoDoc={onChangeTipoDoc}
    paisesOptions={paisesOptions}
    documentosOptions={documentosOptions}
    sending={sending}
    errores={errores}
    nextText={nextText}
    handleNext={handleNext}
    resetFieldError={resetFieldError}
    valuePais={valuePais}
    valueTipoDoc={valueTipoDoc}
    previousText={previousText}
    handlePrevious={handlePrevious}
    nextAriaLabel={nextAriaLabel}
    previousAriaLabel={previousAriaLabel}
    recaptchaRef={recaptchaRef}
  />
);

RequestNewLink.propTypes = {
  paisesOptions: PropTypes.array,
  documentosOptions: PropTypes.array,
  valuePais: PropTypes.string,
  valueTipoDoc: PropTypes.string,
  sending: PropTypes.bool,
  resetFieldError: PropTypes.func,
  recaptchaRef: PropTypes.object,
  onChangePais: PropTypes.func,
  onChangeTipoDoc: PropTypes.func,
  nextText: PropTypes.string,
  nextAriaLabel: PropTypes.string,
  handleNext: PropTypes.func,
  previousText: PropTypes.string,
  previousAriaLabel: PropTypes.string,
  handlePrevious: PropTypes.func,
  errores: PropTypes.object,
};

RequestNewLink.defaultProps = {
  nextText: NEXT_CHALLENGE_BUTTON_TEXT,
  previousText: PREVIOUS_CHALLENGE_BUTTON_TEXT,
  previousAriaLabel: PREVIOUS_CHALLENGE_BUTTON_TEXT,
};

export default RequestNewLink;
