import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

// styles
import styles from './styles.module.css';

const ButtonSolid = ({
  text,
  handleClick,
  isSmall,
  className,
  isLoading,
  isSubmit,
  style,
  ariaLabel,
  id,
  isDisabled,
  isSecondary,
}) => (
  <button
    className={`${className} ${
      isSmall ? styles.buttonSolid__small : styles.buttonSolid
    }
    ${isSecondary ? styles.secundario : ''}`}
    id={id}
    disabled={isDisabled}
    onClick={isLoading ? null : handleClick}
    style={style}
    aria-label={ariaLabel || text}
    type={isSubmit ? 'submit' : 'button'}
  >
    {isLoading ? (
      <Spinner name="ball-beat" fadeIn="none" color="white" />
    ) : (
      <span className={styles.buttonSolid__text} id={id ? `${id}-span` : null}>
        {text}
      </span>
    )}
  </button>
);

ButtonSolid.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmit: PropTypes.bool,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
};

ButtonSolid.defaultProps = {
  isSmall: false,
  className: '',
  isDisabled: false,
  isSecondary: false,
  isLoading: false,
  isSubmit: false,
  style: null,
  ariaLabel: '',
  id: null,
};

export default ButtonSolid;
