import {
  FETCH_MIS_DATOS,
  SET_EMAIL_POR_VALIDAR,
  FETCH_EMAIL_POR_VALIDAR,
  SET_ULTIMO_CAMBIO_PASSWORD,
  CHANGE_USER_PASSWORD,
  FETCH_MIS_DATOS_IDP,
} from 'constants/ActionTypes';

import {
  MIS_DATOS_ESTADO_ERROR,
  MIS_DATOS_ESTADO_EXITO,
  MIS_DATOS_ESTADO_PENDIENTE,
} from 'constants/commonConstants';
export const INITIAL_STATE = {
  currentUser: null,
  loading: true,
  estado: null,
  loadingEmailPorValidar: false,
};

// eslint-disable-next-line default-param-last
export default function misDatosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // FETCH_MIS_DATOS
    // -----------------------------------------------------------------------
    case FETCH_MIS_DATOS.SUCCESS:
      return {
        ...state,
        currentUser: action.payload.data,
        loading: false,
        estado: MIS_DATOS_ESTADO_EXITO,
      };
    case FETCH_MIS_DATOS.ERROR:
      return {
        ...state,
        currentUser: null,
        loading: false,
        estado: MIS_DATOS_ESTADO_ERROR,
      };

    case FETCH_MIS_DATOS.PENDING:
      return {
        ...state,
        currentUser: null,
        loading: true,
        estado: MIS_DATOS_ESTADO_PENDIENTE,
      };
    // -----------------------------------------------------------------------
    // FETCH_MIS_DATOS_IDP
    // -----------------------------------------------------------------------
    case FETCH_MIS_DATOS_IDP.SUCCESS:
      return {
        ...state,
        currentUser: action.payload.data,
        loading: false,
        estado: MIS_DATOS_ESTADO_EXITO,
      };
    case FETCH_MIS_DATOS_IDP.ERROR:
      return {
        ...state,
        currentUser: null,
        loading: false,
        estado: MIS_DATOS_ESTADO_ERROR,
      };

    case FETCH_MIS_DATOS_IDP.PENDING:
      return {
        ...state,
        currentUser: null,
        loading: true,
        estado: MIS_DATOS_ESTADO_PENDIENTE,
      };
    // -----------------------------------------------------------------------
    // E-mail por validar
    // -----------------------------------------------------------------------
    case SET_EMAIL_POR_VALIDAR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          email_por_validar: { email: action.payload.data.email },
          email_validado: false,
        },
      };
    case FETCH_EMAIL_POR_VALIDAR.SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          email_por_validar: { email: action.payload.data.email },
        },
        loadingEmailPorValidar: false,
      };
    case FETCH_EMAIL_POR_VALIDAR.ERROR:
      return {
        ...state,
        loadingEmailPorValidar: false,
      };
    case FETCH_EMAIL_POR_VALIDAR.PENDING:
      return {
        ...state,
        loadingEmailPorValidar: true,
      };
    case CHANGE_USER_PASSWORD.SUCCESS:
    case SET_ULTIMO_CAMBIO_PASSWORD:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ultimo_cambio_contrasena:
            action.payload.data.ultimo_cambio_contrasena,
        },
      };
    // DEFAULT
    default:
      return state;
  }
}
