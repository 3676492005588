/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const TitleCard = ({ title, className }) => (
  <h1 className={className}>{title}</h1>
);

TitleCard.propTypes = {
  title: PropTypes.string,
};

TitleCard.defaultProps = {
  className: '',
};

export default TitleCard;
