import { push } from 'connected-react-router';
import { axiosAPI } from 'utils/axiosBackend';
import { API_REQUEST, BROKER_REQUEST } from 'constants/ApiConstants';
import { buildErrorUrl } from 'utils/utils';
import Auth from 'utils/Auth';
import { LOGOUT_PROCESS } from 'constants/RouterConstants';

// ----------------------------------------------------------------------------
// Auxiliar para determinar reidrecciones a páginas de error.
// ----------------------------------------------------------------------------
const isFetch = action => {
  const fetchActions = [
    'FETCH_FAQS_ERROR',
    'FETCH_MIS_DATOS_ERROR',
    'FETCH_EMAIL_POR_VALIDAR_ERROR',
    'FETCH_PAISES_ERROR',
    'FETCH_PAGINAS_ESTATICAS_ERROR',
  ];
  return fetchActions.some(elem => action === elem);
};

const processExtraAction = (dispatch, extraAction, data) => {
  // FIXME: La redirección se hace de manera muy rebuscada.
  // Utilizando react-router-redux se puede simplificar mucho.
  // Por ahora se deja esta bifurcación, hay que cambiar los casos
  // que ya lo necesitan.
  if (
    typeof extraAction === 'object' &&
    Object.prototype.hasOwnProperty.call(extraAction, 'type') &&
    extraAction.type.startsWith('@@router')
  ) {
    dispatch(extraAction);
  } else {
    dispatch({ type: extraAction, payload: data });
  }
};

// ----------------------------------------------------------------------------
// ApiMiddleware
// ----------------------------------------------------------------------------
const apiMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    // Si el action no es del tipo API_REQUEST
    // que continue el pipeline de middlewares.
    if (action.type !== API_REQUEST && action.type !== BROKER_REQUEST) {
      return next(action);
    }

    // Todas las acciones asincrónicas disparan al comenzar
    // una action PENDING para controlar la latencia de la respuesta.
    dispatch({ type: action.payload.next.PENDING });
    // Se extraen los datos y headers a enviar en el pedido.
    const { method, url, data } = action.payload;
    const headers = Auth.getInstance().isAuthenticated()
      ? { Authorization: `Bearer ${Auth.getInstance().getAuthToken()}` }
      : {};

    // Se retorna la promesa del pedido axios.
    // Se cambia la baseURL en base al pedido
    const baseURL =
      action.type === BROKER_REQUEST
        ? window.REACT_APP_BROKER_HOST
        : window.REACT_APP_BACKEND_HOST;
    const withCredentials = action.type === BROKER_REQUEST;
    return axiosAPI({
      baseURL,
      method,
      url,
      data,
      headers,
      withCredentials,
    })
      .then(resp => {
        // Si el pedido fue exitoso se dispara la action SUCCESS.
        dispatch({ type: action.payload.next.SUCCESS, payload: resp });

        // Si la action tiene EXTRA actions, se disparan todas.
        if (action.payload.next.EXTRA_SUCCESS) {
          action.payload.next.EXTRA_SUCCESS.forEach(extraAction => {
            processExtraAction(dispatch, extraAction, data);
          });
        }
      })
      .catch(err => {
        // En caso de error, se dispara la action ERROR.
        // Si corresponde se redirige a la pantalla de error.
        if (err.request) {
          const code = err.request.status;
          if (
            isFetch(action.payload.next.ERROR) &&
            code >= 400 &&
            code !== 404
          ) {
            dispatch(push(buildErrorUrl('SERVER_ERROR')));
          } else if (code === 404) {
            if (
              err.request.responseURL.includes(
                '/federated-identity/obtener-mis-datos-idp/',
              )
            ) {
              dispatch(push(LOGOUT_PROCESS));
              return;
            }
            dispatch(push(buildErrorUrl('NOT_FOUND_ERROR')));
          }
          dispatch({
            type: action.payload.next.ERROR,
            payload: JSON.parse(err.request.response),
          });
        } else {
          dispatch({ type: action.payload.next.ERROR, payload: err });
        }
        if (action.payload.next.EXTRA_ERROR) {
          action.payload.next.EXTRA_ERROR.forEach(extraAction => {
            processExtraAction(dispatch, extraAction, data);
          });
        }
      });
  };

export default apiMiddleware;
