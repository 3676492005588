import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { history } from 'store/ConfigureStore';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { isEmpty } from 'lodash';

// constantes
import {
  FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL,
  BOTON_ACEPTAR,
  BOTON_FINALIZAR,
  EXITO_ELEVAR_TEXTO,
  EXITO_ELEVAR_TITULO,
  TITULO_REQUISITOS,
  descripcionRequisitos,
  PARA_ACEPTAR_TEXTO,
  PRIMERA_CONDICION_TEXTO,
  SEGUNDA_CONDICION_TEXTO,
  TERCERA_CONDICION_TEXTO,
  CUARTA_CONDICION_TEXTO,
  MENSAJE_FINAL_TEXTO,
  REQUISITOS_TEXTO,
  ADICIONAL_TEXTO,
  ERROR_TEXTO,
  PRIMERO_TEXTO,
  SEGUNDO_TEXTO,
  TERCERO_TEXTO,
  CUARTO_TEXTO,
  YA_ELEVADO_TEXTO,
} from 'constants/commonConstants';

// routes
import { SEGURIDAD_ROUTE } from 'constants/RouterConstants';

// componentes
import BotonVolver from 'components/BotonVolver';
import TitleCard from 'components/TitleCard';
import Card from 'components/Card';
import CardSmall from 'components/CardSmall';
import ButtonSolid from 'components/ButtonSolid';
import CardSpinner from 'components/CardSpinner';
import TextoMultiformato from 'components/TextoMultiformato';
import { CheckboxField } from 'components/RenderField (Nuevo)';

// actions
import { fetchMisDatos } from 'actions/misDatos';

// api services
import { elevarsePorTramiteVideollamada } from 'services/apiServices';

import styles from './AceptarElevacionVideollamada.module.css';

dayjs.extend(customParseFormat); // Se agrega plugin de dayjs para formatear horas.

const AceptarElevacionVideollamada = ({ usuarioLogueado }) => {
  const [cargando, setCargando] = useState(false);
  const [aceptaCondiciones, setAceptaCondiciones] = useState(false);
  const [exito, setExito] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleAceptar = async () => {
    setCargando(true);
    try {
      await elevarsePorTramiteVideollamada();
      setCargando(false);
      setExito(true);
      setError(false);
    } catch (response) {
      setError(true);
      setCargando(false);
      if (response.status === 404) {
        dispatch(fetchMisDatos());
        history.push(`${SEGURIDAD_ROUTE}?msg=${YA_ELEVADO_TEXTO}`);
      }
    }
  };

  const handleEmpezar = () => {
    dispatch(fetchMisDatos());
    history.push(SEGURIDAD_ROUTE);
  };

  const videollamadaInfo = usuarioLogueado?.info_tramite_videollamada_pendiente;

  if (isEmpty(usuarioLogueado)) {
    return (
      <Card>
        <CardSpinner
          spin
          text="Cargando..."
          className="spinner__transparent__videollamada"
        />
      </Card>
    );
  }

  if (exito) {
    return (
      <CardSmall title={EXITO_ELEVAR_TITULO} desc={EXITO_ELEVAR_TEXTO}>
        <div className={styles.botonContenedor_empezar}>
          <ButtonSolid
            text={BOTON_FINALIZAR}
            ariaLabel={BOTON_FINALIZAR}
            handleClick={handleEmpezar}
          />
        </div>
      </CardSmall>
    );
  }

  const condicion1 = (
    <TextoMultiformato
      key="primero"
      listaNormal={['', PRIMERA_CONDICION_TEXTO]}
      listaNegrita={[PRIMERO_TEXTO]}
    />
  );
  const condicion2 = (
    <TextoMultiformato
      key="segundo"
      listaNormal={['', SEGUNDA_CONDICION_TEXTO]}
      listaNegrita={[SEGUNDO_TEXTO]}
    />
  );
  const condicion3 = (
    <TextoMultiformato
      key="tercero"
      listaNormal={['', TERCERA_CONDICION_TEXTO]}
      listaNegrita={[TERCERO_TEXTO]}
    />
  );
  const condicion4 = (
    <TextoMultiformato
      key="cuarto"
      listaNormal={['', CUARTA_CONDICION_TEXTO]}
      listaNegrita={[CUARTO_TEXTO]}
    />
  );

  return (
    <>
      <BotonVolver
        funcionIrAtras={() => history.push(SEGURIDAD_ROUTE)}
        texto={FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL}
      />
      <Card>
        <div className={styles.contenedorPrincipal}>
          <TitleCard title={TITULO_REQUISITOS} className={styles.titulo} />
          <p>
            {descripcionRequisitos(
              videollamadaInfo?.fecha,
              dayjs(videollamadaInfo?.hora_inicio, 'HH:mm:ss').format('HH:mm'),
            )}
          </p>
          <p>{PARA_ACEPTAR_TEXTO}</p>
          <ol className={styles.listaCondiciones}>
            <li key="condicion1" className={styles.condicion}>
              {condicion1}
            </li>
            <li key="condicion2" className={styles.condicion}>
              {condicion2}
            </li>
            <li key="condicion3" className={styles.condicion}>
              {condicion3}
            </li>
            <li key="condicion4" className={styles.condicion}>
              {condicion4}
            </li>
          </ol>
          <p>{MENSAJE_FINAL_TEXTO}</p>
          <p className={styles.letraChicha}>{ADICIONAL_TEXTO}</p>

          <CheckboxField
            name="checkboxAcepto"
            disabled={false}
            label={REQUISITOS_TEXTO}
            onChange={() => setAceptaCondiciones(!aceptaCondiciones)}
            className={styles.checkbox}
            checked={aceptaCondiciones}
          />

          <div className={styles.botonContenedor}>
            <ButtonSolid
              text={BOTON_ACEPTAR}
              ariaLabel={BOTON_ACEPTAR}
              handleClick={handleAceptar}
              isLoading={cargando}
              isDisabled={!aceptaCondiciones}
            />
          </div>
          {error && (
            <div className={styles.errorContenedor}>
              <p className={styles.errorTexto}>{ERROR_TEXTO}</p>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

AceptarElevacionVideollamada.propTypes = {
  usuarioLogueado: PropTypes.object,
};

const mapStateToProps = state => ({
  usuarioLogueado: state.mis_datos.currentUser,
});

export default connect(mapStateToProps, { fetchMisDatos })(
  AceptarElevacionVideollamada,
);
