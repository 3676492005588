import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './store/ConfigureStore';
import AppContainer from './containers/AppContainer';

import './i18n';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
);
