import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

// Constantes
import {
  LOGOUT_CERRANDO_SESION,
  REDIRIGIENDO,
} from 'constants/commonConstants';
import { LOGIN_ROUTE } from 'constants/RouterConstants';

// Actions
import { hideFooter, hideHeader } from 'actions/ui';
import {
  fetchLogoutRequests,
  fetchLogoutResponse,
  fetchLogoutRequestsForceAuth,
} from 'actions/brokerSaml';

// Componentes
import CardSpinner from 'components/CardSpinner';
import IframeTemplate from 'components/IframeTemplate';
import Auth from 'utils/Auth';

const nuevaUrlIDP = (urlActual, redirect) => {
  const nuevaUrl = new URL(urlActual);
  nuevaUrl.searchParams.set('redirect_uri', redirect);
  return nuevaUrl.toString();
};

const SAMLLogoutContainer = ({
  ocultarFooter,
  ocultarHeader,
  logoutRequests,
  logoutResponse,
  logoutRedirect,
  waitLonger,
}) => {
  /*
    Este componente envía las requests/responses redirect de SAML 
    vía Iframes, seteandoles la url correspondiente.
    Tiene distintos temporizadores para esperar que se realicen estos requests/responses.
  */

  // ----------------------------------------------------------------------------
  // Inicialización
  // ----------------------------------------------------------------------------
  const [processState, setProcessState] = useState(null);
  const [forceLogin, setForceLogin] = useState(null);
  const [nivelAutenticacion, setNivelAutenticacion] = useState(null);
  const [frameSrc, setFrameSrc] = useState('');
  const urlIDPRedirect = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const setLogoutUrl = () => {
    if (window.REACT_APP_FEATURED_FLAG_SLO_SAML_REFACTOR) {
      const mismaUrl =
        logoutResponse &&
        logoutRedirect &&
        logoutResponse.split('?')[0] === logoutRedirect;

      if (mismaUrl) {
        // cerrar sesión y redirigir
        Auth.getInstance().cleanStorage();

        if (urlIDPRedirect.current !== null) {
          window.location.href = nuevaUrlIDP(
            urlIDPRedirect.current,
            logoutResponse,
          );
        } else {
          window.location.href = logoutResponse;
        }

        return;
      }
    }
    // Enviar saml response
    if (logoutResponse) {
      setFrameSrc(logoutResponse);
    }
    // En caso de haber guardado el logoutRequest de un idp con redirect=,
    // se debe redirigir al logout del mismo directamente con la redirect_uri original del SP
    if (urlIDPRedirect.current !== null && logoutRedirect) {
      Auth.getInstance().cleanStorage();
      window.location.href = nuevaUrlIDP(
        urlIDPRedirect.current,
        logoutRedirect,
      );
    }
    if (logoutRedirect) {
      // cerrar sesión y redirigir
      setTimeout(() => {
        Auth.getInstance().cleanStorage();
        window.location.href = logoutRedirect;
      }, 1000);
    }
  };

  const getFinalLogout = async () => {
    // Una vez enviados todos los LogoutRequests, debemos responderle al
    // SP un LogoutResponse con el status del deslogueo.
    dispatch(fetchLogoutResponse(processState));
  };

  useEffect(() => {
    setLogoutUrl();
  }, [logoutResponse, logoutRedirect]);

  useEffect(() => {
    ocultarHeader();
    ocultarFooter();

    const parsed = queryString.parse(window.location.search);
    const parsedProcessState = parsed?.process_state;
    const parsedForceLogin = parsed.forceLogin ? parsed.forceLogin : false;
    const parsedAe = parsed?.ae;

    setProcessState(parsedProcessState);
    setForceLogin(parsedForceLogin);
    setNivelAutenticacion(parsedAe);
  }, []);

  useEffect(() => {
    if (forceLogin) {
      // Si es force login, debo cerrar las sesiones abiertas y luego redirigir
      // al login
      if (logoutRequests.length > 0) {
        setTimeout(
          () => {
            history.replace({
              pathname: LOGIN_ROUTE,
              search: nivelAutenticacion
                ? `?forceLogin=${forceLogin}&process_state=${processState}&ae=${nivelAutenticacion}`
                : `?forceLogin=${forceLogin}&process_state=${processState}`,
            });
          },
          waitLonger ? 10000 : 1000,
        );
      }
    } else {
      // Cierro las sesiones abiertas y redirijo a ruta final
      if (logoutRequests.length > 0) {
        setTimeout(
          () => {
            getFinalLogout();
          },
          waitLonger ? 10000 : 4000,
        );
      }
      if (logoutResponse) {
        setLogoutUrl();
      }
    }
  }, [logoutRequests]);

  useEffect(() => {
    if (processState !== null && forceLogin !== null) {
      // Obtener lista de logout requests
      if (forceLogin) {
        dispatch(fetchLogoutRequestsForceAuth(processState));
      } else {
        dispatch(fetchLogoutRequests(processState));
      }
    }
  }, [processState, forceLogin]);

  return (
    <div>
      <IframeTemplate id="iframeLogout" src={frameSrc} />
      {logoutRequests.map((url, index) => {
        if (url.includes('redirect=')) {
          [, urlIDPRedirect.current] = url.split('redirect=');
          return null;
        }
        return <IframeTemplate key={url} id={index} src={url} />;
      })}
      <CardSpinner
        spin
        text={
          processState && !forceLogin ? LOGOUT_CERRANDO_SESION : REDIRIGIENDO
        }
      />
    </div>
  );
};

SAMLLogoutContainer.propTypes = {
  logoutRequests: PropTypes.array,
  logoutResponse: PropTypes.string,
  logoutRedirect: PropTypes.string,
  waitLonger: PropTypes.bool,
  ocultarHeader: PropTypes.func,
  ocultarFooter: PropTypes.func,
};

const mapStateToProps = state => ({
  logoutRequests: state.brokerSaml.logoutRequests,
  logoutResponse: state.brokerSaml.logoutResponse,
  logoutRedirect: state.brokerSaml.logoutRedirect,
  waitLonger: state.brokerSaml.waitLonger,
});

export default connect(mapStateToProps, {
  fetchLogoutRequests,
  fetchLogoutResponse,
  fetchLogoutRequestsForceAuth,
  ocultarFooter: hideFooter,
  ocultarHeader: hideHeader,
})(SAMLLogoutContainer);
