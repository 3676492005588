/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';

import { RenderField } from 'components/RenderField';
import ButtonSection from 'components/ButtonSection';
import CardSmall from 'components/CardSmall';

import {
  CHANGE_USER_EMAIL_ROUTE_SUCCESS,
  VERIFY_PASSWORD_ROUTE,
  SEGURIDAD_ROUTE,
} from 'constants/RouterConstants';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { updateUserEmail, clearEmailUpdateErrors } from 'actions/seguridad';
import { setEmailPorValidar } from 'actions/misDatos';
import { required, email } from 'utils/validators';
import { resetFieldError } from 'utils/utils';
import { CHANGE_EMAIL_TITLE } from 'constants/pageTitles';
import './CambioEmailContainer.css';

class CambioEmailContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.resetFieldError = resetFieldError.bind(this);
    this.handleResetFieldError = this.handleResetFieldError.bind(this);
  }

  componentDidMount() {
    // Si necesita authForPasswordChange se hace un redirect a un metodo de auth.
    // Por defecto la contraseña.
    if (this.props.authForEmailChange && !this.props.verifiedPassword) {
      this.props.history.replace({
        pathname: VERIFY_PASSWORD_ROUTE,
        state: {
          prevPath: this.props.location.pathname,
          basePath: SEGURIDAD_ROUTE,
        },
      });
    } else {
      this.props.showExternalHeader();
      this.props.updateAriaLiveMessage(CHANGE_EMAIL_TITLE);
    }
  }

  componentWillUnmount() {
    this.props.clearEmailUpdateErrors();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.startedEmailChange === null &&
      nextProps.successfulEmailChange === null
    ) {
      this.props.setEmailPorValidar(this.state.email);
      this.props.history.push(CHANGE_USER_EMAIL_ROUTE_SUCCESS);
    }
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  async handleSubmit(values) {
    if (this.props.valid && !this.props.loading) {
      await this.props.updateUserEmail(values.email);
      if (!this.props.successfulEmailChange) {
        this.props.history.push(CHANGE_USER_EMAIL_ROUTE_SUCCESS);
      }
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.props.history.push(SEGURIDAD_ROUTE);
  }

  handleResetFieldError() {
    this.props.clearEmailUpdateErrors();
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    const { handleSubmit } = this.props;
    return (
      <>
        <Helmet title={CHANGE_EMAIL_TITLE} />
        <CardSmall
          className="modificar_correo"
          title="Modificá tu correo"
          desc="Ingresá tu nuevo correo electrónico."
        >
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              name="email"
              type="email"
              component={RenderField}
              className="modificar_correo__input"
              label="Nuevo correo electrónico"
              style={{ marginBottom: '16px' }}
              onChange={e =>
                this.setState({ email: e.target.value.toLowerCase() })
              }
              validate={[required, email]}
              disabled={this.props.loading}
              error={this.props.formErrors}
              resetFieldError={this.resetFieldError}
            />
            <ButtonSection
              textPrimary="Continuar"
              isSubmit
              isLoadingPrimary={this.props.loading}
              ariaLabelPrimary="Confirmar nuevo correo"
              textSecondary="Volver"
              ariaLabelSecondary="Cancelar"
              handleClickSecondary={this.handleBack}
              isVertical
            />
            <div className="help-text__error text-center">
              {this.props.emailUpdateErrors &&
              this.props.emailUpdateErrors.field === 'button'
                ? this.props.emailUpdateErrors.error
                : ''}
            </div>
          </form>
        </CardSmall>
      </>
    );
  }
}
const mapStateToProps = state => ({
  loading: state.seguridad.loading,
  startedEmailChange: state.seguridad.startedEmailChange,
  successfulEmailChange: state.seguridad.successfulEmailChange,
  authForEmailChange: state.seguridad.authForEmailChange,
  verifiedPassword: state.seguridad.verifiedPassword,
  emailUpdateErrors: state.seguridad.emailUpdateErrors,
  formErrors: state.seguridad.emailUpdateErrors,
});

const CambioEmail = connect(mapStateToProps, {
  showExternalHeader,
  updateUserEmail,
  clearEmailUpdateErrors,
  setEmailPorValidar,
  updateAriaLiveMessage,
})(CambioEmailContainer);

export default reduxForm({
  form: 'formCambioEmail',
})(CambioEmail);
