/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import ButtonSection from 'components/ButtonSection';
import { MFA_PAGE_ROUTE } from 'constants/RouterConstants';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { fetchMFA } from 'actions/mfa';
import { totpSelector } from 'store/selectors';
import { MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE } from 'constants/pageTitles';
import {
  MFA_TOTP_VERIFIED_TITLE,
  MFA_TOTP_VERIFIED_DESC,
  BOTON_FINALIZAR,
} from 'constants/commonConstants';
import Auth from 'utils/Auth';

const TOTPVerificarCodigoExitoContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const totp = useSelector(totpSelector);

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE));
    dispatch(fetchMFA(Auth.getInstance().getUserId()));

    if (!totp) {
      history.push(MFA_PAGE_ROUTE);
    }
    return () => dispatch(showInternalHeader());
  }, []);

  const handleCallback = () => {
    history.push(MFA_PAGE_ROUTE);
  };

  return (
    <div>
      {totp ? (
        <>
          <Helmet title={MFA_VERIFY_CODE_TOTP_SUCCESS_TITLE} />
          <CardSmall
            title={MFA_TOTP_VERIFIED_TITLE}
            desc={MFA_TOTP_VERIFIED_DESC}
          >
            <div style={{ marginTop: '24px' }}>
              <ButtonSection
                textPrimary={BOTON_FINALIZAR}
                handleClickPrimary={handleCallback}
                isVertical
              />
            </div>
          </CardSmall>
        </>
      ) : (
        <CardSpinner spin />
      )}
    </div>
  );
};

export default TOTPVerificarCodigoExitoContainer;
