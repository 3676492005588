import {
  API_REQUEST,
  CIUDADANO_PENDING_EMAIL_URL,
  CIUDADANO_DETAIL_URL,
  MIS_DATOS_IDP_URL,
} from 'constants/ApiConstants';
import {
  FETCH_MIS_DATOS,
  SET_EMAIL_POR_VALIDAR,
  FETCH_EMAIL_POR_VALIDAR,
  FETCH_MIS_DATOS_IDP,
} from 'constants/ActionTypes';

import Auth from 'utils/Auth';

export const fetchMisDatos = (id = Auth.getInstance().getUserId()) => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_DETAIL_URL(id),
    method: 'get',
    next: FETCH_MIS_DATOS,
  },
});

export const fetchMisDatosIdp = (id = Auth.getInstance().getUserId()) => ({
  type: API_REQUEST,
  payload: {
    url: MIS_DATOS_IDP_URL(id),
    method: 'get',
    next: FETCH_MIS_DATOS_IDP,
  },
});

export const fetchEmailPorValidar = (id = Auth.getInstance().getUserId()) => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_PENDING_EMAIL_URL(id),
    method: 'get',
    next: FETCH_EMAIL_POR_VALIDAR,
  },
});

export const setEmailPorValidar = email => ({
  type: SET_EMAIL_POR_VALIDAR,
  payload: {
    data: {
      email,
    },
    next: SET_EMAIL_POR_VALIDAR,
  },
});
