import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';

import CardSpinner from 'components/CardSpinner';
import fetchFaqs from 'actions/faqs';
import { HELP_TITLE } from 'constants/pageTitles';
import Ayuda from 'screens/ayuda/ayuda';

export const AyudaContainer = ({ faqs, loading }) => {
  const [columnas, setColumnas] = useState({ izquierda: [], derecha: [] });
  const faqsList = useSelector(state => state.faqs.faqs) || faqs;

  const dispatch = useDispatch();

  const handleExternalHeader = () => dispatch(showExternalHeader());
  const handleAriaLiveMessage = msg => dispatch(updateAriaLiveMessage(msg));
  const handleFetchFaqs = () => dispatch(fetchFaqs());

  const sortFaqs = faqsObj => {
    const izquierda = [];
    const derecha = [];

    faqsObj.forEach((obj, idx) => {
      const faq = { nombre: obj.nombre, faqs: obj.faqs };
      if (idx % 2 === 0) {
        izquierda.push(faq);
      } else {
        derecha.push(faq);
      }
    });

    setColumnas({ izquierda, derecha });
  };

  useEffect(() => {
    handleExternalHeader();
    handleAriaLiveMessage(HELP_TITLE);
    if (Object.keys(faqsList).length === 0) {
      handleFetchFaqs();
    } else {
      sortFaqs(faqsList);
    }
  }, [faqsList]);

  if (loading) {
    return <CardSpinner spin />;
  }
  return (
    <>
      <Helmet title={HELP_TITLE} />
      <Ayuda leftList={columnas.izquierda} rightList={columnas.derecha} />
    </>
  );
};

AyudaContainer.propTypes = {
  loading: PropTypes.bool,
  faqs: PropTypes.array,
};
