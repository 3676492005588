/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';

import CardSpinner from 'components/CardSpinner';
import fetchFaqs from 'actions/faqs';
import { HELP_TITLE } from 'constants/pageTitles';
import Ayuda from './ayuda';

class AyudaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftList: [],
      rightList: [],
    };
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(HELP_TITLE);
    if (Object.keys(this.props.faqs).length === 0) {
      this.props.fetchFaqs();
    } else {
      this.sortFaqs(this.props.faqs);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      Object.keys(nextProps.faqs).length !== Object.keys(this.props.faqs).length
    ) {
      this.sortFaqs(nextProps.faqs);
    }
  }

  sortFaqs(faqs) {
    const categoriasLeft = [];
    const categoriasRight = [];
    let switchCat = true;
    faqs.map(obj => {
      if (switchCat) {
        categoriasLeft.push({ nombre: obj.nombre, faqs: obj.faqs });
      } else {
        categoriasRight.push({ nombre: obj.nombre, faqs: obj.faqs });
      }
      switchCat = !switchCat;
      return true;
    });
    this.setState({ leftList: categoriasLeft, rightList: categoriasRight });
  }

  render() {
    if (this.props.loading) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={HELP_TITLE} />
        <Ayuda
          leftList={this.state.leftList}
          rightList={this.state.rightList}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  faqs: state.faqs.faqs,
  loading: state.faqs.loading,
});

export default connect(mapStateToProps, {
  fetchFaqs,
  showExternalHeader,
  updateAriaLiveMessage,
})(AyudaContainer);
