/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { hideFooter, hideHeader } from 'actions/ui';

import OIDCService from 'utils/OIDCService';
import CardSpinner from 'components/CardSpinner';
import BotonVolver from 'components/BotonVolver';
import InformacionNivel from 'components/InformacionNivel';

import { LOGIN_TITLE } from 'constants/pageTitles';
import * as CONSTANTS from 'constants/commonConstants';
import {
  CI_LOGIN_REDIRECT,
  BROKER_BASE_URL,
  IDP_LOGIN_URL,
  CANCEL_LOGIN_URL,
} from 'constants/ApiConstants';
import {
  FAQ_AUMENTAR_GARANTIA_IDENTIDAD,
  FAQ_VALIDAR_IDENTIDAD,
  RESET_PASSWORD_ROUTE,
  SAML_RESPONSE_ROUTE,
  FAQ_ACTIVAR_TOTP,
  LOGOUT_PROCESS,
} from 'constants/RouterConstants';
import { fetchPaises } from 'actions/registro';
import { resendRecoveryCodeEmail } from 'actions/mfa';
import { setAuthnResponseInfo } from 'actions/brokerSaml';
import { uruguayanCIValidator, maxLengthValidator } from 'utils/validators';
import { isMobile, getPaisesOptions, getDocumentosOptions } from 'utils/utils';
import fetchIDPS from 'actions/federatedIdentity';
import seguridadPrivacidad from 'assets/lector.png';

import CIElectronica from 'assets/logos/IdentityProviders/icon_CedulaDigital.svg';
import defaultLogo from 'assets/logos/IdentityProviders/default_logo_idp.png';
import basicoIntermedio from 'assets/icons/basico_intermedio.svg';
import avanzado from 'assets/icons/avanzado.svg';
import Snackbar from 'components/Snackbar';
import { useLocation } from 'react-router-dom';
import AumentarNID from './aumentarNID';
import { MFA, MFAField } from './mfa';
import MFAForeignField from './foreignForm';
import CIMFAField from './ciField';
import Ae3Methods from './ae3Methods';
import { toLogoutAndBack } from './utils';

const DEFAULT_SNACKBAR_INFO = {
  mostrar: false,
  texto: '',
};

const LoginContainer = props => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const minimalista = searchParams.get('minimalista') === 'True';
  const mensaje = searchParams.get('mensaje');
  const forceLogin = searchParams.get('forceLogin') === 'True';

  const [mfa, setMfa] = useState({
    method: CONSTANTS.WITH_USER_METHOD, // usuario y contraseña o ci electrónica
    nextChallenge: CONSTANTS.IDP_CHALLENGE,
    prevChallenge: CONSTANTS.IDP_CHALLENGE,
    direction: 'left',
  });

  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [paisEmisor, setPaisEmisor] = useState(CONSTANTS.UY_CODE);
  const [datosCiudadano, setDatosCiudadano] = useState({
    username: '',
    password: '',
    totp: '',
    recoverycode: '',
    institutional_email: '',
  });
  const [general, setGeneral] = useState({
    loading: false,
    cancelLoading: false,
    processState: null,
  });
  const [errores, setErrores] = useState({
    error: '',
    code: '',
    field: '',
  });
  const [opcionesLoaders, setOpcionesLoaders] = useState({
    loadingOp1: false,
    loadingOp2: false,
    loadingOp3: false,
  });
  const [identityProviders, setIdentityProviders] = useState([]);
  const [paisesOptions, setPaisesOptions] = useState([]);
  const [documentosOptions, setDocumentosOptions] = useState([]);
  const [snackBarInfo, setSnackBarInfo] = useState(DEFAULT_SNACKBAR_INFO);
  const [mostrarBotonVolver, setMostrarBotonVolver] = useState(false);

  useEffect(() => {
    if (forceLogin) {
      // Si tenemos `forceLogin` hay que redirigir al usuario a logout y volver a la pagina
      // sin la opcion de forceLogin. Esto se hace porque al estar logueado con un IDP, la unica
      // forma de desloguearse es desde la misma pagina del IDP (no alcanza con un iframe)
      // Y la opcion `prompt=login` de OIDC indica que el usuario debe loguearse de nuevo.
      // `forceLogin=True` es una opcion agregada por el backend y solo aparece cuando el usuario
      // estaba logueado y viene con `prompt=login` de nuevo.
      props.history.push(toLogoutAndBack(window.location.href, LOGOUT_PROCESS));
    }
  }, []);

  useEffect(() => {
    if (minimalista) {
      props.hideFooter();
      props.hideHeader();
    }
    if (mensaje?.length > 0) {
      setSnackBarInfo({ mostrar: true, texto: mensaje });
    }
  }, []);

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  const handleNext = (email = false) => {
    setMostrarBotonVolver(true);
    // Se obtiene de los parametros la url a la cual redirigir al usuario luego del login en caso de que se quiera
    const parsed = queryString.parse(props.location.search);
    const redirectUrl = parsed.redirectUrl ? parsed.redirectUrl : null;

    let challenge = null;
    let dataChallenge = null;
    let extra = null;
    const { token } = mfa;

    // --------
    // Idps
    // --------
    if (mfa.nextChallenge === CONSTANTS.IDP_CHALLENGE) {
      challenge = CONSTANTS.IDP_CHALLENGE;
      handleNextChallenge({
        challenge: 'username',
      });
      return;
    }
    const agnt = parsed.agnt ? parsed.agnt : null;

    // --------
    // Username
    // --------
    if (
      mfa.nextChallenge === CONSTANTS.USERNAME_CHALLENGE ||
      mfa.nextChallenge === CONSTANTS.FOREIGN_USERNAME_CHALLENGE
    ) {
      if (
        mfa.nextChallenge === CONSTANTS.USERNAME_CHALLENGE &&
        !handleUsernameValidator(CONSTANTS.USERNAME_FIELD_NAME)
      ) {
        return;
      }

      const parsed = queryString.parse(props.location.search);
      const agnt = parsed.agnt ? parsed.agnt : null;
      const pais_emisor = tipoDocumento ? paisEmisor : null;

      challenge = CONSTANTS.USERNAME_CHALLENGE;
      dataChallenge = {
        // username is always lowercase in the backend.
        username: datosCiudadano.username.toLowerCase(),
        pais_emisor: pais_emisor || null,
        tipo_documento: tipoDocumento || null,
      };
      extra = {
        redirect_url: redirectUrl,
        agnt,
        process_state: general.processState,
      };
    }

    // --------
    // Password
    // --------
    if (mfa.nextChallenge === CONSTANTS.PASSWORD_CHALLENGE) {
      challenge = CONSTANTS.PASSWORD_CHALLENGE;
      dataChallenge = {
        password: datosCiudadano.password,
      };
      if (agnt) {
        dataChallenge = { ...dataChallenge, emailAgent: true };
      }
    }

    // --------
    // Institutional email
    // --------

    if (mfa.nextChallenge === CONSTANTS.ADD_INST_EMAIL_CHALLENGE) {
      challenge = CONSTANTS.ADD_INST_EMAIL_CHALLENGE;
      dataChallenge = {
        institutional_email: datosCiudadano.institutional_email,
      };
    }

    // --------
    // TOTP
    // --------
    if (mfa.nextChallenge === CONSTANTS.TOTP_CHALLENGE) {
      challenge = CONSTANTS.TOTP_CHALLENGE;
      if (email) {
        dataChallenge = {
          emailTOTP: true,
        };
      } else {
        dataChallenge = {
          totp: datosCiudadano.totp,
        };
      }
    }

    // --------
    // RECOVERY CODE
    // --------
    if (mfa.nextChallenge === CONSTANTS.RECOVERY_CODE_CHALLENGE) {
      if (
        errores.error === CONSTANTS.ERROR_BROKER_RECOVERY_CODE_TIEMPO_EXPIRADO
      ) {
        window.location.reload();
      }
      challenge = CONSTANTS.RECOVERY_CODE_CHALLENGE;
      dataChallenge = {
        recoverycode: datosCiudadano.recoverycode,
      };
    }

    // --------
    // Manage signIn
    // --------
    if (!email) {
      setGeneral({
        ...general,
        loading: true,
      });
    }
    OIDCService.getInstance()
      .signIn(challenge, dataChallenge, extra, token)
      .then(resp => {
        if (resp.action && resp.authn_response) {
          props.setAuthnResponseInfo(resp);
        } else {
          handleNextChallenge(resp);
        }
      })
      .catch(err => {
        handleErrors(err);
        return err;
      });
  };

  const handleErrors = err => {
    if (err.data.redirect) {
      window.location.href = err.data.redirect;
    } else {
      setGeneral({
        ...general,
        loading: false,
      });
      setErrores({
        ...errores,
        error: err.data.error,
        field: err.data.field,
        code: err.data.code || '',
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const parseLevel = level => {
    // eslint-disable-next-line default-case
    switch (level) {
      case 'AUTOREGISTRADO':
        return 'Nivel Básico';
      case 'PRESENCIAL':
        return 'Nivel Intermedio';
      case 'CERTIFICADO':
        return 'Nivel Intermedio';
    }
  };

  const handleNextChallenge = resp => {
    setMfa({
      ...mfa,
      prevChallenge: mfa.nextChallenge,
      nextChallenge: resp.challenge,
      direction: 'left',
      token: resp.token,
      ciudadano: resp.name,
      nivel_seguridad: parseLevel(resp.level),
    });
    setGeneral({
      ...general,
      loading: false,
    });
    setErrores({
      ...errores,
      error: '',
      code: '',
    });
    if (resp.email) {
      setDatosCiudadano({
        ...datosCiudadano,
        email: resp.email,
      });
    }
  };

  const handleCancel = () => {
    setGeneral({
      ...general,
      cancelLoading: true,
    });
    if (!general.processState) {
      props.history.goBack();
    } else {
      window.location.href = CANCEL_LOGIN_URL(general.processState);
    }
  };

  const handlePreviousCI = () => {
    setMostrarBotonVolver(false);
    setMfa({
      ...mfa,
      method: CONSTANTS.WITH_USER_METHOD,
      direction: 'none',
    });
  };

  const handlePrevious = () => {
    setMostrarBotonVolver(false);
    if (mfa.prevChallenge === CONSTANTS.USERNAME_CHALLENGE) {
      setTipoDocumento('');
    }
    setMfa({
      ...mfa,
      nextChallenge:
        mfa.prevChallenge === CONSTANTS.USERNAME_CHALLENGE
          ? CONSTANTS.USERNAME_CHALLENGE
          : CONSTANTS.IDP_CHALLENGE,
      prevChallenge: CONSTANTS.IDP_CHALLENGE,
      direction: 'right',
    });
    setErrores({
      ...errores,
      error: '',
    });
    setDatosCiudadano({
      ...datosCiudadano,
      password: '',
      totp: '',
    });
  };

  const handleUsernameValidator = field => {
    if (mfa.nextChallenge === CONSTANTS.USERNAME_CHALLENGE) {
      const result = uruguayanCIValidator(datosCiudadano.username);
      if (result) {
        setErrores({
          ...errores,
          error: result,
          field,
        });
        return false;
      }
      return true;
    }
    if (maxLengthValidator(datosCiudadano.username, CONSTANTS.MAX_LEN_DOC)) {
      setErrores({
        ...errores,
        error: CONSTANTS.getMensajeErrorLargoMaximo(CONSTANTS.MAX_LEN_DOC),
        field,
      });
      return false;
    }
    return true;
  };

  const handleAnotherDocument = event => {
    event.preventDefault();
    setPaisEmisor(CONSTANTS.AR_CODE);
    setErrores({
      ...errores,
      error: '',
      field: '',
    });
    setMfa({
      ...mfa,
      nextChallenge: CONSTANTS.FOREIGN_USERNAME_CHALLENGE,
      prevChallenge: CONSTANTS.USERNAME_CHALLENGE,
      direction: 'left',
    });
    setDatosCiudadano({
      ...datosCiudadano,
      username: '',
    });

    // Inicializar Paises
    if (!props.paises.length) {
      props.fetchPaises();
    } else {
      cargarPaises(true);
    }
  };

  const handleLoginConCI = () => {
    setOpcionesLoaders({
      ...opcionesLoaders,
      loadingOp1: true,
    });
    window.location.href = `${CI_LOGIN_REDIRECT}/${props.location.search}`;
  };

  const onChangePais = async (e, setFieldValue) => {
    setPaisEmisor(e.target.value);
    // Necesario para actualizar el valor en formik del tipo_documento
    if (setFieldValue) {
      setFieldValue('tipo_documento', tipoDocumento);
    }
  };

  const onChangeTipoDoc = e => {
    setTipoDocumento(e.target.value);
  };

  const handleClickCI = e => {
    setMostrarBotonVolver(true);
    e.preventDefault();
    setMfa({
      ...mfa,
      method: CONSTANTS.WITH_CI_METHOD,
      direction: 'none',
    });
  };

  const handleIdP = (e, idIdP) => {
    e.preventDefault();
    if (idIdP !== 'identificacion_electronica_py') {
      const processState = general.processState ? general.processState : '';
      window.location.href = `${BROKER_BASE_URL}/${IDP_LOGIN_URL(
        idIdP,
        processState,
      )}`;
    }
  };

  const handleTOTP = () => {
    window.location.replace(FAQ_ACTIVAR_TOTP);
  };

  const handleValidarIdentidad = () => {
    setOpcionesLoaders({
      ...opcionesLoaders,
      loadingOp3: true,
    });
    window.location.replace(FAQ_VALIDAR_IDENTIDAD);
  };

  const handleIniciarSesionOtraManera = () => {
    setMfa({
      ...mfa,
      prevChallenge: mfa.nextChallenge,
      nextChallenge: CONSTANTS.AE3_CHALLENGE,
      direction: 'left',
    });
  };

  // eslint-disable-next-line no-shadow
  const resendRecoveryCodeEmail = e => {
    e.preventDefault();
    props.resendRecoveryCodeEmail(mfa.token);
    setSnackBarInfo({ mostrar: true, texto: CONSTANTS.SNACKBAR_TEXTO_LOGIN });
  };

  const IDPlist = (idps, ci_allowed, loadings) => {
    let CiElectronica = [];
    if (ci_allowed && !isMobile()) {
      CiElectronica = [
        {
          identifier: CONSTANTS.CEDULA_ELECTRONICA_ID,
          name: CONSTANTS.CEDULA_ELECTRONICA_NOMBRE,
          description: CONSTANTS.CEDULA_ELECTRONICA_DESCRIPTION,
          nivel_seguridad: CONSTANTS.CEDULA_ELECTRONICA_NIVEL,
          logo: logoIDP(CIElectronica),
          handleClick: handleClickCI,
          ariaLabel: `${CONSTANTS.IDP_ARIA_LABEL}${CONSTANTS.CEDULA_ELECTRONICA_NOMBRE}`,
        },
      ];
    }
    const listIDPaux = [];
    idps.forEach(elem =>
      listIDPaux.push({
        identifier: elem.identificador,
        name: elem.nombre,
        description: elem.descripcion,
        nivel_seguridad: elem.nivel_seguridad,
        logo: logoIDP(elem.logo),
        handleClick: handleIdP,
        loading: loadings[elem.identificador],
        ariaLabel: `${CONSTANTS.IDP_ARIA_LABEL}${elem.nombre}`,
      }),
    );
    const listIDP = CiElectronica.concat(listIDPaux);
    setIdentityProviders(listIDP);
  };

  const logoIDP = logo => {
    if (logo === null) {
      return defaultLogo;
    }
    return logo;
  };

  const cargarPaises = filtrar => {
    const dataPaises = getPaisesOptions(props.paises, filtrar);

    setPaisesOptions(dataPaises.paisesOptions);
    if (paisEmisor) {
      cargarDocumentos(paisEmisor, props.paises);
    } else {
      setPaisEmisor(dataPaises.paisEmisor);
    }
  };

  const cargarDocumentos = (pEmisor, paises) => {
    const dataDocumentos = getDocumentosOptions(pEmisor, paises);
    setDocumentosOptions(dataDocumentos.documentosOptions);
    setTipoDocumento(dataDocumentos.tipoDocumento);
  };

  // --------------------------------------------------------------------------
  // Effects
  // --------------------------------------------------------------------------

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    const processState = parsed.process_state ? parsed.process_state : null;
    const ae = parsed.ae ? parsed.ae : null;
    const nid = parsed.nid ? parsed.nid : null;
    if (ae === 'ci') {
      setMfa({
        ...mfa,
        method: CONSTANTS.WITH_CI_METHOD,
      });
    }
    if (nid === '3') {
      setMfa({
        ...mfa,
        nextChallenge: CONSTANTS.AE3_CHALLENGE,
      });
    }
    setGeneral({
      ...general,
      processState,
      nid,
      ae,
    });
    props.fetchIDPS(processState);
  }, []);

  useEffect(() => {
    if (props.paises && props.paises.length > 0) {
      cargarPaises(true);
    }
  }, [props.paises]);

  useEffect(() => {
    const loadingIdp = [];
    if (props.idps) {
      // eslint-disable-next-line no-return-assign
      props.idps.forEach(idp => (loadingIdp[idp.identificador] = false));
      IDPlist(props.idps, props.ci_allowed, loadingIdp);
    }
  }, [props.idps]);

  useEffect(() => {
    if (props.action && props.authnResponse) {
      props.history.push(SAML_RESPONSE_ROUTE);
    }
  }, [props.authnResponse, props.action]);

  useEffect(() => {
    if (paisEmisor && props.paises && props.paises.length > 0) {
      cargarDocumentos(paisEmisor, props.paises);
    }
  }, [paisEmisor]);

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  const opciones = [
    {
      disponible: true,
      text: CONSTANTS.MORE_NID_REQUIRED_OPTION1,
      handleClick: handleIniciarSesionOtraManera,
      loading: opcionesLoaders.loadingOp1,
    },
  ];

  if (
    mfa.nextChallenge === CONSTANTS.MORE_AE_REQUIRED ||
    mfa.nextChallenge === CONSTANTS.MORE_RID_AND_AE_REQUIRED
  ) {
    opciones.push({
      text: CONSTANTS.MORE_NID_REQUIRED_OPTION2,
      handleClick: handleTOTP,
      loading: opcionesLoaders.loadingOp2,
    });
  }

  if (
    mfa.nextChallenge === CONSTANTS.MORE_RID_REQUIRED ||
    mfa.nextChallenge === CONSTANTS.MORE_RID_AND_AE_REQUIRED
  ) {
    opciones.push({
      text: CONSTANTS.MORE_NID_REQUIRED_OPTION3,
      handleClick: handleValidarIdentidad,
      loading: opcionesLoaders.loadingOp3,
    });
  }

  opciones.push({
    text: CONSTANTS.CANCEL_AND_PREVIOUS_BUTTON_TEXT,
    handleClick: handleCancel,
    loading: general.cancelLoading,
  });

  const handleBotonVolver = () => {
    if (mfa.method === CONSTANTS.WITH_USER_METHOD) {
      if (
        mfa.nextChallenge !== CONSTANTS.IDP_CHALLENGE &&
        [
          CONSTANTS.USERNAME_CHALLENGE,
          CONSTANTS.FOREIGN_USERNAME_CHALLENGE,
          CONSTANTS.PASSWORD_CHALLENGE,
          CONSTANTS.TOTP_CHALLENGE,
          CONSTANTS.RECOVERY_CODE_CHALLENGE,
          CONSTANTS.ADD_INST_EMAIL_CHALLENGE,
        ].includes(mfa.nextChallenge) &&
        mfa.nextChallenge !== CONSTANTS.AE3_CHALLENGE
      ) {
        return handlePrevious();
      }
    } else if (mfa.method === CONSTANTS.CI_CHALLENGE && !general.ae) {
      return handlePreviousCI();
    }
    return props.history.goBack();
  };

  let challenge;
  if (mfa.method === CONSTANTS.WITH_USER_METHOD) {
    switch (mfa.nextChallenge) {
      case CONSTANTS.IDP_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.IDP_CHALLENGE}
            name={CONSTANTS.USERNAME_FIELD_NAME}
            className="mfa__login"
            title={CONSTANTS.TITULO_LOGIN_BROKER}
            type="idp"
            gub_uy_allowed={props.gub_uy_allowed}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={
              general.processState
                ? CONSTANTS.CANCEL_BUTTON_TEXT
                : CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT
            }
            handlePrevious={handleCancel}
            errores={errores}
            loading={general.loading}
            cancelLoading={general.cancelLoading}
            identityProviders={identityProviders}
          />
        );
        break;
      case CONSTANTS.USERNAME_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.USERNAME_CHALLENGE}
            name={CONSTANTS.USERNAME_FIELD_NAME}
            className="mfa__login__cedula"
            title="Identificate con tu usuario gub.uy"
            placeholder={CONSTANTS.USERNAME_FIELD_PLACEHOLDER}
            label={CONSTANTS.USERNAME_FIELD_LABEL}
            value={datosCiudadano.username}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                username: val,
              });
              setErrores({
                ...errores,
                error: '',
                code: '',
              });
            }}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={
              general.processState
                ? CONSTANTS.CANCEL_BUTTON_TEXT
                : CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT
            }
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
            cancelLoading={general.cancelLoading}
            linkText={CONSTANTS.USERNAME_FIELD_LINK_TEXT}
            handleLink={handleAnotherDocument}
            ariaLabel={CONSTANTS.USERNAME_FIELD_DESCRIPTION}
            identityProviders={identityProviders}
          />
        );
        break;
      case CONSTANTS.FOREIGN_USERNAME_CHALLENGE:
        challenge = (
          <MFAForeignField
            key={CONSTANTS.FOREIGN_USERNAME_CHALLENGE}
            name={CONSTANTS.FOREIGN_USERNAME_CHALLENGE}
            hidden={props.loading_paises}
            title="Identificate con tu usuario gub.uy"
            placeholder={CONSTANTS.USERNAME_FIELD_PLACEHOLDER}
            valueUsername={datosCiudadano.username}
            valuePais={paisEmisor}
            valueTipoDoc={tipoDocumento}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                username: val,
              });
              setErrores({
                ...errores,
                error: '',
                code: '',
              });
            }}
            onChangePais={onChangePais}
            onChangeTipoDoc={onChangeTipoDoc}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT}
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
            paisesOptions={paisesOptions}
            documentosOptions={documentosOptions}
            ariaLabel={CONSTANTS.FOREIGN_USERNAME_FIELD_DESCRIPTION}
            identityProviders={identityProviders}
          />
        );
        break;
      case CONSTANTS.PASSWORD_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.PASSWORD_CHALLENGE}
            name={CONSTANTS.PASSWORD_FIELD_NAME}
            className="mfa__login__contrasena"
            type="password"
            title="Identificate con tu usuario gub.uy"
            placeholder=""
            label={CONSTANTS.PASSWORD_FIELD_LABEL}
            value={datosCiudadano.password}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                password: val,
              });
              setErrores({
                ...errores,
                error: '',
              });
            }}
            linkText={CONSTANTS.PASSWORD_FIELD_LINK_TEXT}
            linkUrl={RESET_PASSWORD_ROUTE}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT}
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
            ariaLabel={CONSTANTS.PASSWORD_FIELD_DESCRIPTION}
            extraFieldValue={datosCiudadano.username}
          />
        );
        break;
      case CONSTANTS.TOTP_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.TOTP_CHALLENGE}
            name={CONSTANTS.TOTP_FIELD_NAME}
            title="Verificación en dos pasos"
            className="mfa__login__verificacion"
            placeholder={CONSTANTS.TOTP_FIELD_PLACEHOLDER}
            label={CONSTANTS.VERIFICATION_CODE_FIELD_LABEL}
            value={datosCiudadano.totp}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                totp: val,
              });
              setErrores({
                ...errores,
                error: '',
              });
            }}
            handleLink={e => {
              e.preventDefault();
              handleNext(true);
            }}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT}
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
            ariaLabel={CONSTANTS.TOTP_FIELD_DESCRIPTION}
            nombre_ciudadano={mfa.ciudadano}
            nivel_seguridad={mfa.nivel_seguridad}
            extraFieldValue={datosCiudadano.username}
          />
        );
        break;
      case CONSTANTS.RECOVERY_CODE_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.RECOVERY_CODE_CHALLENGE}
            name={CONSTANTS.RECOVERY_CODE_FIELD_NAME}
            title={CONSTANTS.RECOVERY_CODE_FIELD_TITLE}
            className="mfa__login__correo"
            description={CONSTANTS.RECOVERY_CODE_FIELD_DESCRIPTION}
            placeholder={CONSTANTS.RECOVERY_CODE_FIELD_PLACEHOLDER}
            label={CONSTANTS.VERIFICATION_CODE_FIELD_LABEL}
            value={datosCiudadano.recoverycode}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                recoverycode: val,
              });
              setErrores({
                ...errores,
                error: '',
              });
            }}
            handleLink={resendRecoveryCodeEmail}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT}
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
            ariaLabel={CONSTANTS.RECOVERY_CODE_FIELD_DESCRIPTION}
            email={datosCiudadano.email}
            nombre_ciudadano={mfa.ciudadano}
            nivel_seguridad={mfa.nivel_seguridad}
          />
        );
        break;
      case CONSTANTS.ADD_INST_EMAIL_CHALLENGE:
        challenge = (
          <MFAField
            key={CONSTANTS.ADD_INST_EMAIL_CHALLENGE}
            name={CONSTANTS.ADD_INST_EMAIL_FIELD_NAME}
            title={CONSTANTS.ADD_INST_EMAIL_FIELD_TITLE}
            description={CONSTANTS.ADD_INST_EMAIL_FIELD_DESCRIPTION}
            placeholder=""
            className="mfa__login__mail_institucional"
            label={CONSTANTS.ADD_INST_EMAIL_FIELD_LABEL}
            value={datosCiudadano.institutional_email}
            onChange={e => {
              const val = e.target.value;
              setDatosCiudadano({
                ...datosCiudadano,
                institutional_email: val,
              });
              setErrores({
                ...errores,
                error: '',
              });
            }}
            handleLink={resendRecoveryCodeEmail}
            nextText={CONSTANTS.NEXT_CHALLENGE_BUTTON_TEXT}
            handleNext={handleNext}
            previousText={CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT}
            handlePrevious={handlePrevious}
            errores={errores}
            loading={general.loading}
          />
        );
        break;
      case CONSTANTS.MORE_RID_REQUIRED:
      case CONSTANTS.MORE_AE_REQUIRED:
      case CONSTANTS.MORE_RID_AND_AE_REQUIRED:
        challenge = (
          <AumentarNID
            key={
              mfa.nextChallenge === CONSTANTS.MORE_AE_REQUIRED
                ? CONSTANTS.MORE_AE_REQUIRED
                : CONSTANTS.MORE_RID_REQUIRED
            }
            title={CONSTANTS.MORE_NID_REQUIRED_TITLE}
            description={CONSTANTS.MORE_NID_REQUIRED_DESCRIPTION}
            opciones={opciones}
            linkText={CONSTANTS.MORE_NID_REQUIRED_LINK}
            linkHref={FAQ_AUMENTAR_GARANTIA_IDENTIDAD}
          />
        );
        break;
      case CONSTANTS.AE3_CHALLENGE:
        challenge = (
          <Ae3Methods
            key={CONSTANTS.AE3_CHALLENGE}
            title="Identificación digital"
            description={CONSTANTS.AE3_CHALLENGE_DESCRIPTION}
            previousText={
              general.nid
                ? CONSTANTS.CANCEL_BUTTON_TEXT
                : CONSTANTS.PREVIOUS_CHALLENGE_BUTTON_TEXT
            }
            handlePrevious={general.nid ? handleCancel : handlePrevious}
            cancelLoading={general.cancelLoading}
            identityProviders={identityProviders}
          />
        );
        break;
      default:
        challenge = null;
    }
  } else {
    challenge = (
      <CIMFAField
        key={CONSTANTS.CI_CHALLENGE}
        name={CONSTANTS.CI_CHALLENGE}
        title={CONSTANTS.TITULO_LOGIN_CEDULA}
        description={CONSTANTS.CI_FIELD_DESCRIPTION}
        img={seguridadPrivacidad}
        nextText={CONSTANTS.CI_CHALLENGE_BUTTON_TEXT}
        handleNext={handleLoginConCI}
        loading={opcionesLoaders.loadingOp1}
      />
    );
  }
  let content;
  if (challenge && props.idps !== null) {
    content = (
      <>
        {!minimalista &&
          mfa.nextChallenge !== CONSTANTS.IDP_CHALLENGE &&
          mfa.method === CONSTANTS.WITH_USER_METHOD && (
            <InformacionNivel
              texto="Tu usuario gub.uy puede alcanzar "
              nivel="nivel de seguridad básico o intermedio."
              logo={basicoIntermedio}
              textoLink="¿Qué es esto?"
              rutaLink="https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/politicas-y-gestion/identificacion-segura-confiable"
            />
          )}
        {!minimalista && mfa.method !== CONSTANTS.WITH_USER_METHOD && (
          <InformacionNivel
            texto="Tu usuario gub.uy puede alcanzar "
            nivel="nivel de seguridad avanzado."
            logo={avanzado}
            textoLink="¿Qué es esto?"
            rutaLink="https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/politicas-y-gestion/identificacion-segura-confiable"
          />
        )}

        {!(minimalista && !mostrarBotonVolver) && (
          <BotonVolver funcionIrAtras={handleBotonVolver} />
        )}

        <MFA direction={mfa.direction}>{challenge}</MFA>
      </>
    );
  } else {
    content = <CardSpinner spin />;
  }

  return (
    <>
      <Helmet title={LOGIN_TITLE} />
      {content}
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo(DEFAULT_SNACKBAR_INFO)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  loading_paises: state.registro.loading,
  paises: state.registro.paises,
  federatedIdentity: state.federatedIdentity,
  idps: state.federatedIdentity.idps,
  ci_allowed: state.federatedIdentity.ci_allowed,
  gub_uy_allowed: state.federatedIdentity.gub_uy_allowed,
  authenticationUrl: state.federatedIdentity.authenticationUrl,
  authnResponse: state.brokerSaml.authnResponse,
  action: state.brokerSaml.action,
});

export default connect(mapStateToProps, {
  fetchPaises,
  setAuthnResponseInfo,
  fetchIDPS,
  resendRecoveryCodeEmail,
  hideFooter,
  hideHeader,
})(LoginContainer);
