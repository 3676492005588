import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { TIMEZONE_MONTEVIDEO } from 'constants/commonConstants';
import styles from './styles.module.css';

const IconoAgregarAlCalendario = ({
  nombreEvento,
  datetimeEvento,
  descripcionEvento,
  tipo,
}) => {
  const handleClick = () => {
    // horaEvento trae un timezone que no es el correcto, ya que se generan
    // estos objetos en el timezone de la persona para una correcta visualización.
    // Por dar un ejemplo, si llega 2023/09/26 10:00:00 +0500, la videollamada es en realidad
    // el 2023/09/26 10:00:00 -0300.
    // Primero debe hacerse ese ajuste y luego convertir a UTC, para su correcta visualización
    // en el calendar de la persona.
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const fechaEvento = dayjs(datetimeEvento).format('YYYY-MM-DD');
    const horaEvento = dayjs(datetimeEvento).format('HH:mm:ss');

    const fechaInicioUruguay = dayjs.tz(
      `${fechaEvento} ${horaEvento}`,
      TIMEZONE_MONTEVIDEO,
    );
    const fechaInicioUTC = fechaInicioUruguay.utc();
    const fechaFinUTC = fechaInicioUTC.add(
      Number(window.REACT_APP_DURACION_VIDEOLLAMADA),
      'minute',
    );

    let eventUrl;
    let fechaInicioFormateada = `${fechaInicioUTC.format('YYYYMMDDTHHmmss')}Z`;
    let fechaFinFormateada = `${fechaFinUTC.format('YYYYMMDDTHHmmss')}Z`;

    switch (tipo) {
      case 'google':
        eventUrl =
          'https://calendar.google.com/calendar/r/eventedit?' +
          `text=${nombreEvento}` +
          `&dates=${fechaInicioFormateada}%2F${fechaFinFormateada}` +
          `&details=${descripcionEvento}`;
        break;
      case 'apple':
        eventUrl = encodeURI(
          `data:text/calendar;charset=utf8,${[
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `DTSTART:${fechaInicioFormateada || ''}`,
            `DTEND:${fechaFinFormateada || ''}`,
            `SUMMARY:${nombreEvento || ''}`,
            `DESCRIPTION:${descripcionEvento}`,
            'END:VEVENT',
            'END:VCALENDAR',
          ].join('\n')}`,
        );
        break;
      case 'outlook':
        fechaInicioFormateada = `${fechaInicioUTC.format(
          'YYYY-MM-DDTHH[%3A]mm[%3A]ss',
        )}Z`;
        fechaFinFormateada = `${fechaFinUTC.format(
          'YYYY-MM-DDTHH[%3A]mm[%3A]ss',
        )}Z`;

        eventUrl =
          'https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose' +
          '&rru=addevent' +
          `&startdt=${fechaInicioFormateada}` +
          `&enddt=${fechaFinFormateada}` +
          `&subject=${nombreEvento}` +
          `&body=${descripcionEvento}`;
        break;
      default:
        eventUrl = '';
    }
    return window.open(eventUrl);
  };

  return (
    <input
      className={styles[`botonCalendario_${tipo}`]}
      type="button"
      onClick={handleClick}
      alt={`agregar_calendario_${tipo}`}
    />
  );
};

IconoAgregarAlCalendario.propTypes = {
  nombreEvento: PropTypes.string,
  datetimeEvento: PropTypes.object,
  descripcionEvento: PropTypes.string,
  tipo: PropTypes.string,
};

export default IconoAgregarAlCalendario;
