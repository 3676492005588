/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

import './styles.css';

const LoginOptionTab = ({
  text,
  handleClick,
  // isSmall,
  // className,
  isLoading,
  isSubmit,
  style,
  ariaLabel,
  id,
  isDisabled,
  // isSecondary,
  description,
  nivel_seguridad,
  nivel_seguridad_logo,
  logo,
}) => (
  <button
    className="login-option-tab"
    id={id}
    disabled={isDisabled}
    onClick={isLoading ? null : handleClick}
    type={isSubmit ? 'submit' : 'button'}
    style={style}
    aria-label={ariaLabel || text}
  >
    <div className="login-option-tab__info">
      {logo && (
        <img
          src={logo}
          className="login-option-tab__info__logo"
          alt={`Ingresar con ${text}`}
        />
      )}
      <div className="login-option-tab__info__texto">
        <span
          className="login-option-tab__info__texto__nombre"
          id={id ? `${id}-span` : null}
        >
          {text}
        </span>
        <span
          className="login-option-tab__info__texto__descripcion"
          id={id ? `${id}-span` : null}
        >
          {description}
        </span>
      </div>
    </div>
    <div className="login-option-tab__nivel">
      <span
        className="login-option-tab__nivel__texto"
        id={id ? `${id}-span` : null}
      >
        {nivel_seguridad}
      </span>
      <img
        className="login-option-tab__nivel__logo"
        src={nivel_seguridad_logo}
        alt={`Nivel de seguridad ${nivel_seguridad}`}
      />
    </div>
    {isLoading && <Spinner name="ball-beat" fadeIn="none" color="white" />}
  </button>
);

LoginOptionTab.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmit: PropTypes.bool,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  logo: PropTypes.any,
  idpName: PropTypes.string,
  description: PropTypes.string,
  nivel_seguridad: PropTypes.string,
  nivel_seguridad_logo: PropTypes.any,
};

LoginOptionTab.defaultProps = {
  isSmall: false,
  className: '',
  isDisabled: false,
  isSecondary: false,
  isLoading: false,
  isSubmit: false,
  style: null,
  ariaLabel: '',
  id: null,
  description: '',
  nivel_seguridad: '',
};

export default LoginOptionTab;
