/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSpinner from 'components/CardSpinner';
import fetchFaqs from 'actions/faqs';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { UNAVAILABLE_ROUTE } from 'constants/RouterConstants';
import { pageTitleWithSuffix } from 'constants/pageTitles';
import AyudaItemDetail from './ayudaItemDetail';

class AyudaItemDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.showExternalHeader();
    if (Object.keys(this.props.faqs).length === 0) {
      this.props.fetchFaqs();
    } else {
      this.searchFaqBySlug(this.props.faqs, this.props.match.params.slug);
      this.props.updateAriaLiveMessage(
        this.state.faq ? this.state.faq.titulo : '',
      );
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.faqs).length) {
      this.searchFaqBySlug(nextProps.faqs, nextProps.match.params.slug);
    }
  }

  searchFaqBySlug(faqsList, slug) {
    const faq = faqsList
      .map(faqs => faqs.faqs.filter(obj => obj.slug === slug))
      .filter(obj => obj.length !== 0);

    if (faq.length !== 0) {
      const elem = faq[0][0];
      this.setState({
        faq: elem,
      });
    } else {
      this.props.history.push(UNAVAILABLE_ROUTE);
    }
  }

  render() {
    return this.state.faq ? (
      <>
        <Helmet title={pageTitleWithSuffix(this.state.faq.titulo)} />
        <AyudaItemDetail faq={this.state.faq} />
      </>
    ) : (
      <CardSpinner spin />
    );
  }
}

const mapStateToProps = state => ({
  faqs: state.faqs.faqs,
});

export default connect(mapStateToProps, {
  fetchFaqs,
  showExternalHeader,
  updateAriaLiveMessage,
})(AyudaItemDetailContainer);
