import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import CardSmall from 'components/CardSmall';
import ButtonSection from 'components/ButtonSection';
import { MFA_TOTP_CARD_QR_DESC_TEXT } from 'constants/commonConstants';

import './totpCard.css';

const TOTPCard = ({ title, desc, code, url, handleContinue, handleBack }) => (
  <CardSmall>
    <div className="totp-card__title">{title}</div>
    <div className="totp-card__desc">{desc}</div>
    <div className="totp-card-fill">
      <div className="totp-card-section totp-card-section--qr-code">
        <QRCode
          className="totp-card__qr"
          value={url}
          renderAs="svg"
          size={128}
          level="Q"
        />
      </div>
      <div className="totp-card-section totp-card-section--text">
        <div className="totp-card-section__desc">
          {MFA_TOTP_CARD_QR_DESC_TEXT}
        </div>
        <div className="totp-card-section__code">{code}</div>
      </div>
    </div>
    <ButtonSection
      handleClickPrimary={handleContinue}
      textPrimary="Continuar"
      handleClickSecondary={handleBack}
      textSecondary="Volver"
      isVertical
    />
  </CardSmall>
);

TOTPCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  code: PropTypes.string,
  url: PropTypes.string,
  handleContinue: PropTypes.func,
  handleBack: PropTypes.func,
};

TOTPCard.defaultProps = {
  url: 'test',
};

export default TOTPCard;
