import {
  SHOW_INTERNAL_HEADER,
  SHOW_EXTERNAL_HEADER,
  SHOW_DIGITAL_SIGNATURE_MODAL,
  HIDE_DIGITAL_SIGNATURE_MODAL,
  SHOW_HEADER,
  HIDE_HEADER,
  SHOW_FOOTER,
  HIDE_FOOTER,
  UPDATE_ARIA_LIVE_MESSAGE,
} from 'constants/ActionTypes';

export const INITIAL_STATE = {
  header: {
    showInternalHeader: true,
    showHeader: true,
  },
  footer: {
    showFooter: true,
  },
  misDatos: {
    showModalAssociateDigitalSignature: false,
  },
  aria: {
    ariaLiveMessage: '',
  },
};

// eslint-disable-next-line default-param-last
export default function uiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_INTERNAL_HEADER:
      return {
        ...state,
        header: { ...state.header, showInternalHeader: true },
      };

    case SHOW_EXTERNAL_HEADER:
      return {
        ...state,
        header: { ...state.header, showInternalHeader: false },
      };

    case SHOW_DIGITAL_SIGNATURE_MODAL:
      return {
        ...state,
        misDatos: {
          ...state.misDatos,
          showModalAssociateDigitalSignature: true,
        },
      };

    case HIDE_DIGITAL_SIGNATURE_MODAL:
      return {
        ...state,
        misDatos: {
          ...state.misDatos,
          showModalAssociateDigitalSignature: false,
        },
      };

    case SHOW_HEADER:
      return {
        ...state,
        header: { ...state.header, showHeader: true },
      };

    case SHOW_FOOTER:
      return {
        ...state,
        footer: { ...state.footer, showFooter: true },
      };

    case HIDE_HEADER:
      return {
        ...state,
        header: { ...state.header, showHeader: false },
      };

    case HIDE_FOOTER:
      return {
        ...state,
        footer: { ...state.footer, showFooter: false },
      };

    case UPDATE_ARIA_LIVE_MESSAGE:
      return {
        ...state,
        aria: { ...state.aria, ariaLiveMessage: action.payload.text },
      };
    // DEFAULT
    default:
      return state;
  }
}
