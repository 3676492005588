/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import EnvioInstrucciones from 'components/EnvioInstrucciones';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { RESET_PASSWORD_ROUTE } from 'constants/RouterConstants';
import { RESET_PASSWORD_LINK_SENT_TITLE } from 'constants/pageTitles';
import {
  YA_CASI_TERMINAS,
  RECIBIRAS_INSTRUCCIONES_SI_ESTAS_EN_SISTEMA,
} from 'constants/commonConstants';

const ResetPasswordSentContainer = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(RESET_PASSWORD_LINK_SENT_TITLE));
    if (location.state) {
      setEmail(location.state.email);
    }
  }, []);

  const sendAgain = e => {
    e.preventDefault();
    history.push(RESET_PASSWORD_ROUTE);
  };

  return (
    <>
      <Helmet title={RESET_PASSWORD_LINK_SENT_TITLE} />
      <EnvioInstrucciones
        title={YA_CASI_TERMINAS}
        desc={
          email !== '' ? (
            <div>
              Te enviamos las instrucciones para continuar a tu correo{' '}
              <b style={{ letterSpacing: '1px' }}>{email}</b>.
            </div>
          ) : (
            RECIBIRAS_INSTRUCCIONES_SI_ESTAS_EN_SISTEMA
          )
        }
        action1={sendAgain}
      />
    </>
  );
};

export default ResetPasswordSentContainer;
