import React from 'react';
import PropTypes from 'prop-types';

import ButtonLink from 'components/ButtonLink';
import ButtonOption from './buttonOption';

import './aumentarNID.css';

const AumentarNID = ({ title, description, opciones, linkText, linkHref }) => {
  const optionButtons = opciones.map((opcion, index) => (
    <ButtonOption
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      text={opcion.text}
      style={index !== 0 ? { borderTop: '0' } : null}
      handleClick={opcion.handleClick}
      isLoading={opcion.loading}
    />
  ));

  return (
    <div className="mfa__aumentar_nid">
      <h2 className="mfa__title">{title}</h2>
      <div className="mfa__subheading">{description}</div>
      <div className="mfa__aumentar_nid__container">
        {optionButtons}
        <div className="mfa__aumentar_nid__container__link">
          <ButtonLink
            type="button"
            target="_blank"
            to={linkHref}
            aria-label={linkText}
            title={linkText}
          />
        </div>
      </div>
    </div>
  );
};

AumentarNID.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  opciones: PropTypes.array,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
};

export default AumentarNID;
