import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import ButtonSolid from 'components/ButtonSolid';
import ButtonLink from 'components/ButtonLink';
import Alert from 'components/Alert';

import './infoSection.css';
import 'styles/bootstrap/bootstrap-grid.css';

// ---------------------------------------------------------------------------
// InfoSection's helpers
// ---------------------------------------------------------------------------
const ContentInfoSectionItem = ({
  contentText,
  contentValue,
  infoLinkText,
  infoLinkUrl,
  buttonName,
  buttonCallback,
  actionIsLoading,
  buttonIsDisabled,
  ariaLabel,
}) => {
  let nivel = null;
  if (contentValue) {
    if (contentValue === 'Verificado' || contentValue === 'Certificado') {
      nivel = 'Intermedio';
    } else if (
      contentValue === 'Autoregistro con CI' ||
      contentValue === 'Autoregistrado'
    ) {
      nivel = 'Básico';
    }
  }

  return (
    <div className="info-section__item">
      <div className="info-section__item__left">{contentText}</div>
      <div className="info-section__item__right">
        <div
          className={`info-section__item__right__value info-section__${
            (contentValue === 'Asociado' ||
              contentValue === 'Autoregistro con CI' ||
              contentValue === 'Activada') &&
            'text-green'
          } info-section__${
            (contentValue === 'No asociado' ||
              contentValue === 'Autoregistrado' ||
              contentValue === 'Desactivada') &&
            'text-red'
          } 
          info-section__${
            (contentValue === 'Certificado' || contentValue === 'Verificado') &&
            'text-mustard'
          }`}
        >
          {nivel || contentValue}
        </div>
        {infoLinkText ? (
          <div className="info-section__item__right__link">
            <ButtonLink
              to={infoLinkUrl}
              aria-label={infoLinkText}
              title={infoLinkText}
              external
              newTab
            />
          </div>
        ) : null}
        {buttonCallback ? (
          <div className="info-section__item__right__button">
            <ButtonSolid
              text={buttonName}
              handleClick={buttonCallback}
              isLoading={actionIsLoading}
              isSmall
              ariaLabel={ariaLabel}
              isDisabled={buttonIsDisabled}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ContentInfoSectionItem.propTypes = {
  contentText: PropTypes.string,
  contentValue: PropTypes.string,
  infoLinkText: PropTypes.string,
  infoLinkUrl: PropTypes.string,
  buttonName: PropTypes.string,
  buttonCallback: PropTypes.func,
  buttonIsDisabled: PropTypes.bool,
  actionIsLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

const ContentInfoSectionSignature = ({
  signatureText,
  signatureValue,
  signatureLink,
  infoLinkText,
  infoLinkUrl,
  buttonName,
  buttonCallback,
  buttonIsDisabled,
  actionIsLoading,
  ariaLabel,
}) => (
  <div className="info-section__item">
    <div className="info-section__item__left">{signatureText}</div>
    <div className="info-section__item__right">
      <div className="info-section__item__right__signature">
        <div
          className={`info-section__item__right__signature__value ${
            signatureValue === 'Asociado' && 'info-section__text-green'
          }`}
        >
          {signatureValue}
        </div>
        <span className="info-section__item__right__signature__separator" />
        {signatureLink}
      </div>
      {infoLinkText ? (
        <div className="info-section__item__right__link">
          <ButtonLink
            to={infoLinkUrl}
            aria-label={infoLinkText}
            title={infoLinkText}
            external
          />
        </div>
      ) : null}
      {buttonCallback ? (
        <div className="info-section__item__right__button">
          <ButtonSolid
            text={buttonName}
            handleClick={buttonCallback}
            isLoading={actionIsLoading}
            isSmall
            ariaLabel={ariaLabel}
            isDisabled={buttonIsDisabled}
          />
        </div>
      ) : null}
    </div>
  </div>
);

ContentInfoSectionSignature.propTypes = {
  signatureText: PropTypes.string,
  signatureValue: PropTypes.string,
  signatureLink: PropTypes.object,
  infoLinkText: PropTypes.string,
  infoLinkUrl: PropTypes.string,
  buttonName: PropTypes.string,
  buttonCallback: PropTypes.func,
  buttonIsDisabled: PropTypes.bool,
  actionIsLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

// ---------------------------------------------------------------------------
// InfoSection
// ---------------------------------------------------------------------------
const InfoSection = ({
  title,
  contentText,
  buttonName,
  typeAlert,
  contentValue,
  textAlert,
  linkText,
  showAlert,
  linkOnClickAlert,
  buttonCallback,
  buttonIsDisabled,
  ariaLabel,
  signatureText,
  signatureValue,
  signatureLink,
  infoLinkText,
  infoLinkUrl,
  actionIsLoading,
  showExtraContent,
  extraContent,
}) => (
  <section className="info-section">
    <Box title={title}>
      {signatureText ? (
        <div className="info-section__item">
          <ContentInfoSectionItem
            contentText={contentText}
            contentValue={contentValue}
          />
          {signatureLink ? (
            <div style={{ marginTop: '24px' }}>
              <ContentInfoSectionSignature
                signatureText={signatureText}
                signatureValue={signatureValue}
                infoLinkText={infoLinkText}
                infoLinkUrl={infoLinkUrl}
                buttonName={buttonName}
                buttonCallback={buttonCallback}
                actionIsLoading={actionIsLoading}
                ariaLabel={ariaLabel}
                signatureLink={signatureLink}
                buttonIsDisabled={buttonIsDisabled}
              />
            </div>
          ) : (
            <div style={{ marginTop: '24px' }}>
              <ContentInfoSectionItem
                contentText={signatureText}
                contentValue={signatureValue}
                infoLinkText={infoLinkText}
                infoLinkUrl={infoLinkUrl}
                buttonName={buttonName}
                buttonCallback={buttonCallback}
                actionIsLoading={actionIsLoading}
                ariaLabel={ariaLabel}
                buttonIsDisabled={buttonIsDisabled}
              />
            </div>
          )}
        </div>
      ) : (
        <ContentInfoSectionItem
          contentText={contentText}
          contentValue={contentValue}
          infoLinkText={infoLinkText}
          infoLinkUrl={infoLinkUrl}
          buttonName={buttonName}
          buttonCallback={buttonCallback}
          actionIsLoading={actionIsLoading}
          ariaLabel={ariaLabel}
          buttonIsDisabled={buttonIsDisabled}
        />
      )}
      {showAlert ? (
        <Alert
          type={typeAlert}
          text={textAlert || 'No verificaste tu correo electrónico aún.'}
          linkText={linkText || 'Reenviar correo »'}
          linkOnClick={linkOnClickAlert}
        />
      ) : null}
      {showExtraContent && extraContent}
    </Box>
  </section>
);

InfoSection.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  buttonName: PropTypes.string,
  typeAlert: PropTypes.string,
  contentValue: PropTypes.string,
  textAlert: PropTypes.string,
  linkText: PropTypes.string,
  showAlert: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  linkOnClickAlert: PropTypes.func,
  buttonCallback: PropTypes.func,
  buttonIsDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  signatureText: PropTypes.string,
  signatureValue: PropTypes.string,
  signatureLink: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  infoLinkText: PropTypes.string,
  infoLinkUrl: PropTypes.string,
  actionIsLoading: PropTypes.bool,
  showExtraContent: PropTypes.bool,
  extraContent: PropTypes.object,
};

InfoSection.defaultProps = {
  showAlert: false,
  buttonIsDisabled: false,
  actionIsLoading: false,
  showExtraContent: false,
  extraContent: null,
};

export default InfoSection;
