/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card';
import ModalConfirmarAccion from 'components/ModalConfirmarAccion';
import ButtonSection from 'components/ButtonSection';
import lockIcon from 'assets/auth_2_factor.png';
import lockIcon2x from 'assets/auth_2_factor@2x.png';
import lockIcon3x from 'assets/auth_2_factor@3x.png';
import {
  MFA_SECTION_DEFAULT_TEXT,
  MFA_SECTION_DEFAULT_DESC,
  MFA_BACK_BUTTON_TEXT,
  MFA_BACK_BUTTON_ARIA_LABEL,
  MFA_CONF_BUTTON_ARIA_LABEL,
  MFA_TOTP_MODAL_DELETE_TITLE,
  MFA_TOTP_MODAL_DELETE_DESC,
  CANCEL_BUTTON_TEXT,
  MFA_TOTP_DEACTIVATE_BUTTON_TEXT,
  MFA_TOTP_DEACTIVATE_ARIA_LABEL,
  MFA_TOTP_DEACTIVATE_CANCEL_ARIA_LABEL,
} from 'constants/commonConstants';

import './mfaWrapper.css';

const MFASection = ({
  title,
  desc,
  showButton = false,
  buttonText = 'Volver',
}) => (
  <div>
    <div className="mfa-card__line"></div>
    <div className="mfa-card-section__title">{title}</div>
    <div className="mfa-card-section__desc">{desc}</div>
    {showButton ? <button>{buttonText}</button> : null}
  </div>
);

MFASection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  actionButtonCallback: PropTypes.func,
};

export const MFAWrapper = props => {
  const {
    title,
    desc,
    handleBack,
    handleClick,
    handleClickTitle,
    isLoading,
    showModalDesactivarTOTP,
    handleShowModalDesactivarTOTP,
    handleDesactivar,
    totpActivado,
    isDangerPrimary,
  } = props;
  return (
    <Card className="card__mid">
      <div>
        <img
          className="mfa-card__logo"
          src={lockIcon}
          srcSet={`${lockIcon2x} 2x, ${lockIcon3x} 3x`}
          alt="Configuración de Autenticación en dos pasos"
        />
        <div className="mfa-card__title">{title}</div>
        <div className="mfa-card__desc">{desc}</div>
        <ButtonSection
          textSecondary={MFA_BACK_BUTTON_TEXT}
          ariaLabelSecondary={MFA_BACK_BUTTON_ARIA_LABEL}
          handleClickSecondary={handleBack}
          textPrimary={handleClickTitle}
          ariaLabelPrimary={MFA_CONF_BUTTON_ARIA_LABEL}
          handleClickPrimary={handleClick}
          isLoadingPrimary={isLoading}
          style={{ margin: '24px auto 0' }}
          isDangerPrimary={isDangerPrimary}
        />
        <MFASection
          title={MFA_SECTION_DEFAULT_TEXT}
          desc={MFA_SECTION_DEFAULT_DESC}
        />
      </div>
      {totpActivado ? (
        <ModalConfirmarAccion
          loading={isLoading}
          isOpen={showModalDesactivarTOTP}
          onClose={handleShowModalDesactivarTOTP}
          title={MFA_TOTP_MODAL_DELETE_TITLE}
          text={MFA_TOTP_MODAL_DELETE_DESC}
          buttonTextPositiveAction={MFA_TOTP_DEACTIVATE_BUTTON_TEXT}
          buttonAriaLabelPositiveAction={MFA_TOTP_DEACTIVATE_ARIA_LABEL}
          handleSubmitPositiveAction={handleDesactivar}
          buttonTextNegativeAction={CANCEL_BUTTON_TEXT}
          buttonAriaLabelNegativeAction={MFA_TOTP_DEACTIVATE_CANCEL_ARIA_LABEL}
          handleSubmitNegativeAction={handleShowModalDesactivarTOTP}
          isDangerPrimary={isDangerPrimary}
        />
      ) : null}
    </Card>
  );
};

MFAWrapper.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  handleBack: PropTypes.func,
  handleClick: PropTypes.func,
  handleClickTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  showModalDesactivarTOTP: PropTypes.bool,
  handleShowModalDesactivarTOTP: PropTypes.func,
  handleDesactivar: PropTypes.func,
  totpActivado: PropTypes.bool,
  isDangerPrimary: PropTypes.bool,
};

export default MFAWrapper;
