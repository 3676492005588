import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traducciones
const resources = {
  es: {
    translation: {
      title: 'Te identificaste con {{idp}}',
      informacionPersonal: 'Información personal',
      nombreYApellido: 'Nombre y Apellido:',
      nivel: 'Nivel:',
      email: 'Email:',
      pais: 'País:',
      tipoDeDocumento: 'Tipo de documento:',
      documento: 'Documento:',
      sinDatos: 'Sin datos',
      continuarTramite: 'Continuar con el trámite',
      nivelBajoParaContinuarTramite:
        'Para realizar este trámite con identidad transfronteriza, es necesario un nivel avanzado (oro) de identificación digital',
    },
  },
  pt: {
    translation: {
      title: 'Você se identificou com {{idp}}',
      informacionPersonal: 'Informação pessoal',
      nombreYApellido: 'Nome e Sobrenome:',
      nivel: 'Nível:',
      email: 'E-mail:',
      pais: 'País:',
      tipoDeDocumento: 'Tipo de documento:',
      documento: 'Documento:',
      sinDatos: 'Não há dados',
      continuarTramite: 'Continue com o processo',
      nivelBajoParaContinuarTramite:
        'Para realizar este procedimento com identidade transfronteiriça é necessário um nível avançado (ouro) de identificação digital',
    },
  },
};

// Configuración de i18next
i18n
  .use(initReactI18next) // Pasa i18n a react-i18next
  .init({
    resources,
    lng: 'es', // Idioma inicial
    fallbackLng: 'es', // Idioma de reserva
    interpolation: {
      escapeValue: false, // React ya hace escaping de por sí
    },
  });

export default i18n;
