/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import LoginOptionTab from 'components/LoginOptionTab';

import gubUyLogo from 'assets/logos/gubuy.svg';
import basicoIntermedio from 'assets/icons/basico_intermedio.svg';
import avanzado from 'assets/icons/avanzado.svg';

export const LoginOption = ({
  name,
  handleClick,
  className,
  logo,
  description,
  nivel_seguridad,
  nivel_seguridad_logo,
  identifier,
}) => (
  <div className={`login-option ${className}`}>
    <LoginOptionTab
      className="login-option__button"
      handleClick={e => {
        handleClick(e, identifier);
      }}
      text={name}
      logo={logo}
      description={description}
      nivel_seguridad={nivel_seguridad}
      nivel_seguridad_logo={nivel_seguridad_logo}
    />
  </div>
);

LoginOption.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.any,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  nivel_seguridad: PropTypes.string,
  description: PropTypes.string,
};

LoginOption.defaultProps = {
  className: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  style: null,
  nivel_seguridad: '',
  description: '',
};

export const LoginSection = ({
  gub_uy_allowed,
  identityProviders,
  handleNext,
}) => {
  const LoginList = identityProviders.map((idp, index) => (
    <LoginOption
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      identifier={idp.identifier}
      name={idp.name}
      ariaLabel={idp.ariaLabel}
      logo={idp.logo}
      description={idp.description}
      nivel_seguridad="Avanzado"
      nivel_seguridad_logo={avanzado}
      handleClick={idp.handleClick}
    />
  ));

  return (
    <div className="login-section">
      {gub_uy_allowed && (
        <LoginOption
          key="gub_uy_option"
          identifier="usuarioGubUy"
          name="Usuario Gub.uy"
          ariaLabel="Accede con Usuario Gub.uy"
          logo={gubUyLogo}
          description="Realiza trámites con tu número de documento y contraseña"
          nivel_seguridad="Básico o intermedio"
          nivel_seguridad_logo={basicoIntermedio}
          handleClick={handleNext}
        />
      )}
      {identityProviders.length > 0 && identityProviders !== null && (
        <div>{LoginList}</div>
      )}
    </div>
  );
};

LoginOption.propTypes = {
  gub_uy_allowed: PropTypes.bool,
  identityProviders: PropTypes.array,
};
