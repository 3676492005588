import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { verifyUserPassword, clearPasswordErrors } from 'actions/seguridad';
import { AUTH_TITLE } from 'constants/pageTitles';
import { useHistory, useLocation } from 'react-router-dom';

import {
  seguridadCargandoSelector,
  verifiedPasswordErrorsSelector,
  verifiedPasswordSelector,
} from 'store/selectors';
import AuthContrasena from './AuthContrasena';

const AuthContraseñaContainer = () => {
  const [error, setError] = useState(null);

  const verifiedPassword = useSelector(verifiedPasswordSelector);
  const verifiedPasswordErrors = useSelector(verifiedPasswordErrorsSelector);
  const cargando = useSelector(seguridadCargandoSelector);

  const dispatch = useDispatch();

  const location = useLocation();

  const history = useHistory();

  useEffect(
    () => () => {
      dispatch(clearPasswordErrors());
    },
    [],
  );

  useEffect(() => {
    if (verifiedPassword) {
      if (location.state && location.state.prevPath) {
        history.push(location.state.prevPath);
      }
    }
    if (verifiedPasswordErrors) {
      setError(verifiedPasswordErrors.error);
    }
  }, [verifiedPassword, verifiedPasswordErrors, location.state, history]);

  const handleVerificarContrasena = contrasena => {
    if (!cargando) {
      dispatch(verifyUserPassword(contrasena));
    }
  };

  const handleIrAtras = () => {
    history.replace(location.state.basePath);
  };

  return (
    <>
      <Helmet title={AUTH_TITLE} />
      <AuthContrasena
        cargando={cargando}
        handleVerificarContrasena={handleVerificarContrasena}
        handleIrAtras={handleIrAtras}
        contrasenaError={error}
      />
    </>
  );
};

export default AuthContraseñaContainer;
