import Auth from 'utils/Auth';

const INITIAL_STATE = {
  isAuthenticated: false,
};

// eslint-disable-next-line default-param-last
export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // DEFAULT
    default:
      return {
        ...state,
        isAuthenticated: Auth.getInstance().isAuthenticated(),
      };
  }
}
