import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RenderField } from 'components/RenderField (Nuevo)';
import CardSmall from 'components/CardSmall';

import {
  MFA_TOTP_CARD_BACK_BUTTON_TEXT,
  MFA_TOTP_CARD_SUBMIT_BUTTON_TEXT,
  VALIDAR_CONTRASENA_INPUT_LABEL,
  VALIDAR_CONTRASENA_INPUT_LINK_TEXTO,
  VALIDAR_CONTRASENA_TEXTO_DESCRIPCION,
  VALIDAR_CONTRASENA_TEXTO_TITULO,
} from 'constants/commonConstants';
import { RESET_PASSWORD_ROUTE } from 'constants/RouterConstants';

import ButtonSolid from 'components/ButtonSolid';
import { isEmpty } from 'lodash';
import styles from './styles.module.css';

const AuthContrasena = ({
  cargando,
  handleVerificarContrasena,
  handleIrAtras,
  contrasenaError,
}) => {
  const [contrasena, setContrasena] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    handleVerificarContrasena(contrasena);
  };

  return (
    <CardSmall
      title={VALIDAR_CONTRASENA_TEXTO_TITULO}
      desc={VALIDAR_CONTRASENA_TEXTO_DESCRIPCION}
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className={styles.contenedorInput}>
            <RenderField
              name="password"
              type="password"
              value={contrasena}
              label={VALIDAR_CONTRASENA_INPUT_LABEL}
              onChange={e => setContrasena(e.target.value)}
              hidePlaceholder
              disabled={cargando}
              error={contrasenaError}
              linkText={VALIDAR_CONTRASENA_INPUT_LINK_TEXTO}
              linkUrl={RESET_PASSWORD_ROUTE}
            />
            {contrasenaError && (
              <span className={styles.textoError}>{contrasenaError}</span>
            )}
          </div>
          <div className={styles.contenedorBotones}>
            <ButtonSolid
              text={MFA_TOTP_CARD_BACK_BUTTON_TEXT}
              ariaLabel={MFA_TOTP_CARD_BACK_BUTTON_TEXT}
              handleClick={handleIrAtras}
              isSecondary
              className={styles.botonSecundario}
            />
            <ButtonSolid
              text={MFA_TOTP_CARD_SUBMIT_BUTTON_TEXT}
              ariaLabel={MFA_TOTP_CARD_SUBMIT_BUTTON_TEXT}
              isLoading={cargando}
              isSubmit
              isDisabled={isEmpty(contrasena)}
            />
          </div>
        </form>
      </div>
    </CardSmall>
  );
};

AuthContrasena.propTypes = {
  cargando: PropTypes.bool,
  handleVerificarContrasena: PropTypes.func,
  handleIrAtras: PropTypes.func,
  contrasenaError: PropTypes.string,
};

export default AuthContrasena;
