import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

// iconos
import basico from 'assets/icons/basico.svg';
import basicoIntermedio from 'assets/icons/basico_intermedio.svg';
import avanzado from 'assets/icons/avanzado.svg';

// constantes
import { MAS_INFORMACION_METODOS } from 'constants/RouterConstants';
import { MAS_INFORMACION_METODOS_DISPONIBLES } from 'constants/commonConstants';

// componentes
import ButtonLink from 'components/ButtonLink';
import Card from 'components/Card';
import BotonVolver from 'components/BotonVolver';

import styles from './paginaEstaticaDetail.module.css';

const obtenerImagen = url => {
  if (url === 'basico') {
    return basico;
  }
  if (url === 'basico_intermedio') {
    return basicoIntermedio;
  }
  if (url === 'avanzado') {
    return avanzado;
  }
  return url;
};

const MarkdownText = ({ skipHtml, contenido }) => (
  <ReactMarkdown
    className={styles.markdown}
    skipHtml={skipHtml}
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeRaw]}
    transformImageUri={obtenerImagen}
  >
    {contenido}
  </ReactMarkdown>
);

MarkdownText.propTypes = {
  skipHtml: PropTypes.bool,
  contenido: PropTypes.string,
};

const PaginaEstatica = ({ pagina, skipHtml }) => (
  <Card>
    <MarkdownText contenido={pagina.contenido} skipHtml={skipHtml} />
  </Card>
);

PaginaEstatica.propTypes = {
  pagina: PropTypes.object,
  skipHtml: PropTypes.bool,
};

const PaginaEstaticaMetodosDisponibles = ({ pagina, skipHtml }) => (
  <div className={styles.metodos_disponibles__contenedor}>
    <BotonVolver />
    <div className={styles.metodos_disponibles__titulo}>
      <h1>{pagina.titulo}</h1>
    </div>
    <div className={styles.metodos_disponibles}>
      <MarkdownText contenido={pagina.contenido} skipHtml={skipHtml} />
    </div>
    <div className={styles.metodos_disponibles__boton}>
      <ButtonLink
        title={MAS_INFORMACION_METODOS_DISPONIBLES}
        to={MAS_INFORMACION_METODOS}
        external
        newTab={false}
      />
    </div>
  </div>
);

PaginaEstaticaMetodosDisponibles.propTypes = {
  pagina: PropTypes.object,
  skipHtml: PropTypes.bool,
};

export { PaginaEstatica, PaginaEstaticaMetodosDisponibles };
