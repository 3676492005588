import {
  API_REQUEST,
  CLIENTS_DETAIL_URL,
  USERCONSENT_BASE_URL,
} from 'constants/ApiConstants';
import { CLIENT_DETAIL, ACCEPT_CONSENT } from 'constants/ActionTypes';

export const fetchClient = (id, scopes) => ({
  type: API_REQUEST,
  payload: {
    url: CLIENTS_DETAIL_URL(id, scopes),
    method: 'get',
    next: CLIENT_DETAIL,
  },
});

export const acceptConsent = data => ({
  type: API_REQUEST,
  payload: {
    url: USERCONSENT_BASE_URL,
    method: 'post',
    data,
    next: ACCEPT_CONSENT,
  },
});
