import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

// Constantes
import { LOGOUT_PROCESS_TITLE } from 'constants/pageTitles';
import { INDEX_ROUTE } from 'constants/RouterConstants';
import { LOGOUT_CERRANDO_SESION } from 'constants/commonConstants';

// Acciones
import { hideFooter, hideHeader } from 'actions/ui';
import { setLogoutRequests } from 'actions/brokerSaml';

// Utils
import { buildRedirectUrlLogoutOidc } from 'utils/utils';
import Auth from 'utils/Auth';

// Componentes
import CardSpinner from 'components/CardSpinner';
import IframeTemplate from 'components/IframeTemplate';

const LogoutProcessContainer = ({
  ocultarFooter,
  ocultarHeader,
  logoutRequests,
  waitLonger,
  cargarLogoutRequests,
}) => {
  const [postLogoutRedirectUri, setPostLogoutRedirectUri] =
    useState(INDEX_ROUTE);
  const [state, setState] = useState(null);

  useEffect(() => {
    ocultarHeader();
    ocultarFooter();
    const parsed = queryString.parse(window.location.search);
    setPostLogoutRedirectUri(parsed.post_logout_redirect_uri || null);
    setState(parsed.state || null);

    const logout = async () => {
      const data = await Auth.getInstance().logout(
        parsed.post_logout_redirect_uri,
        parsed.state,
      );

      if (typeof data === 'object') {
        if (data.redirect) {
          Auth.getInstance().cleanStorage();
          window.location.href = data.redirect;
        } else {
          cargarLogoutRequests(data);
        }
      }
    };
    logout();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (logoutRequests.length > 0) {
      const timer = setTimeout(
        () => {
          Auth.getInstance().cleanStorage();
          const url = buildRedirectUrlLogoutOidc(postLogoutRedirectUri, state);
          const redirectToPage = logoutRequests.find(val =>
            val.toLowerCase().startsWith('redirect='),
          );
          if (redirectToPage) {
            if (postLogoutRedirectUri) {
              const redirectHost = redirectToPage.slice(9).split('?')[0];
              window.location.replace(
                `${redirectHost}?redirect_uri=${postLogoutRedirectUri}`,
              );
            } else {
              window.location.replace(redirectToPage.slice(9));
            }
          } else if (url !== INDEX_ROUTE) {
            window.location.href = url;
          } else {
            window.location.replace(INDEX_ROUTE);
          }
        },
        // 1 seg porque NO espero por respuestas, o 10 seg si envio una solicitud a coesys
        waitLonger ? 10000 : 1000,
      );
      return () => clearTimeout(timer);
    }
  }, [logoutRequests]);

  return (
    <div>
      <Helmet title={LOGOUT_PROCESS_TITLE} />
      <CardSpinner
        spin
        text={LOGOUT_CERRANDO_SESION}
        className="spinner__transparent"
      />
      {logoutRequests
        .filter(url => url.slice(0, 9).toLowerCase() !== 'redirect=')
        .map((url, index) => (
          <IframeTemplate key={url} id={index} src={url} />
        ))}
    </div>
  );
};

LogoutProcessContainer.propTypes = {
  ocultarHeader: PropTypes.func,
  ocultarFooter: PropTypes.func,
  cargarLogoutRequests: PropTypes.func,
  logoutRequests: PropTypes.array,
  waitLonger: PropTypes.bool,
};

const mapStateToProps = state => ({
  logoutRequests: state.brokerSaml.logoutRequests,
  waitLonger: state.brokerSaml.waitLonger,
});

export default connect(mapStateToProps, {
  ocultarFooter: hideFooter,
  ocultarHeader: hideHeader,
  cargarLogoutRequests: setLogoutRequests,
})(LogoutProcessContainer);
