import { ID_URUGUAY_PROVIDER } from 'constants/commonConstants';
// El ciudadano temporal no serializa el user asociado
export const esCiudadanoTemporal = usuario => !Object.hasOwn(usuario, 'user');

export const correoElectronico = usuario =>
  esCiudadanoTemporal(usuario) ? usuario.email : usuario.user.email;

const origenRegistroMap = {
  'Autoregistrado con CI Electrónica': 'tu CI',
};

export const origenRegistro = usuario =>
  esCiudadanoTemporal(usuario)
    ? origenRegistroMap[usuario?.origen_registro.nombre] ||
      usuario?.origen_registro.nombre
    : ID_URUGUAY_PROVIDER;
