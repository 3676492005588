/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Card from 'components/Card';
import ButtonLink from 'components/ButtonLink';
import { AYUDA_ROUTE } from 'constants/RouterConstants';
import { DESCRIPTION_NO_RELATED_QUESTIONS } from 'constants/commonConstants';

import './ayudaItemDetail.css';

const BreadCrumbs = props => (
  <nav>
    <span className="breadcrumbs">
      <ButtonLink
        to={AYUDA_ROUTE}
        aria-label="Volver a todas las preguntas"
        title="Preguntas Frecuentes"
        buttonType="header"
      />
    </span>
    <span className="breadcrumbs" style={{ padding: '0 6px' }}>
      /
    </span>
    <span className="breadcrumbs">{props.categoria}</span>
  </nav>
);

const MarkdownText = props => (
  <div className="faq-detail__container">
    <ReactMarkdown children={props.respuesta} skipHtml />
  </div>
);

const PreguntasRelacionadas = props => {
  const lista = props.faqs.length
    ? props.faqs.map(faq => (
        <span
          className="block m-b-16px"
          style={{ fontSize: '14px' }}
          key={faq.id}
        >
          <ButtonLink
            to={`${AYUDA_ROUTE}/${faq.slug}`}
            title={faq.titulo}
            buttonType="header"
          />
        </span>
      ))
    : null;
  return (
    <div>
      <h4 className="m-t-0 faq-detail__empty">Preguntas relacionadas</h4>
      {lista || (
        <p className="faq-detail__p">{DESCRIPTION_NO_RELATED_QUESTIONS}</p>
      )}
    </div>
  );
};

const AyudaItemDetail = props => (
  <Card>
    <div className="row">
      <section className="col-md-8 col-xs-12">
        <BreadCrumbs categoria={props.faq.categorias.nombre} />
        <h1 className="faq-detail--title">{props.faq.titulo}</h1>
        <MarkdownText respuesta={props.faq.respuesta} />
      </section>
      <aside className="col-md-4 col-xs-12">
        <PreguntasRelacionadas faqs={props.faq.faqs_relacionadas} />
      </aside>
    </div>
  </Card>
);

export default AyudaItemDetail;
