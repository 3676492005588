import React from 'react';
import TagManager from 'react-gtm-module';

import App from 'components/App';

// Google Tag Manager initializer
if (window.REACT_APP_GTM_KEY) {
  const tagManagerArgs = {
    gtmId: window.REACT_APP_GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

// eslint-disable-next-line react/jsx-props-no-spreading
const AppContainer = props => <App {...props} />;

export default AppContainer;
