import React from 'react';

// constantes
import {
  FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL,
  FLUJO_VIDEOLLAMADA_OBTENER_NIVEL_INTERMEDIO,
} from 'constants/commonConstants';
import { SEGURIDAD_ROUTE } from 'constants/RouterConstants';

// componentes
import BotonVolver from 'components/BotonVolver';
import TitleCard from 'components/TitleCard';
import Card from 'components/Card';

// componentes internos
import Agenda from './Agenda';

// estilos
import styles from './ContenedorVideollamada.module.css';

const ContenedorVideollamada = () => {
  const funcionIrAtras = () => {
    window.location.href = SEGURIDAD_ROUTE;
  };

  return (
    <>
      <BotonVolver
        funcionIrAtras={funcionIrAtras}
        texto={FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL}
      />
      <TitleCard
        title={FLUJO_VIDEOLLAMADA_OBTENER_NIVEL_INTERMEDIO}
        className={styles.titulo}
      />
      <Card innerClassName={styles.contenedor}>
        <Agenda />
      </Card>
    </>
  );
};

export default ContenedorVideollamada;
