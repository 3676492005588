/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  MFA_CARD_TITLE,
  MFA_CARD_NO_ACTIVATE_DESC,
  INFO_SEC_MFA_DISABLED_BUTTON,
} from 'constants/commonConstants';
import { SEGURIDAD_ROUTE } from 'constants/RouterConstants';
import { MFA_TITLE } from 'constants/pageTitles';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { TOTPActivate, fetchMFA, deleteTOTP } from 'actions/mfa';
import CardSpinner from 'components/CardSpinner';
import Auth from 'utils/Auth';
import { formatFullDate } from 'utils/utils';
import { MFAWrapper } from './mfaWrapper';

class MultiFactorAuthContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------

  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleShowModalDesactivarTOTP =
      this.handleShowModalDesactivarTOTP.bind(this);
    this.handleDesactivarTOTP = this.handleDesactivarTOTP.bind(this);

    this.state = {
      showModalDesactivarTOTP: false,
    };
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(MFA_TITLE);
    if (Object.keys(this.props.estado).length === 0) {
      this.props.fetchMFA(Auth.getInstance().getUserId());
    }
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  handleBack(e) {
    e.preventDefault();
    this.props.history.replace(SEGURIDAD_ROUTE);
  }

  handleClick(e) {
    e.preventDefault();
    if (!this.props.estado.activado) {
      this.props.TOTPActivate();
    } else {
      this.handleShowModalDesactivarTOTP();
    }
  }

  noScrollBody(shouldNotScroll) {
    if (shouldNotScroll) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  handleShowModalDesactivarTOTP() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const showModalDesactivarTOTP = !this.state.showModalDesactivarTOTP;
    this.setState({ showModalDesactivarTOTP });
    this.noScrollBody(showModalDesactivarTOTP);
  }

  handleDesactivarTOTP() {
    this.props.deleteTOTP(this.props.methods.totp.id);
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    if (this.props.loading) {
      return <CardSpinner spin />;
    }

    const handleClickTitle = this.props.estado.activado
      ? 'Desactivar'
      : INFO_SEC_MFA_DISABLED_BUTTON;
    const fechaActivado = this.props.estado.fecha_activado;
    const desc = fechaActivado
      ? `Activado el ${formatFullDate(fechaActivado)}`
      : MFA_CARD_NO_ACTIVATE_DESC;

    return (
      <>
        <Helmet title={MFA_TITLE} />
        <MFAWrapper
          title={MFA_CARD_TITLE}
          desc={desc}
          handleBack={this.handleBack}
          handleClick={this.handleClick}
          handleClickTitle={handleClickTitle}
          isLoading={this.props.loading}
          totpActivado={this.props.estado.activado}
          handleShowModalDesactivarTOTP={this.handleShowModalDesactivarTOTP}
          showModalDesactivarTOTP={this.state.showModalDesactivarTOTP}
          handleDesactivar={this.handleDesactivarTOTP}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  estado: state.mfa.estado,
  methods: state.mfa.methods,
  loading: state.mfa.loading,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
  TOTPActivate,
  fetchMFA,
  deleteTOTP,
})(MultiFactorAuthContainer);
