import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import Auth from 'utils/Auth';
import * as ROUTES from 'constants/RouterConstants';
import CardSpinner from 'components/CardSpinner';

import usePermissions from 'hooks/usePermissions';
import { fetchMisDatos, fetchMisDatosIdp } from 'actions/misDatos';
import { MIS_DATOS_ESTADO_PENDIENTE } from 'constants/commonConstants';
import {
  cargandoUsuarioLogueadoSelector,
  usuarioLogueadoEstadoSelector,
  usuarioLogueadoSelector,
} from 'store/selectors';
import AutogestionIdp from 'screens/AutogestionIdp/autogestionIdp';
import ConsentContainer from 'screens/consent/ConsentContainer';

const ConPermisosCondicion = ({ children, permisos, condicion }) => {
  const cargando = useSelector(cargandoUsuarioLogueadoSelector);
  const usuarioLogueado = useSelector(usuarioLogueadoSelector);

  const hasPermissions = usePermissions(permisos);

  if (cargando) {
    return <CardSpinner spin className="spinner__transparent" />;
  }

  const cumpleCondicion = condicion ? condicion(usuarioLogueado) : true;

  if (!hasPermissions || !cumpleCondicion) {
    return <Redirect to={ROUTES.UNAVAILABLE_ROUTE} />;
  }

  return children;
};

ConPermisosCondicion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  permisos: PropTypes.array,
  condicion: PropTypes.func,
};

const containersPermitidosIdp = [AutogestionIdp, ConsentContainer];

export const ciudadanoAutenticado = (WrappedComponent, permiso, condicion) => {
  const ComponenteCiudadanoAutenticado = props => {
    const [ciudadanoLogueado, setCiudadanoLogueado] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    const dispatch = useDispatch();

    const cargando = useSelector(cargandoUsuarioLogueadoSelector);
    const estado = useSelector(usuarioLogueadoEstadoSelector);
    const usuarioLogueado = useSelector(usuarioLogueadoSelector);

    const checkAuth = forceLogin => {
      if (Auth.getInstance().isAuthenticated() && !forceLogin) {
        setCiudadanoLogueado(true);
      } else {
        Auth.getInstance().cleanStorage();
        Auth.getInstance()
          .obtainToken()
          .then(() => {
            setCiudadanoLogueado(Auth.getInstance().isAuthenticated());
          })
          .catch(() => {
            setCiudadanoLogueado(false);
          });
      }
    };

    useEffect(() => {
      const parsed = queryString.parse(window.location.search);
      const forceLogin = parsed.forceLogin ? parsed.forceLogin : null;
      setRedirectUrl(parsed.redirectUrl);
      checkAuth(forceLogin);
    }, []);

    if (ciudadanoLogueado === null) {
      return <CardSpinner spin className="spinner__transparent" />;
    }

    if (
      ciudadanoLogueado &&
      !usuarioLogueado &&
      cargando &&
      estado !== MIS_DATOS_ESTADO_PENDIENTE
    ) {
      if (!Auth.getInstance().getEsIDP()) {
        dispatch(fetchMisDatos());
      } else {
        dispatch(fetchMisDatosIdp());
      }

      return <CardSpinner spin className="spinner__transparent" />;
    }

    if (
      !window.REACT_APP_FEATURED_FLAG_HABILITAR_AUTOGESTION_IDPS &&
      ciudadanoLogueado &&
      usuarioLogueado &&
      Auth.getInstance().getEsIDP() &&
      !containersPermitidosIdp.includes(WrappedComponent)
    ) {
      return <Redirect to={ROUTES.AUTOGESTION_IDP} />;
    }

    if (ciudadanoLogueado && (permiso || condicion)) {
      return (
        <ConPermisosCondicion permisos={permiso} condicion={condicion}>
          <WrappedComponent {...props} />
        </ConPermisosCondicion>
      );
    }
    if (ciudadanoLogueado) {
      return <WrappedComponent {...props} />;
    }
    return (
      <Redirect
        to={`${ROUTES.LOGIN_ROUTE}${
          redirectUrl ? `?redirectUrl=${redirectUrl}` : ''
        }`}
      />
    );
  };

  return ComponenteCiudadanoAutenticado;
};
