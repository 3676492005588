import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  HEADER_HOME_ARIA_LABEL,
  HEADER_GUB_UY,
  HEADER_ID_URUGUAY,
} from 'constants/commonConstants';
import { HOME_ROUTE } from 'constants/RouterConstants';
import {
  isAuthenticatedSelector,
  mostrarHeaderSelector,
} from 'store/selectors';
import HeaderMenuInterno from 'components/Header/HeaderMenuInterno';
import HeaderMenuExterno from 'components/Header/HeaderMenuExterno';

import styles from './styles.module.css';

const Header = () => {
  const [menuAbierto, setMenuAbierto] = useState(false);

  const estaAutenticado = useSelector(isAuthenticatedSelector);
  const mostrarHeader = useSelector(mostrarHeaderSelector);

  const handleIrAGubUy = () => {
    window.location.replace(HOME_ROUTE);
  };

  return (
    mostrarHeader && (
      <>
        <div id="overlay"></div>
        <header role="banner">
          <div className={styles.headerWrapper}>
            <div className={styles.headerWrapperIzquierda}>
              <button
                onClick={handleIrAGubUy}
                aria-label={`Ir a ${HEADER_HOME_ARIA_LABEL}`}
                type="button"
              >
                <span
                  className={`${styles.headerWrapperIzquierdaLogo} Brand-text`}
                >
                  {HEADER_GUB_UY}
                </span>
              </button>
              {!estaAutenticado && (
                <>
                  <p className={styles.headerWrapperIzquierdaGuion}>|</p>
                  <p className={styles.headerWrapperIzquierdaTexto}>
                    {HEADER_ID_URUGUAY}
                  </p>
                </>
              )}
            </div>
            <div className={styles.headerWrapperDerecha}>
              {!estaAutenticado ? (
                <HeaderMenuExterno />
              ) : (
                <HeaderMenuInterno
                  menuAbierto={menuAbierto}
                  setMenuAbierto={setMenuAbierto}
                />
              )}
            </div>
          </div>
        </header>
      </>
    )
  );
};

export default Header;
