/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

// utils
import Auth from 'utils/Auth';

// actions
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { updateUserEmail } from 'actions/seguridad';
import { fetchEmailPorValidar } from 'actions/misDatos';

// componentes
import EnvioInstrucciones from 'components/EnvioInstrucciones';
import CardSpinner from 'components/CardSpinner';

// constantes
import { CHANGE_EMAIL_LINK_SENT_TITLE } from 'constants/pageTitles';
import {
  SNACKBAR_TEXTO_CAMBIO_EMAIL,
  YA_CASI_TEXT,
  PARA_CONTINUAR_TEXT,
} from 'constants/commonConstants';

// selectors
import {
  cargandoEmailPorValidarSelector,
  usuarioLogueadoSelector,
  emailPorValidarSelector,
  isAuthenticatedSelector,
} from 'store/selectors';

import Snackbar from 'components/Snackbar';

const CambioEmailSuccess = () => {
  const [mostrarSnackBar, setMostrarSnackBar] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(cargandoEmailPorValidarSelector);
  const currentUser = useSelector(usuarioLogueadoSelector);
  const emailPorValidar = useSelector(emailPorValidarSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if ((!currentUser || !currentUser.email_por_validar) && isAuthenticated) {
      dispatch(fetchEmailPorValidar(Auth.getInstance().getUserId()));
    }
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(CHANGE_EMAIL_LINK_SENT_TITLE));

    return () => {
      dispatch(showInternalHeader());
    };
  }, [
    currentUser,
    isAuthenticated,
    fetchEmailPorValidar,
    showExternalHeader,
    showInternalHeader,
    updateAriaLiveMessage,
  ]);

  const handleResendEmail = e => {
    e.preventDefault();
    dispatch(updateUserEmail(emailPorValidar));
    setMostrarSnackBar(true);
  };

  if (loading) {
    return <CardSpinner spin />;
  }

  return (
    <>
      <Helmet title={CHANGE_EMAIL_LINK_SENT_TITLE} />
      <EnvioInstrucciones
        title={YA_CASI_TEXT}
        desc={PARA_CONTINUAR_TEXT}
        action1={handleResendEmail}
      />
      <Snackbar
        desplegar={mostrarSnackBar}
        mensaje={SNACKBAR_TEXTO_CAMBIO_EMAIL}
        onClose={() => setMostrarSnackBar(false)}
      />
    </>
  );
};

export default CambioEmailSuccess;
