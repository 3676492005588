/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import {
  CAPTCHA_FIELD_NAME,
  EMAIL_FIELD_LABEL,
  EMAIL_FIELD_NAME,
  getMensajeErrorLargoMaximo,
  MAX_LEN_TEXT_AREA,
  REQUERIDO,
  CORREO_INVALIDO,
} from 'constants/commonConstants';
import {
  CaptchaField,
  CheckboxField,
  RadioButtonField,
  RenderField,
  TextField,
} from 'components/RenderField (Nuevo)';
import ButtonSection from 'components/ButtonSection';
import Card from 'components/Card';
import TitleCard from 'components/TitleCard';
import { validEmail } from 'utils/validators';

import './feedback.css';

const Form = props => {
  const {
    envioFeedbackFormError,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    recaptchaRef,
    resetError,
    setFieldValue,
    touched,
    values,
  } = props;

  return (
    <form className="feedback-form" onSubmit={handleSubmit}>
      {/* Pregunta 1 */}
      <div className="feedback-form__section">
        <span className="feedback-form__section--left">
          La información que leí me parece:
        </span>
        <div className="feedback-form__section--right">
          <RadioButtonField
            name="pregunta1"
            value="1"
            label="Muy útil"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta1"
            value="2"
            label="Algo útil"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta1"
            value="3"
            label="Poco útil"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta1"
            value="4"
            label="Nada útil"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
        </div>
      </div>
      {/* Pregunta 2 */}
      <div className="feedback-form__section">
        <span className="feedback-form__section--left">
          El lenguaje utilizado es:
        </span>
        <div className="feedback-form__section--right">
          <RadioButtonField
            name="pregunta2"
            value="1"
            label="Muy claro"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta2"
            value="2"
            label="Algo claro"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta2"
            value="3"
            label="Poco claro"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
          <RadioButtonField
            name="pregunta2"
            value="4"
            label="Nada claro"
            disabled={isSubmitting}
            onChange={handleChange}
            resetFieldError={resetError}
          />
        </div>
      </div>
      {/* Pregunta 3 */}
      <div className="feedback-form__section">
        <span className="feedback-form__section--left">
          Indicá las frases con las que te sentís identificado/a (se puede
          marcar más de una):
        </span>
        <div className="feedback-form__section--right">
          <CheckboxField
            name="pregunta3Checkbox1"
            disabled={isSubmitting}
            label="La forma en que se presenta la información me ayuda a entenderla"
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox1}
          />
          <CheckboxField
            name="pregunta3Checkbox2"
            disabled={isSubmitting}
            label="La información debería poder comprenderse y encontrarse fácilmente"
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox2}
          />
          <CheckboxField
            name="pregunta3Checkbox3"
            disabled={isSubmitting}
            label="No me importa cómo se presenta la información, sino que se comprenda el contenido"
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox3}
          />
          <CheckboxField
            name="pregunta3Checkbox4"
            disabled={isSubmitting}
            label="Me gustaría que el Estado presentara más información de esta manera"
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox4}
          />
          <CheckboxField
            name="pregunta3Checkbox5"
            disabled={isSubmitting}
            label="Me resulta complicado encontrar la información de esta forma"
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox5}
          />
          <CheckboxField
            name="pregunta3Checkbox6"
            disabled={isSubmitting}
            label="Otra..."
            onChange={handleChange}
            resetFieldError={resetError}
            checked={values?.pregunta3Checkbox6}
          />
        </div>
      </div>
      {/* Pregunta 4 */}
      <div className="feedback-form__section">
        <TextField
          name="pregunta4"
          type="text"
          expanded
          label="¿Qué podemos mejorar?"
          onChange={handleChange}
          resetFieldError={resetError}
          hidePlaceholder={false}
          placeholder="Escriba aquí sus comentarios"
          disabled={isSubmitting}
          touched={touched.pregunta4}
          error={
            envioFeedbackFormError !== null &&
            envioFeedbackFormError.field === 'pregunta_4'
              ? envioFeedbackFormError.error
              : errors.pregunta4
          }
        />
      </div>
      {/* Email */}
      <div className="feedback-form__section">
        <RenderField
          name={EMAIL_FIELD_NAME}
          type="text"
          expanded
          label={EMAIL_FIELD_LABEL}
          onChange={handleChange}
          disabled={isSubmitting}
          autocomplete="off"
          error={
            envioFeedbackFormError !== null &&
            envioFeedbackFormError.field === 'email'
              ? envioFeedbackFormError.error
              : errors.email
          }
          resetFieldError={resetError}
          value={values.email}
          touched={touched.email}
        />
      </div>
      {/* ReCAPTCHA */}
      <div className="feedback-form__captcha-container">
        <CaptchaField
          name={CAPTCHA_FIELD_NAME}
          recaptchaRef={recaptchaRef}
          resetFieldError={resetError}
          setFieldValue={setFieldValue}
          error={
            envioFeedbackFormError !== null &&
            envioFeedbackFormError.field === 'captcha_response'
              ? envioFeedbackFormError.error
              : errors.captchaResponse
          }
          touched={touched.captchaResponse}
        />
      </div>
      <div className="feedback-form__error-msg">
        {envioFeedbackFormError !== null &&
          envioFeedbackFormError.field === 'button' &&
          envioFeedbackFormError.error}
      </div>
      <ButtonSection
        textPrimary="Enviar consulta"
        isSubmit
        isLoadingPrimary={isSubmitting}
        ariaLabelPrimary="Enviar formulario de feedback"
      />
    </form>
  );
};

const FeedbackFormFormik = withFormik({
  mapPropsToValues: () => ({
    pregunta1: '0',
    pregunta2: '0',
    pregunta3Checkbox1: false,
    pregunta3Checkbox2: false,
    pregunta3Checkbox3: false,
    pregunta3Checkbox4: false,
    pregunta3Checkbox5: false,
    pregunta3Checkbox6: false,
    pregunta4: '',
    email: '',
    captchaResponse: '',
  }),

  validationSchema: Yup.object().shape({
    pregunta4: Yup.string().max(
      MAX_LEN_TEXT_AREA,
      getMensajeErrorLargoMaximo(MAX_LEN_TEXT_AREA),
    ),
    email: Yup.string().test('validEmail', CORREO_INVALIDO, value =>
      validEmail(value),
    ),
    captchaResponse: Yup.string().required(REQUERIDO),
  }),

  handleSubmit: async (values, bag) => {
    await bag.props.enviarFeedback(values, bag.setFieldValue);
    bag.setSubmitting(false);
  },
})(Form);

const FeedbackForm = ({
  enviarFeedback,
  envioFeedbackFormError,
  recaptchaRef,
  resetError,
  subtitle,
  title,
}) => (
  <Card>
    <TitleCard title={title} />
    <div className="headline feedback__subtitle">{subtitle}</div>
    <FeedbackFormFormik
      envioFeedbackFormError={envioFeedbackFormError}
      enviarFeedback={enviarFeedback}
      resetError={resetError}
      recaptchaRef={recaptchaRef}
    />
  </Card>
);

FeedbackForm.propTypes = {
  enviarFeedback: PropTypes.func,
  envioFeedbackFormError: PropTypes.object,
  recaptchaRef: PropTypes.object,
  resetError: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default FeedbackForm;
