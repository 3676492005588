import {
  SHOW_INTERNAL_HEADER,
  SHOW_EXTERNAL_HEADER,
  SHOW_DIGITAL_SIGNATURE_MODAL,
  HIDE_DIGITAL_SIGNATURE_MODAL,
  SHOW_HEADER,
  HIDE_HEADER,
  SHOW_FOOTER,
  HIDE_FOOTER,
  UPDATE_ARIA_LIVE_MESSAGE,
} from 'constants/ActionTypes';

// ---------------------------------------------------------------------------
// Header
// ---------------------------------------------------------------------------
export const showInternalHeader = () => ({
  type: SHOW_INTERNAL_HEADER,
});

export const showExternalHeader = () => ({
  type: SHOW_EXTERNAL_HEADER,
});

export const showHeader = () => ({
  type: SHOW_HEADER,
});

export const hideHeader = () => ({
  type: HIDE_HEADER,
});

// ---------------------------------------------------------------------------
// Footer
// ---------------------------------------------------------------------------
export const showFooter = () => ({
  type: SHOW_FOOTER,
});

export const hideFooter = () => ({
  type: HIDE_FOOTER,
});

// ---------------------------------------------------------------------------
// Modals
// ---------------------------------------------------------------------------
export const showModalDigitalSignature = () => ({
  type: SHOW_DIGITAL_SIGNATURE_MODAL,
});

export const hideModalDigitalSignature = () => ({
  type: HIDE_DIGITAL_SIGNATURE_MODAL,
});

// ---------------------------------------------------------------------------
// ARIA
// ---------------------------------------------------------------------------
export const updateAriaLiveMessage = text => ({
  type: UPDATE_ARIA_LIVE_MESSAGE,
  payload: {
    text,
  },
});
