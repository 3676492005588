import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import Auth from './Auth';

// ----------------------------------------------------------------------------
// API
// ----------------------------------------------------------------------------
export const axiosAPI = axios.create({
  baseURL: window.REACT_APP_BACKEND_HOST,
});

// ----------------------------------------------------------------------------
// Broker
// ----------------------------------------------------------------------------
export const axiosAuth = axios.create({
  baseURL: window.REACT_APP_BROKER_HOST,
  withCredentials: true,
});

axiosAuth.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        Auth.getInstance().cleanStorage();
      } else if (error.response.status === 302) {
        window.location.replace(error.response.data.location);
      }
    }
    return Promise.reject(error);
  },
);
