/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import ButtonSection from 'components/ButtonSection';
import CardSmall from 'components/CardSmall';

import Auth from 'utils/Auth';
import { LOGIN_ROUTE, SEGURIDAD_ROUTE } from 'constants/RouterConstants';
import { SIGNUP_ACCOUNT_ACTIVATED_TITLE } from 'constants/pageTitles';
import {
  CUENTA_FUE_ACTIVADA,
  BIENVENIDA_GUBUY,
  EMPEZAR,
} from 'constants/commonConstants';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { fetchMisDatos } from 'actions/misDatos';
import { isAuthenticatedSelector } from 'store/selectors';

const RegistroSuccessContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(SIGNUP_ACCOUNT_ACTIVATED_TITLE));
    if (isAuthenticated) {
      dispatch(fetchMisDatos(Auth.getInstance().getUserId()));
    }
  }, []);

  return (
    <>
      <Helmet title={SIGNUP_ACCOUNT_ACTIVATED_TITLE} />
      <CardSmall title={CUENTA_FUE_ACTIVADA} desc={BIENVENIDA_GUBUY}>
        <ButtonSection
          textPrimary={EMPEZAR}
          handleClickPrimary={() => {
            if (isAuthenticated) {
              history.push(SEGURIDAD_ROUTE);
            } else {
              history.push(LOGIN_ROUTE);
            }
          }}
        />
      </CardSmall>
    </>
  );
};

export default RegistroSuccessContainer;
