/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import RequestNewLink from 'components/RequestNewLinkForm';
import { fetchPaises } from 'actions/registro';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { RESET_PASSWORD_SENT_ROUTE } from 'constants/RouterConstants';
import { RESET_PASSWORD_TITLE } from 'constants/pageTitles';
import { requestResetPassword } from 'services/apiServices';
import {
  CAPTCHA_FIELD_NAME,
  CI_CODE,
  UY_CODE,
} from 'constants/commonConstants';
import {
  handleOnChangePaises,
  initPaises,
  renderDocumentosOptions,
  renderPaisesOptions,
} from 'utils/utils';
import { uruguayanCIValidator } from 'utils/validators';

class ResetPasswordContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      paisesOptions: [],
      documentosOptions: [],
      tipo_documento: null,
      pais_emisor: UY_CODE,
      sending: false,
      errores: {
        error: '',
        field: '',
      },
    };

    // Metodos de inicialización para paises.
    this.renderPaisesOptions = renderPaisesOptions.bind(this);
    this.renderDocumentosOptions = renderDocumentosOptions.bind(this);
    this.utilsHandleOnChangePais = handleOnChangePaises.bind(this);
    this.initPaises = initPaises.bind(this);

    this.handleOnChangePais = this.handleOnChangePais.bind(this);
    this.handleResetFieldError = this.handleResetFieldError.bind(this);
    this.handleOnChangeTipoDoc = this.handleOnChangeTipoDoc.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.paises.length !== prevState.paisesOptions.length) {
      return {
        paisesOptions: nextProps.paises.map(pais => ({
          value: pais.codigo,
          label: pais.nombre,
        })),
        pais_emisor: nextProps.paises[0].codigo,
        documentosOptions: nextProps.paises[0].documentos.map(documento => ({
          value: documento.tipo_documento.codigo,
          label: documento.tipo_documento.nombre,
        })),
        tipo_documento:
          nextProps.paises[0].documentos[0].tipo_documento.codigo.toString(),
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.updateAriaLiveMessage(RESET_PASSWORD_TITLE);
    this.props.showExternalHeader();
    this.initPaises();
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  async handleSubmit(values, setFieldValue) {
    if (!this.state.sending) {
      if (
        this.state.pais_emisor === UY_CODE &&
        this.state.tipo_documento === CI_CODE &&
        uruguayanCIValidator(values.numero_documento) !== null
      ) {
        const result = uruguayanCIValidator(values.numero_documento);
        this.setState(prevState => ({
          errores: {
            ...prevState.errores,
            error: result,
            field: 'numero_documento',
          },
        }));
      } else {
        try {
          this.setState({ sending: true });
          const data = {
            doc: values.numero_documento,
            pais_emisor: this.state.pais_emisor,
            tipo_doc: this.state.tipo_documento,
            captcha_response: values.captcha_response,
          };
          const response = await requestResetPassword(data);
          this.props.history.push({
            pathname: RESET_PASSWORD_SENT_ROUTE,
            state: { email: response.data.email },
          });
        } catch (response) {
          if (response.data.next) {
            window.location.href = response.data.next;
          } else {
            this.setState(prevState => ({
              errores: {
                ...prevState.errores,
                error: response.data.error,
                field: response.data.field,
              },
            }));
            if (this.recaptchaRef.current.getValue() !== '') {
              setFieldValue(CAPTCHA_FIELD_NAME, '');
              this.recaptchaRef.current.reset();
            }
          }
        } finally {
          this.setState({ sending: false });
        }
      }
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.props.history.goBack();
  }

  handleOnChangePais = async (e, setFieldValue) => {
    await this.utilsHandleOnChangePais(e, setFieldValue);
  };

  handleOnChangeTipoDoc(e) {
    this.setState({ tipo_documento: e.target.value });
  }

  handleResetFieldError() {
    if (this.state.errores.error) {
      this.setState(prevState => ({
        errores: {
          ...prevState.errores,
          error: '',
          field: '',
        },
      }));
    }
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    if (this.props.loading_paises) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={RESET_PASSWORD_TITLE} />
        <CardSmall
          title="Reestablece tu contraseña"
          desc="Para continuar precisamos algunos datos que utilizaste para crear tu cuenta."
        >
          <RequestNewLink
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            handleNext={this.handleSubmit}
            errores={this.state.errores}
            nextAriaLabel="Solicitar link para restablecer contraseña"
            previousAriaLabel="Volver"
            valuePais={this.state.pais_emisor}
            valueTipoDoc={this.state.tipo_documento}
            resetFieldError={this.handleResetFieldError}
            onChangePais={this.handleOnChangePais}
            onChangeTipoDoc={this.handleOnChangeTipoDoc}
            nextText="Continuar"
            handlePrevious={this.handleBack}
            sending={this.state.sending}
            paisesOptions={this.state.paisesOptions}
            documentosOptions={this.state.documentosOptions}
            recaptchaRef={this.recaptchaRef}
          />
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.mis_datos.currentUser,
  loading_paises: state.registro.loading,
  paises: state.registro.paises,
});

export default connect(mapStateToProps, {
  updateAriaLiveMessage,
  showExternalHeader,
  fetchPaises,
})(ResetPasswordContainer);
