/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import arrowIcon from 'assets/icons/Grey.png';
import arrowIcon2x from 'assets/icons/Grey@2x.png';
import arrowIcon3x from 'assets/icons/Grey@3x.png';

import styles from './buttonOption.css';

const ButtonOption = ({
  text,
  handleClick,
  className = '',
  isLoading = false,
  style = null,
  ariaLabel = '',
}) =>
  isLoading ? (
    <button
      className={`${className} button-option`}
      type="button"
      onClick={null}
      style={style}
      aria-label={ariaLabel || text}
    >
      <div className={`${className} button-option__spinner`}>
        <Spinner name="ball-beat" fadeIn="none" color={styles.blue} />
      </div>
    </button>
  ) : (
    <button
      className={`${className} button-option`}
      type="button"
      onClick={handleClick}
      style={style}
      aria-label={ariaLabel || text}
    >
      <span className="button-option__text">{text}</span>
      <img
        className="button-option__img"
        src={arrowIcon}
        srcSet={`${arrowIcon2x} 2x, ${arrowIcon3x} 3x`}
        alt="Botón de más opciones"
      />
    </button>
  );

ButtonOption.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  isSmall: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonOption;
