import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import misDatosReducer from '../reducers/misDatos';
import seguridadReducer from '../reducers/seguridad';
import registroReducer from '../reducers/registro';
import uiReducer from '../reducers/ui';
import faqsReducer from '../reducers/faqs';
import resetPasswordReducer from '../reducers/resetPassword';
import customRouterReducer from '../reducers/customRouter';
import brokerOidcReducer from '../reducers/brokerOidc';
import brokerSamlReducer from '../reducers/brokerSaml';
import federatedIdentityReducer from '../reducers/federatedIdentity';
import authReducer from '../reducers/authReducer';
import mfaReducer from '../reducers/mfa';
import paginaEstaticaReducer from '../reducers/paginaEstatica';
import apiMiddleware from '../middlewares/ApiMiddleware';

export const REDUX_STATE_KEY = 'reduxState';

const persistedState = () => {
  const existingState = localStorage.getItem(REDUX_STATE_KEY);
  if (existingState) {
    return JSON.parse(existingState);
  }
  return {};
};

export const history = createBrowserHistory();
const middlewares = [apiMiddleware, routerMiddleware(history)];
const reducers = combineReducers({
  auth: authReducer,
  brokerOidc: brokerOidcReducer,
  brokerSaml: brokerSamlReducer,
  customRouter: customRouterReducer,
  faqs: faqsReducer,
  federatedIdentity: federatedIdentityReducer,
  form: reduxFormReducer,
  mfa: mfaReducer,
  mis_datos: misDatosReducer,
  paginaEstatica: paginaEstaticaReducer,
  registro: registroReducer,
  resetPassword: resetPasswordReducer,
  router: connectRouter(history),
  seguridad: seguridadReducer,
  ui: uiReducer,
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
export const store = createStore(
  reducers,
  persistedState(),
  composeEnhancers(applyMiddleware(...middlewares)),
);
/* eslint-enable */

store.subscribe(() => {
  localStorage.setItem(REDUX_STATE_KEY, JSON.stringify(store.getState()));
});
