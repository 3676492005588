import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Box = ({ title, titleClass, children, contentClass }) => (
  <fieldset
    className={contentClass ? `${styles.box} ${contentClass}` : styles.box}
  >
    <legend>
      <h2 className={titleClass}>{title}</h2>
    </legend>
    <div className={styles.box__divider}></div>
    {children}
  </fieldset>
);

Box.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  contentClass: PropTypes.string,
};

export default Box;
