/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import EnvioInstrucciones from 'components/EnvioInstrucciones';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import {
  REGISTRO_REENVIAR_CORREO_ACTIVACION,
  ERROR_ROUTE,
} from 'constants/RouterConstants';
import { SIGNUP_LINK_RESENT_TITLE } from 'constants/pageTitles';

class ResetPasswordSentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.sendAgain = this.sendAgain.bind(this);
  }

  componentDidMount() {
    this.props.showInternalHeader();
    this.props.updateAriaLiveMessage(SIGNUP_LINK_RESENT_TITLE);
    if (this.props.email === '') {
      this.props.history.push(ERROR_ROUTE);
    }
  }

  sendAgain(e) {
    e.preventDefault();
    this.props.history.push(REGISTRO_REENVIAR_CORREO_ACTIVACION);
  }

  render() {
    return (
      <>
        <Helmet title={SIGNUP_LINK_RESENT_TITLE} />
        <EnvioInstrucciones
          title="Ya casi terminás..."
          desc={
            <div>
              Te enviamos las instrucciones para continuar a tu correo{' '}
              <b style={{ letterSpacing: '1px' }}>{this.props.email}</b>.
            </div>
          }
          action1={this.sendAgain}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  email: state.registro.email,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
})(ResetPasswordSentContainer);
