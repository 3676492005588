import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';

import dayjs from 'dayjs';
import 'dayjs/locale/es';

// constantes
import {
  AGENDA_VIDEOLLAMADA,
  CONFIRMA_DATOS,
  NOMBRE_LABEL,
  APELLIDO_LABEL,
  TIPO_DOCUMENTO_LABEL,
  NUMERO_DOCUMENTO_LABEL,
  PAIS_EMISOR_LABEL,
  CORREO_LABEL,
  ALGUN_DATO_INCORRECTO,
  ANCHO_TABLET,
  HORA_LABEL,
  FECHA_LABEL,
  ERROR_CUPOS,
  ERROR_VIDEOLLAMADA_AGENDADA,
  HORA_URUGUAY,
  ERROR_VIDEOLLAMADA_CONSULTA_AGENDADA,
} from 'constants/commonConstants';

// componentes
import TitleCard from 'components/TitleCard';

// componentes internos
import ContextoAgenda from '../ContextoAgenda';

// estilos
import styles from './Confirmacion.module.css';

export const ConfirmacionConsulta = ({
  fechaSeleccionada,
  disponibilidadSeleccionada,
}) => {
  const { errorCupos, errorVideollamadaAgendada } = useContext(ContextoAgenda);

  const fechaReservaFormateada = dayjs(fechaSeleccionada)
    .locale('es')
    .format('D [d]e MMMM [d]e YYYY');
  const horaReservaFormateada = dayjs(disponibilidadSeleccionada?.value)
    .locale('es')
    .format('HH:mm [hs]');

  return (
    <div className={styles.container}>
      <TitleCard title={CONFIRMA_DATOS} className={styles.titulo} />
      <div className={styles.body}>
        <div className={styles.datosAgenda}>
          <TitleCard title={AGENDA_VIDEOLLAMADA} className={styles.titulo} />
          <div className={styles.datosAgendaBody}>
            <div className={styles.alinearDerecha}>
              <p>{FECHA_LABEL}</p>
              <p>{HORA_LABEL}</p>
            </div>
            <div className={styles.alinearIzquierda}>
              <p>{fechaReservaFormateada}</p>
              <p>
                {horaReservaFormateada} {HORA_URUGUAY}
              </p>
            </div>
          </div>
        </div>
        {(errorCupos || errorVideollamadaAgendada) && (
          <div className={styles.error}>
            <p>
              {errorCupos ? ERROR_CUPOS : ERROR_VIDEOLLAMADA_CONSULTA_AGENDADA}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const Confirmacion = ({ usuarioLogueado }) => {
  const [ancho] = useWindowSize();
  const esMovil = ancho < ANCHO_TABLET;
  const {
    fechaSeleccionada,
    disponibilidadSeleccionada,
    errorCupos,
    errorVideollamadaAgendada,
  } = useContext(ContextoAgenda);
  const fechaReservaFormateada = dayjs(fechaSeleccionada)
    .locale('es')
    .format('D [d]e MMMM [d]e YYYY');
  const horaReservaFormateada = dayjs(disponibilidadSeleccionada?.value)
    .locale('es')
    .format('HH:mm [hs]');

  const renderDatosUsuario = () =>
    esMovil ? (
      <div className={styles.datosUsuarioMobile}>
        <div>
          <p className={styles.paragraphTitle}>{NOMBRE_LABEL}</p>
          <p>{usuarioLogueado?.primer_nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{APELLIDO_LABEL}</p>
          <p>{usuarioLogueado?.primer_apellido}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{TIPO_DOCUMENTO_LABEL}</p>
          <p>{usuarioLogueado?.documento?.tipo_documento?.nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{NUMERO_DOCUMENTO_LABEL}</p>
          <p>{usuarioLogueado?.numero_documento}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{PAIS_EMISOR_LABEL}</p>
          <p>{usuarioLogueado?.documento?.pais_emisor?.nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{CORREO_LABEL}</p>
          <p>{usuarioLogueado?.user?.email}</p>
        </div>
      </div>
    ) : (
      <div className={styles.datosUsuario}>
        <div className={styles.alinearDerecha}>
          <p>{NOMBRE_LABEL}</p>
          <p>{APELLIDO_LABEL}</p>
          <p>{TIPO_DOCUMENTO_LABEL}</p>
          <p>{NUMERO_DOCUMENTO_LABEL}</p>
          <p>{PAIS_EMISOR_LABEL}</p>
          <p>{CORREO_LABEL}</p>
        </div>
        <div className={styles.alinearIzquierda}>
          <p>{usuarioLogueado?.primer_nombre}</p>
          <p>{usuarioLogueado?.primer_apellido}</p>
          <p>{usuarioLogueado?.documento?.tipo_documento?.nombre}</p>
          <p>{usuarioLogueado?.numero_documento}</p>
          <p>{usuarioLogueado?.documento?.pais_emisor?.nombre}</p>
          <p>{usuarioLogueado?.user?.email}</p>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <TitleCard title={CONFIRMA_DATOS} className={styles.titulo} />
      <div className={styles.body}>
        <div className={styles.datosAgenda}>
          <TitleCard title={AGENDA_VIDEOLLAMADA} className={styles.titulo} />
          <div className={styles.datosAgendaBody}>
            <div className={styles.alinearDerecha}>
              <p>{FECHA_LABEL}</p>
              <p>{HORA_LABEL}</p>
            </div>
            <div className={styles.alinearIzquierda}>
              <p>{fechaReservaFormateada}</p>
              <p>
                {horaReservaFormateada} {HORA_URUGUAY}
              </p>
            </div>
          </div>
        </div>
        {renderDatosUsuario()}
        <div className={styles.aclaracion}>
          <p>{ALGUN_DATO_INCORRECTO}</p>
        </div>
        {errorCupos ||
          (errorVideollamadaAgendada && (
            <div className={styles.error}>
              <p>{errorCupos ? ERROR_CUPOS : ERROR_VIDEOLLAMADA_AGENDADA}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export const mapStateToProps = state => ({
  usuarioLogueado: state.mis_datos.currentUser,
});

ConfirmacionConsulta.propTypes = {
  fechaSeleccionada: PropTypes.instanceOf(Date),
  disponibilidadSeleccionada: PropTypes.object,
};

Confirmacion.propTypes = {
  usuarioLogueado: PropTypes.object,
};

export default connect(mapStateToProps)(Confirmacion);
