/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import './styles.css';

const ButtonLink = ({
  to,
  title,
  buttonType,
  className,
  external,
  disabled,
  newTab,
  dark,
  ...props
}) =>
  external ? (
    <a
      href={to}
      target={newTab ? '_blank' : '_self'}
      className={`button-link button-link-${buttonType} ${className} ${
        disabled ? 'button-link-disabled' : null
      }`}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {title || props.children}
    </a>
  ) : (
    <NavLink
      to={disabled ? '' : to}
      className={`button-link button-link-${buttonType} ${className} ${
        disabled ? 'button-link-disabled' : null
      } ${dark ? 'button-link-dark' : null}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {title || props.children}
    </NavLink>
  );

ButtonLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  className: PropTypes.string,
  buttonType: PropTypes.oneOf(['default', 'footer', 'header', 'header-dark']),
  external: PropTypes.bool,
  disabled: PropTypes.bool,
  newTab: PropTypes.bool,
  dark: PropTypes.bool,
};

ButtonLink.defaultProps = {
  to: '/',
  buttonType: 'default',
  className: '',
  external: false,
  dark: false,
};

export default ButtonLink;
