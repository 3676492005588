/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSuccess from 'components/CardSuccess';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { CHANGE_PASSWORD_SUCCESS_TITLE } from 'constants/pageTitles';

import * as ROUTES from 'constants/RouterConstants';
import { BACK_SEGURIDAD_BUTTON_TEXT } from 'constants/commonConstants';

class ChangePasswordSuccess extends React.Component {
  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(CHANGE_PASSWORD_SUCCESS_TITLE);
  }

  componentWillUnmount() {
    this.props.showInternalHeader();
  }

  render() {
    let returnUrlAux = null;
    let headerTextAux = null;
    if (this.props.isAuthenticated) {
      returnUrlAux = ROUTES.SEGURIDAD_ROUTE;
      headerTextAux = BACK_SEGURIDAD_BUTTON_TEXT;
    }
    return (
      <>
        <Helmet title={CHANGE_PASSWORD_SUCCESS_TITLE} />
        <CardSuccess
          title="Tu contraseña se modificó"
          description="Dedicá unos instantes a revisar tu casilla de correo y
                       la configuración de seguridad para asegurarte de que los
                       cambios se hayan comunicado y actualizado correctamente."
          returnUrl={returnUrlAux}
          headerText={headerTextAux}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
})(ChangePasswordSuccess);
