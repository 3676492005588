/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

import iconWarning from 'assets/icons/warning.png';
import iconWarning2x from 'assets/icons/warning@2x.png';
import iconWarning3x from 'assets/icons/warning@3x.png';

import windowClose from 'assets/icons/window-close.png';
import windowClose2x from 'assets/icons/window-close@2x.png';
import windowClose3x from 'assets/icons/window-close@3x.png';
import ButtonLink from '../ButtonLink';

const Alert = ({
  type,
  text,
  linkOnClick,
  linkText,
  className,
  handleClose,
  isMFA = false,
  listErrors,
}) => {
  let lista;
  if (listErrors) {
    lista =
      listErrors !== null
        ? listErrors.map(elem => <li key={elem}>{`* ${elem}`}</li>)
        : null;
  }
  return (
    <div
      name="alert"
      className={`alert alert--${type} clearfix ${className}`}
      style={{ width: isMFA ? '100%' : null }}
    >
      {handleClose && (
        // eslint-disable-next-line react/button-has-type
        <button className="alert-close-button" onClick={handleClose}>
          <img
            src={windowClose}
            srcSet={`${windowClose2x} 2x, ${windowClose3x} 3x`}
            alt="botón de cerrar alerta"
          />
        </button>
      )}
      {type === 'warning' ? (
        <div>
          {!isMFA && (
            <div className="alert__icon">
              <img
                src={iconWarning}
                srcSet={`${iconWarning2x} 2x, ${iconWarning3x} 3x`}
                alt="Ícono de alerta"
              />
            </div>
          )}
          <div className="alert__icon__text">
            {text}
            {linkOnClick && (
              <ButtonLink
                type="button"
                onClick={linkOnClick}
                className="alert__action"
                title={linkText}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          {text}
          {linkOnClick && (
            <ButtonLink
              type="button"
              onClick={linkOnClick}
              className="alert__action"
              title={linkText}
            />
          )}
          {listErrors && (
            <div>
              <span>Para poder continuar completa los siguientes datos</span>
              <ul>{lista}</ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkOnClick: PropTypes.func,
  handleClose: PropTypes.func,
  isMFA: PropTypes.bool,
};

export default Alert;
