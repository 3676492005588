/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const TextoMultiformato = ({
  className,
  listaNormal,
  listaNegrita,
  classNameNegrita,
  listaSubrayado,
  listaLink,
}) => {
  // Primero genero los htmls correspondientes a cada tipo de texto
  // listaLink recibe un arreglo de arreglos de la forma [['texto1', 'link1'], ['texto2', 'link2']]
  // Se usa React.Fragment para evitar warning de falta de key en elementos vacios (<></>)
  const htmlListaNormal = listaNormal.map((textoNormal, indice) =>
    textoNormal !== '' ? (
      <React.Fragment key={`normal${indice}`}>{textoNormal}</React.Fragment>
    ) : (
      <React.Fragment key={`normalVacio${indice}`}></React.Fragment>
    ),
  );
  const htmlListaNegrita = listaNegrita.map((textoNegrita, indice) =>
    textoNegrita !== '' ? (
      <b key={`negrita${indice}`} className={classNameNegrita}>
        {textoNegrita}
      </b>
    ) : (
      <React.Fragment key={`negritaVacio${indice}`}></React.Fragment>
    ),
  );
  const htmlListaSubrayado = listaSubrayado.map((textoSubrayado, indice) =>
    textoSubrayado !== '' ? (
      <u key={`subrayado${indice}`}>{textoSubrayado}</u>
    ) : (
      <React.Fragment key={`subrayadoVacio${indice}`}></React.Fragment>
    ),
  );
  const htmlListaLink = listaLink.map((tuplaLink, indice) =>
    tuplaLink.length === 2 ? (
      <a key={`link${indice}`} href={tuplaLink[1]}>
        {tuplaLink[0]}
      </a>
    ) : (
      <React.Fragment key={`linkVacio${indice}`}></React.Fragment>
    ),
  );

  // Aqui se mezclan los arreglos, por lo que primero va el texto normal, luego negrita, luego subrayado y por ultimo link.

  const unirListasIntercaladas = (
    htmlNormal,
    htmlNegrita,
    htmlSubrayado,
    htmlLink,
  ) => {
    const union = [];
    const largoMaximo = Math.max(
      htmlNormal.length,
      htmlNegrita.length,
      htmlSubrayado.length,
      htmlLink.length,
    );
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < largoMaximo; i++) {
      if (htmlNormal[i]) {
        union.push(htmlNormal[i]);
      }
      if (htmlNegrita[i]) {
        union.push(htmlNegrita[i]);
      }
      if (htmlSubrayado[i]) {
        union.push(htmlSubrayado[i]);
      }
      if (htmlLink[i]) {
        union.push(htmlLink[i]);
      }
    }
    return union;
  };
  /*
   Uso:
    listaNormal = ['texto1', '', 'texto5']
    listaNegrita = ['', 'texto3']
    listaSubrayado = []
    listaLink = [['texto2','linkTexto2'], ['texto4', 'linkTexto4']]

    resultado: <p>texto1<a href='linkTexto2'>Texto2</a><b>texto3</b><a href='linkTexto4'>Texto4</a>texto5</p>
  */
  return (
    <p className={className}>
      {unirListasIntercaladas(
        htmlListaNormal,
        htmlListaNegrita,
        htmlListaSubrayado,
        htmlListaLink,
      )}
    </p>
  );
};

TextoMultiformato.propTypes = {
  className: PropTypes.string,
  listaNormal: PropTypes.arrayOf(PropTypes.string),
  listaNegrita: PropTypes.arrayOf(PropTypes.string),
  classNameNegrita: PropTypes.string,
  listaSubrayado: PropTypes.arrayOf(PropTypes.string),
  listaLink: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

TextoMultiformato.defaultProps = {
  className: '',
  listaNormal: [],
  listaNegrita: [],
  classNameNegrita: '',
  listaSubrayado: [],
  listaLink: [],
};

export default TextoMultiformato;
