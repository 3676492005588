import React from 'react';
import SnackbarMui from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

import { SNACKBAR_DURACION } from 'constants/commonConstants';

import styles from './styles.module.css';

const Snackbar = ({ desplegar, mensaje, onClose }) => (
  <SnackbarMui
    className={styles.snackbar}
    open={desplegar}
    message={mensaje}
    autoHideDuration={SNACKBAR_DURACION}
    onClose={onClose}
  />
);

Snackbar.propTypes = {
  desplegar: PropTypes.bool,
  mensaje: PropTypes.string,
  onClose: PropTypes.func,
};

export default Snackbar;
