import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './tablaAcciones.css';

function formatoTimestamp(timestamp) {
  return `${moment(new Date(timestamp)).format('DD/MM/YYYY, HH:mm')} hs`;
}

const TablaAcciones = ({ acciones }) => (
  <section className="acciones">
    {acciones.length > 0 ? (
      <table className="acciones__tabla acciones__tabla__striped acciones__tabla__responsive">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Acción</th>
            <th>Responsable</th>
          </tr>
        </thead>
        <tbody>
          {acciones.map(accion => (
            <tr key={accion.id}>
              <td>{formatoTimestamp(accion.timestamp_access)}</td>
              <td>{accion.descripcion}</td>
              <td>{accion.responsable}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>No hay registros para mostrar</p>
    )}
  </section>
);

TablaAcciones.propTypes = {
  acciones: PropTypes.arrayOf(PropTypes.object),
};

export default TablaAcciones;
