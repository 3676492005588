import React from 'react';
import PropTypes from 'prop-types';

import ReCAPTCHA from 'react-google-recaptcha';

import { REQUERIDO } from 'constants/commonConstants';
import styles from './styles.module.css';

const CaptchaField = ({ recaptchaRef, error, touched, onChange }) => (
  <div className={`${styles['render-field']} ${styles.captcha}`}>
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={window.REACT_APP_RECAPTCHA_KEY}
      onChange={response => onChange(response)}
    />
    {error && touched && (
      <div className={styles['form-field__help-text__error__div']}>
        <span className={styles['render-field__help-text__error']}>
          {REQUERIDO}
        </span>
      </div>
    )}
  </div>
);

CaptchaField.propTypes = {
  recaptchaRef: PropTypes.object,
  error: PropTypes.object,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CaptchaField;
