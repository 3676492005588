import { API_REQUEST, FETCH_FAQS_URL } from 'constants/ApiConstants';
import { FETCH_FAQS } from 'constants/ActionTypes';

const fetchFaqs = () => ({
  type: API_REQUEST,
  payload: {
    url: FETCH_FAQS_URL,
    method: 'get',
    next: FETCH_FAQS,
  },
});

// eslint-disable-next-line no-restricted-exports
export default fetchFaqs;
