import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

import './styles.css';

const ButtonOutline = ({
  text,
  handleClick,
  isSmall,
  className,
  isLoading,
  style = null,
  ariaLabel,
  isDisabled,
  isSecondary,
  // eslint-disable-next-line react/prop-types
  isMFA,
}) =>
  // eslint-disable-next-line no-nested-ternary
  isSecondary ? (
    <button
      className={`${
        isMFA ? 'button-secondary-outline-mfa' : 'button-secondary-outline'
      } ${className}`}
      type="button"
      onClick={handleClick}
      aria-label={ariaLabel || text}
    >
      <span className="button-outline__text">{text}</span>
    </button>
  ) : isLoading ? (
    <button
      className={`${
        isSmall ? 'button-outline--small' : 'button-outline'
      } ${className}`}
      type="button"
      disabled={isDisabled}
      onClick={null}
      style={style}
      aria-label={ariaLabel || text}
    >
      <Spinner name="ball-beat" fadeIn="none" color="white" />
    </button>
  ) : (
    <button
      className={`${
        isSmall ? 'button-outline--small' : 'button-outline'
      } ${className}`}
      type="button"
      disabled={isDisabled}
      onClick={handleClick}
      style={style}
      aria-label={ariaLabel || text}
    >
      <span className="button-outline__text">{text}</span>
    </button>
  );

ButtonOutline.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
  isSecondary: PropTypes.bool,
};

ButtonOutline.defaultProps = {
  isSmall: false,
  className: '',
  isDisabled: false,
  isLoading: false,
  style: null,
  ariaLabel: '',
  isSecondary: false,
};

export default ButtonOutline;
