// eslint-disable-next-line default-param-last
export default function customRouterReducer(state = null, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
      };
    default:
      return state;
  }
}
