import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { history } from 'store/ConfigureStore';
import { buildErrorUrl } from 'utils/utils';
import { isEmpty } from 'lodash';

import useWindowSize from 'hooks/useWindowSize';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// constantes
import {
  AGENDA_VIDEOLLAMADA,
  REVISA_DATOS,
  NOMBRE_LABEL,
  APELLIDO_LABEL,
  TIPO_DOCUMENTO_LABEL,
  NUMERO_DOCUMENTO_LABEL,
  PAIS_EMISOR_LABEL,
  CORREO_LABEL,
  ANCHO_TABLET,
  HORA_LABEL,
  FECHA_LABEL,
  FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL,
  CANCELAR_AGENDA,
  HORA_URUGUAY,
} from 'constants/commonConstants';

// actions
import { fetchMisDatos } from 'actions/misDatos';

// componentes
import TitleCard from 'components/TitleCard';
import Card from 'components/Card';
import BotonVolver from 'components/BotonVolver';
import { Link } from 'react-router-dom';
import ButtonSolid from 'components/ButtonSolid';
import CardSpinner from 'components/CardSpinner';

// rutas
import {
  SEGURIDAD_ROUTE,
  VIDEOLLAMADA_CANCELADA,
} from 'constants/RouterConstants';

// llamadas a la api
import { cancelarReservaVideollamada } from 'services/apiServices';

// estilos
import styles from './ConfirmarCancelacionReserva.module.css';

dayjs.extend(customParseFormat); // Se agrega plugin de dayjs para formatear horas.

const ConfirmarCancelacionReserva = ({ usuarioLogueado }) => {
  const [ancho] = useWindowSize();
  const dispatch = useDispatch();
  const esMovil = ancho < ANCHO_TABLET;
  const [cargando, setCargando] = useState(false);

  const fecha = usuarioLogueado?.info_tramite_videollamada_pendiente?.fecha;

  const hora = dayjs(
    usuarioLogueado?.info_tramite_videollamada_pendiente?.hora_inicio,
    'HH:mm:ss',
  ).format('HH:mm');

  const fechaReservaFormateada = dayjs(fecha)
    .locale('es')
    .format('D [d]e MMMM [d]e YYYY');

  const horaReservaFormateada = `${hora} hs`;

  const renderDatosUsuario = () =>
    esMovil ? (
      <div className={styles.datosUsuarioMobile}>
        <div>
          <p className={styles.paragraphTitle}>{NOMBRE_LABEL}</p>
          <p>{usuarioLogueado?.primer_nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{APELLIDO_LABEL}</p>
          <p>{usuarioLogueado?.primer_apellido}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{TIPO_DOCUMENTO_LABEL}</p>
          <p>{usuarioLogueado?.documento?.tipo_documento?.nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{NUMERO_DOCUMENTO_LABEL}</p>
          <p>{usuarioLogueado?.numero_documento}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{PAIS_EMISOR_LABEL}</p>
          <p>{usuarioLogueado?.documento?.pais_emisor?.nombre}</p>
        </div>
        <div>
          <p className={styles.paragraphTitle}>{CORREO_LABEL}</p>
          <p>{usuarioLogueado?.user?.email}</p>
        </div>
      </div>
    ) : (
      <div className={styles.datosUsuario}>
        <div className={styles.alinearDerecha}>
          <p>{NOMBRE_LABEL}</p>
          <p>{APELLIDO_LABEL}</p>
          <p>{TIPO_DOCUMENTO_LABEL}</p>
          <p>{NUMERO_DOCUMENTO_LABEL}</p>
          <p>{PAIS_EMISOR_LABEL}</p>
          <p>{CORREO_LABEL}</p>
        </div>
        <div className={styles.alinearIzquierda}>
          <p>{usuarioLogueado?.primer_nombre}</p>
          <p>{usuarioLogueado?.primer_apellido}</p>
          <p>{usuarioLogueado?.documento?.tipo_documento?.nombre}</p>
          <p>{usuarioLogueado?.numero_documento}</p>
          <p>{usuarioLogueado?.documento?.pais_emisor?.nombre}</p>
          <p>{usuarioLogueado?.user?.email}</p>
        </div>
      </div>
    );

  const handleClick = async () => {
    const videollamadaInfo =
      usuarioLogueado.info_tramite_videollamada_pendiente;
    setCargando(true);
    try {
      await cancelarReservaVideollamada(videollamadaInfo.id);
      setCargando(false);
      dispatch(fetchMisDatos());
      history.push(VIDEOLLAMADA_CANCELADA);
    } catch {
      setCargando(false);
      history.push(buildErrorUrl('SERVER_ERROR'));
    }
  };

  if (isEmpty(usuarioLogueado) || cargando)
    return (
      <CardSpinner
        spin
        text="Cargando..."
        className="spinner__transparent__videollamada"
      />
    );

  return (
    <>
      <Link to={SEGURIDAD_ROUTE}>
        <BotonVolver texto={FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL} />
      </Link>
      <Card innerClassName={styles.container}>
        <div className={styles.container}>
          <TitleCard title={REVISA_DATOS} className={styles.titulo} />
          <div className={styles.body}>
            <div className={styles.datosAgenda}>
              <TitleCard
                title={AGENDA_VIDEOLLAMADA}
                className={styles.titulo}
              />
              <div className={styles.datosAgendaBody}>
                <div className={styles.alinearDerecha}>
                  <p>{FECHA_LABEL}</p>
                  <p>{HORA_LABEL}</p>
                </div>
                <div className={styles.alinearIzquierda}>
                  <p>{fechaReservaFormateada}</p>
                  <p>
                    {horaReservaFormateada} {HORA_URUGUAY}
                  </p>
                </div>
              </div>
            </div>
            {renderDatosUsuario()}
          </div>
          <ButtonSolid
            text={CANCELAR_AGENDA}
            ariaLabel={CANCELAR_AGENDA}
            handleClick={handleClick}
            className={styles.botonCancelarReserva}
          />
        </div>
      </Card>
    </>
  );
};

export const mapStateToProps = state => ({
  usuarioLogueado: state.mis_datos.currentUser,
});

ConfirmarCancelacionReserva.propTypes = {
  usuarioLogueado: PropTypes.object,
};

export default connect(mapStateToProps)(ConfirmarCancelacionReserva);
