import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import ButtonLink from '../ButtonLink';

const InformacionNivel = ({
  logo,
  texto,
  nivel,
  rutaLink,
  textoLink,
  className,
}) => (
  <div className={`${className} informacion_nivel`}>
    <img
      src={logo}
      className="informacion_nivel__logo"
      alt={`${texto} ${nivel}`}
    />
    <p className="informacion_nivel__texto">
      {texto}
      <b>{nivel}</b>
    </p>
    <ButtonLink
      className="informacion_nivel__link"
      buttonType="header-dark"
      to={rutaLink}
      aria-label={textoLink}
      title={textoLink}
      external
      newTab={false}
    />
  </div>
);

InformacionNivel.propTypes = {
  logo: PropTypes.any,
  texto: PropTypes.string,
  nivel: PropTypes.string,
  rutaLink: PropTypes.string,
  textoLink: PropTypes.string,
  className: PropTypes.string,
};

InformacionNivel.defaultProps = {
  logo: null,
  texto: '',
  nivel: '',
  rutaLink: '',
  textoLink: '',
  className: '',
};

export default InformacionNivel;
