import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  REQUERIDO_VALIDADOR_MENSAJE,
  REQUERIDO,
  CONTRASENA_INVALIDA,
  ONLY_NUMBERS_VALIDATOR_MSG,
  PASSWORD_SEGURA,
  PASSWORD_SIMILARITY_MSG,
  FORTALEZA_PASSWORD,
  TIP_PASSWORD_SEGURA_1,
  TIP_PASSWORD_SEGURA_2,
} from 'constants/commonConstants';

import { handleChangeErrorWidget } from 'utils/utils';

import styles from './styles.module.css';

const showErrors = (error, widgetText) => {
  if (error === REQUERIDO) {
    return (
      <span className={styles['render-field-nuevo__help-text__error']}>
        {REQUERIDO}
      </span>
    );
  }
  if (
    widgetText === ONLY_NUMBERS_VALIDATOR_MSG ||
    widgetText === PASSWORD_SIMILARITY_MSG ||
    widgetText === REQUERIDO_VALIDADOR_MENSAJE
  ) {
    return (
      <span className={styles['render-field-nuevo__help-text__error']}>
        {CONTRASENA_INVALIDA}
      </span>
    );
  }
  return (
    <span className={styles['render-field-nuevo__help-text__error']}>
      {error}
    </span>
  );
};

export const PasswordField = ({
  label,
  value,
  name,
  type,
  onFocus,
  onBlur,
  onChange,
  required,
  tabIndex,
  resetFieldError,
  error,
  touched,
}) => {
  const [mostrarWidget, setMostrarWidget] = useState(false);
  const [mostrarTips, setMostrarTips] = useState(false);
  const [clickEnMostrarTips, setClickEnMostrarTips] = useState(false);
  const passwordInputRef = useRef(null);

  const handleFocus = () => {
    setMostrarWidget(true);
  };

  const handleBlur = () => {
    if (clickEnMostrarTips) {
      passwordInputRef.current.focus();
      setClickEnMostrarTips(false);
      setMostrarWidget(true);
      setMostrarTips(true);
    } else {
      setMostrarTips(false);
      setMostrarWidget(false);
    }
  };

  const handleShowTips = () => {
    setMostrarTips(true);
    setMostrarWidget(true);
    setClickEnMostrarTips(true);
    passwordInputRef.current.focus();
  };

  const handleMouseDownOnTips = e => {
    e.preventDefault();
  };

  const msgs = handleChangeErrorWidget(value, error);
  const barWidth = msgs ? { width: msgs.val } : { width: 0 };

  return (
    <div className="render-field-nuevo password-field-nuevo">
      <span className={styles['render-field-nuevo__label-container']}>
        <label className="alt" htmlFor={name}>
          {`${label}${required ? ' *' : ''}:`}
        </label>
      </span>
      <div className={styles.inputContainer}>
        <input
          placeholder={label}
          type={type}
          value={value}
          tabIndex={tabIndex}
          id={name}
          onFocus={e => {
            if (onFocus) onFocus(e);
            handleFocus();
          }}
          onBlur={e => {
            if (onBlur) onBlur(e);
            handleBlur();
          }}
          onChange={e => {
            if (onChange) onChange(e);
            if (resetFieldError) resetFieldError(e);
          }}
          ref={passwordInputRef}
          className={
            error && touched ? styles['render-field-nuevo__input--error'] : ''
          }
        />
        <div className={styles.passwordWidget}>
          {mostrarWidget && (
            <div className={styles['password-field-nuevo__popup']}>
              <h4 className={styles['password-field-nuevo__popup__title']}>
                {FORTALEZA_PASSWORD}
              </h4>
              <div>
                <div className={styles['password-field__bar-base']}>
                  <div
                    style={barWidth}
                    className={styles[`password-field__bar-${msgs.code}`]}
                  />
                </div>
                <p
                  className={`${styles.caption} password-field__text-${msgs.code}`}
                >
                  {msgs.text}
                </p>
              </div>
              {mostrarTips ? (
                <div className={styles['password-field-nuevo__popup__tips']}>
                  <p className="caption">{TIP_PASSWORD_SEGURA_1}</p>
                  <p className="caption">{TIP_PASSWORD_SEGURA_2}</p>
                </div>
              ) : (
                <button
                  type="button"
                  className={styles['password-field-nuevo__popup__tips__link']}
                  // Need a onMouseDown event in order to execute onClick before onBlur.
                  // Otherwise onBlur gets called first and onClick never happens.
                  onMouseDown={handleMouseDownOnTips}
                  onClick={handleShowTips}
                  tabIndex={-1}
                >
                  {PASSWORD_SEGURA}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles['render-field-nuevo__helper-text-container']}>
        {error && touched && showErrors(error, msgs.text)}
      </div>
    </div>
  );
};

PasswordField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  tabIndex: PropTypes.number,
  resetFieldError: PropTypes.func,
};
