/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSuccess from 'components/CardSuccess';
import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { isAuthenticatedSelector } from 'store/selectors';
import { CHANGE_EMAIL_SUCCESS_TITLE } from 'constants/pageTitles';
import {
  CORREO_CREADO_EXITOSAMENTE,
  REVISA_CORREO_Y_SEGURIDAD,
} from 'constants/commonConstants';

const CambioEmailValidatedContainer = () => {
  const dispatch = useDispatch();
  const estaAutenticado = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(CHANGE_EMAIL_SUCCESS_TITLE));
    return () => {
      if (estaAutenticado) {
        dispatch(showInternalHeader());
      }
    };
  }, []);

  return (
    <>
      <Helmet title={CHANGE_EMAIL_SUCCESS_TITLE} />
      <CardSuccess
        title={CORREO_CREADO_EXITOSAMENTE}
        description={REVISA_CORREO_Y_SEGURIDAD}
      />
    </>
  );
};

export default CambioEmailValidatedContainer;
