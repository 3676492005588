import { FETCH_PAGINA_ESTATICA } from 'constants/ActionTypes';

import { API_REQUEST, FETCH_PAGINA_ESTATICA_URL } from 'constants/ApiConstants';

const fetchPaginaEstatica = slug => ({
  type: API_REQUEST,
  payload: {
    url: FETCH_PAGINA_ESTATICA_URL(slug),
    method: 'get',
    next: FETCH_PAGINA_ESTATICA,
  },
});

export default fetchPaginaEstatica;
