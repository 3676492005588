import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { history } from 'store/ConfigureStore';
import { isEmpty } from 'lodash';

// Servicios
import { iniciarProcesoFirmaDigital } from 'services/apiServices';

// Permisos
import usePermissions from 'hooks/usePermissions';

// Constantes
import {
  INFO_SEC_NIVEL_SEGURIDAD_TITLE,
  NIVEL_SEGURIDAD_BASICO,
  NIVEL_SEGURIDAD_INTERMEDIO,
  INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_ASOCIADO,
  INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_REGISTRAR,
  INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_MODIFICAR,
  INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_NIVEL,
  INFO_SEC_NIVEL_SEGURIDAD_CONCURRIR_PAC,
  INFO_SEC_NIVEL_SEGURIDAD_AGENDAR_VIDEOLLAMADA,
  INFO_SEC_NIVEL_SEGURIDAD_LINK_FIRMADO,
  NIVEL_AUTOREGISTRO_CI,
  NIVEL_AUTOREGISTRADO,
  NIVEL_VERIFICADO,
  NIVEL_CERTIFICADO,
  INFO_SEC_NIVEL_SEGURIDAD_CERTIFICADO,
  INFO_LINK_TEXT,
  textoTieneVideollamada,
  VALIDAR_TEXTO,
  TEXTO_BOTON_INGRESAR_VIDEOLLAMADA,
  TEXTO_BOTON_CANCELAR_AGENDA,
  TEXTO_INFORMATIVO_NIVEL_BASICO,
  TEXTO_INFORMATIVO_NIVEL_INTERMEDIO,
  INFO_ICONO_NIVEL_SEGURIDAD,
  INFO_ICONO_MAS_INFORMACION,
  TEXTO_INFO_VALIDAR_IDENTIDAD,
  TEXTO_VIRTUAL,
  TEXTO_PRESENCIAL,
  TEXTO_INFO_ACTIVAR_SEGUNDO_FACTOR,
  TEXTO_VER_DOCUMENTO_FIRMADO,
  TEXTO_OBTENER_NIVEL_INTERMEDIO,
  TEXTO_CONOCE_MAS,
  TEXTO_LINK_CONOCE_MAS,
  TEXTO_ELEVADO_POR_FIRMA,
  TEXTO_ELEVADO_POR_VIDEOLLAMADA,
  TEXTO_ELEVADO_POR_ELEVACION,
  TEXTO_ELIGE_METODO,
  TEXTO_WARNING_TRAMITE_RECHAZADO,
  ESTADO_RECHAZADO_AGENTE,
  ESTADO_EXITOSO,
  TEXTO_ERROR_INICIAR_PROCESO_FIRMA,
  TEXTO_TITULO_MODAL_INGRESO_VIDEOLLAMADA,
  TEXTO_MODAL_INGRESO_VIDEOLLAMADA,
  ESTADOS_INGRESAR_VIDEOLLAMADA,
  HORA_URUGUAY,
} from 'constants/commonConstants';
import { PERMISO_TRAMITAR_VIDEOLLAMADA } from 'constants/permissionsConstants';
import {
  PUNTOS_ATENCION_CIUDADANIA,
  FAQ_AUMENTAR_GARANTIA_IDENTIDAD,
  AGENDAR_VIDEOLLAMADA,
  CONFIRMACION_CANCELAR_VIDEOLLAMADA,
  CONFIRMAR_ELEVACION_VIDEOLLAMADA,
  METODOS_DISPONIBLES_ROUTE,
  INGRESO_VIDEOLLAMADA,
  MAS_INFORMACION_METODOS,
} from 'constants/RouterConstants';

// Componentes
import ButtonSolid from 'components/ButtonSolid';
import ButtonSection from 'components/ButtonSection';
import TextoMultiformato from 'components/TextoMultiformato';
import ButtonLink from 'components/ButtonLink';
import Box from 'components/Box';
import InfoItem from 'components/InfoItem';
import externalIcon from 'assets/icons/external-url.png';
import externalIcon2x from 'assets/icons/external-url@2x.png';
import externalIcon3x from 'assets/icons/external-url@3x.png';
import iconoNivelBasico from 'assets/icons/basico.svg';
import iconoNivelIntermedio from 'assets/icons/basico_intermedio.svg';
import iconoInfo from 'assets/icons/icono-info.svg';
import Alert from 'components/Alert';
import Snackbar from 'components/Snackbar';
import ModalBox from 'components/Modal';

// utils
import { puedeIngresarVideollamada } from 'utils/utils';

// Estilos
import styles from './nivelSeguridad.module.css';

dayjs.extend(customParseFormat); // Se agrega plugin de dayjs para formatear horas.

const SNACKBAR_INFO_DEFAULT = { mostrar: false, texto: '' };

const NivelSeguridadSection = ({
  currentUser,
  isMobile,
  handleShowModalFirmaElectronica,
  mfaActivado,
  botonesDeshabilitados,
}) => {
  const [cargandoFirma, setCargandoFirma] = useState(false);
  const puedeTramitarVideollamada = usePermissions([
    PERMISO_TRAMITAR_VIDEOLLAMADA,
  ]);
  const [snackBarInfo, setSnackBarInfo] = useState(SNACKBAR_INFO_DEFAULT);
  const [mostrarModal, setMostrarModal] = useState(false);

  const handleProcesoFirma = async () => {
    setCargandoFirma(true);
    try {
      const response = await iniciarProcesoFirmaDigital();
      setCargandoFirma(false);
      if (response?.data) {
        window.location.href = response.data;
      }
    } catch (response) {
      setCargandoFirma(false);
      setSnackBarInfo({
        mostrar: true,
        texto: response?.data || TEXTO_ERROR_INICIAR_PROCESO_FIRMA,
      });
    }
  };

  const getNivelSeguridad = nivel => {
    if (nivel === NIVEL_AUTOREGISTRO_CI || nivel === NIVEL_AUTOREGISTRADO) {
      return NIVEL_SEGURIDAD_BASICO;
    }
    if (nivel === NIVEL_VERIFICADO || nivel === NIVEL_CERTIFICADO) {
      return NIVEL_SEGURIDAD_INTERMEDIO;
    }
    return nivel;
  };

  const nivelSeguridad = getNivelSeguridad(
    currentUser.nivel_de_confianza.value,
  );

  let nivelSeguridadTexto = nivelSeguridad;
  if (nivelSeguridad === NIVEL_SEGURIDAD_INTERMEDIO) {
    if (currentUser.firma_validada) {
      nivelSeguridadTexto += TEXTO_ELEVADO_POR_FIRMA;
    } else if (
      currentUser.info_tramite_videollamada_pendiente?.estado === ESTADO_EXITOSO
    ) {
      nivelSeguridadTexto += TEXTO_ELEVADO_POR_VIDEOLLAMADA;
    } else {
      nivelSeguridadTexto += TEXTO_ELEVADO_POR_ELEVACION;
    }
  }

  const firmaAction = currentUser.firma_validada
    ? INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_MODIFICAR
    : INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_REGISTRAR;
  const firmaActionAriaLabel = currentUser.firma_validada
    ? INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_MODIFICAR
    : INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_REGISTRAR;

  const firmaLinkContrato = currentUser.firma_validada && (
    <ButtonLink
      className={styles.linkFirma}
      to={currentUser.firma_validada}
      external
    >
      {TEXTO_VER_DOCUMENTO_FIRMADO}
      <span className={styles['icono-box']}>
        <img
          className={styles.icono}
          src={externalIcon}
          srcSet={`${externalIcon2x} 2x, ${externalIcon3x} 3x`}
          alt={INFO_SEC_NIVEL_SEGURIDAD_LINK_FIRMADO}
        />
      </span>
    </ButtonLink>
  );

  const nivelSeguridadBasico = (
    <div className={styles.contenedorMetodos}>
      <div className={styles.contenedorLabelDetalle}>
        <div className={styles.labelDetalle}>{TEXTO_ELIGE_METODO}</div>
      </div>
      <div className={styles.cajaBotones}>
        <p className={styles.textoInformativoNivelMetodos}>{TEXTO_VIRTUAL}</p>
        {puedeTramitarVideollamada &&
          currentUser.documento?.pais_emisor?.nombre.toUpperCase() ===
            'URUGUAY' && (
            <Link
              to={AGENDAR_VIDEOLLAMADA}
              tabIndex={-1}
              className={
                botonesDeshabilitados ? styles.linkDisabled : undefined
              }
            >
              <ButtonSolid
                text={INFO_SEC_NIVEL_SEGURIDAD_AGENDAR_VIDEOLLAMADA}
                isSmall
                ariaLabel={firmaActionAriaLabel}
                isDisabled={botonesDeshabilitados}
                className={styles.boton}
              />
            </Link>
          )}
        <ButtonSolid
          text={firmaAction}
          handleClick={
            currentUser.email_validado && !isMobile
              ? handleProcesoFirma
              : handleShowModalFirmaElectronica
          }
          isLoading={cargandoFirma}
          isSmall
          ariaLabel={firmaActionAriaLabel}
          isDisabled={botonesDeshabilitados}
          className={styles.boton}
        />
        <p className={styles.textoInformativoNivelMetodos}>
          {TEXTO_PRESENCIAL}
        </p>
        <ButtonSolid
          text={INFO_SEC_NIVEL_SEGURIDAD_CONCURRIR_PAC}
          isSmall
          ariaLabel={INFO_SEC_NIVEL_SEGURIDAD_CONCURRIR_PAC}
          isDisabled={botonesDeshabilitados}
          className={styles.boton}
          handleClick={() => {
            window.open(PUNTOS_ATENCION_CIUDADANIA, '_blank');
          }}
        />
      </div>
      <div className={styles.masInformacionDetalle}>
        <img
          className={styles.iconoInfoNivelSeguridad}
          src={iconoInfo}
          alt={INFO_ICONO_MAS_INFORMACION}
        />
        {TEXTO_INFO_VALIDAR_IDENTIDAD}
      </div>
    </div>
  );

  const textosMfa = !mfaActivado ? (
    TEXTO_INFO_ACTIVAR_SEGUNDO_FACTOR
  ) : (
    <TextoMultiformato
      className={styles.textoMultiformatoNiveles}
      listaNormal={[TEXTO_CONOCE_MAS]}
      listaLink={[[TEXTO_LINK_CONOCE_MAS, METODOS_DISPONIBLES_ROUTE]]}
    />
  );

  const detallesNivelSeguridadIntermedio = tieneFirmaAsociada => (
    <>
      {tieneFirmaAsociada && (
        <div className={styles.contenedorNivelIntermedio}>
          <InfoItem
            label={INFO_SEC_NIVEL_SEGURIDAD_CERTIFICADO}
            content={
              <div className={styles.contenedorDatosFirmaDigital}>
                <div className={styles.texto_verde}>
                  {INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_ASOCIADO}
                </div>
                {firmaLinkContrato}
                <ButtonLink
                  title={INFO_LINK_TEXT}
                  to={FAQ_AUMENTAR_GARANTIA_IDENTIDAD}
                  className={styles.botonLink}
                />
                <ButtonSolid
                  text={firmaAction}
                  handleClick={
                    currentUser.email_validado && !isMobile
                      ? handleProcesoFirma
                      : handleShowModalFirmaElectronica
                  }
                  isSmall
                  ariaLabel={firmaActionAriaLabel}
                  isDisabled={botonesDeshabilitados}
                  isLoading={cargandoFirma}
                />
              </div>
            }
          />
        </div>
      )}
      <div className={styles.infoNivelSeguridad}>
        <img
          className={styles.iconoInfoNivelSeguridad}
          src={iconoInfo}
          alt={INFO_ICONO_MAS_INFORMACION}
        />
        {textosMfa}
      </div>
    </>
  );

  const videollamadaInfo = currentUser?.info_tramite_videollamada_pendiente;

  const redirectVideollamada = () => {
    if (
      window.REACT_APP_HABILITAR_VIDEOLLAMADA_5_MINUTOS_ANTES &&
      !puedeIngresarVideollamada(videollamadaInfo)
    ) {
      setMostrarModal(true);
      return;
    }
    window.location.href = INGRESO_VIDEOLLAMADA;
  };

  const redirectPaginaElevarse = () => {
    history.push(CONFIRMAR_ELEVACION_VIDEOLLAMADA);
  };

  const redirectCancelarReservaVideollamada = () => {
    history.push(CONFIRMACION_CANCELAR_VIDEOLLAMADA);
  };

  const stylesButtons = { marginLeft: 0, marginTop: 0 };

  const fechaReservaFormateada = dayjs(videollamadaInfo?.fecha)
    .locale('es')
    .format('D/M/YYYY');

  const nivelSeguridadBasicoVideollamada = (
    listaNormal,
    listaNegrita,
    accion,
    accionCancelar = null,
    textoCancelar = null,
  ) => (
    <InfoItem
      label={TEXTO_OBTENER_NIVEL_INTERMEDIO}
      content={
        <div className={styles.videollamadaInfo}>
          <TextoMultiformato
            key="textoTieneVideollamada"
            listaNormal={listaNormal}
            listaNegrita={listaNegrita}
          />
          <div className={styles.contenedorVideollamadaBotones}>
            <ButtonSection
              style={stylesButtons}
              secondClassPrimary={styles.botonesVideollamadaPrimario}
              secondClassSecondary={styles.botonesVideollamadaSecundario}
              handleClickPrimary={accion}
              handleClickSecondary={accionCancelar}
              textPrimary={TEXTO_BOTON_INGRESAR_VIDEOLLAMADA}
              textSecondary={textoCancelar}
            />
          </div>
        </div>
      }
    />
  );

  const detalleAgendado = () => {
    const listaNormalAgendado = ['Tienes una videollamada agendada para el '];

    const listaNegritaAgendado = [
      textoTieneVideollamada(
        fechaReservaFormateada,
        dayjs(videollamadaInfo?.hora_inicio, 'HH:mm:ss').format('HH:mm'),
      ),
      ` ${HORA_URUGUAY}.`,
    ];
    return nivelSeguridadBasicoVideollamada(
      listaNormalAgendado,
      listaNegritaAgendado,
      redirectVideollamada,
      redirectCancelarReservaVideollamada,
      TEXTO_BOTON_CANCELAR_AGENDA,
    );
  };

  const detallePendienteUsuario = () => {
    const listaNormalPendienteUsuario = [
      'Tienes pendiente',
      'los términos de tu solicitud para obtener un nivel de seguridad Intermedio.',
    ];
    const listaNegritaPendienteUsuario = [VALIDAR_TEXTO];
    return nivelSeguridadBasicoVideollamada(
      listaNormalPendienteUsuario,
      listaNegritaPendienteUsuario,
      redirectPaginaElevarse,
    );
  };

  const elevadoPorVideollamada = videollamadaInfo?.estado === ESTADO_EXITOSO;

  const obtenerDetallePorNivel = (nivel, usuarioLogueado) => {
    if (nivel === NIVEL_SEGURIDAD_BASICO) {
      if (puedeTramitarVideollamada && !isEmpty(videollamadaInfo)) {
        if (ESTADOS_INGRESAR_VIDEOLLAMADA.includes(videollamadaInfo?.estado)) {
          return detalleAgendado();
        }
        if (videollamadaInfo?.estado === 'Pendiente usuario') {
          return detallePendienteUsuario();
        }
      }
      return nivelSeguridadBasico;
    }
    return detallesNivelSeguridadIntermedio(usuarioLogueado.firma_validada);
  };

  let textoInformativoNivel = TEXTO_INFORMATIVO_NIVEL_BASICO;
  if (nivelSeguridad === NIVEL_SEGURIDAD_INTERMEDIO) {
    textoInformativoNivel = TEXTO_INFORMATIVO_NIVEL_INTERMEDIO;
  }

  const infoNivelContent = (
    <div className={styles.infoNivelContenedor}>
      <img
        className={styles.iconoNivelSeguridad}
        src={
          nivelSeguridad === NIVEL_SEGURIDAD_BASICO
            ? iconoNivelBasico
            : iconoNivelIntermedio
        }
        alt={INFO_ICONO_NIVEL_SEGURIDAD}
      />
      {nivelSeguridadTexto}
    </div>
  );

  const fechaVideollamada = dayjs(videollamadaInfo?.fecha);

  return (
    <>
      <Box
        title={INFO_SEC_NIVEL_SEGURIDAD_TITLE}
        contentClass={styles.contenedor}
      >
        <div
          className={
            elevadoPorVideollamada
              ? styles.contenedorPrincipalVideollamada
              : styles.contenedorPrincipal
          }
        >
          <div className={styles.contenedorNivelActual}>
            <InfoItem
              label={INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_NIVEL}
              contentClass={styles.textoNivelSeguridad}
              content={infoNivelContent}
            />
            <p className={styles.textoInformativoNivel}>
              {textoInformativoNivel}
            </p>
          </div>
          {obtenerDetallePorNivel(nivelSeguridad, currentUser)}
        </div>
        {videollamadaInfo?.estado === ESTADO_RECHAZADO_AGENTE &&
        puedeTramitarVideollamada &&
        dayjs().isBefore(fechaVideollamada.add(1, 'month')) ? (
          <Alert
            type="warning"
            text={TEXTO_WARNING_TRAMITE_RECHAZADO(
              fechaVideollamada.locale('es').format('dddd DD [de] MMMM'),
              dayjs(videollamadaInfo?.hora_inicio, 'HH:mm:ss').format('HH:mm'),
            )}
            linkText="link."
            linkOnClick={e => {
              e.preventDefault();
              window.location.href = MAS_INFORMACION_METODOS;
            }}
          />
        ) : null}
        <Snackbar
          desplegar={snackBarInfo.mostrar}
          mensaje={snackBarInfo.texto}
          onClose={() => setSnackBarInfo(SNACKBAR_INFO_DEFAULT)}
        />
      </Box>
      <ModalBox
        texto={TEXTO_MODAL_INGRESO_VIDEOLLAMADA(
          fechaVideollamada.locale('es').format('DD/MM/YYYY'),
          dayjs(videollamadaInfo?.hora_inicio, 'HH:mm:ss').format('HH:mm'),
        )}
        titulo={TEXTO_TITULO_MODAL_INGRESO_VIDEOLLAMADA}
        abierto={mostrarModal}
        onClose={() => setMostrarModal(false)}
      />
    </>
  );
};

NivelSeguridadSection.propTypes = {
  currentUser: PropTypes.object,
  isMobile: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  handleShowModalFirmaElectronica: PropTypes.func,
  mfaActivado: PropTypes.bool,
  botonesDeshabilitados: PropTypes.bool,
};

export default NivelSeguridadSection;
