/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ButtonSolid from '../ButtonSolid';
import ButtonOutline from '../ButtonOutline';

import './styles.css';

const ButtonSection = ({
  idPrimary,
  textPrimary,
  handleClickPrimary,
  isLoadingPrimary,
  isSubmit,
  ariaLabelPrimary,
  idSecondary,
  textSecondary,
  handleClickSecondary,
  isLoadingSecondary,
  ariaLabelSecondary,
  style,
  isDisabledPrimary,
  isDisabledSecondary,
  isDangerPrimary,
  isVertical,
  isMFA,
  secondClassPrimary,
  secondClassSecondary,
  className,
}) => (
  <div
    className={`${
      secondClassPrimary || secondClassSecondary
        ? 'button-section__second'
        : `${isVertical ? 'button-section--vertical' : 'button-section'}`
    }${className ? ` ${className}` : ''}`}
    style={style}
  >
    {isVertical
      ? textSecondary && (
          <ButtonOutline
            handleClick={handleClickSecondary}
            className={textPrimary ? 'button-section__secondary__vertical' : ''}
            text={textSecondary}
            isSecondary
          />
        )
      : textSecondary && (
          <ButtonOutline
            id={idSecondary}
            className={
              secondClassSecondary ||
              (textPrimary ? 'button-section__secondary' : '')
            }
            isDisabled={isDisabledSecondary}
            text={textSecondary}
            handleClick={handleClickSecondary}
            isLoading={isLoadingSecondary}
            ariaLabel={ariaLabelSecondary}
            isSecondary={isMFA}
            isMFA={isMFA}
          />
        )}
    {textPrimary && (
      <ButtonSolid
        id={idPrimary}
        className={secondClassPrimary || (isDangerPrimary ? '__danger' : '')}
        isDisabled={isDisabledPrimary}
        text={textPrimary}
        handleClick={handleClickPrimary}
        isLoading={isLoadingPrimary}
        isSubmit={isSubmit}
        ariaLabel={ariaLabelPrimary}
        isMFA={isMFA}
      />
    )}
  </div>
);

ButtonSection.propTypes = {
  idPrimary: PropTypes.string,
  textPrimary: PropTypes.string,
  handleClickPrimary: PropTypes.func,
  isDisabledPrimary: PropTypes.bool,
  isLoadingPrimary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  ariaLabelPrimary: PropTypes.string,
  idSecondary: PropTypes.string,
  textSecondary: PropTypes.string,
  handleClickSecondary: PropTypes.func,
  isDisabledSecondary: PropTypes.bool,
  isLoadingSecondary: PropTypes.bool,
  ariaLabelSecondary: PropTypes.string,
  style: PropTypes.object,
  isDangerPrimary: PropTypes.bool,
  isVertical: PropTypes.bool,
  isMFA: PropTypes.bool,
};

ButtonSection.defaultProps = {
  isLoadingPrimary: false,
  isSubmit: false,
  ariaLabelPrimary: '',
  isDisabledPrimary: false,
  isDisabledSecondary: false,
  isLoadingSecondary: false,
  ariaLabelSecondary: '',
  style: null,
  isVertical: false,
  isMFA: false,
};

export default ButtonSection;
