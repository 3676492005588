import React from 'react';
import ButtonLink from 'components/ButtonLink';
import { NO_RECIBISTE_CORREO } from 'constants/commonConstants';

import { REGISTRO_REENVIAR_CORREO_ACTIVACION } from 'constants/RouterConstants';

import styles from './registro.module.css';

const RegistroFooter = () => (
  <aside className={styles.registro__footer}>
    <div className={styles.registro__footer__divider} />
    <div className={styles.registro__footer__content}>
      <p>{NO_RECIBISTE_CORREO}</p>
      <ButtonLink to={REGISTRO_REENVIAR_CORREO_ACTIVACION}>
        Solicitá uno nuevo »
      </ButtonLink>
    </div>
  </aside>
);

export default RegistroFooter;
