import React from 'react';
import PropTypes from 'prop-types';

import ButtonSolid from 'components/ButtonSolid';

import styles from './errorPage.module.css';

const ErrorPage = ({
  titulo,
  descripcion,
  descripcionSinUrlRegistro,
  descripcionConUrlRegistro,
  urlRegistro,
  textoBoton,
  handleBoton,
  imagen,
  altImagen,
}) => {
  const obtenerDescripcion = () => {
    if (descripcionSinUrlRegistro || descripcionConUrlRegistro) {
      return (
        <div className={styles.error__desc__content__text}>
          {urlRegistro ? descripcionConUrlRegistro : descripcionSinUrlRegistro}
          {urlRegistro && <a href={urlRegistro}>aquí.</a>}
        </div>
      );
    }
    return (
      <div className={styles.error__desc__content__text}>{descripcion}</div>
    );
  };
  return (
    <div className={`row ${styles.error}`}>
      <div className={`col-xs-12 col-md-6 col-md-push-6 ${styles.error__img}`}>
        <img alt={altImagen} src={imagen[0]} />
      </div>
      <div className={`col-xs-12 col-md-6 col-md-pull-6 ${styles.error__desc}`}>
        <div className={styles.error__desc__content}>
          <h1>{titulo}</h1>
          {obtenerDescripcion()}
          {handleBoton ? (
            <ButtonSolid
              className={styles.error__desc__content__btn}
              text={textoBoton}
              handleClick={handleBoton}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
  descripcionSinUrlRegistro: PropTypes.string,
  descripcionConUrlRegistro: PropTypes.string,
  textoBoton: PropTypes.string,
  handleBoton: PropTypes.func,
  imagen: PropTypes.array,
  urlRegistro: PropTypes.string,
  altImagen: PropTypes.string,
};

export default ErrorPage;
