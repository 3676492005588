import _forOwn from 'lodash/forOwn';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import _isPlainObject from 'lodash/isPlainObject';

const traverse = (object, transform) => {
  const structure = Array.isArray(object) ? [] : {};

  _forOwn(object, (value, key) => {
    let newValue = value;
    if (_isPlainObject(value) || Array.isArray(value)) {
      newValue = traverse(value, transform);
    }

    structure[transform(key)] = newValue;
  });

  return structure;
};

const toCamel = object => traverse(object, key => _camelCase(key));

const toSnake = object => traverse(object, key => _snakeCase(key));

const abbreviate = string => string.split(/\s+/).slice(1, 2)[0];

export default {
  toCamel,
  toSnake,
  abbreviate,
};
