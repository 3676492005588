import { FETCH_FAQS } from 'constants/ActionTypes';

const INITIAL_STATE = {
  faqs: {},
  loading: true,
};
// eslint-disable-next-line default-param-last
export default function faqsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FAQS.SUCCESS: {
      return {
        ...state,
        faqs: action.payload.data,
        loading: false,
      };
    }
    case FETCH_FAQS.ERROR:
      return {
        ...state,
        faqs: {},
        loading: false,
      };
    case FETCH_FAQS.PENDING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
