/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { enviarFeedbackForm } from 'services/apiServices';
import {
  EMAIL_FIELD_NAME,
  CAPTCHA_FIELD_NAME,
} from 'constants/commonConstants';
import { FEEDBACK_FORM_TITLE } from 'constants/pageTitles';
import { ENVIO_FEEDBACK_FORM_SUCCESS } from 'constants/RouterConstants';
import FeedbackForm from './feedbackForm';

class FeedbackContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(FEEDBACK_FORM_TITLE);
  }

  isEquivalent(objectA, objectB) {
    const aProps = Object.getOwnPropertyNames(objectA);
    for (let i = 0; i < aProps.length; i += 1) {
      const propName = aProps[i];
      if (
        propName !== CAPTCHA_FIELD_NAME &&
        propName !== EMAIL_FIELD_NAME &&
        objectA[propName] !== objectB[propName]
      ) {
        return false;
      }
    }
    return true;
  }

  enviarFeedback = async (values, setFieldValue) => {
    const estadoInicial = {
      pregunta1: '0',
      pregunta2: '0',
      pregunta3Checkbox1: false,
      pregunta3Checkbox2: false,
      pregunta3Checkbox3: false,
      pregunta3Checkbox4: false,
      pregunta3Checkbox5: false,
      pregunta3Checkbox6: false,
      pregunta4: '',
    };

    if (this.isEquivalent(values, estadoInicial)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          error: 'No se puede enviar el formulario vacío.',
          field: 'button',
        },
      }));
    } else if (!this.state.errors) {
      await enviarFeedbackForm(values)
        .then(() => {
          this.props.history.push(ENVIO_FEEDBACK_FORM_SUCCESS);
        })
        .catch(response => {
          if (response.data.next) {
            window.location.href = response.data.next;
          } else {
            this.setState({ errors: response.data });
            if (this.recaptchaRef.current.getValue() !== '') {
              setFieldValue(CAPTCHA_FIELD_NAME, '');
              this.recaptchaRef.current.reset();
            }
          }
        });
    }
  };

  resetError = () => {
    if (this.state.errors) {
      this.setState({ errors: null });
    }
  };

  render() {
    const title = 'Ayúdenos a mejorar';
    const subtitle =
      'Nos interesa tu opinión sobre la nueva forma de presentar la información en el Estado.';

    return (
      <>
        <Helmet title={FEEDBACK_FORM_TITLE} />
        <FeedbackForm
          title={title}
          subtitle={subtitle}
          enviarFeedback={this.enviarFeedback}
          resetError={this.resetError}
          recaptchaRef={this.recaptchaRef}
          envioFeedbackFormError={this.state.errors}
        />
      </>
    );
  }
}

export default connect(null, {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
})(FeedbackContainer);
