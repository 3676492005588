/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CardSmall from 'components/CardSmall';
import CardSpinner from 'components/CardSpinner';
import RequestNewLink from 'components/RequestNewLinkForm';
import { reenvioEmailActivacion, fetchPaises } from 'actions/registro';
import { updateAriaLiveMessage, showExternalHeader } from 'actions/ui';
import { REGISTRO_RESENDED_ACTIVATION_EMAIL } from 'constants/RouterConstants';
import { SIGNUP_RESEND_LINK_TITLE } from 'constants/pageTitles';
import { UY_CODE, CI_CODE } from 'constants/commonConstants';
import {
  handleOnChangePaises,
  initPaises,
  renderDocumentosOptions,
  renderPaisesOptions,
} from 'utils/utils';
import { uruguayanCIValidator } from 'utils/validators';

class RegistroResendMailContainer extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      paisesOptions: [],
      documentosOptions: [],
      tipo_documento: null,
      pais_emisor: UY_CODE,
      errores: {
        error: '',
        field: '',
      },
    };

    // Metodos de inicialización para paises.
    this.renderPaisesOptions = renderPaisesOptions.bind(this);
    this.renderDocumentosOptions = renderDocumentosOptions.bind(this);
    this.utilsHandleOnChangePais = handleOnChangePaises.bind(this);
    this.initPaises = initPaises.bind(this);

    this.handleOnChangePais = this.handleOnChangePais.bind(this);
    this.handleResetFieldError = this.handleResetFieldError.bind(this);
    this.handleOnChangeTipoDoc = this.handleOnChangeTipoDoc.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.resended) {
      this.props.history.push(REGISTRO_RESENDED_ACTIVATION_EMAIL);
    }
    if (nextProps.paises.length !== this.props.paises.length) {
      this.renderPaisesOptions(nextProps.paises);
    }
    if (nextProps.resendErrors !== null) {
      this.setState(prevState => ({
        errores: {
          ...prevState.errores,
          error: nextProps.resendErrors.error,
          field: nextProps.resendErrors.field,
        },
      }));
    }
  }

  componentDidMount() {
    this.props.updateAriaLiveMessage(SIGNUP_RESEND_LINK_TITLE);
    this.props.showExternalHeader();
    this.initPaises();
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  async handleSubmit(values, setFieldValue) {
    if (!this.props.sending) {
      if (
        this.state.pais_emisor === UY_CODE &&
        this.state.tipo_documento === CI_CODE &&
        uruguayanCIValidator(values.numero_documento) !== null
      ) {
        const result = uruguayanCIValidator(values.numero_documento);
        if (result) {
          this.setState(prevState => ({
            errores: {
              ...prevState.errores,
              error: result,
              field: 'numero_documento',
            },
          }));
        }
      } else {
        await this.props.reenvioEmailActivacion(
          values.numero_documento,
          this.state.pais_emisor,
          this.state.tipo_documento,
          values.captcha_response,
        );
      }
      if (!this.props.resended && this.recaptchaRef.current.getValue() !== '') {
        setFieldValue('captcha_response', '');
        this.recaptchaRef.current.reset();
      }
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.props.history.goBack();
  }

  handleOnChangePais = async (e, setFieldValue) => {
    await this.utilsHandleOnChangePais(e, setFieldValue);
  };

  handleOnChangeTipoDoc(e) {
    this.setState({ tipo_documento: e.target.value });
  }

  handleResetFieldError() {
    if (this.state.errores.error) {
      this.setState(prevState => ({
        errores: {
          ...prevState.errores,
          error: '',
          field: '',
        },
      }));
    }
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  render() {
    if (this.props.loading_paises) {
      return <CardSpinner spin />;
    }
    return (
      <>
        <Helmet title={SIGNUP_RESEND_LINK_TITLE} />
        <CardSmall
          title="Reenviar correo electrónico de activación de cuenta"
          desc="Para continuar precisamos algunos datos que utilizaste para crear tu cuenta."
        >
          <RequestNewLink
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            handleNext={this.handleSubmit}
            errores={this.state.errores}
            requestFormError={this.props.resendErrors}
            nextAriaLabel="Reenviar correo electrónico de activación de cuenta"
            previousAriaLabel="Volver"
            valuePais={this.state.pais_emisor}
            valueTipoDoc={this.state.tipo_documento}
            resetFieldError={this.handleResetFieldError}
            onChangePais={this.handleOnChangePais}
            onChangeTipoDoc={this.handleOnChangeTipoDoc}
            nextText="Reenviar"
            handlePrevious={this.handleBack}
            sending={this.props.sending}
            paisesOptions={this.state.paisesOptions}
            documentosOptions={this.state.documentosOptions}
            recaptchaRef={this.recaptchaRef}
          />
        </CardSmall>
      </>
    );
  }
}

const mapStateToProps = state => ({
  sending: state.registro.sending,
  resended: state.registro.resended,
  loading_paises: state.registro.loading,
  resendErrors: state.registro.resendErrors,
  paises: state.registro.paises,
});

export default connect(mapStateToProps, {
  reenvioEmailActivacion,
  updateAriaLiveMessage,
  showExternalHeader,
  fetchPaises,
})(RegistroResendMailContainer);
