import Auth from 'utils/Auth';
import { LOGIN_BASE_URL, CREATE_CONSENT_URL } from 'constants/ApiConstants';
import { axiosAuth } from './axiosBackend';

let instance = null;

export default class OIDCService {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor() {
    if (!instance) {
      this.loginURL = LOGIN_BASE_URL;
      this.consentURL = CREATE_CONSENT_URL;

      this.signIn = this.signIn.bind(this);
      this.handleConsent = this.handleConsent.bind(this);
      instance = this;
    }
    // eslint-disable-next-line no-constructor-return
    return instance;
  }

  static getInstance() {
    if (instance === null) {
      instance = new OIDCService();
    }
    return instance;
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------

  /*
  SignIn con credenciales de Ciudadano.
  @returns {String} redirect_url
  */
  async signIn(challenge, dataChallenge, extra, token) {
    const data = {
      challenge,
      data_challenge: dataChallenge,
      extra,
      token,
    };

    return axiosAuth({
      method: 'post',
      url: this.loginURL,
      data,
    })
      .then(resp => {
        // Si location es porque la autenticación fue exitosa.
        // En caso contrario, el broker seguirá enviando challenges.
        if (resp.data.location) {
          window.location.replace(resp.data.location);
        }
        return resp.data;
      })
      .catch(err => {
        throw err.response;
      });
  }

  /*
  Maneja el consentimiento del ciudadano en cuanto a la app y los scopes dados.
  @returns {String} redirect_url
  */
  async handleConsent(data) {
    const headers = Auth.getInstance().getAuthToken()
      ? { Authorization: `Bearer ${Auth.getInstance().getAuthToken()}` }
      : {};
    axiosAuth({
      method: 'post',
      url: this.consentURL,
      data,
      headers,
    })
      .then(resp => {
        window.location.replace(resp.data.location);
      })
      .catch(err => {
        window.location.replace(err.response.data.location);
        throw err;
      });
  }
}
