import React from 'react';
import PropTypes from 'prop-types';
import Step from '../Step';
import Line from './line';

import styles from './styles.module.css';

// eslint-disable-next-line react/prop-types
const Stepper = ({ steps, currentStep, handleClick }) => (
  <section className={styles.main_container}>
    {steps.map(({ id, stepText }, indexText) => (
      <div className={styles.step_vector_container} key={id}>
        <Step
          text={stepText}
          number={id}
          isCurrentStep={currentStep === id}
          handleClick={handleClick}
        />
        {indexText < steps.length - 1 && <Line />}
      </div>
    ))}
  </section>
);

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
};

export default Stepper;
