import { FETCH_IDPS } from 'constants/ActionTypes';

const INITIAL_STATE = {
  loading: true,
  infoPorValidar: null,
  idps: null,
  ci_allowed: false,
  gub_uy_allowed: false,
  next: null,
  dataSAML: null,
  passwordChangeErrors: null,
  checkboxesErrors: null,
};

export default function federatedIdentityReducer(
  // eslint-disable-next-line default-param-last
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
    case FETCH_IDPS.SUCCESS:
      return {
        ...state,
        idps: action.payload.data.idps,
        ci_allowed: action.payload.data.ci_allowed,
        gub_uy_allowed: action.payload.data.gub_uy_allowed,
        loading: false,
      };
    case FETCH_IDPS.PENDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IDPS.ERROR:
      return {
        ...state,
        idps: [],
        ci_allowed: false,
        gub_uy_allowed: false,
        loading: false,
      };
    default:
      return state;
  }
}
