import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

// react-phone-input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

// constants
import {
  VERIFY_PASSWORD_ROUTE,
  SEGURIDAD_ROUTE,
} from 'constants/RouterConstants';
import { CHANGE_EMAIL_TITLE } from 'constants/pageTitles';
import {
  MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO,
  MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL,
  MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO,
  MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL,
  MODIFICAR_NUMERO_TELEFONO_DESCRIPCION,
  MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO,
  MODIFICAR_NUMERO_TELEFONO_TITULO,
  TEXTO_NUMERO_TELEFONO_AVISO,
  TEXTO_PLACEHOLDER_NUMERO_TELEFONO,
} from 'constants/commonConstants';

// actions
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { fetchMisDatos } from 'actions/misDatos';

// selectors
import {
  usuarioLogueadoSelector,
  verifiedPasswordSelector,
} from 'store/selectors';

// api services
import { cambiarNumeroTelefono } from 'services/apiServices';

// componentes
import CardSmall from 'components/CardSmall';
import ButtonSolid from 'components/ButtonSolid';
import CardSuccess from 'components/CardSuccess';

// styles
import styles from './CambioNumeroTelefonoContainer.module.css';

const CambioNumeroTelefonoContainer = () => {
  const verifiedPassword = useSelector(verifiedPasswordSelector);
  const usuarioLogueado = useSelector(usuarioLogueadoSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [cargando, setCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);

  const [pantallaExito, setPantallaExito] = useState(false);

  useEffect(() => {
    // Si necesita authForPasswordChange se hace un redirect a un metodo de auth.
    // Por defecto la contraseña.
    if (!verifiedPassword) {
      history.replace({
        pathname: VERIFY_PASSWORD_ROUTE,
        state: {
          prevPath: location.pathname,
          basePath: SEGURIDAD_ROUTE,
        },
      });
    } else {
      showExternalHeader();
      updateAriaLiveMessage(CHANGE_EMAIL_TITLE);
    }
  }, []);

  const { watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      numero_telefono: usuarioLogueado?.numero_telefono,
    },
    mode: 'onChange',
  });

  const numeroTelefono = watch('numero_telefono');

  const handleBotonPrincipal = async () => {
    setCargando(true);
    try {
      const data = { numero_telefono: numeroTelefono };
      await cambiarNumeroTelefono(data, usuarioLogueado.id);
      dispatch(fetchMisDatos());
      setPantallaExito(true);
    } catch {
      setMostrarError(true);
    } finally {
      setCargando(false);
    }
  };

  const handleOnFocusNumeroTelefono = valor => {
    if (isEmpty(numeroTelefono) && valor.key !== 'Backspace') {
      setValue('numero_telefono', '+598');
    }
  };

  const handleOnBlurNumeroTelefono = () => {
    if (numeroTelefono.length <= 4) {
      setValue('numero_telefono', '');
    }
  };

  if (!pantallaExito) {
    return (
      <CardSmall
        title={MODIFICAR_NUMERO_TELEFONO_TITULO}
        desc={MODIFICAR_NUMERO_TELEFONO_DESCRIPCION}
      >
        <form onSubmit={handleSubmit(handleBotonPrincipal)}>
          <div className={styles.contenedorNumeroTelefono}>
            <PhoneInput
              placeholder={TEXTO_PLACEHOLDER_NUMERO_TELEFONO}
              inputClass={styles.inputNumeroTelefono}
              country="uy"
              value={numeroTelefono}
              onChange={valor => setValue('numero_telefono', valor)}
              localization={es}
              enableLongNumbers
              disableCountryCode={isEmpty(numeroTelefono)}
              onFocus={handleOnFocusNumeroTelefono}
              onBlur={handleOnBlurNumeroTelefono}
            />
            <p className={styles.help_text}>{TEXTO_NUMERO_TELEFONO_AVISO}</p>
          </div>
          <div className={styles.contenedorBotones}>
            <ButtonSolid
              text={MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO}
              ariaLabel={MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL}
              handleClick={() => history.push(SEGURIDAD_ROUTE)}
              className={styles.botonSecundario}
            />
            <ButtonSolid
              text={MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO}
              ariaLabel={MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL}
              isLoading={cargando}
              isSubmit
              isDisabled={numeroTelefono?.length <= 4}
            />
          </div>
          {mostrarError && (
            <p className={styles.textoError}>
              {MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR}
            </p>
          )}
        </form>
      </CardSmall>
    );
  }
  return (
    <CardSuccess
      title={MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO}
      description={MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO}
      returnUrl={SEGURIDAD_ROUTE}
      headerText={MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER}
    />
  );
};

export default CambioNumeroTelefonoContainer;
