import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

import useWindowSize from 'hooks/useWindowSize';
import {
  ANCHO_TABLET,
  HEADER_LOGOUT_ARIA_LABEL,
  HEADER_LOGOUT_TEXT,
  HEADER_MENU_ALT_TEXT,
  HEADER_SEGURIDAD_ARIA_LABEL,
  HEADER_SEGURIDAD_SCREEN_TEXT,
} from 'constants/commonConstants';
import { useSelector } from 'react-redux';
import { usuarioLogueadoSelector } from 'store/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGOUT_PROCESS, SEGURIDAD_ROUTE } from 'constants/RouterConstants';
import iconoAbrir from '../../../assets/icons/menu-down.png';

import styles from './styles.module.css';

const HeaderMenuInterno = ({ menuAbierto, setMenuAbierto }) => {
  const [ancho] = useWindowSize();
  const esMovil = ancho < ANCHO_TABLET;

  const anchorEl = useRef();

  const location = useLocation();

  const usuarioLogueado = useSelector(usuarioLogueadoSelector);

  const history = useHistory();

  const menuOrigen = {
    vertical: 'top',
    horizontal: esMovil ? 'left' : 'right',
  };

  useEffect(() => {
    if (esMovil) {
      const overlay = document.getElementById('overlay');
      if (menuAbierto) {
        overlay.className = 'overlay--visible';
      } else {
        overlay.className = 'overlay--hidden';
      }
    }
  }, [menuAbierto]);

  const handleIrALogout = () => {
    history.push(LOGOUT_PROCESS);
  };
  const handleIrASeguridad = () => {
    history.push(SEGURIDAD_ROUTE);
  };

  const username = usuarioLogueado?.nombre_completo || '';
  const nivel = usuarioLogueado?.nivel;

  const textoNivel = nivel ? `- ${nivel}` : '';

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setMenuAbierto(true);
        }}
        className={styles.boton}
        ref={anchorEl}
      >
        {`${username} ${!esMovil ? textoNivel : ''}`}
        <img src={iconoAbrir} alt={HEADER_MENU_ALT_TEXT} />
      </button>
      <Menu
        className={styles.menuItem}
        transitionDuration={200}
        id="menu"
        anchorOrigin={menuOrigen}
        anchorEl={() => anchorEl.current}
        keepMounted
        open={menuAbierto}
        onClose={() => setMenuAbierto(false)}
      >
        {esMovil && (
          <div className={styles.headerInternoRoute}>
            <MenuItem disabled>{nivel}</MenuItem>
          </div>
        )}
        {location.pathname !== SEGURIDAD_ROUTE && (
          <div className={styles.headerInternoRoute}>
            <MenuItem
              aria-label={HEADER_SEGURIDAD_ARIA_LABEL}
              onClick={handleIrASeguridad}
            >
              {HEADER_SEGURIDAD_SCREEN_TEXT}
            </MenuItem>
          </div>
        )}
        <MenuItem
          aria-label={HEADER_LOGOUT_ARIA_LABEL}
          onClick={handleIrALogout}
        >
          {HEADER_LOGOUT_TEXT}
        </MenuItem>
      </Menu>
    </>
  );
};

HeaderMenuInterno.propTypes = {
  menuAbierto: PropTypes.bool,
  setMenuAbierto: PropTypes.func,
};

export default HeaderMenuInterno;
