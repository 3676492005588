import jwtDecode from 'jwt-decode';

// eslint-disable-next-line import/no-cycle
import { INDEX_ROUTE, LOGOUT_PROCESS } from 'constants/RouterConstants';
import {
  REFRESH_TOKEN_URL,
  REFRESH_INTERVAL,
  OBTAIN_TOKEN_URL,
} from 'constants/ApiConstants';
// eslint-disable-next-line import/no-cycle
import { axiosAuth } from './axiosBackend';
import { buildUrlLogoutOidc } from './utils';

let instance = null;

export default class Auth {
  constructor() {
    if (!instance) {
      this.getAuthToken = this.getAuthToken.bind(this);
      this.setAuthToken = this.setAuthToken.bind(this);

      this.isAuthenticated = this.isAuthenticated.bind(this);
      this.isTokenExpired = this.isTokenExpired.bind(this);
      this.getUserId = this.getUserId.bind(this);
      this.getEsIDP = this.getEsIDP.bind(this);
      this.storeToken = this.storeToken.bind(this);
      this.decodeToken = this.decodeToken.bind(this);
      this.obtainToken = this.obtainToken.bind(this);

      this.logout = this.logout.bind(this);
      this.refreshToken = this.refreshToken.bind(this);
      this.refresherAction = this.refresherAction.bind(this);

      this.id = Math.random();
      this.storage = window.localStorage;
      this.refresher = null;
      instance = this;
    }
    // eslint-disable-next-line no-constructor-return
    return instance;
  }

  static getInstance() {
    if (instance === null) {
      instance = new Auth();
    }
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
    return instance;
  }

  /*
   Retorna el JWT para acceso a recursos del Backend.
   @returns {String} JWT.
  */
  getAuthToken() {
    return this.storage.getItem('token');
  }

  /*
   Almacena el token pasado como parámetro en el brwoser.
   @returns {String} token.
  */
  setAuthToken(newToken) {
    this.storage.setItem('token', newToken);
    return newToken;
  }

  /*
   Indica si hay un ciudadano logueado.
   @returns {Boolean} Retorna True si hay un ciudadano loagueado, false en otro caso.
  */
  isAuthenticated() {
    const res = !this.isTokenExpired();
    if (!res) {
      this.cleanStorage();
    }
    return res;
  }

  /*
   Retorna True si el token almacenado en el browser expiró.
   @returns {Boolean}
  */
  isTokenExpired() {
    const decoded = this.decodeToken();
    if (!decoded || typeof decoded.exp === 'undefined') {
      return true;
    }
    const d = new Date(0);
    d.setUTCSeconds(decoded.exp);
    if (d === null) {
      return true;
    }
    return new Date().valueOf() > d.valueOf();
  }

  /*
   Parsea el JWT almacenado en el browser y retorna el userID.
   @returns {Integer} ID.
  */
  getUserId() {
    const decoded = this.decodeToken();
    return decoded ? decoded.cid : null;
  }

  /*
   Parsea el JWT almacenado en el browser y retorna si corresponde a un usuario de idp.
   @returns {Boolean}.
  */
  getEsIDP() {
    const decoded = this.decodeToken();
    return decoded?.idp;
  }

  /*
   Decodifica el JWT almacenado.
   No se valida firma.
   @returns {Object} Token decodificado.
  */
  decodeToken() {
    const token = this.getAuthToken();
    let decoded;
    try {
      decoded = jwtDecode(token);
    } catch (err) {
      decoded = null;
    }
    return decoded;
  }

  /*
   Obtiene y almacena un JWT con nueva fecha de vencimiento a partir
   del token actual.
   @returns {String} JWT.
  */
  refreshToken() {
    axiosAuth({
      method: 'post',
      url: REFRESH_TOKEN_URL,
      data: { token: this.getAuthToken() },
    })
      .then(response => {
        this.setAuthToken(response.data.token);
      })
      .catch(() => {
        clearInterval(this.refresher);
        this.storage.clear();
        window.location.replace(LOGOUT_PROCESS);
      });
  }

  /*
   Obtiene y almacena un JWT con nueva fecha de vencimiento a partir
   de la sesión existente con el Broker de autenticación.
   @returns {String} JWT.
  */
  obtainToken() {
    return new Promise((resolve, reject) => {
      axiosAuth({
        method: 'get',
        url: OBTAIN_TOKEN_URL,
      })
        .then(response => {
          this.setAuthToken(response.data.token);
          resolve();
        })
        .catch(err => reject(err));
    });
  }

  /*
   Retorna el JWT para acceso a recursos del Backend.
   @returns {String} JWT.
  */
  async logout(redirectUri, state) {
    const url = await buildUrlLogoutOidc(redirectUri, state);
    const headers = { Authorization: `Bearer ${this.getAuthToken()}` };
    const result = await axiosAuth({
      method: 'get',
      url,
      headers,
    })
      .then(response => {
        if (response) {
          return response.data;
        }
        return false;
      })
      .catch(() => false);

    if (typeof result !== 'boolean') {
      return result;
    }
    this.cleanStorage();
    clearInterval(this.refresher);
    window.location.replace(INDEX_ROUTE);
    return true;
  }

  /*
   Extracts token from URL and stores it in localStorage.
   If Google Analytics was configured, an event is dispatched.
   @returns {Integer} ID.
  */
  storeToken(token) {
    this.setAuthToken(token);
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
  }

  /*
  Elimina el token del storage.
  */
  cleanStorage() {
    this.storage.removeItem('token');
    this.storage.removeItem('reduxState');
  }

  /*
  Método ejecutado por refresher
  */
  refresherAction() {
    if (this.isAuthenticated()) {
      this.refreshToken();
    }
  }

  startRefresher() {
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
  }
}

Auth.getInstance().startRefresher();
