import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  CONTACTO_ROUTE,
  AYUDA_ROUTE,
  ACERCA_DE_ROUTE,
  TERMS_N_CONDS_ROUTE,
  PRIVACIDAD_ROUTE,
} from 'constants/RouterConstants';
import {
  USUARIO_GUB_UY,
  HEADER_ID_URUGUAY,
  SITIO_OFICIAL_TEXTO,
} from 'constants/commonConstants';
import ButtonLink from '../ButtonLink';
import { FeedbackSection } from '../FeedbackSection';

import styles from './styles.module.css';

const FooterContainer = ({ showFooter, isAuthenticated }) =>
  showFooter ? (
    <footer role="contentinfo">
      <FeedbackSection />
      <div className={styles['footer-wrapper']}>
        <div className={styles['footer-wrapper__top']}>
          <div className={styles['footer-wrapper__top__left']}>
            {isAuthenticated ? USUARIO_GUB_UY : HEADER_ID_URUGUAY}
          </div>
          <div className={styles['footer-wrapper__top__right']}>
            <div className={styles['footer-wrapper__top__right__col']}>
              <ButtonLink
                to={CONTACTO_ROUTE}
                className={styles['footer-a']}
                buttonType="footer"
                title="Contacto"
              />
            </div>
            <div className={styles['footer-wrapper__top__right__col']}>
              <ButtonLink
                to={AYUDA_ROUTE}
                className={styles['footer-a']}
                buttonType="footer"
                title="Preguntas Frecuentes"
              />
            </div>
            <div className={styles['footer-wrapper__top__right__col']}>
              <ButtonLink
                to={ACERCA_DE_ROUTE}
                className={styles['footer-a']}
                buttonType="footer"
                title="Acerca de"
                external
                newTab
              />
            </div>
          </div>
        </div>
        <div className={styles['footer-wrapper__bottom']}>
          <div className={styles['footer-wrapper__bottom__right']}>
            {SITIO_OFICIAL_TEXTO}
          </div>
          <div className={styles['footer-wrapper__bottom__right__col']}>
            <ButtonLink
              to={TERMS_N_CONDS_ROUTE}
              className={styles['footer-a']}
              buttonType="footer"
              title="Términos de uso"
              external
              newTab
            />
          </div>
          <div className={styles['footer-wrapper__bottom__right__col']}>
            <ButtonLink
              to={PRIVACIDAD_ROUTE}
              className={styles['footer-a']}
              buttonType="footer"
              title="Política de privacidad"
              external
              newTab
            />
          </div>
        </div>
      </div>
    </footer>
  ) : null;

FooterContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  showFooter: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  showFooter: state.ui.footer.showFooter,
});

export default connect(mapStateToProps)(FooterContainer);
