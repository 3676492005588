import {
  FETCH_PAISES,
  CREATE_CIUDADANO,
  CLEAR_REGISTRO_ERRORS,
  RESET,
  RESEND_ACTIVATION,
  ACTIVATE_ACCOUNT,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  paises: [],
  ciudadanoCreated: null,
  registroErrors: null,
  creating: false,
  loading: false,
  resendErrors: null,
  resended: false,
  sending: false,
  activating: false,
  activationError: null,
  next: null,
  email: '',
};

// eslint-disable-next-line default-param-last
export default function RegistroReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PAISES.SUCCESS:
      return {
        ...state,
        paises: action.payload.data,
        loading: false,
      };

    case FETCH_PAISES.PENDING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PAISES.ERROR:
      return {
        ...state,
        paises: [],
        loading: false,
      };

    case CREATE_CIUDADANO.SUCCESS:
      return {
        ...state,
        ciudadanoCreated: true,
        creating: false,
        next: null,
      };

    case CREATE_CIUDADANO.PENDING:
      return {
        ...state,
        creating: true,
        next: null,
      };

    case CREATE_CIUDADANO.ERROR:
      return {
        ...state,
        ciudadanoCreated: false,
        registroErrors: action.payload,
        creating: false,
        next: action.payload.next ? action.payload.next : null,
      };

    case RESEND_ACTIVATION.SUCCESS:
      return {
        ...state,
        resendErrors: null,
        resended: true,
        sending: false,
        email: action.payload ? action.payload.data.email : '',
      };

    case RESEND_ACTIVATION.PENDING:
      return {
        ...state,
        resendErrors: null,
        resended: false,
        sending: true,
        email: '',
      };

    case RESEND_ACTIVATION.ERROR:
      return {
        ...state,
        resendErrors: action.payload,
        resended: false,
        sending: false,
        email: '',
      };
    case CLEAR_REGISTRO_ERRORS:
      return {
        ...state,
        resendErrors: null,
        registroErrors: null,
        resended: false,
        sending: false,
      };

    case ACTIVATE_ACCOUNT.PENDING:
      return {
        ...state,
        activating: true,
        activationError: null,
      };

    case ACTIVATE_ACCOUNT.SUCCESS:
      return {
        ...state,
        activating: false,
      };

    case ACTIVATE_ACCOUNT.ERROR:
      return {
        ...state,
        activating: false,
        activationError: action.payload,
      };

    case RESET:
      return {
        ...state,
        ciudadanoCreated: null,
        registroErrors: null,
        creating: false,
        activationError: null,
        activating: false,
      };
    // DEFAULT
    default:
      return state;
  }
}
