import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useWindowSize from 'hooks/useWindowSize';
import {
  ELIGE_DIA_HORA,
  ELIGE_DIA,
  ELIGE_HORA,
  ANCHO_TABLET,
  NO_HAY_CUPOS_DISPONIBLES,
  DURACION_ESTIMADA_VIDEOLLAMADA,
  ACLARACION_HORA_URUGUAY,
} from 'constants/commonConstants';
import TitleCard from 'components/TitleCard';
import Calendario from 'components/Calendario';
import SelectorHora from 'components/SelectorHora';
import ContextoAgenda from '../ContextoAgenda';
import styles from './PasoAgenda.module.css';

const PasoAgenda = ({
  fechasDisponibles,
  horasDisponibles,
  obtenerHorasPorFecha,
}) => {
  const [ancho] = useWindowSize();
  const esMovil = ancho < ANCHO_TABLET;
  const {
    setFechaSeleccionada,
    setDisponibilidadSeleccionada,
    fechaSeleccionada,
    disponibilidadSeleccionada,
    setHorasDisponibles,
  } = useContext(ContextoAgenda);
  const [isLoading, setIsLoading] = useState(false);

  const obtenerHorasDisponibles = async fecha => {
    if (fecha) {
      setIsLoading(true);
      const fechaFormateada = dayjs(fecha).format('YYYY-MM-DD');
      try {
        const response = await obtenerHorasPorFecha(fechaFormateada);
        setHorasDisponibles(
          response?.data?.map((cupo, index) => ({
            id: index,
            value: dayjs(`${fechaFormateada}T${cupo.hora_inicio}`),
          })),
        );
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  // Chequeo si es por cambio de fecha o por render
  const fechaSeleccionadaAnterior = useRef(fechaSeleccionada);
  useEffect(() => {
    const cambioFecha = fechaSeleccionadaAnterior.current !== fechaSeleccionada;
    if (fechaSeleccionada && (cambioFecha || !disponibilidadSeleccionada)) {
      obtenerHorasDisponibles(fechaSeleccionada);
      setDisponibilidadSeleccionada(null);
    }
  }, [fechaSeleccionada]);

  if (fechasDisponibles.length === 0) {
    return <p>{NO_HAY_CUPOS_DISPONIBLES}</p>;
  }

  return (
    <>
      <TitleCard
        title={esMovil ? ELIGE_DIA : ELIGE_DIA_HORA}
        className={styles.titulo}
      />
      <p className={styles.subtitulo}>{DURACION_ESTIMADA_VIDEOLLAMADA}</p>
      <p className={styles.aclaracionHora}>{ACLARACION_HORA_URUGUAY}</p>
      <div className={styles.fechaHora}>
        <Calendario
          fechasDisponibles={fechasDisponibles}
          setFechaSeleccionada={setFechaSeleccionada}
          fechaSeleccionada={fechaSeleccionada}
        />
        {esMovil && horasDisponibles.length > 0 && (
          <TitleCard
            title={ELIGE_HORA}
            className={`${styles.titulo} ${styles.eligeHora}`}
          />
        )}
        {horasDisponibles.length > 0 && (
          <SelectorHora
            loading={isLoading}
            periodos={horasDisponibles}
            onChange={setDisponibilidadSeleccionada}
            selected={
              disponibilidadSeleccionada
                ? { id: disponibilidadSeleccionada.id }
                : null
            }
          />
        )}
      </div>
    </>
  );
};

PasoAgenda.propTypes = {
  fechasDisponibles: PropTypes.arrayOf(PropTypes.object),
  horasDisponibles: PropTypes.arrayOf(PropTypes.object),
  obtenerHorasPorFecha: PropTypes.func,
};

export default PasoAgenda;
