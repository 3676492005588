import React from 'react';
import PropTypes from 'prop-types';

import { CONTACTO_ROUTE } from 'constants/RouterConstants';
import CardSmall from '../CardSmall';
import ButtonLink from '../ButtonLink';

import './styles.css';

const EnvioInstrucciones = ({
  title,
  desc,
  tip1,
  tip1Action,
  tip2,
  tip2Action,
  action1,
}) => (
  <CardSmall title={title} desc={desc}>
    <span className="caption instrucciones__desc-secondary">
      Puede encontrarse en los correos no deseados (SPAM).
    </span>
    <div className="instrucciones__divider"></div>
    <div className="instrucciones__tip-container">
      <span className="caption instrucciones__tip-container__tip">{tip1}</span>
      <ButtonLink
        title={tip1Action}
        className="caption instrucciones__tip-container__tip-action"
        type="button"
        onClick={action1}
        aria-label="Solicita un nuevo link"
      />
    </div>
    <div className="instrucciones__tip-container">
      <span className="caption instrucciones__tip-container__tip">{tip2}</span>
      <ButtonLink
        title={tip2Action}
        className="caption instrucciones__tip-container__tip-action"
        to={CONTACTO_ROUTE}
        aria-label="Comúnicate con nosotros"
      />
    </div>
  </CardSmall>
);

EnvioInstrucciones.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tip1: PropTypes.string,
  tip2: PropTypes.string,
  tip1Action: PropTypes.string,
  tip2Action: PropTypes.string,
  action1: PropTypes.func,
};

EnvioInstrucciones.defaultProps = {
  tip1: '¿No lo recibiste aún?',
  tip1Action: 'Solicitá uno nuevo »',
  tip2: '¿Estás teniendo problemas?',
  tip2Action: 'Comunicate con nosotros »',
};

export default EnvioInstrucciones;
