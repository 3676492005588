import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// componentes
import CardSpinner from 'components/CardSpinner';
import { CheckboxField } from 'components/RenderField (Nuevo)';

// react-phone-input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

// context
import ContextoAgenda from '../ContextoAgenda';

// imagenes
import imagenNumeroSerieCI from '../../../assets/numeroSerieCi.jpeg';

// constantes
import {
  IMAGEN_NUMERO_SERIE_CI,
  LARGO_NUMERO_SERIE_CI,
  TEXTO_ERROR_NUMERO_SERIE,
  TEXTO_ERROR_PRIMER_APELLIDO,
  TEXTO_ERROR_PEDIDO_FALLIDO,
  TERMINOS_CONDICIONES_GUARDAR_NUMERO_SERIE,
  TEXTO_COMPLETAR_DATOS,
  TEXTO_NUMERO_SERIE_DE_CEDULA,
  TEXTO_NUMERO_SERIE,
  TEXTO_NOMBRE_COMPLETO,
  TEXTO_PRESIONE_CONTINUAR,
  TEXTO_CASO_ERROR,
  TEXTO_NUMERO_TELEFONO,
  TEXTO_PLACEHOLDER_NUMERO_TELEFONO,
  TEXTO_PLACEHOLDER_NUMERO_SERIE,
  TEXTO_ERROR_MAYOR_DE_EDAD,
  TEXTO_NUMERO_TELEFONO_AVISO,
} from '../../../constants/commonConstants';

// styles
import styles from './DatosPersonales.module.css';

const DatosPersonales = ({
  pasosCompletos,
  setPasosCompletos,
  erroresDnic,
  setErroresDnic,
  setLlamarDnic,
  usuarioLogueado,
}) => {
  const {
    handleAceptaGuardarNumeroDeSerie,
    aceptaGuardarNumeroDeSerie,
    setDatosPersonales,
    datosPersonales,
  } = useContext(ContextoAgenda);

  const {
    register,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      numero_serie_ci: datosPersonales.numeroSerie,
      numero_telefono: datosPersonales.numeroTelefono,
    },
    mode: 'onChange',
  });

  const numeroSerie = watch('numero_serie_ci');
  const numeroTelefono = watch('numero_telefono');

  const numeroSerieRequisitos = {
    required: true,
    minLength: LARGO_NUMERO_SERIE_CI,
    maxLength: LARGO_NUMERO_SERIE_CI,
  };

  useEffect(() => {
    const copiaPasosCompletos = [...pasosCompletos];

    let checkeoNumeroSerie = erroresDnic.numeroSerie;
    let checkeoPrimerApellido = erroresDnic.primerApellido;
    let checkeoPedidoFallido = erroresDnic.pedidoFallido;
    let checkeoMayorDeEdad = erroresDnic.esMayorDeEdad;

    if (isDirty) {
      setErroresDnic({
        numeroSerie: false,
        primerApellido: false,
        pedidoFallido: false,
        esMayorDeEdad: false,
      });
      checkeoNumeroSerie = false;
      checkeoPrimerApellido = false;
      checkeoPedidoFallido = false;
      checkeoMayorDeEdad = false;

      setLlamarDnic(true);
    }

    copiaPasosCompletos[1] =
      isValid &&
      !checkeoNumeroSerie &&
      !checkeoPrimerApellido &&
      !checkeoPedidoFallido &&
      !checkeoMayorDeEdad &&
      (!window.REACT_APP_HABILITAR_NUMERO_TELEFONO_AGENDA_VIDEOLLAMADA ||
        numeroTelefono?.length > 4);

    setPasosCompletos(copiaPasosCompletos);
    setDatosPersonales({
      ...datosPersonales,
      numeroSerie,
      numeroTelefono,
    });
  }, [isValid, isDirty, numeroTelefono]);

  if (isEmpty(usuarioLogueado))
    return (
      <CardSpinner
        spin
        text="Cargando..."
        className="spinner__transparent__videollamada"
      />
    );

  const inputNumeroSerie = (
    <div className={styles.input_label_container}>
      <label
        className={
          erroresDnic.numeroSerie || erroresDnic.primerApellido
            ? styles.form_error_label
            : styles.form_label
        }
      >
        {TEXTO_NUMERO_SERIE_DE_CEDULA}
        <input
          className={
            erroresDnic.numeroSerie || erroresDnic.primerApellido
              ? styles.form_error_input
              : styles.form_input
          }
          {...register('numero_serie_ci', numeroSerieRequisitos)}
          placeholder={TEXTO_PLACEHOLDER_NUMERO_SERIE}
          maxLength={LARGO_NUMERO_SERIE_CI}
        />
      </label>
    </div>
  );

  const nombreCompleto = [
    usuarioLogueado?.primer_nombre,
    usuarioLogueado?.segundo_nombre,
    usuarioLogueado?.primer_apellido,
    usuarioLogueado?.segundo_apellido,
  ]
    .filter(elemento => typeof elemento === 'string')
    .join(' ');

  const inputNombreCompleto = (
    <div className={styles.input_label_container}>
      <label
        className={
          erroresDnic.primerApellido
            ? styles.form_error_label
            : styles.form_label
        }
      >
        {TEXTO_NOMBRE_COMPLETO}
        <input
          className={
            erroresDnic.primerApellido
              ? styles.form_error_input
              : styles.form_input
          }
          disabled
          value={nombreCompleto}
        />
      </label>
    </div>
  );

  const handleOnFocusNumeroTelefono = valor => {
    if (isEmpty(numeroTelefono) && valor.key !== 'Backspace') {
      setValue('numero_telefono', '+598');
    }
  };

  const handleOnBlurNumeroTelefono = () => {
    if (numeroTelefono.length <= 4) {
      setValue('numero_telefono', '');
    }
  };

  const inputNumeroTelefono = (
    <div
      className={
        usuarioLogueado?.numero_telefono
          ? styles.input_label_container
          : styles.input_label_container_numero_telefono_help_text
      }
    >
      <label className={styles.form_label}>
        {TEXTO_NUMERO_TELEFONO}
        <div className={styles.contenedorNumeroTelefonoDisabled}>
          <PhoneInput
            placeholder={TEXTO_PLACEHOLDER_NUMERO_TELEFONO}
            inputClass={styles.inputNumeroTelefono}
            country="uy"
            value={numeroTelefono}
            onChange={valor => setValue('numero_telefono', valor)}
            localization={es}
            enableLongNumbers
            disabled={usuarioLogueado?.numero_telefono}
            disableCountryCode={isEmpty(numeroTelefono)}
            onFocus={handleOnFocusNumeroTelefono}
            onBlur={handleOnBlurNumeroTelefono}
          />
          {!usuarioLogueado?.numero_telefono && (
            <p className={styles.help_text}>{TEXTO_NUMERO_TELEFONO_AVISO}</p>
          )}
        </div>
      </label>
    </div>
  );

  const terminosCondiciones = (
    <span className={styles.span_checkbox}>
      {TERMINOS_CONDICIONES_GUARDAR_NUMERO_SERIE}
    </span>
  );

  return (
    <form className={styles.form_container}>
      <p className={styles.form_p}>{TEXTO_COMPLETAR_DATOS}</p>
      {inputNombreCompleto}
      <div
        className={
          erroresDnic.numeroSerie &&
          erroresDnic.primerApellido &&
          erroresDnic.pedidoFallido
            ? styles.contenedorInputsError
            : styles.contenedorInputs
        }
      >
        {window.REACT_APP_HABILITAR_NUMERO_TELEFONO_AGENDA_VIDEOLLAMADA &&
          inputNumeroTelefono}
        {inputNumeroSerie}
        {erroresDnic.numeroSerie && (
          <p className={styles.error_message}>{TEXTO_ERROR_NUMERO_SERIE}</p>
        )}
        {erroresDnic.primerApellido && (
          <p className={styles.error_message}>{TEXTO_ERROR_PRIMER_APELLIDO}</p>
        )}
        {erroresDnic.pedidoFallido && (
          <p className={styles.error_message}>{TEXTO_ERROR_PEDIDO_FALLIDO}</p>
        )}
        {erroresDnic.esMayorDeEdad && (
          <p className={styles.error_message}>{TEXTO_ERROR_MAYOR_DE_EDAD}</p>
        )}
      </div>
      <div className={styles.numero_serie_container}>
        <div className={styles.texto_informativo_container}>
          <div className={styles.vector} />
          <div className={styles.contenedor_texto_vector}>
            <p className={styles.texto_informativo}>{TEXTO_NUMERO_SERIE}</p>
            <div className={styles.vector_recto} />
          </div>
        </div>
        <img
          className={styles.imagen_numero_serie}
          src={imagenNumeroSerieCI}
          alt={IMAGEN_NUMERO_SERIE_CI}
        />
        <CheckboxField
          input
          name="terminos"
          label={terminosCondiciones}
          onChange={handleAceptaGuardarNumeroDeSerie}
          error={errors.terminos}
          resetFieldError={() => {}}
          checked={aceptaGuardarNumeroDeSerie}
          className="guardar-numero-serie"
        />
      </div>
      <p className={styles.textoContinuar}>{TEXTO_PRESIONE_CONTINUAR}</p>
      <p className={styles.textoError}>{TEXTO_CASO_ERROR}</p>
    </form>
  );
};

DatosPersonales.propTypes = {
  pasosCompletos: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setPasosCompletos: PropTypes.func.isRequired,
  erroresDnic: PropTypes.object.isRequired,
  setErroresDnic: PropTypes.func.isRequired,
  setLlamarDnic: PropTypes.func.isRequired,
  usuarioLogueado: PropTypes.object,
};

export default DatosPersonales;
