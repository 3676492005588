/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  showExternalHeader,
  showInternalHeader,
  updateAriaLiveMessage,
} from 'actions/ui';
import { verifyUserPassword, clearPasswordErrors } from 'actions/seguridad';
import { AUTH_TITLE } from 'constants/pageTitles';
import AuthCambioContrasena from './AuthContrasena';

class AuthContraseñaContainerViejo extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      field: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleResetFieldError = this.handleResetFieldError.bind(this);
  }

  componentDidMount() {
    this.props.showExternalHeader();
    this.props.updateAriaLiveMessage(AUTH_TITLE);
  }

  componentWillUnmount() {
    this.props.showInternalHeader();
    this.props.clearPasswordErrors();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.verifiedPassword) {
      if (this.props.location.state) {
        if (this.props.location.state.prevPath) {
          this.props.history.push(this.props.location.state.prevPath);
        }
      }
    }
    if (nextProps.verifiedPasswordErrors) {
      this.setState({
        error: nextProps.verifiedPasswordErrors.error,
        field: nextProps.verifiedPasswordErrors.field,
      });
    }
  }

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------
  handleSubmit(values) {
    if (!this.props.loading) {
      this.props.verifyUserPassword(values.password);
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.props.history.replace(this.props.location.state.basePath);
  }

  handleResetFieldError() {
    if (this.state.error) {
      this.setState({
        error: null,
        field: null,
      });
    }
  }

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  render() {
    const title = 'Verificá tu identidad';
    const desc = 'Ingresá tu contraseña actual para continuar';
    return (
      <>
        <Helmet title={AUTH_TITLE} />
        <AuthCambioContrasena
          title={title}
          desc={desc}
          loading={this.props.loading}
          handleSubmit={this.handleSubmit}
          handleBack={this.handleBack}
          resetFieldError={this.handleResetFieldError}
          passwordErrors={this.state.error}
          field={this.state.field}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.seguridad.loading,
  startedPasswordChange: state.seguridad.startedPasswordChange,
  successfulPasswordChange: state.seguridad.successfulPasswordChange,
  authForPasswordChange: state.seguridad.authForPasswordChange,
  verifiedPassword: state.seguridad.verifiedPassword,
  verifiedPasswordErrors: state.seguridad.verifiedPasswordErrors,
});

export default connect(mapStateToProps, {
  showExternalHeader,
  showInternalHeader,
  verifyUserPassword,
  clearPasswordErrors,
  updateAriaLiveMessage,
})(AuthContraseñaContainerViejo);
