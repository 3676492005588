import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const CardSmall = ({ title, desc, children, className, style }) => (
  <div className={`small-card ${className}`} role="region" style={style}>
    {title ? (
      <div>
        <h2>{title}</h2>
        <div className="subheading">{desc}</div>
      </div>
    ) : null}
    {children}
  </div>
);

CardSmall.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CardSmall;
