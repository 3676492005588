import React from 'react';
import { connect } from 'react-redux';

import { showExternalHeader } from 'actions/ui';
import Landing from './landing';

class LandingContainer extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    this.props.showExternalHeader();
  }

  render() {
    return <Landing />;
  }
}

export default connect(null, {
  showExternalHeader,
})(LandingContainer);
