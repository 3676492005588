import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { history } from 'store/ConfigureStore';
import { seguridadCargandoSelector } from 'store/selectors';

import ButtonSection from 'components/ButtonSection';
import ButtonLink from 'components/ButtonLink';
import CardSmall from 'components/CardSmall';
import { showExternalHeader, updateAriaLiveMessage } from 'actions/ui';
import { approveEmailChange } from 'actions/seguridad';
import { CHANGE_EMAIL_APPROVE_TITLE } from 'constants/pageTitles';
import {
  VALIDA_CAMBIO_TEXT,
  HACIENDO_CLICK_TEXT,
  VALIDAR_CORREO_TEXT,
  ULTIMO_PASO_TEXT,
} from 'constants/commonConstants';

import styles from './CambioEmailApproveContainer.module.css';

const CambioEmailApproveContainer = () => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const cargando = useSelector(seguridadCargandoSelector);

  useEffect(() => {
    const { uuid, email } = queryString.parse(history.location.search);
    setState({ email: window.atob(email), uuid });
    dispatch(showExternalHeader());
    dispatch(updateAriaLiveMessage(CHANGE_EMAIL_APPROVE_TITLE));
  }, [history]);

  const handleClickAccept = () => {
    dispatch(approveEmailChange(state.uuid));
  };

  return (
    <>
      <Helmet title={CHANGE_EMAIL_APPROVE_TITLE} />
      <CardSmall title={ULTIMO_PASO_TEXT}>
        <p>
          {VALIDA_CAMBIO_TEXT}
          <ButtonLink
            to={`mailto:${state.email}`}
            title={state.email}
            external
            className={styles.botonLink}
          />
          {HACIENDO_CLICK_TEXT}
        </p>
        <ButtonSection
          idPrimary="change-email-submit-button"
          textPrimary={VALIDAR_CORREO_TEXT}
          handleClickPrimary={handleClickAccept}
          isLoadingPrimary={cargando}
        />
      </CardSmall>
    </>
  );
};

export default CambioEmailApproveContainer;
